import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { History } from 'history';
import Cookies from 'universal-cookie';
import type { AuthDispatch, Tuser } from '@interfaces/globals.types';
import { onLoginSuccess } from '@utils/login.utils';
import { NEST_SERVER_URL, SERVER_URL } from '@config/path';
import { RouteComponentProps } from 'react-router-dom';
import { removeCookie, setCookie } from '@config/cookies';

type LoginPayload = { id: string; pwd: string };

export const loginUser = async (
  dispatch: AuthDispatch,
  loginPayload: LoginPayload,
  history: RouteComponentProps['history'],
) => {
  const requestOptions: AxiosRequestConfig = {
    url: `${SERVER_URL}/auth/signin`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
    data: loginPayload,
  };

  try {
    const response = await axios(requestOptions);
    const { id } = JSON.parse(response.config.data);
    if (response.status === 200) {
      onLoginSuccess(response);
      dispatch({
        type: 'LOGIN_SUCCESS',
        user: id,
        loading: false,
      });
      return response.data;
    } else {
      dispatch({
        type: 'LOGIN_ERROR',
        errorMessage: response.data.error[0],
        loading: false,
      });
    }
    return;
  } catch (e) {
    dispatch({
      type: 'LOGIN_ERROR',
      errorMessage: String(e),
      loading: false,
    });
    alert('아이디나 비밀번호가 맞지 않습니다. 관리자에게 문의하세요.');
  }
};

export const refresh = async (
  dispatch: AuthDispatch,
  loginhandler: (login: boolean) => void,
  history: RouteComponentProps['history'],
) => {
  await axios
    .get(`${SERVER_URL}/auth/verifyrefreshtoken`, {
      headers: {
        'Content-Type': 'application/json',
        // cookies: `${refreshToken}`,
      },
      withCredentials: true,
    })
    .then(async (res) => {
      const { accessToken } = res.data;
      axios.defaults.headers.common['Authorization'] = accessToken;

      dispatch({
        type: 'LOGIN_SUCCESS',
        user: res.data.userId,
        loading: false,
      });

      setCookie('userClass', res.data.userClass);
      loginhandler(true);
    })
    .catch((err) => {
      logout(dispatch);
      removeCookie('userClass');
      loginhandler(false);
      history.push('/login');
    });
};

export async function logout(dispatch: AuthDispatch) {
  await axios
    .post(
      `${NEST_SERVER_URL}/auth/logout`,
      {},
      {
        withCredentials: true,
      },
    )
    .then((res) => {
      dispatch({ type: 'LOGOUT', user: '' });
      axios.defaults.headers.common['Authorization'] = '';
      removeCookie('userClass');
      return res.data;
    })
    .catch((err) => {
      console.log(err.response);
    });
}
export const getUser = async (): Promise<Tuser[]> => {
  const requestOptions: AxiosRequestConfig = {
    url: `${SERVER_URL}/user`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  };

  try {
    const response = await axios(requestOptions);
    if (response) {
      return response.data;
    }
    return [];
  } catch (e) {
    return [];
  }
};
