import { useState, useEffect } from 'react';
import useFetch from '@utils/customHook/useFetch';
import { SiteaddIn } from '@dummydata/dummy';
import { Site, PassProps } from '@interfaces/site.interface';
import { useAuthState } from '@config/context';
import SetAddworkInner from '@pages/productmanager/setaddwork-inner';
import SetAddworkPRInner from '@pages/productmanager/setaddwork-inner-pr';
import { useLocation } from 'react-router';
import { useLoading } from '@config/loadingContext';

export default function Setaddwork() {
  const { loadingNow, loadingHandler } = useLoading();
  const [GPmodalNow, setGPmodalNow] = useState(false);
  const GPmodalhandler = (boolean: boolean) => setGPmodalNow(boolean);
  const [PRmodalNow, setPRmodalNow] = useState(false);
  const PRmodalhandler = (boolean: boolean) => setPRmodalNow(boolean);
  const { payload, loading, error } = useFetch<Site>(`site`, loadingNow);
  const [selectedSite, setSelectedSite] = useState<Site>({
    ...SiteaddIn,
  });
  const [sellerNameEN, setSellerNameEN] = useState('');
  const location = useLocation<PassProps>();
  useEffect(() => {
    payload && fromProductList(payload.data);
  }, [payload]);
  const fromProductList = (payloadCopy: Site[]) => {
    location.state &&
      SelectEvents({
        ...payloadCopy[
          payloadCopy.findIndex(
            (a) => a.sellerNameEN === location.state.passProps,
          )
        ],
      });
  };
  const SelectEvents = (row: Site) => {
    setSelectedSite(row);
    setSellerNameEN(row.sellerNameEN);
    GPmodalhandler(true);
  };
  return (
    <>
      <div className="page-title">
        <div className="title_left">
          <h3>
            <i className="fa fa-cubes" />
            사이트 후가공 단가 <small>후가공관리</small>
          </h3>
        </div>
      </div>
      <div className="clearfix"></div>
      <div className="row">
        <div className="col-12">
          <div className="contents-wrap x_panel">
            <div className="x_content setETC-table-wrap table-wrap-green">
              <div className="react-bootstrap-table">
                <table className="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th>판매업체</th>
                      <th>사이트url</th>
                      <th>관리자</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payload &&
                      payload.data.map((code, idx) => {
                        return (
                          <tr key={idx}>
                            <td>
                              {code.sellerName} &lt;{code.topSeller}&gt;
                            </td>
                            <td>
                              <a
                                href={'https://' + code.siteUrl}
                                target="blink"
                              >
                                {code.siteUrl}
                              </a>
                            </td>

                            <td>
                              {code.adminName} &lt;{code.adminPosition}&gt;
                            </td>
                            <td>
                              <button
                                className="tableBtn tableBtn-green btnW-90"
                                onClick={() => SelectEvents(code)}
                              >
                                사이트 선택
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {GPmodalNow && (
        <SetAddworkInner
          selectedSite={selectedSite}
          sellerNameEN={sellerNameEN}
          GPmodalhandler={GPmodalhandler}
          PRmodalhandler={PRmodalhandler}
        />
      )}
      {PRmodalNow && (
        <SetAddworkPRInner
          selectedSite={selectedSite}
          sellerNameEN={sellerNameEN}
          GPmodalhandler={GPmodalhandler}
          PRmodalhandler={PRmodalhandler}
        />
      )}
    </>
  );
}
