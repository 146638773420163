import { Dispatch, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { SERVER_URL } from '@config/path';
import { FirstInput, ProductListType } from '@interfaces/tableCost.interfaces';
import { Site } from '@interfaces/site.interface';
import { basicConst } from '@dummydata/dummy';
import PreviewModal from '@components/modal/previewModal';
import ViewModal from '@components/modal/viewModal';
import { useAuthState, useSidbarContext } from '@config/context';
import CSS from 'csstype';
import { useLoading } from '@config/loadingContext';

interface Props {
  selectedSite: Site;
  productListNow: ProductListType[];
  setProductListNow: Dispatch<ProductListType[]>;
  setGPmodalNow: Dispatch<boolean>;
  filterNow: ProductListType[];
  setFilterNow: Dispatch<ProductListType[]>;
  UPList: string[];
  setUPList: Dispatch<string[]>;
}
export default function GetProductModal({
  selectedSite,
  productListNow,
  setProductListNow,
  setGPmodalNow,
  filterNow,
  setFilterNow,
  UPList,
  setUPList,
}: Props) {
  const { loadingNow, loadingHandler } = useLoading();
  /**페이지이동 state*/
  const history = useHistory();
  const { setNavOpen } = useSidbarContext();
  /**선택한 메인 state*/
  let basicCopy = JSON.parse(JSON.stringify(basicConst));
  let originCopy = JSON.parse(JSON.stringify(basicConst));
  const [firstInputs, setFirstInputs] = useState<FirstInput>({ ...basicCopy });
  /**변경시 백업 state*/
  const [originInputs, setOriginInputs] = useState<FirstInput>({
    ...originCopy,
  });
  const [types, setTypes] = useState<string[]>([
    'all',
    'priceComplete',
    'priceCalculation',
  ]);
  const [typeNow, setTypeNow] = useState<string>('all');
  const ChangeType = (type: string) => {
    let copy = [...productListNow];
    if (type === 'all') {
      setFilterNow(copy);
      let uniqueList = copy
        .map((ar: ProductListType) => {
          return ar.category[0].categoryName1st;
        })
        .filter(
          (thing: string, index: number, self: string[]) =>
            index === self.findIndex((t) => t === thing),
        );
      setUPList(uniqueList);
    } else {
      let FN = copy.filter((t) => t.typeOfPriceDetermintion === type);
      setFilterNow(FN);
      let uniqueList = FN.map((ar: ProductListType) => {
        return ar.category[0].categoryName1st;
      }).filter(
        (thing: string, index: number, self: string[]) =>
          index === self.findIndex((t) => t === thing),
      );
      setUPList(uniqueList);
    }
    setTypeNow(type);
    setNavNow('전체');
  };
  const [navNow, setNavNow] = useState('전체');
  const changeNav = (name: string) => {
    if (name === '전체') {
      setFilterNow(
        typeNow !== 'all'
          ? productListNow.filter((t) => t.typeOfPriceDetermintion === typeNow)
          : [...productListNow],
      );
    } else {
      let FN = productListNow.filter(
        (t) => t.category[0].categoryName1st === name,
      );
      setFilterNow(
        typeNow !== 'all'
          ? FN.filter((t) => t.typeOfPriceDetermintion === typeNow)
          : [...FN],
      );
    }
    setNavNow(name);
  };
  /**미리보기모달 state*/
  const [previewName, setPreviewName] = useState<string>('');
  const [preview, setPreview] = useState<boolean>(false); //프리뷰
  const previewhandler = (boolean: boolean) => setPreview(boolean);
  const PerviewEvents = (productID: string) => {
    setPreviewName(productID);
    loadingHandler(true);
    previewhandler(true);
  };

  /** 모달 state*/
  const [SPModal, setSPModal] = useState<boolean>(false);
  const changeSPModal = (bool: boolean) => {
    setSPModal(bool);
  };
  /**
   * 기준 상품 수정하는 함수
   * @param productNameEN
   */
  const EditEvents = async (productNameEN: string) => {
    loadingHandler(true);
    await axios
      .get(`${SERVER_URL}/product/${productNameEN}`)
      .then((res) => {
        setFirstInputs(res.data); //데이터 업데이트
        changeSPModal(true);
        console.log('오리지날 데이터받기');
        let Copy = { ...res.data };
        let NewCopy = JSON.parse(JSON.stringify(Copy));
        setPreviewName(productNameEN);
        setOriginInputs(NewCopy);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const checkGWItem = (NameEN: string) => {
    let copyFilter = [...filterNow];
    let IndexN = copyFilter.findIndex((a) => a.productNameEN === NameEN);
    copyFilter[IndexN].checkbox = !copyFilter[IndexN].checkbox;
    setFilterNow(copyFilter);
  };
  const passProps = selectedSite.sellerNameEN;
  const sendSelectProducts = async () => {
    let params = filterNow.filter((a) => a.checkbox === true);
    loadingHandler(true);
    let URLs = [];
    for (let i = 0; i < params.length; i++) {
      let targetN = params[i]._id;
      let toN = selectedSite.sellerNameEN;
      URLs[i] = `${SERVER_URL}/productbysite/send?target=${targetN}&to=${toN}`;
    }
    URLs.length > 0 &&
      (await axios
        .all(URLs.map((url) => axios.post(url)))
        .then((data) => {
          alert('가져가기 완료');
          loadingHandler(false);
          setGPmodalNow(false);
          setNavOpen('setprice');
          history.push({
            pathname: '/product-manager/setprice',
            state: { passProps: passProps },
          });
        })
        .catch((err) => {
          alert('가져가기 실패');
          loadingHandler(false);
          setGPmodalNow(false);
          setNavOpen('setprice');
          history.push({
            pathname: '/product-manager/setprice',
            state: { passProps: passProps },
          });
        }));
  };
  const sendSelectOne = async (ID: string) => {
    let toN = selectedSite.sellerNameEN;
    loadingHandler(true);
    await axios
      .post(`${SERVER_URL}/productbysite/send?target=${ID}&to=${toN}`)
      .then((res) => {
        loadingHandler(false);
        alert('가져가기 완료');
        let copy = filterNow.map((a) => {
          if (a._id === ID) {
            return (a = { ...a, siteId: true });
          } else {
            return a;
          }
        });
        setFilterNow(copy);
        let copy2 = productListNow.map((a) => {
          if (a._id === ID) {
            return (a = { ...a, siteId: true });
          } else {
            return a;
          }
        });
        setProductListNow(copy2);
      })
      .catch((err) => {
        loadingHandler(false);
      });
  };
  return (
    <>
      <div className="getProduct-window ">
        <div className="GW-bg"></div>
        <div className="GW-wrap">
          <div className="closeBtn" onClick={() => setGPmodalNow(false)}>
            <i className="fa fa-times"></i>
          </div>
          <div className="GW-title mb-3">
            <h4>기준 상품 가져오기</h4>
          </div>
          <div className="GW-topTypes">
            {types.map((type, idxT) => {
              return (
                <div
                  key={idxT}
                  className={`topType ${typeNow === type ? 'active' : ''}`}
                  onClick={() => ChangeType(type)}
                >
                  {type === 'all'
                    ? '전체'
                    : type === 'priceComplete'
                    ? '완성형'
                    : '계산형'}
                </div>
              );
            })}
          </div>
          <div className="GW-inner">
            <div className="GW-inner-left">
              <ul>
                <li
                  className={`GW-nav ${
                    navNow && navNow === '전체' ? 'active' : ''
                  }`}
                  onClick={() => changeNav('전체')}
                >
                  기준상품전체
                </li>
                {UPList &&
                  UPList.map((list, idx) => {
                    return (
                      <li
                        className={`GW-nav ${
                          navNow && navNow === list ? 'active' : ''
                        }`}
                        key={idx}
                        onClick={() => changeNav(list)}
                      >
                        {list}
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="GW-inner-right">
              <div className="GW-cont">
                <div className="GW-cont-head">
                  <ul>
                    <li>
                      <div className="GW-cont-itemIndex cont-w70">번호</div>
                      <div className="cont-w95">대분류</div>
                      <div className="cont-w70">중분류</div>
                      <div className="cont-w150">제품명</div>
                      <div className="cont-w150">제품명</div>
                      <div className="cont-wEX460">ACTION</div>
                      <div className="cont-w95">선택</div>
                    </li>
                  </ul>
                </div>
                <div className="GW-cont-body">
                  <ul>
                    {filterNow &&
                      filterNow.map((cont, index) => {
                        return (
                          <li
                            className={`GW-cont-item ${
                              cont.siteId ? 'sited' : ''
                            }`}
                            key={String(cont._id + index)}
                          >
                            <div className="GW-cont-itemIndex cont-w70">
                              {!cont.siteId ? (
                                <label htmlFor={'select' + index + 1}>
                                  <input
                                    id={'select' + index + 1}
                                    type="checkbox"
                                    onChange={() =>
                                      checkGWItem(cont.productNameEN)
                                    }
                                    checked={filterNow[index].checkbox === true}
                                  />
                                  <span>{index + 1}</span>
                                </label>
                              ) : (
                                <label
                                  className="nodrop"
                                  htmlFor={'select' + index + 1}
                                >
                                  <input
                                    id={'select' + index + 1}
                                    type="checkbox"
                                    checked={true}
                                    readOnly
                                  />
                                  <span>{index + 1}</span>
                                </label>
                              )}
                            </div>
                            <div className="GW-cont-itemCate01 cont-w95">
                              {cont.category[0].categoryName1st}
                            </div>
                            <div className="GW-cont-itemCate02 cont-w70">
                              {cont.category[0].categoryName2nd}
                            </div>
                            <div className="GW-cont-itemProduct cont-w150">
                              {cont.productNameKO}
                            </div>
                            <div className="GW-cont-itemProduct cont-w150">
                              {cont.productNameEN}
                            </div>
                            {/* <div className="GW-cont-itembtns cont-wEX460">
                              <div
                                className="GW-cont-btn GW-cont-btnPreview"
                                onClick={() => PerviewEvents(cont._id)}
                              >
                                <span className="mobileSpan">
                                  <i
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span className="deskSpan">
                                  주문화면 미리보기
                                </span>
                              </div>
                              <div
                                className="GW-cont-btn GW-cont-btnPrice"
                                onClick={() => EditEvents(cont.productNameEN)}
                              >
                                <span className="mobileSpan">
                                  <i
                                    className="fa fa-file-text-o"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span className="deskSpan">
                                  관리자등록 미리보기
                                </span>
                              </div>
                              <div className="GW-cont-btn GW-cont-btnList">
                                <span className="mobileSpan">
                                  <i
                                    className="fa fa-server"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span className="deskSpan">
                                  가져간 사이트목록
                                </span>
                              </div>
                              <div className="GW-cont-btn GW-cont-btnLog">
                                <span className="mobileSpan">
                                  <i
                                    className="fa fa-file-text-o"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span className="deskSpan">
                                  업데이트 이력보기
                                </span>
                              </div>
                            </div> */}
                            <div className="GW-cont-selectwrap cont-w95">
                              {!cont.siteId ? (
                                <div
                                  className="btn yBtn yBtn-lg purple btn-inline-block false"
                                  data-toggle="tooltip"
                                  onClick={() => sendSelectOne(cont._id)}
                                >
                                  <i className="fa fa-check"></i> 선택
                                </div>
                              ) : (
                                <div
                                  className="btn yBtn yBtn-lg gray btn-inline-block false nodrop"
                                  data-toggle="tooltip"
                                >
                                  <i className="fa fa-check"></i> 선택
                                </div>
                              )}
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="GW-btns mt-4">
            <div
              className="btn yBtn yBtn-xl purple btn-inline-block false"
              data-toggle="tooltip"
              onClick={() => sendSelectProducts()}
            >
              <i className="fa fa-check"></i> 기준상품 선택
            </div>
          </div>
        </div>
      </div>
      {SPModal && (
        <ViewModal
          firstInputs={firstInputs}
          setFirstInputs={setFirstInputs}
          originInputs={originInputs}
          setOriginInputs={setOriginInputs}
          changeSPModal={changeSPModal}
          previewhandler={previewhandler}
        />
      )}
      {preview && (
        <PreviewModal
          from="standardPreview"
          previewProduct={previewName}
          previewhandler={previewhandler}
        />
      )}
    </>
  );
}
