import {
  OVERLAY_STYLE,
  simpleList_STYLE,
  CLOSE_STYLE,
} from '@styles/modal.css';

interface IModalProps {
  close: (target: string) => void;
  children: JSX.Element;
  id: string;
}

export default function ModalEmpty(props: IModalProps) {
  const { close, id } = props;
  return (
    <>
      <div
        className={`modalY-overlay`}
        onClick={(e) => close(e.currentTarget.id)}
        id={id}
      ></div>
      <div className={`previewY preview-window`} style={simpleList_STYLE}>
        <div
          className="closeBtn"
          id={id}
          onClick={(e) => close(e.currentTarget.id)}
        >
          <i className="fa fa-times"></i>
        </div>
        <div>{props.children}</div>
      </div>
    </>
  );
}
