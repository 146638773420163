import axios from 'axios';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { SERVER_URL } from '@config/path';
import { useAuthDispatch, refresh, useAuthState } from '@config/reducer.index';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
import { useTimer } from 'react-timer-hook';
import { MESSAGE } from '@utils/textMessage';
import { getCookie } from '@config/cookies';

interface IProps {
  loginhandler: (login: boolean) => void;
}

export default function Timer({ loginhandler }: IProps) {
  let expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + 60 * 60 * 3); // 3시간
  const dispatch = useAuthDispatch();
  const history = useHistory();
  const { seconds, minutes, hours, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn('onExpire called'),
  });
  return (
    <>
      <div className="log_time_box">
        <div className="log_time_box_open">
          <div className="log_time_txt col-md-7">
            <p>로그인 유지 시간</p>
            <div>
              <span>{hours}</span>시간 <span>{minutes}</span>분
              <span> {seconds}</span>초
            </div>
          </div>
          <button
            className=""
            onClick={() => {
              refresh(dispatch, loginhandler, history);
              restart(expiryTimestamp);
            }}
          >
            시간 연장
          </button>
        </div>
        <button
          className="log_time_box_sm"
          onClick={() => refresh(dispatch, loginhandler, history)}
        >
          <i className="fa fa-clock-o" aria-hidden="true"></i>
          로그인 연장
        </button>
        <ul className="nav child_menu">
          <li>
            <div>
              <p>로그인 유지 시간</p>
              <span>{hours}</span>시간 <span>{minutes}</span>분
              <span> {seconds}</span>초
            </div>
          </li>
        </ul>
      </div>
    </>
  );
}
