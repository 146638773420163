import { Dispatch, useState, SetStateAction } from 'react';
import {
  Draggable,
  Droppable,
  DragDropContext,
  DragUpdate,
} from 'react-beautiful-dnd';
import { CateType, CateItemType } from '@interfaces/site.interface';
import { Site } from '@interfaces/site.interface';
// import ReorderWindow from './reorder/reorder-window';
type reOrderTYPE = {
  ID: string;
  NAME: string;
};
interface Props {
  modalType: string;
  modalIndex: number;
  allCate: CateType[];
  setAllCate: Dispatch<SetStateAction<CateType[]>>;
  changeModal: (type: string, bool: boolean) => void;
}

export default function CategoryReorder({
  modalType,
  modalIndex,
  allCate,
  setAllCate,
  changeModal,
}: Props) {
  let reorderReady: reOrderTYPE[] =
    modalType == 'mainOrder'
      ? allCate
          .map((codeA) => {
            return {
              ID: codeA._id ? String(codeA._id) : '',
              NAME: String(codeA.cateName),
            };
          })
          .filter(
            (thing: reOrderTYPE, index: number, self: reOrderTYPE[]) =>
              index === self.findIndex((t) => t.NAME === thing.NAME),
          )
      : modalType == 'subOrder'
      ? allCate
          .filter((code) => code.cateName == allCate[modalIndex].cateName)
          .map((codeA, idxA) => {
            let newNum = String(idxA + 1) + '. ';
            return {
              ID: codeA._id ? String(codeA._id) : '',
              NAME: String(
                codeA.subCateName !== ''
                  ? newNum + codeA.subCateName
                  : newNum + '미설정',
              ),
            };
          })
      : allCate[modalIndex].cateItems.map((codeA, idxA) => {
          return {
            ID: codeA._id ? String(codeA._id) : '',
            NAME: String(codeA.title),
          };
        });
  const [reOrderColumns, setReOrderColumns] =
    useState<reOrderTYPE[]>(reorderReady);
  const reOrderFinish = () => {
    if (modalType == 'mainOrder') {
      let result = reOrderColumns
        .map((codeA) => {
          return allCate.filter((codeB) => codeB.cateName == codeA.NAME);
        })
        .reduce(function (acc, cur) {
          return acc.concat(cur);
        });
      result && setAllCate(result);
    } else if (modalType == 'subOrder') {
      let temp = reOrderColumns
        .map((codeA) => {
          let findId = codeA.ID;
          return allCate.filter((code) => code._id == codeA.ID);
        })
        .reduce(function (acc, cur) {
          return acc.concat(cur);
        });
      let findIdx = allCate.findIndex(
        (code) => code.cateName == allCate[modalIndex].cateName,
      );
      let result = [...allCate];
      result.splice(findIdx, reOrderColumns.length, ...temp);
      result && setAllCate(result);
    } else {
      let result = [...allCate];
      let copy = reOrderColumns
        .map((codeA) => {
          return allCate[modalIndex].cateItems.filter(
            (codeB) => codeB.title == codeA.NAME,
          );
        })
        .reduce(function (acc, cur) {
          return acc.concat(cur);
        });
      result[modalIndex].cateItems = copy;
      result && setAllCate(result);
    }
    changeModal(modalType, false);
  };

  const onDragEnd = ({ source, destination }: DragUpdate) => {
    if (destination === undefined || destination === null) return null;
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    )
      return null;
    const newList = reOrderColumns.filter((_, idx) => idx !== source.index);
    newList.splice(destination.index, 0, reOrderColumns[source.index]);
    setReOrderColumns(newList);
    return null;
  };
  return (
    <>
      <div id="reorder-wrap">
        <div className="reorder-inner">
          <div className="reorder-cont">
            <h3>
              계산식 옵션
              <span> - 순서변경</span>
            </h3>
            {reOrderColumns && (
              <DragDropContext onDragEnd={onDragEnd}>
                <div className="ReorderStyledWrap mb-3">
                  <Droppable droppableId="reorderId" key="reorderkey">
                    {(provided) => (
                      <div className="StyledColumn">
                        <ul
                          className="StyledList"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {reOrderColumns.map((code, index) => (
                            <Draggable
                              key={code.NAME + code.ID}
                              draggableId={code.NAME + code.ID}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <li
                                  className={`StyledItem ${
                                    snapshot.isDragging ? 'dragging' : ''
                                  }
                                                            `}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div>{code.NAME}</div>
                                </li>
                              )}
                            </Draggable>
                          ))}

                          {provided.placeholder}
                        </ul>
                      </div>
                    )}
                  </Droppable>
                </div>
              </DragDropContext>
            )}
          </div>
          <div className="reorder-buttonWrap text-center">
            <div
              className="yBtn yBtn-xs gray me-2 d-inline-block"
              onClick={() => changeModal(modalType, false)}
            >
              이전으로
            </div>
            <div
              className="yBtn yBtn-md green text-center d-inline-block"
              onClick={() => reOrderFinish()}
            >
              선택완료
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
