import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLoading } from '@config/loadingContext';
import NumberFormat from 'react-number-format';
import { stringNumberFunc, roundNumberFunc } from '@utils/common';
import {
  NewAddwork,
  NewAddworkPrice,
  TableName,
} from '@interfaces/addwork.interface';
interface IProps {
  AddworkType: string;
  newItem: NewAddwork;
  setNewItem: Dispatch<SetStateAction<NewAddwork>>;
  newTable: TableName;
}
export default function SetAddWorkPrice({
  AddworkType,
  newItem,
  setNewItem,
  newTable,
}: IProps) {
  const { loadingHandler } = useLoading();
  const [idAll, setIdAll] = useState<string>('');
  const [positionN, setPositionN] = useState(false);
  const positionChange = () => setPositionN(!positionN);

  /**
   * 가격 handler
   * @param e input event
   * @param idxA price index
   * @param idxC price.value index
   */
  const handlePrice = (
    e: React.ChangeEvent<HTMLInputElement>,
    idxA: number,
    idxC: number,
  ) => {
    const { name, value } = e.target;
    let copyData = { ...newItem };
    let copyPrc = { ...copyData.price[idxA].value[idxC] };
    value !== '0' && value !== ''
      ? (copyPrc = { ...copyPrc, [name]: Number(value.replace(/,/g, '')) })
      : (copyPrc = { ...copyPrc, [name]: 0 });

    copyData.price[idxA].value[idxC] = copyPrc;
    copyData && setNewItem(copyData);
  };
  /** 복사 state */
  const [copiedArray, setCopiedArray] = useState<TableName[]>([]);
  useEffect(() => {
    copiedArray.length > 0 && console.log('카피데이터있음');
  }, [copiedArray]);
  /**
   * 복사 액션
   * @param n 복사된 index string
   */
  const priceCopy = async (n: string) => {
    loadingHandler(true);
    let indexCopy = Number(n.split('-')[1]);
    let copyData = JSON.parse(JSON.stringify(newItem.price[indexCopy].value));
    let noID = copyData.map((a: TableName) => {
      delete a._id;
      return a;
    });
    if (copyData) {
      setCopiedArray(noID);
      alert('데이터 복사성공!');
      loadingHandler(false);
    } else {
      alert('데이터 복사에 실패하였습니다. 다시 복사버튼을 눌러주세요.');
      loadingHandler(false);
    }
  };
  /**
   * 붙여넣기 액션
   * @param n 붙여넣을 index string
   * @constant newItem
   */
  const pricePaste = (n: string) => {
    loadingHandler(true);
    let indexCopy = Number(n.split('-')[1]);
    let copy = JSON.parse(JSON.stringify(newItem.price));
    copiedArray.length > 0
      ? ((copy[indexCopy].value = [...copiedArray]),
        setNewItem({
          ...newItem,
          price: copy,
        }))
      : alert('카피한 데이터가 없습니다.');

    loadingHandler(false);
    /**fake작업할때*/

    // let Fake: TableName[] = [];
    // let noID = Fake.map((a) => {
    //   delete a._id;
    //   return a;
    // });
    // copy[indexCopy].value = noID;
    // copiedArray &&
    //   copy &&
    //   setNewItem({
    //     ...newItem,
    //     price: copy,
    //   });

    /**/
  };
  /**
   * 리셋 액션
   * @param n 붙여넣을 index string
   * @constant newItem
   */
  const priceReset = (n: string) => {
    let indexCopy = Number(n.split('-')[1]);
    let copy = [...newItem.price];
    let copyData = copy[indexCopy].value.map((a) => {
      return JSON.parse(JSON.stringify(newTable));
    });
    copy[indexCopy].value = copyData;
    copiedArray &&
      setNewItem({
        ...newItem,
        price: JSON.parse(JSON.stringify(copy)),
      });
  };
  /**
   * 개별 줄추가 액션
   * @param line 추가될줄 index number
   */
  const addLine = (idxA: number, idxC: number) => {
    let instant = [...newItem.price];
    instant[idxA].value.splice(idxC, 0, { ...newTable });
    let NewCopy = JSON.parse(JSON.stringify(instant));
    setNewItem({ ...newItem, price: NewCopy });
  };
  /**
   * 개별 줄제거 액션
   * @param line 제거될줄 index number
   */
  const delLine = (idxA: number, idxC: number) => {
    let instant = [...newItem.price];
    instant[idxA].value.splice(idxC, 1);
    let NewCopy = JSON.parse(JSON.stringify(instant));
    instant[idxA].value.length > 0
      ? setNewItem({ ...newItem, price: NewCopy })
      : alert('최소 1줄은 필요합니다.');
  };
  /**
   * 가격인풋에서의 키보드 액션
   * @param id 이동기준 index string
   * @param code 누른 키보드 key
   */
  const keyPressNow = (
    event: React.KeyboardEvent<HTMLElement>,
    id: string,
    code: string,
  ) => {
    let idB = Number(id.split('-')[1]);
    let idC = Number(id.split('-')[2]);
    let idD = Number(id.split('-')[3]);
    let lengthB = newItem.price.length - 1;
    let lengthC = newItem.price[idB].value.length - 1;
    let lengthD =
      Object.keys(newItem.price[idB].value[idC]).filter((a) => a !== '_id')
        .length - 1;
    if (!event.shiftKey) {
      if (code === 'ArrowRight') {
        if (idB < lengthB) {
          if (idD < lengthD) {
            idD++;
          } else {
            idB++;
            idD = 0;
          }
        } else {
          if (idD < lengthD) {
            idD++;
          } else {
            alert('마지막 가격표입니다.');
          }
        }
      } else if (
        code === 'NumpadEnter' ||
        code === 'Enter' ||
        code === 'ArrowDown'
      ) {
        if (idC < lengthC) {
          idC++;
        } else {
          alert('최하단입니다.');
          return;
        }
      } else if (code === 'ArrowUp') {
        if (idC > 0) {
          idC--;
        } else {
          alert('최상단입니다.');
          return;
        }
      } else if (code === 'ArrowLeft') {
        if (idB > 0) {
          if (idD !== 0) {
            idD--;
          } else {
            idB--;
            idD = lengthD;
          }
        } else {
          if (idD !== 0) {
            idD--;
          } else {
            alert('첫 가격표입니다.');
          }
        }
      }
      setIdAll('input-' + idB + '-' + idC + '-' + idD);
    }
  };
  /**항목명 변경 Function */
  const KeyChanger = (str: string) => {
    let result =
      str == 'max'
        ? '최대'
        : str === 'min'
        ? '최소'
        : str === 'qty'
        ? '수량'
        : str === 'value'
        ? '데이터'
        : str === 'calcValue'
        ? '수정데이터'
        : str === 'overValue'
        ? '추가데이터'
        : str;
    return result;
  };
  useEffect(() => document.getElementById(idAll)?.focus(), [idAll]);
  let NewTableKeys =
    AddworkType == 'new'
      ? Object.keys(newItem.price[0].value[0]).filter(
          (filt) => filt !== '_id' && filt !== 'calcValue',
        )
      : Object.keys(newItem.price[0].value[0]).filter((filt) => filt !== '_id');
  return (
    <>
      <div className="contents-wrap x_panel mb-3">
        <div className="x_content">
          <div className="form-horizontal form-label-left">
            <div className="newoption-wrap form-group">
              <div className="row">
                <div className="newoption-title cost-title col-md-2 col-sm-2 col-xs-12">
                  가격표
                </div>
                <div className="newoption-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                  {positionN && <div className="position-bg"></div>}
                  <div
                    className={`newoption-price-table position-wrap ${
                      positionN ? 'fixed' : ''
                    }`}
                  >
                    <div
                      className="table-position-btn"
                      onClick={() => positionChange()}
                    >
                      <i
                        className={
                          positionN ? 'fa fa-compress' : 'fa fa-expand'
                        }
                      ></i>
                    </div>
                    <div className="price-table-wrap">
                      <div className="pr-block-outer">
                        {newItem &&
                          newItem.price.map((codeA, idxA) => {
                            return (
                              <div
                                key={'table-' + idxA}
                                className="d-inline-block pr-table-wrap"
                              >
                                <table className="table table-striped yTable">
                                  <thead>
                                    {codeA.code && (
                                      <tr>
                                        <th
                                          colSpan={
                                            AddworkType === 'new' ||
                                            AddworkType === 'edit'
                                              ? NewTableKeys.filter(
                                                  (code) =>
                                                    code !== 'calcValue',
                                                ).length + 1
                                              : NewTableKeys.length + 1
                                          }
                                          className="blueHead"
                                        >
                                          {codeA.code
                                            .map((aa) => {
                                              let NameNow = newItem.selecters
                                                .find((bb) =>
                                                  bb.select.some(
                                                    (c) =>
                                                      c.code.toUpperCase() ==
                                                      aa.toUpperCase(),
                                                  ),
                                                )
                                                ?.select.find(
                                                  (cc) =>
                                                    cc.code.toUpperCase() ==
                                                    aa.toUpperCase(),
                                                )?.title;
                                              return NameNow;
                                            })
                                            .map((dd, idx) => {
                                              return (
                                                <span key={'span-' + dd + idx}>
                                                  {dd}
                                                </span>
                                              );
                                            })}
                                        </th>
                                      </tr>
                                    )}
                                    <tr>
                                      {AddworkType !== 'new' &&
                                      AddworkType !== 'edit'
                                        ? NewTableKeys.map((aa, idx) => {
                                            return (
                                              <th key={'span-' + aa + idx}>
                                                {KeyChanger(aa)}
                                              </th>
                                            );
                                          })
                                        : NewTableKeys.filter(
                                            (codeB) => codeB !== 'calcValue',
                                          ).map((bb, idx) => {
                                            return (
                                              <th key={'span-' + bb + idx}>
                                                {KeyChanger(bb)}
                                              </th>
                                            );
                                          })}
                                      <th>줄관리</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {codeA.value &&
                                      codeA.value.map((codeC, idxC) => {
                                        let NewTableEntry =
                                          AddworkType === 'new' ||
                                          AddworkType === 'edit'
                                            ? Object.entries(codeC).filter(
                                                (filt) =>
                                                  filt[0] !== '_id' &&
                                                  filt[0] !== 'calcValue',
                                              )
                                            : Object.entries(codeC).filter(
                                                (filt) => filt[0] !== '_id',
                                              );
                                        return (
                                          <tr key={idxA + '-' + idxC}>
                                            {NewTableEntry.map(
                                              (codeD, idxD) => {
                                                if (
                                                  AddworkType === 'new' ||
                                                  AddworkType === 'edit' ||
                                                  codeD[0] === 'calcValue'
                                                ) {
                                                  return (
                                                    <td key={'td-' + idxD}>
                                                      <NumberFormat
                                                        id={
                                                          'input-' +
                                                          idxA +
                                                          '-' +
                                                          idxC +
                                                          '-' +
                                                          idxD
                                                        }
                                                        className="val-input"
                                                        type="text"
                                                        name={codeD[0]}
                                                        value={
                                                          codeD[1] !== 0
                                                            ? codeD[1]
                                                            : ''
                                                        }
                                                        onChange={(
                                                          e: React.ChangeEvent<HTMLInputElement>,
                                                        ) =>
                                                          handlePrice(
                                                            e,
                                                            idxA,
                                                            idxC,
                                                          )
                                                        }
                                                        autoFocus={true}
                                                        autoComplete="off"
                                                        thousandSeparator
                                                        onKeyDown={(
                                                          event: React.KeyboardEvent<HTMLElement>,
                                                        ) => {
                                                          return keyPressNow(
                                                            event,
                                                            (
                                                              event.target as HTMLElement
                                                            ).id,
                                                            event.code,
                                                          );
                                                        }}
                                                      />
                                                    </td>
                                                  );
                                                } else {
                                                  return (
                                                    <td key={'td-' + idxD}>
                                                      <NumberFormat
                                                        id={
                                                          'input-' +
                                                          idxA +
                                                          '-' +
                                                          idxC +
                                                          '-' +
                                                          idxD
                                                        }
                                                        className="val-input disabled"
                                                        type="text"
                                                        name={codeD[0]}
                                                        value={
                                                          codeD[1] !== 0
                                                            ? codeD[1]
                                                            : ''
                                                        }
                                                        autoComplete="off"
                                                        thousandSeparator
                                                        disabled
                                                      />
                                                    </td>
                                                  );
                                                }
                                              },
                                            )}
                                            <td>
                                              <div
                                                className="line-tools line-tool-plus"
                                                id={`plus-${idxC}`}
                                                onClick={() =>
                                                  addLine(idxA, idxC)
                                                }
                                              >
                                                <i className="fa fa-plus"></i>
                                              </div>
                                              <div
                                                className="line-tools line-tool-minus"
                                                id={`minus-${idxC}`}
                                                onClick={() =>
                                                  delLine(idxA, idxC)
                                                }
                                              >
                                                <i className="fa fa-minus"></i>
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    <tr>
                                      <td
                                        colSpan={NewTableKeys.length + 1}
                                        className="price-tools-wrap"
                                      >
                                        <div
                                          className="price-tools"
                                          id={'copyBtn-' + String(idxA)}
                                          onClick={(
                                            event: React.MouseEvent<HTMLElement>,
                                          ) =>
                                            priceCopy(
                                              (event.target as HTMLElement).id,
                                            )
                                          }
                                        >
                                          <i className="fa fa-clone"></i> 복사
                                        </div>
                                        <div
                                          id={'pasteBtn-' + String(idxA)}
                                          className="price-tools"
                                          onClick={(
                                            event: React.MouseEvent<HTMLElement>,
                                          ) =>
                                            pricePaste(
                                              (event.target as HTMLElement).id,
                                            )
                                          }
                                        >
                                          <i className="fa fa-paste"></i>{' '}
                                          붙여넣기
                                        </div>
                                        <div
                                          id={'resetBtn-' + String(idxA)}
                                          className="price-tools"
                                          onClick={(
                                            event: React.MouseEvent<HTMLElement>,
                                          ) =>
                                            priceReset(
                                              (event.target as HTMLElement).id,
                                            )
                                          }
                                        >
                                          <i className="fa fa-undo"></i> 초기화
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    {positionN && (
                      <div className="floating-Btn pull-right">
                        <div
                          className="btn yBtn yBtn-small grey btn-inline-block ms-2"
                          data-toggle="tooltip"
                          onClick={() => positionChange()}
                        >
                          닫기
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
