import React, {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useRef,
} from 'react';
import useFetch from '@utils/customHook/useFetch';
import { SERVER_URL } from '@config/path';
import axios, { AxiosRequestConfig } from 'axios';
import { useLoading } from '@config/loadingContext';
import { ProductListType, Data } from '@interfaces/tableCost.interfaces';
import AddWorkSelect from '@components/modal/addworkComp/addwork-select';
import AddWorkPrice from '@components/modal/addworkComp/addwork-price';
import { AddworkDummy } from '@dummydata/dummy';
import {
  NewAddwork,
  NewAddworkSelecters,
  TableName,
} from '@interfaces/addwork.interface';
import { YAddworkDummy } from '@dummydata/yunAddworkDummy';
import { deleteEverything } from '@utils/common';
import { Tuser } from '@interfaces/globals.types';
interface IProps {
  userType: string;
  userItem: Tuser;
  setUserItem: Dispatch<SetStateAction<Tuser>>;
  UseUserAPI: (kind: 'new' | 'del' | 'edit', item: Tuser) => Promise<void>;
  closeModal: (target: string) => void;
}
export default function UserModal({
  userType,
  userItem,
  setUserItem,
  UseUserAPI,
  closeModal,
}: IProps) {
  const { loadingNow, loadingHandler } = useLoading();

  const ChangeNewOpt = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserItem((prev) => {
      return { ...prev, [name]: value };
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="my-4">
            <div className="newitem-bg contents-wrap x_panel">
              <div className="x_content">
                <div className="form-horizontal form-label-left">
                  <div className="newoption-wrap form-group">
                    <div className="row">
                      <div className="newoption-title cost-title col-md-2 col-sm-2 col-xs-12">
                        후가공 종류
                      </div>
                      <div className="newoption-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                        <input
                          type="text"
                          className="form-control halfinput"
                          name="userId"
                          value={userItem.userId}
                          onChange={(e) => {
                            ChangeNewOpt(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="newoption-wrap form-group">
                    <div className="row">
                      <div className="newoption-title cost-title col-md-2 col-sm-2 col-xs-12">
                        후가공 종류
                      </div>
                      <div className="newoption-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                        <input
                          type="text"
                          className="form-control halfinput"
                          name="userId"
                          value={userItem.userId}
                          onChange={(e) => {
                            ChangeNewOpt(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="newoption-wrap form-group">
                    <div className="row">
                      <div className="newoption-title cost-title col-md-2 col-sm-2 col-xs-12">
                        후가공 종류
                      </div>
                      <div className="newoption-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                        <input
                          type="text"
                          className="form-control halfinput"
                          name="userId"
                          value={userItem.userId}
                          onChange={(e) => {
                            ChangeNewOpt(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="newoption-wrap form-group">
                    <div className="row">
                      <div className="newoption-title cost-title col-md-2 col-sm-2 col-xs-12">
                        후가공 종류
                      </div>
                      <div className="newoption-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                        <input
                          type="text"
                          className="form-control halfinput"
                          name="userId"
                          value={userItem.userId}
                          onChange={(e) => {
                            ChangeNewOpt(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="row  py-5">
            {userType === 'userEdit' && (
              <div className="col-sm-12 text-center complete-btn-wrap">
                <div
                  className="btn yBtn yBtn-small green btn btn-inline-block ms-2"
                  data-toggle="tooltip"
                  onClick={() => UseUserAPI('edit', userItem)}
                >
                  임시 저장
                </div>
              </div>
            )}
            <div className="col-sm-12  text-center complete-btn-wrap my-4">
              <div
                className="price-start-btn btn btn-inline-block"
                data-toggle="tooltip"
                onClick={() => {
                  UseUserAPI(userType === 'userNew' ? 'new' : 'edit', userItem);
                }}
              >
                <span>
                  <i className="fa fa-check me-1" aria-hidden="true"></i>
                  {userType === 'userNew' ? '상품 생성' : '저장 후 닫기'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
