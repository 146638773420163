import Cookies, { CookieSetOptions } from 'universal-cookie';

const cookies = new Cookies();
const defaultOpt = {
  path: '/',
  overwrite: true,
};
export const setCookie = (
  name: string,
  value: string | number,
  option?: CookieSetOptions,
) => {
  return cookies.set(name, value, { ...defaultOpt, ...option });
};
export const getCookie = (name: string) => {
  return cookies.get(name);
};
export const getAllCookie = () => {
  return cookies.getAll();
};
export const removeCookie = (name: string, option?: CookieSetOptions) => {
  return cookies.remove(name, { ...defaultOpt, ...option });
};
