import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { Range, getTrackBackground } from 'react-range';

interface Props {
  setTimeStamp: Dispatch<SetStateAction<number[]>>;
  toTimestamp: (values: number[]) => number[];
  timeFormat: (val: string) => string;
}

export default function SliderTotal({
  setTimeStamp,
  toTimestamp,
  timeFormat,
}: Props) {
  const STEP = 0.5;
  const MIN = 0;
  const MAX = 24;
  const [values, setValues] = useState([13, 19]);
  const handleTime = (values: number[]) => {
    setValues(values);
    let makeStamp = toTimestamp(values);
    setTimeStamp(makeStamp);
  };
  useEffect(() => {
    handleTime(values);
  }, []);
  return (
    <>
      <Range
        values={values}
        step={STEP}
        min={MIN}
        max={MAX}
        onChange={(values) => handleTime(values)}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '36px',
              display: 'flex',
              width: '100%',
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: '5px',
                width: '100%',
                borderRadius: '4px',
                background: getTrackBackground({
                  values,
                  colors: ['#548BF4', '#ccc', '#548BF4'],
                  min: MIN,
                  max: MAX,
                }),
                alignSelf: 'center',
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ index, props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '30px',
              width: '30px',
              borderRadius: '100%',
              backgroundColor: '#FFF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 2px 6px #AAA',
            }}
          >
            <div
              style={{
                position: 'absolute',
                width: '80px',
                top: '-25px',
                color: '#fff',
                fontWeight: 'bold',
                fontSize: '12px',
                lineHeight: '12px',
                padding: '4px',
                borderRadius: '4px',
                backgroundColor: 'rgba(0,0,0,0.4)',
              }}
            >
              {timeFormat(values[index].toFixed(1))}
            </div>
            <div
              style={{
                borderRadius: '100%',
                height: '8px',
                width: '8px',
                backgroundColor: isDragged ? '#548BF4' : '#CCC',
              }}
            />
          </div>
        )}
      />
    </>
  );
}
