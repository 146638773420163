import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import CSS from 'csstype';
import axios from 'axios';
import { SERVER_URL } from '@config/path';
import {
  FirstInput,
  noticeSelectType,
  noticeListType,
} from '@interfaces/tableCost.interfaces';
import S3ImageLoader from '@components/s3ImageLoader';
import { S3 } from 'aws-sdk';
import { useLoading } from '@config/loadingContext';
import { noticeSelectDummy } from '@dummydata/dummy';
import NoticeIcon from '@components/icon/noticeIcon';
import Texteditor from './texteditor';
import DOMPurify from 'dompurify';

interface Props {
  siteName: string;
  firstInputs: FirstInput;
  setFirstInputs: Dispatch<SetStateAction<FirstInput>>;
  closeModal: () => void;
}
export default function NoticeModal({
  siteName,
  firstInputs,
  setFirstInputs,
  closeModal,
}: Props) {
  const { loadingNow, loadingHandler } = useLoading();

  //경고창 공지사항

  const changeNOTICEtype = (type: string, str: string) => {
    if (str === 'new') {
      type === 'CONFIRM'
        ? setNOTICESelect({
            ...NOTICESelect,
            code: codeNow,
            nextStepBlock: false,
            noticenumber: 0,
            quantity: 0,
            noticeType: type,
            description: '',
          })
        : setNOTICESelect({
            ...NOTICESelect,
            noticeType: type,
            description: '',
          });
    } else {
      type === 'CONFIRM'
        ? setEditModalArr({
            ...EditModalArr,
            code: codeNow,
            nextStepBlock: false,
            noticenumber: 0,
            quantity: 0,
            noticeType: type,
            description: '',
          })
        : setEditModalArr({
            ...EditModalArr,
            noticeType: type,
            description: '',
          });
    }
  };
  const [NoticeList, setNoticeList] = useState<noticeListType[]>([]);
  const [imgUploadUrlData, setUploadUrlData] =
    useState<S3.ManagedUpload.SendData>();

  const getImgUrl = (
    element: S3.ManagedUpload.SendData,
    TypeFinal: string | undefined,
  ) => {
    setUploadUrlData(element);
    TypeFinal === 'new'
      ? setNOTICESelect({ ...NOTICESelect, description: element.Location })
      : setEditModalArr({
          ...EditModalArr,
          description: element.Location,
        });
  };

  useEffect(() => {
    const getnoticeList = async () => {
      let productBysiteIdNow = String(firstInputs._id);
      await axios
        .get(
          SERVER_URL +
            `/productbysite/${productBysiteIdNow}?fields=constraintId`,
        )
        .then((response) => {
          setNoticeList(response.data.data.constraintId);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getnoticeList();
  }, []);

  let codeNow = firstInputs.selecters.map((code) => {
    return '';
  });

  const [NOTICESelect, setNOTICESelect] = useState<noticeSelectType>({
    ...noticeSelectDummy,
    code: codeNow,
  });

  /* 제한사항 */
  const limited: { from: string; to: string }[] = firstInputs.selecters
    .map((a) =>
      a.types
        .map((b) =>
          b.class.map((c) => {
            return { from: b.code, to: c };
          }),
        )
        .flat(),
    )
    .flat();
  /**
   * 옵션명 onchange 액션
   * @param index selecter index
   * @constant firstInput
   */
  const handleChange = (str: string, coden: string, idx: number) => {
    let possiblelyLimited = limited.filter((a) =>
      str === 'new'
        ? NOTICESelect.code.some((b) => b.includes(a.from) || b.includes(a.to))
        : EditModalArr.code.some((b) => b.includes(a.from) || b.includes(a.to)),
    );
    let codeCopy =
      str === 'new' ? [...NOTICESelect.code] : [...EditModalArr.code];
    if (codeCopy[idx] == coden) {
      codeCopy[idx] = '';
    } else {
      if (possiblelyLimited.length > 0) {
        let finalLimited = possiblelyLimited.filter(
          (a) => a.from.includes(coden) || a.to.includes(coden),
        );
        if (finalLimited.length > 0) {
          alert(
            finalLimited
              .map((code) => {
                return (
                  code.from +
                  '는(은) ' +
                  code.to +
                  '와 함께 선택될 수 없습니다.'
                );
              })
              .join('\n'),
          );
          return;
        }
      }
      codeCopy[idx] = coden;
    }
    str === 'new'
      ? setNOTICESelect({ ...NOTICESelect, code: codeCopy })
      : setEditModalArr({ ...EditModalArr, code: codeCopy });
  };

  const handleETC = (
    str: string,
    name: string,
    value: string | number | boolean,
  ) => {
    if (str === 'new') {
      if (name == 'quantity') {
        let qVal = NOTICESelect.quantity == Number(value) ? 0 : Number(value);
        setNOTICESelect({ ...NOTICESelect, quantity: qVal });
      } else {
        setNOTICESelect({ ...NOTICESelect, [name]: value });
      }
    } else {
      if (name == 'quantity') {
        let qVal = EditModalArr.quantity == Number(value) ? 0 : Number(value);
        setEditModalArr({ ...EditModalArr, quantity: qVal });
      } else {
        setEditModalArr({ ...EditModalArr, [name]: value });
      }
    }
  };

  /**
   * 저장, 완료 Action
   * @constant firstInputs
   * @constant priceStep
   */
  const saveNow = async () => {
    loadingHandler(true);
    if (NOTICESelect.noticeType === 'TEXT' && NOTICESelect.description === '') {
      alert('주의사항을 입력해주세요.');
      return;
    } else if (
      NOTICESelect.noticeType === 'IMG' &&
      NOTICESelect.description === ''
    ) {
      alert('주의사항 이미지를 업로드해주세요.');
      return;
    } else if (
      NOTICESelect.noticeType === 'CONFIRM' &&
      NOTICESelect.description === ''
    ) {
      alert('전체 주의사항을 입력해주세요.');
      return;
    } else {
      let productIdNow = String(firstInputs._id);
      let makeconstraint = [
        ...NOTICESelect.code,
        NOTICESelect.quantity + '-qty',
        NOTICESelect.noticenumber + '-number',
      ].filter(
        (a) =>
          a !== '' &&
          a !== '0-qty' &&
          a !== '-qty' &&
          a !== '0-number' &&
          a !== '-number',
      );

      let noticeCopy = {
        constraint: makeconstraint,
        description: NOTICESelect.description,
        nextStepBlock: NOTICESelect.nextStepBlock,
        noticeType: NOTICESelect.noticeType,
      };

      await axios
        .post(SERVER_URL + '/notice', {
          ...noticeCopy,
          productId: productIdNow,
        })
        .then((response) => {
          setNoticeList([...NoticeList, response.data.data]);
          alert('성공');
          loadingHandler(false);
        })
        .catch(function (error) {
          console.log(error);
          alert('주의사항 등록 실패');
          loadingHandler(false);
        });
    }
  };
  const fixNotice = async (indexA: number) => {
    if (EditModalArr.noticeType === 'TEXT' && EditModalArr.description === '') {
      alert('주의사항을 입력해주세요.');
      return;
    } else if (
      EditModalArr.noticeType === 'IMG' &&
      EditModalArr.description === ''
    ) {
      alert('주의사항 이미지를 업로드해주세요.');
      return;
    } else if (
      EditModalArr.noticeType === 'CONFIRM' &&
      EditModalArr.description === ''
    ) {
      alert('전체 주의사항을 입력해주세요.');
      return;
    } else {
      let ni = NoticeList[indexA]._id;
      let makeconstraint = [
        ...EditModalArr.code,
        EditModalArr.quantity + '-qty',
        EditModalArr.noticenumber + '-number',
      ].filter(
        (a) =>
          a !== '' &&
          a !== '0-qty' &&
          a !== '-qty' &&
          a !== '0-number' &&
          a !== '-number',
      );

      let modalcopy = {
        ...EditModalArr,
        constraint: makeconstraint,
      };

      let noticefixcopy = [...NoticeList];
      noticefixcopy[indexA] = modalcopy;

      await axios
        .put(`${SERVER_URL}/notice/${ni}`, modalcopy)
        .then(function (response) {
          setNoticeList(noticefixcopy);
          changeEditModal(false);
          loadingHandler(false);
        })
        .catch(function (error) {
          loadingHandler(false);
        });
    }
  };

  const TotalNoticeHandler = async (indexA: number) => {
    let ni = NoticeList[indexA]._id;
    let turnNotice = !NoticeList[indexA].isUse;
    await axios
      .put(SERVER_URL + `/notice/${ni}/noticeOnOff`, { isUse: turnNotice })
      .then(function (response) {
        let onoffnotice = [...NoticeList];
        onoffnotice[indexA] = response.data.data;
        setNoticeList(onoffnotice);
      })
      .catch(function (error) {
        console.log('온오프 설정 실패');
      });
  };
  const noticeChange = (
    e: { target: { name: string; value: string } },
    str: string,
  ) => {
    const { name, value } = e.target;

    str === 'new'
      ? setNOTICESelect({ ...NOTICESelect, [name]: value })
      : setEditModalArr({ ...EditModalArr, [name]: value });
  };

  const noticeDelete = async (indexA: number) => {
    loadingHandler(true);
    let pi = firstInputs._id;
    let ni = NoticeList[indexA]._id;
    let delnotice = NoticeList.filter((a, i) => i !== indexA);

    await axios
      .delete(`${SERVER_URL}/notice/${ni}?productId=${pi}`)
      .then(function (response) {
        setNoticeList(delnotice);
        alert('삭제 성공');
        loadingHandler(false);
      })
      .catch(function (error) {
        console.log('error', error);
        alert('삭제 실패');
        loadingHandler(false);
      });
  };

  const [EditModal, setEditModal] = useState(false);
  const changeEditModal = (bool: boolean) => {
    setEditModal(bool);
    setEditModalArr({
      ...noticeSelectDummy,
    });
  };
  const [EditModalID, setEditModalID] = useState<number>(0);

  const [EditModalArr, setEditModalArr] = useState<noticeSelectType>({
    ...noticeSelectDummy,
  });

  const subnoticeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditModalArr({ ...EditModalArr, [name]: value });
  };

  const OpenEditModal = (idxA: number) => {
    if (!EditModal && idxA !== undefined) {
      changeEditModal(true);
      let noticeArr = NoticeList[idxA];
      let noticeNum =
        noticeArr.constraint.filter((codeA) => codeA.includes('-number'))
          .length > 0
          ? noticeArr.constraint
              .filter((codeA) => codeA.includes('-number'))[0]
              .replace('-number', '')
          : 0;
      let noticeQty =
        noticeArr.constraint.filter((codeA) => codeA.includes('-qty')).length >
        0
          ? noticeArr.constraint
              .filter((codeA) => codeA.includes('-qty'))[0]
              .replace('-qty', '')
          : 0;
      let newModalCopy = {
        ...noticeArr,
        code: firstInputs.selecters.map((codeA) => {
          let filtNow = noticeArr.constraint.filter(
            (codeB) => codeB.slice(0, 3) === codeA.types[0].code.slice(0, 3),
          );
          if (filtNow.length > 0) {
            return filtNow[0];
          } else {
            return '';
          }
        }),
        constraint: [],
        noticenumber: Number(noticeNum),
        quantity: Number(noticeQty),
        noticeType: noticeArr.noticeType,
      };
      setEditModalArr(newModalCopy);
      setEditModalID(idxA);
    } else {
      changeEditModal(false);
    }
  };

  return (
    <>
      <div
        className="closeBtn"
        onClick={() => {
          closeModal();
        }}
      >
        <i className="fa fa-times"></i>
      </div>

      {firstInputs && (
        <>
          <div className="bg-f7f7f7 p-5">
            <div className="contents-wrap x_panel">
              <div className="x_title">
                <h4>
                  <span>
                    <i>{siteName}</i>
                  </span>{' '}
                  - {firstInputs.productNameKO} 주의사항 설정
                </h4>
              </div>
              <div className="clearfix"></div>
              <div className="x_content">
                <form
                  id="firstForm"
                  className="form-horizontal form-label-left"
                >
                  <div className="form-group">
                    <div className="cost-cont">
                      <table
                        id="code-select-table"
                        className="table yTable"
                        cellSpacing="0"
                      >
                        <thead>
                          <tr>
                            <th>옵션명</th>
                            <th>등록할 주의사항 옵션 선택</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="todayInputs-title align-middle">
                              <div className="selecterName">주의사항 타입</div>
                            </td>
                            <td className="todayInputs-content">
                              <div className="option-input-group-wrap ub">
                                <div className="input-group">
                                  <input
                                    id={`textselect-input`}
                                    className="todayInputs"
                                    name={'qty-todayInput'}
                                    type="checkbox"
                                    checked={NOTICESelect.noticeType === 'TEXT'}
                                    onChange={() =>
                                      changeNOTICEtype('TEXT', 'new')
                                    }
                                  />
                                  <label htmlFor={`textselect-input`}>
                                    옵션별 공지
                                  </label>
                                  <input
                                    id={`imgselect-input`}
                                    className="todayInputs"
                                    name={'qty-todayInput'}
                                    type="checkbox"
                                    checked={NOTICESelect.noticeType === 'IMG'}
                                    onChange={() =>
                                      changeNOTICEtype('IMG', 'new')
                                    }
                                  />
                                  <label htmlFor={`imgselect-input`}>
                                    이미지 공지
                                  </label>
                                  <input
                                    id={`confirmselect-input`}
                                    className="todayInputs"
                                    name={'qty-todayInput'}
                                    type="checkbox"
                                    checked={
                                      NOTICESelect.noticeType === 'CONFIRM'
                                    }
                                    onChange={() =>
                                      changeNOTICEtype('CONFIRM', 'new')
                                    }
                                  />
                                  <label htmlFor={`confirmselect-input`}>
                                    전체 확인 공지
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                          {firstInputs &&
                            firstInputs.selecters.map(
                              (newCategoryMap, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="todayInputs-title align-middle">
                                      <div className="selecterName">
                                        {newCategoryMap.selecterName}
                                      </div>
                                    </td>
                                    <td className="todayInputs-content">
                                      <div className="option-input-group-wrap ub">
                                        <div className="input-group">
                                          {firstInputs.selecters[
                                            index
                                          ].types.map((b, i) => {
                                            return (
                                              <React.Fragment key={b.code}>
                                                <input
                                                  id={`${b.code}-input`}
                                                  className="todayInputs"
                                                  name={`${firstInputs.selecters[index].selecterName}-input`}
                                                  type="checkbox"
                                                  checked={
                                                    NOTICESelect.code.includes(
                                                      b.code,
                                                    )
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={() =>
                                                    handleChange(
                                                      'new',
                                                      b.code,
                                                      index,
                                                    )
                                                  }
                                                  disabled={
                                                    NOTICESelect.noticeType ===
                                                    'CONFIRM'
                                                  }
                                                />
                                                <label
                                                  htmlFor={`${b.code}-input`}
                                                >
                                                  {b.title}
                                                </label>
                                              </React.Fragment>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              },
                            )}
                          <tr>
                            <td className="todayInputs-title align-middle">
                              <div className="selecterName">수량</div>
                            </td>
                            <td className="todayInputs-content">
                              <div className="option-input-group-wrap ub">
                                <div className="input-group">
                                  {firstInputs &&
                                    firstInputs.productTypes[0].price.map(
                                      (type, index) => {
                                        return (
                                          <React.Fragment
                                            key={`${type.quantity}-key-${index}`}
                                          >
                                            <input
                                              id={`${type.quantity}-input`}
                                              className="todayInputs"
                                              name={'qty-todayInput'}
                                              type="checkbox"
                                              checked={
                                                NOTICESelect.quantity ==
                                                Number(type.quantity)
                                              }
                                              onChange={(e) =>
                                                handleETC(
                                                  'new',
                                                  'quantity',
                                                  Number(type.quantity),
                                                )
                                              }
                                              disabled={
                                                NOTICESelect.noticeType ===
                                                'CONFIRM'
                                              }
                                            />
                                            <label
                                              htmlFor={`${type.quantity}-input`}
                                            >
                                              {type.quantity}
                                            </label>
                                          </React.Fragment>
                                        );
                                      },
                                    )}
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="selecterName">건수</div>
                            </td>
                            <td className="todayInputs-content">
                              <div className="option-input-group-wrap ub">
                                <div className="input-group">
                                  <input
                                    className="nocInput"
                                    type="text"
                                    value={NOTICESelect.noticenumber}
                                    onChange={(e) =>
                                      handleETC(
                                        'new',
                                        'noticenumber',
                                        String(e.target.value),
                                      )
                                    }
                                    disabled={
                                      NOTICESelect.noticeType === 'CONFIRM'
                                    }
                                    name="noticenumber"
                                  />
                                  건
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="todayInputs-title align-middle">
                              <div className="selecterName">배경 설정</div>
                            </td>
                            <td className="todayInputs-content">
                              <div className="option-input-group-wrap ub">
                                <div className="input-group input-group-no">
                                  <div className="input-group">
                                    <input
                                      id="nolimit"
                                      name="nextStepBlock"
                                      type="radio"
                                      className="todayInputs"
                                      onChange={(e) => {
                                        handleETC(
                                          'new',
                                          'nextStepBlock',
                                          false,
                                        );
                                      }}
                                      disabled={
                                        NOTICESelect.noticeType === 'CONFIRM'
                                      }
                                      checked={
                                        NOTICESelect.nextStepBlock === false
                                      }
                                    />
                                    <label htmlFor="nolimit">배경없음</label>
                                    <input
                                      id="timelimit"
                                      name="nextStepBlock"
                                      type="radio"
                                      className="todayInputs"
                                      onChange={(e) => {
                                        handleETC('new', 'nextStepBlock', true);
                                      }}
                                      disabled={
                                        NOTICESelect.noticeType === 'CONFIRM'
                                      }
                                      checked={
                                        NOTICESelect.nextStepBlock === true
                                      }
                                    />
                                    <label htmlFor="timelimit">배경있음</label>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="todayInputs-title align-middle">
                              <div className="selecterName">주의사항</div>
                            </td>
                            <td className="todayInputs-content">
                              <div className="option-input-group-wrap ub">
                                {NOTICESelect.noticeType === 'IMG' ? (
                                  <div className="input-group input-group-no input-im">
                                    <div className="noticeUp">
                                      <S3ImageLoader
                                        TypeNow="new"
                                        subCategories={'notice'}
                                        getImgUrl={getImgUrl}
                                      />
                                      <p>
                                        ※ 경고창 이미지는 가로 최대 400px /
                                        세로최대 300px이하로 등록해주세요.
                                      </p>
                                    </div>
                                    {NOTICESelect.noticeType === 'IMG' &&
                                    NOTICESelect.description !== '' ? (
                                      <div
                                        className={`noticeimg scroolBar`}
                                        name={'qty-todayInput'}
                                      >
                                        <img
                                          src={NOTICESelect.description}
                                          alt={NOTICESelect.description}
                                        />
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                ) : NOTICESelect.noticeType === 'CONFIRM' ? (
                                  <div className="input-group input-group-no input-im">
                                    <div className="noticeUp conform_input">
                                      <input
                                        className="form-control"
                                        type="text"
                                        onChange={(
                                          e: React.ChangeEvent<HTMLInputElement>,
                                        ) => noticeChange(e, 'new')}
                                        name="description"
                                      />

                                      <p>
                                        ※ 제품 페이지에 들어가자마자 표시되는
                                        옵션 설정 불가 경고창입니다.
                                      </p>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="input-group input-group-no">
                                    <div className="input-group input-group-no nt_input">
                                      <Texteditor
                                        noticeChange={noticeChange}
                                        newedit={'new'}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="row py-1">
              <div className="col-sm-12  text-center complete-btn-wrap my-3">
                <div
                  className="yBtn yBtn-xl purple btn-inline-block"
                  data-toggle="tooltip"
                  onClick={() => {
                    saveNow();
                  }}
                >
                  <span>
                    <i className="fa fa-plus" aria-hidden="true"></i> 등록하기
                  </span>
                </div>
              </div>
            </div>
            <div className="clearfix"></div>
            {NoticeList.length > 0 && (
              <>
                <div className="contents-wrap x_panel">
                  <div className="x_content">
                    <div className="todayView-item-wrap mt-2">
                      <div className="row">
                        {NoticeList.map((c, idxA) => {
                          return (
                            <div
                              className="todayView-item col-sm-12 col-md-12 col-lg-6"
                              key={idxA}
                            >
                              <div className="todayView-item-inner">
                                <div className="">
                                  {c.constraint.filter(
                                    (filt) =>
                                      !filt.includes('-qty') &&
                                      !filt.includes('-number'),
                                  ).length > 0 ? (
                                    <div className="noticeView-options notice-codeop">
                                      {c.constraint
                                        .filter(
                                          (filt) =>
                                            !filt.includes('-qty') &&
                                            !filt.includes('-number'),
                                        )
                                        .map((d, idxB) => {
                                          let newName = firstInputs.selecters
                                            .find((a) =>
                                              a.types.find((b) => b.code == d),
                                            )
                                            ?.types.find(
                                              (c) => c.code == d,
                                            )?.title;
                                          return (
                                            <p
                                              className="d-inline-block"
                                              key={d}
                                            >
                                              {idxB !== 0 && (
                                                <span className="px-1 devider">
                                                  /
                                                </span>
                                              )}
                                              <span>{newName}</span>
                                            </p>
                                          );
                                        })}
                                    </div>
                                  ) : (
                                    <div className="noticeView-options notice-codeop">
                                      <p>
                                        <span>모든 옵션</span>
                                      </p>
                                    </div>
                                  )}
                                  <div className="noticeView-options notice-qtyop">
                                    <p className="d-inline-block">
                                      {c.constraint.filter((filt) =>
                                        filt.includes('-qty'),
                                      ).length == 0 ? (
                                        <span>모든 매수</span>
                                      ) : (
                                        <span>
                                          {c.constraint
                                            .filter((filt) =>
                                              filt.includes('-qty'),
                                            )[0]
                                            .replace('-qty', '')}
                                          매
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                  <div className="noticeView-options notice-numberop">
                                    <p className="d-inline-block">
                                      {c.constraint.filter((filt) =>
                                        filt.includes('-number'),
                                      ).length == 0 ? (
                                        <span>모든 건수</span>
                                      ) : (
                                        <span>
                                          {c.constraint
                                            .filter((filt) =>
                                              filt.includes('-number'),
                                            )[0]
                                            .replace('-number', '')}
                                          건
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                  <div className="noticeView-options notice-backon ">
                                    <p className="d-inline-block">
                                      {c.nextStepBlock == true ? (
                                        <span>배경있음</span>
                                      ) : (
                                        <span>배경없음</span>
                                      )}
                                    </p>
                                  </div>
                                  {/* {c.noticeType === 'CONFIRM' ? (
                                    <div className="noticeView-options notice-conform ">
                                      <p className="d-inline-block">
                                        <span>전체 경고</span>
                                      </p>
                                    </div>
                                  ) : (
                                    <></>
                                  )} */}
                                  <div className="todayInputs-icons-wrap pull-right">
                                    <div className="todayView-item-total noticeView-item-total">
                                      <div className="todayView-title noticeView-title noticeView-options align-middle d-inline-block">
                                        <div className="selecterName">
                                          주의사항 ON/OFF
                                        </div>
                                      </div>
                                      <div className="todayView-content noticeView-content d-inline-block">
                                        <label className="salesSwitch noticeSwitch">
                                          <input
                                            type="checkbox"
                                            checked={c.isUse ? true : false}
                                            onChange={() =>
                                              TotalNoticeHandler(idxA)
                                            }
                                          />
                                          <span className="slider noticeslider round"></span>

                                          <p
                                            className={
                                              c.isUse ? 'pNow' : 'pNowoff'
                                            }
                                          >
                                            {c.isUse ? '적용중' : '적용안함'}
                                          </p>
                                        </label>
                                      </div>
                                    </div>
                                    <div className="noticeView-content d-inline-block">
                                      <p
                                        className="todayInputs-icons todayInputs-open"
                                        onClick={() => OpenEditModal(idxA)}
                                      >
                                        <i className="fa fa-cog"></i>
                                      </p>
                                      <p
                                        className="todayInputs-icons todayInputs-del ms-1"
                                        onClick={() => noticeDelete(idxA)}
                                      >
                                        <i className="fa fa-times"></i>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="clearfix"></div>
                                <div className="todayView-qty mt-4 mb-3 text-center">
                                  {c.noticeType !== 'IMG' ? (
                                    c.noticeType === 'CONFIRM' ? (
                                      <div className="todayView-qty-item noticeView-list">
                                        <div className="notice-header notice-conform-header">
                                          <h3>전체 경고</h3>
                                        </div>
                                        <div className="notice-inner">
                                          <span>{c.description}</span>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="todayView-qty-item noticeView-list ">
                                        <div className="notice-header">
                                          <div className="notice-headerIcon">
                                            <NoticeIcon />
                                          </div>
                                          <h3>주문시 참고사항</h3>
                                        </div>
                                        {typeof window !== 'undefined' && (
                                          <div className="notice-inner ql-editor">
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(
                                                  String(c.description),
                                                ),
                                              }}
                                            ></span>
                                          </div>
                                        )}
                                      </div>
                                    )
                                  ) : (
                                    <div
                                      className={`noticeimg scroolBar`}
                                      name={'qty-todayInput'}
                                    >
                                      <img
                                        src={c.description}
                                        alt={c.description}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clearfix"></div>
              </>
            )}
            <div className="row py-1">
              <div className="col-sm-12  text-center complete-btn-wrap mt-3">
                <div
                  className="btn yBtn yBtn-small green btn btn-inline-block"
                  data-toggle="tooltip"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  <span>
                    <i className="fa fa-check" aria-hidden="true"></i> 완료
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {EditModal && (
        <>
          <div className="modalY-overlay" onClick={() => closeModal()}></div>
          <div className="item-edit-window item-edit-window-ver1 d-inline-block previewY preview-window">
            <div className="item-edit-bg"></div>
            <div className="cost-cont mb-3">
              <table
                id="code-select-table"
                className="table yTable"
                cellSpacing="0"
              >
                <thead>
                  <tr>
                    <th>옵션명</th>
                    <th>변경할 주의사항 옵션 선택</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="todayInputs-title align-middle">
                      <div className="selecterName">주의사항 타입</div>
                    </td>
                    <td className="todayInputs-content">
                      <div className="option-input-group-wrap ub">
                        <div className="input-group">
                          <input
                            id={`textselect-edit-input`}
                            className="todayInputs"
                            name={'qty-todayInput'}
                            type="checkbox"
                            checked={EditModalArr.noticeType === 'TEXT'}
                            onChange={() => changeNOTICEtype('TEXT', 'modal')}
                          />
                          <label htmlFor={`textselect-edit-input`}>
                            옵션별 공지
                          </label>
                          <input
                            id={`imgselect-edit-input`}
                            className="todayInputs"
                            name={'qty-todayInput'}
                            type="checkbox"
                            checked={EditModalArr.noticeType === 'IMG'}
                            onChange={() => changeNOTICEtype('IMG', 'modal')}
                          />
                          <label htmlFor={`imgselect-edit-input`}>
                            이미지 공지
                          </label>
                          <input
                            id={`confirmselect-edit-input`}
                            className="todayInputs"
                            name={'qty-todayInput'}
                            type="checkbox"
                            checked={EditModalArr.noticeType === 'CONFIRM'}
                            onChange={() =>
                              changeNOTICEtype('CONFIRM', 'modal')
                            }
                          />
                          <label htmlFor={`confirmselect-edit-input`}>
                            전체 확인 공지
                          </label>
                        </div>
                      </div>
                    </td>
                  </tr>
                  {firstInputs &&
                    firstInputs.selecters.map((newCategoryMap, index) => {
                      return (
                        <tr key={index}>
                          <td className="todayInputs-title align-middle">
                            <div className="selecterName">
                              {newCategoryMap.selecterName}
                            </div>
                          </td>
                          <td className="todayInputs-content">
                            <div className="option-input-group-wrap ub">
                              <div className="input-group">
                                {firstInputs.selecters[index].types.map(
                                  (b, i) => {
                                    return (
                                      <React.Fragment key={b.code}>
                                        <input
                                          id={`${b.code}-input-edit-modal`}
                                          className="todayInputs"
                                          name={`${b.code}-input`}
                                          type="checkbox"
                                          checked={
                                            EditModalArr.code.includes(b.code)
                                              ? true
                                              : false
                                          }
                                          onChange={() =>
                                            handleChange('modal', b.code, index)
                                          }
                                          disabled={
                                            EditModalArr.noticeType ===
                                            'CONFIRM'
                                          }
                                        />
                                        <label
                                          htmlFor={`${b.code}-input-edit-modal`}
                                        >
                                          {b.title}
                                        </label>
                                      </React.Fragment>
                                    );
                                  },
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  <tr>
                    <td className="todayInputs-title align-middle">
                      <div className="selecterName">수량</div>
                    </td>
                    <td className="todayInputs-content">
                      <div className="option-input-group-wrap ub">
                        <div className="input-group">
                          {firstInputs &&
                            firstInputs.productTypes[0].price.map(
                              (type, index) => {
                                return (
                                  <React.Fragment
                                    key={`${type.quantity}-key-${index}`}
                                  >
                                    <input
                                      id={`${type.quantity}-input-edit-modal`}
                                      className="todayInputs"
                                      name="quantity-modal"
                                      type="checkbox"
                                      checked={
                                        EditModalArr.quantity == type.quantity
                                      }
                                      onChange={(e) =>
                                        handleETC(
                                          'modal',
                                          'quantity',
                                          Number(type.quantity),
                                        )
                                      }
                                      disabled={
                                        EditModalArr.noticeType === 'CONFIRM'
                                      }
                                    />
                                    <label
                                      htmlFor={`${type.quantity}-input-edit-modal`}
                                    >
                                      {type.quantity}
                                    </label>
                                  </React.Fragment>
                                );
                              },
                            )}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="selecterName">건수</div>
                    </td>
                    <td className="todayInputs-content">
                      <div className="option-input-group-wrap ub">
                        <div className="input-group">
                          <input
                            className="nocInput"
                            type="text"
                            value={EditModalArr.noticenumber}
                            onChange={(e) =>
                              handleETC('modal', 'noticenumber', e.target.value)
                            }
                            name="noticenumber"
                            disabled={EditModalArr.noticeType === 'CONFIRM'}
                          />
                          건
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="todayInputs-title align-middle">
                      <div className="selecterName">배경 설정</div>
                    </td>
                    <td className="todayInputs-content">
                      <div className="option-input-group-wrap ub">
                        <div className="input-group input-group-no">
                          <div className="input-group">
                            <input
                              id="nolimit-edit-modal"
                              name="nextStepBlock"
                              type="radio"
                              className="todayInputs"
                              onChange={(e) => {
                                handleETC('modal', 'nextStepBlock', false);
                              }}
                              disabled={EditModalArr.noticeType === 'CONFIRM'}
                              checked={EditModalArr.nextStepBlock === false}
                            />
                            <label htmlFor="nolimit-edit-modal">배경없음</label>
                            <input
                              id="timelimit-edit-modal"
                              name="nextStepBlock"
                              type="radio"
                              className="todayInputs"
                              onChange={(e) => {
                                handleETC('modal', 'nextStepBlock', true);
                              }}
                              disabled={EditModalArr.noticeType === 'CONFIRM'}
                              checked={EditModalArr.nextStepBlock === true}
                            />
                            <label htmlFor="timelimit-edit-modal">
                              배경있음
                            </label>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="todayInputs-title align-middle">
                      <div className="selecterName">주의사항</div>
                    </td>
                    <td className="todayInputs-content">
                      <div className="option-input-group-wrap ub">
                        {EditModalArr.noticeType === 'IMG' ? (
                          <div className="input-group input-group-no input-im">
                            <div className="noticeUp">
                              <S3ImageLoader
                                TypeNow="modal"
                                subCategories={'notice'}
                                getImgUrl={getImgUrl}
                              />
                              <p>
                                ※ 경고창 이미지는 가로 최대 400px / 세로최대
                                300px이하로 등록해주세요.
                              </p>
                            </div>
                            {EditModalArr.noticeType === 'IMG' &&
                            EditModalArr.description !== '' ? (
                              <div
                                className={`noticeimg scroolBar`}
                                name={'qty-todayInput'}
                              >
                                <img
                                  src={EditModalArr.description}
                                  alt={EditModalArr.description}
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : EditModalArr.noticeType === 'CONFIRM' ? (
                          <div className="input-group input-group-no input-im">
                            <div className="noticeUp conform_input">
                              <input
                                className="form-control"
                                type="text"
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => noticeChange(e, 'modal')}
                                name="description"
                                value={EditModalArr.description}
                              />

                              <p>
                                ※ 제품 페이지에 들어가자마자 표시되는 옵션 설정
                                불가 경고창입니다.
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="input-group input-group-no">
                            <div className="input-group input-group-no nt_input">
                              <Texteditor
                                noticeChange={noticeChange}
                                newedit={'modal'}
                                descriptiondata={EditModalArr.description}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="modal-wrap">
              <div className="text-center">
                <div
                  className="btn yBtn yBtn-small btn btn-inline-block"
                  data-toggle="tooltip"
                  onClick={() => {
                    setEditModal(false);
                  }}
                >
                  <span>
                    <i className="fa fa-times" aria-hidden="true"></i> 닫기
                  </span>
                </div>
                <div
                  className="btn yBtn yBtn-small purple btn btn-inline-block"
                  data-toggle="tooltip"
                  onClick={() => {
                    fixNotice(EditModalID);
                  }}
                >
                  <span>
                    <i className="fa fa-plus" aria-hidden="true"></i> 수정완료
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
