import { NewAddwork } from '@interfaces/addwork.interface';
export const YUNdummy: FirstInput = {
  _id: '62f06ea8eb32c1c6e6f62120',
  productNameKO: '문어발 전단지',
  productNameEN: 'LeafletOctopus',
  categoryCode: 'CA_LF',
  saleStatus: false,
  typeOfPriceDetermintion: 'priceComplete',
  category: [
    {
      categoryName1st: '전단지',
      categoryName2nd: 'Offset',
      categoryName3rd: 'MP_OLF',
    },
  ],
  selecters: [
    {
      selecterName: '용지선택',
      priceOrder: 1,
      originally: 0,
      kindCode: 'materialCode',
      types: [
        {
          code: 'MAT:ART-100',
          title: '아트-100g',
          options: {
            weight: '100',
          },
          class: [],
        },
        {
          code: 'MAT:ART-120',
          title: '아트-120g',
          options: {
            weight: '120',
          },
          class: [],
        },
        {
          code: 'MAT:ART-150',
          title: '아트-150g',
          options: {
            weight: '150',
          },
          class: [],
        },
        {
          code: 'MAT:ART-180',
          title: '아트-180g',
          options: {
            weight: '180',
          },
          class: [],
        },
        {
          code: 'MAT:ART-200',
          title: '아트-200g',
          options: {
            weight: '200',
          },
          class: [],
        },
        {
          code: 'MAT:ART-250',
          title: '아트-250g',
          options: {
            weight: '250',
          },
          class: [],
        },
        {
          code: 'MAT:ART-300',
          title: '아트-300g',
          options: {
            weight: '300',
          },
          class: [],
        },
        {
          code: 'MAT:SNW-100',
          title: '스노우화이트-100g',
          options: {
            weight: '100',
          },
          class: [],
        },
        {
          code: 'MAT:SNW-120',
          title: '스노우화이트-120g',
          options: {
            weight: '120',
          },
          class: [],
        },
        {
          code: 'MAT:SNW-150',
          title: '스노우화이트-150g',
          options: {
            weight: '150',
          },
          class: [],
        },
        {
          code: 'MAT:SNW-180',
          title: '스노우화이트-180g',
          options: {
            weight: '180',
          },
          class: [],
        },
        {
          code: 'MAT:SNW-200',
          title: '스노우화이트-200g',
          options: {
            weight: '200',
          },
          class: [],
        },
        {
          code: 'MAT:SNW-250',
          title: '스노우화이트-250g',
          options: {
            weight: '250',
          },
          class: [],
        },
        {
          code: 'MAT:SNW-300',
          title: '스노우화이트-300g',
          options: {
            weight: '300',
          },
          class: [],
        },
        {
          code: 'MAT:MOJ-80',
          title: '모조-80g',
          options: {
            weight: '80',
            priceRate: '1',
            customPriceRate: '1',
          },
          class: [],
        },
        {
          code: 'MAT:MOJ-100',
          title: '모조-100g',
          options: {
            weight: '100',
          },
          class: [],
        },
        {
          code: 'MAT:MOJ-120',
          title: '모조-120g',
          options: {
            weight: '120',
          },
          class: [],
        },
        {
          code: 'MAT:MOJ-150',
          title: '모조-150g',
          options: {
            weight: '150',
          },
          class: [],
        },
        {
          code: 'MAT:MOJ-180',
          title: '모조-180g',
          options: {
            weight: '180',
          },
          class: [],
        },
        {
          code: 'MAT:MOJ-220',
          title: '모조-220g',
          options: {
            weight: '220',
          },
          class: [],
        },
        {
          code: 'MAT:MOJ-260',
          title: '모조-260g',
          options: {
            weight: '260',
          },
          class: [],
        },
      ],
    },
    {
      selecterName: '인쇄도수(앞면)',
      priceOrder: -1,
      originally: 1,
      kindCode: 'colorFrontCode',
      types: [
        {
          code: 'COL:F4',
          title: '컬러4도',
          options: {
            color: '4',
          },
          class: [],
        },
      ],
    },
    {
      selecterName: '인쇄도수(뒷면)',
      priceOrder: 2,
      originally: 2,
      kindCode: 'colorBackCode',
      types: [
        {
          code: 'COL:B0',
          title: '없음',
          options: {
            color: '0',
          },
          class: [],
        },
        {
          code: 'COL:B4',
          title: '컬러4도',
          options: {
            color: '4',
          },
          class: [],
        },
      ],
    },
    {
      selecterName: '사이즈',
      priceOrder: 0,
      originally: 3,
      kindCode: 'sizeCode',
      types: [
        {
          code: 'SIZ:LEO-A4',
          title: 'A4 국8절(210x297)',
          options: {
            width: '210',
            height: '297',
            cutMargin: '1.5',
            div: '8',
            raw: 'A',
            exWidth: '',
            exHeight: '',
            envelopeCap: '',
            envelopeType: '',
            plateSize: '',
            productSize: '',
          },
          class: [],
        },
        {
          code: 'SIZ:LEO-B5',
          title: 'B5 16절(182x257)',
          options: {
            width: '182',
            height: '257',
            cutMargin: '1.5',
            div: '16',
            raw: 'B',
            exWidth: '',
            exHeight: '',
            envelopeCap: '',
            envelopeType: '',
            plateSize: '',
            productSize: '',
          },
          class: [],
        },
      ],
    },
    {
      selecterName: '문어발수',
      priceOrder: 4,
      originally: 4,
      kindCode: 'octopusTypeCode',
      types: [
        {
          code: 'OTP:66',
          title: '6cm 6개',
          class: [],
        },
        {
          code: 'OTP:68',
          title: '6cm 8개',
          class: [],
        },
        {
          code: 'OTP:76',
          title: '7cm 6개',
          class: [],
        },
        {
          code: 'OTP:78',
          title: '7cm 8개',
          class: [],
        },
      ],
    },
  ],
  productTypes: [
    {
      selectors: [
        {
          title: 'A4 국8절',
          code: 'SIZ:LEO-A4',
        },
        {
          title: '아트-100g',
          code: 'MAT:ART-100',
        },
        {
          title: '없음',
          code: 'COL:B0',
        },
        {
          title: '6cm 6개',
          code: 'OTP:66',
        },
      ],
      price: [
        {
          value: 154300,
          quantity: 4000,
        },
        {
          value: 252800,
          quantity: 8000,
        },
        {
          value: 343300,
          quantity: 12000,
        },
        {
          value: 425700,
          quantity: 16000,
        },
        {
          value: 510200,
          quantity: 20000,
        },
        {
          value: 602700,
          quantity: 24000,
        },
        {
          value: 681200,
          quantity: 28000,
        },
        {
          value: 762000,
          quantity: 32000,
        },
        {
          value: 851300,
          quantity: 36000,
        },
        {
          value: 932600,
          quantity: 40000,
        },
        {
          value: 1021100,
          quantity: 44000,
        },
        {
          value: 1108500,
          quantity: 48000,
        },
        {
          value: 1195800,
          quantity: 52000,
        },
        {
          value: 1283000,
          quantity: 56000,
        },
        {
          value: 1370000,
          quantity: 60000,
        },
        {
          value: 1456800,
          quantity: 64000,
        },
        {
          value: 1543500,
          quantity: 68000,
        },
        {
          value: 1630000,
          quantity: 72000,
        },
        {
          value: 1716300,
          quantity: 76000,
        },
        {
          value: 1802600,
          quantity: 80000,
        },
      ],
    },
  ],
  createdAt: '2022-08-08T02:02:16.282Z',
  product_id: 170,
  __v: 0,
  createdBy: 'e00055',
  modifiedAt: '2024-04-08T01:51:33.357Z',
  modifiedBy: 'subak008',
  isExposure: true,
};

export const YUNeditdummy = {};
// export const YUNeditdummyBak = {
//   _id: '6369fbd4b769c403f8639ea7',
//   totalPriceCalc: 'total(92%)',
//   productTypes: [
//     {
//       selectors: [
//         {
//           title: '스노우화이트-300g',
//           code: 'MAT:SNW-300',
//         },
//         {
//           title: '양면유광코팅',
//           code: 'COT:LT',
//         },
//         {
//           title: '포토카드 86×54',
//           code: 'SIZ:PC-86X54',
//         },
//         {
//           title: '4㎜',
//           code: 'GDR:4',
//         },
//         {
//           title: '단면4도',
//           code: 'COL:40',
//         },
//       ],
//       price: [
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-500',
//           quantity: 100,
//           value: 6500,
//           _id: '64228a2d92bff7407b2fc06b',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-300',
//           quantity: 200,
//           value: 6500,
//           _id: '64228a2d92bff7407b2fc06c',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-800',
//           quantity: 500,
//           value: 11500,
//           _id: '64228a2d92bff7407b2fc06d',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-1800',
//           quantity: 1000,
//           value: 20000,
//           _id: '64228a2d92bff7407b2fc06e',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-3700',
//           quantity: 2000,
//           value: 39000,
//           _id: '64228a2d92bff7407b2fc06f',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5500',
//           quantity: 3000,
//           value: 58500,
//           _id: '64228a2d92bff7407b2fc070',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 4000,
//           value: 78000,
//           _id: '64228a2d92bff7407b2fc071',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-6000',
//           quantity: 5000,
//           value: 97500,
//           _id: '64228a2d92bff7407b2fc072',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-7000',
//           quantity: 6000,
//           value: 108000,
//           _id: '64228a2d92bff7407b2fc073',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-7000',
//           quantity: 7000,
//           value: 126000,
//           _id: '64228a2d92bff7407b2fc074',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 8000,
//           value: 144000,
//           _id: '64228a2d92bff7407b2fc075',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 9000,
//           value: 162000,
//           _id: '64228a2d92bff7407b2fc076',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 10000,
//           value: 170000,
//           _id: '64228a2d92bff7407b2fc077',
//         },
//       ],
//       _id: '64228a2d92bff7407b2fc06a',
//     },
//     {
//       selectors: [
//         {
//           title: '스노우화이트-300g',
//           code: 'MAT:SNW-300',
//         },
//         {
//           title: '양면유광코팅',
//           code: 'COT:LT',
//         },
//         {
//           title: '포토카드 86×54',
//           code: 'SIZ:PC-86X54',
//         },
//         {
//           title: '4㎜',
//           code: 'GDR:4',
//         },
//         {
//           title: '양면8도',
//           code: 'COL:44',
//         },
//       ],
//       price: [
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-700',
//           quantity: 100,
//           value: 7500,
//           _id: '64228a2d92bff7407b2fc079',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-500',
//           quantity: 200,
//           value: 7500,
//           _id: '64228a2d92bff7407b2fc07a',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-1000',
//           quantity: 500,
//           value: 12500,
//           _id: '64228a2d92bff7407b2fc07b',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-2200',
//           quantity: 1000,
//           value: 25000,
//           _id: '64228a2d92bff7407b2fc07c',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-4600',
//           quantity: 2000,
//           value: 49000,
//           _id: '64228a2d92bff7407b2fc07d',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5500',
//           quantity: 3000,
//           value: 73500,
//           _id: '64228a2d92bff7407b2fc07e',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 4000,
//           value: 98000,
//           _id: '64228a2d92bff7407b2fc07f',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-6000',
//           quantity: 5000,
//           value: 117500,
//           _id: '64228a2d92bff7407b2fc080',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-7000',
//           quantity: 6000,
//           value: 138000,
//           _id: '64228a2d92bff7407b2fc081',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-7000',
//           quantity: 7000,
//           value: 161000,
//           _id: '64228a2d92bff7407b2fc082',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 8000,
//           value: 184000,
//           _id: '64228a2d92bff7407b2fc083',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 9000,
//           value: 207000,
//           _id: '64228a2d92bff7407b2fc084',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 10000,
//           value: 220000,
//           _id: '64228a2d92bff7407b2fc085',
//         },
//       ],
//       _id: '64228a2d92bff7407b2fc078',
//     },
//     {
//       selectors: [
//         {
//           title: '스노우화이트-300g',
//           code: 'MAT:SNW-300',
//         },
//         {
//           title: '양면유광코팅',
//           code: 'COT:LT',
//         },
//         {
//           title: '포토카드 86×54',
//           code: 'SIZ:PC-86X54',
//         },
//         {
//           title: '6㎜',
//           code: 'GDR:6',
//         },
//         {
//           title: '단면4도',
//           code: 'COL:40',
//         },
//       ],
//       price: [
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-500',
//           quantity: 100,
//           value: 6500,
//           _id: '64228a2d92bff7407b2fc087',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-300',
//           quantity: 200,
//           value: 6500,
//           _id: '64228a2d92bff7407b2fc088',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-800',
//           quantity: 500,
//           value: 11500,
//           _id: '64228a2d92bff7407b2fc089',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-1800',
//           quantity: 1000,
//           value: 20000,
//           _id: '64228a2d92bff7407b2fc08a',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-3700',
//           quantity: 2000,
//           value: 39000,
//           _id: '64228a2d92bff7407b2fc08b',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5500',
//           quantity: 3000,
//           value: 58500,
//           _id: '64228a2d92bff7407b2fc08c',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 4000,
//           value: 78000,
//           _id: '64228a2d92bff7407b2fc08d',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-6000',
//           quantity: 5000,
//           value: 97500,
//           _id: '64228a2d92bff7407b2fc08e',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-7000',
//           quantity: 6000,
//           value: 108000,
//           _id: '64228a2d92bff7407b2fc08f',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-7000',
//           quantity: 7000,
//           value: 126000,
//           _id: '64228a2d92bff7407b2fc090',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 8000,
//           value: 144000,
//           _id: '64228a2d92bff7407b2fc091',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 9000,
//           value: 162000,
//           _id: '64228a2d92bff7407b2fc092',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 10000,
//           value: 170000,
//           _id: '64228a2d92bff7407b2fc093',
//         },
//       ],
//       _id: '64228a2d92bff7407b2fc086',
//     },
//     {
//       selectors: [
//         {
//           title: '스노우화이트-300g',
//           code: 'MAT:SNW-300',
//         },
//         {
//           title: '양면유광코팅',
//           code: 'COT:LT',
//         },
//         {
//           title: '포토카드 86×54',
//           code: 'SIZ:PC-86X54',
//         },
//         {
//           title: '6㎜',
//           code: 'GDR:6',
//         },
//         {
//           title: '양면8도',
//           code: 'COL:44',
//         },
//       ],
//       price: [
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-700',
//           quantity: 100,
//           value: 7500,
//           _id: '64228a2d92bff7407b2fc095',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-500',
//           quantity: 200,
//           value: 7500,
//           _id: '64228a2d92bff7407b2fc096',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-1000',
//           quantity: 500,
//           value: 12500,
//           _id: '64228a2d92bff7407b2fc097',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-2200',
//           quantity: 1000,
//           value: 25000,
//           _id: '64228a2d92bff7407b2fc098',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-4600',
//           quantity: 2000,
//           value: 49000,
//           _id: '64228a2d92bff7407b2fc099',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5500',
//           quantity: 3000,
//           value: 73500,
//           _id: '64228a2d92bff7407b2fc09a',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 4000,
//           value: 98000,
//           _id: '64228a2d92bff7407b2fc09b',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-6000',
//           quantity: 5000,
//           value: 117500,
//           _id: '64228a2d92bff7407b2fc09c',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-7000',
//           quantity: 6000,
//           value: 138000,
//           _id: '64228a2d92bff7407b2fc09d',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-7000',
//           quantity: 7000,
//           value: 161000,
//           _id: '64228a2d92bff7407b2fc09e',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 8000,
//           value: 184000,
//           _id: '64228a2d92bff7407b2fc09f',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 9000,
//           value: 207000,
//           _id: '64228a2d92bff7407b2fc0a0',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 10000,
//           value: 220000,
//           _id: '64228a2d92bff7407b2fc0a1',
//         },
//       ],
//       _id: '64228a2d92bff7407b2fc094',
//     },
//     {
//       selectors: [
//         {
//           title: '스노우화이트-300g',
//           code: 'MAT:SNW-300',
//         },
//         {
//           title: '홀로그램코팅 도트-양면',
//           code: 'COT:HD-4',
//         },
//         {
//           title: '포토카드 86×54',
//           code: 'SIZ:PC-86X54',
//         },
//         {
//           title: '4㎜',
//           code: 'GDR:4',
//         },
//         {
//           title: '단면4도',
//           code: 'COL:40',
//         },
//       ],
//       price: [
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-500',
//           quantity: 100,
//           value: 8000,
//           _id: '64228a2d92bff7407b2fc0a3',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-300',
//           quantity: 200,
//           value: 8000,
//           _id: '64228a2d92bff7407b2fc0a4',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-800',
//           quantity: 500,
//           value: 15500,
//           _id: '64228a2d92bff7407b2fc0a5',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-1800',
//           quantity: 1000,
//           value: 27000,
//           _id: '64228a2d92bff7407b2fc0a6',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-3700',
//           quantity: 2000,
//           value: 49000,
//           _id: '64228a2d92bff7407b2fc0a7',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5500',
//           quantity: 3000,
//           value: 71500,
//           _id: '64228a2d92bff7407b2fc0a8',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 4000,
//           value: 94000,
//           _id: '64228a2d92bff7407b2fc0a9',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-6000',
//           quantity: 5000,
//           value: 116500,
//           _id: '64228a2d92bff7407b2fc0aa',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-7000',
//           quantity: 6000,
//           value: 136000,
//           _id: '64228a2d92bff7407b2fc0ab',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-7000',
//           quantity: 7000,
//           value: 153000,
//           _id: '64228a2d92bff7407b2fc0ac',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 8000,
//           value: 170000,
//           _id: '64228a2d92bff7407b2fc0ad',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 9000,
//           value: 187000,
//           _id: '64228a2d92bff7407b2fc0ae',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 10000,
//           value: 204000,
//           _id: '64228a2d92bff7407b2fc0af',
//         },
//       ],
//       _id: '64228a2d92bff7407b2fc0a2',
//     },
//     {
//       selectors: [
//         {
//           title: '스노우화이트-300g',
//           code: 'MAT:SNW-300',
//         },
//         {
//           title: '홀로그램코팅 도트-양면',
//           code: 'COT:HD-4',
//         },
//         {
//           title: '포토카드 86×54',
//           code: 'SIZ:PC-86X54',
//         },
//         {
//           title: '4㎜',
//           code: 'GDR:4',
//         },
//         {
//           title: '양면8도',
//           code: 'COL:44',
//         },
//       ],
//       price: [
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-700',
//           quantity: 100,
//           value: 9000,
//           _id: '64228a2d92bff7407b2fc0b1',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-500',
//           quantity: 200,
//           value: 9000,
//           _id: '64228a2d92bff7407b2fc0b2',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-1000',
//           quantity: 500,
//           value: 17500,
//           _id: '64228a2d92bff7407b2fc0b3',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-2200',
//           quantity: 1000,
//           value: 32000,
//           _id: '64228a2d92bff7407b2fc0b4',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-4600',
//           quantity: 2000,
//           value: 59000,
//           _id: '64228a2d92bff7407b2fc0b5',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5500',
//           quantity: 3000,
//           value: 86500,
//           _id: '64228a2d92bff7407b2fc0b6',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 4000,
//           value: 114000,
//           _id: '64228a2d92bff7407b2fc0b7',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-6000',
//           quantity: 5000,
//           value: 141500,
//           _id: '64228a2d92bff7407b2fc0b8',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-7000',
//           quantity: 6000,
//           value: 166000,
//           _id: '64228a2d92bff7407b2fc0b9',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-7000',
//           quantity: 7000,
//           value: 188000,
//           _id: '64228a2d92bff7407b2fc0ba',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 8000,
//           value: 210000,
//           _id: '64228a2d92bff7407b2fc0bb',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 9000,
//           value: 232000,
//           _id: '64228a2d92bff7407b2fc0bc',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 10000,
//           value: 254000,
//           _id: '64228a2d92bff7407b2fc0bd',
//         },
//       ],
//       _id: '64228a2d92bff7407b2fc0b0',
//     },
//     {
//       selectors: [
//         {
//           title: '스노우화이트-300g',
//           code: 'MAT:SNW-300',
//         },
//         {
//           title: '홀로그램코팅 도트-양면',
//           code: 'COT:HD-4',
//         },
//         {
//           title: '포토카드 86×54',
//           code: 'SIZ:PC-86X54',
//         },
//         {
//           title: '6㎜',
//           code: 'GDR:6',
//         },
//         {
//           title: '단면4도',
//           code: 'COL:40',
//         },
//       ],
//       price: [
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-500',
//           quantity: 100,
//           value: 8000,
//           _id: '64228a2d92bff7407b2fc0bf',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-300',
//           quantity: 200,
//           value: 8000,
//           _id: '64228a2d92bff7407b2fc0c0',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-800',
//           quantity: 500,
//           value: 15500,
//           _id: '64228a2d92bff7407b2fc0c1',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-1800',
//           quantity: 1000,
//           value: 27000,
//           _id: '64228a2d92bff7407b2fc0c2',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-3700',
//           quantity: 2000,
//           value: 49000,
//           _id: '64228a2d92bff7407b2fc0c3',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5500',
//           quantity: 3000,
//           value: 71500,
//           _id: '64228a2d92bff7407b2fc0c4',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 4000,
//           value: 94000,
//           _id: '64228a2d92bff7407b2fc0c5',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-6000',
//           quantity: 5000,
//           value: 116500,
//           _id: '64228a2d92bff7407b2fc0c6',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-7000',
//           quantity: 6000,
//           value: 136000,
//           _id: '64228a2d92bff7407b2fc0c7',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-7000',
//           quantity: 7000,
//           value: 153000,
//           _id: '64228a2d92bff7407b2fc0c8',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 8000,
//           value: 170000,
//           _id: '64228a2d92bff7407b2fc0c9',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 9000,
//           value: 187000,
//           _id: '64228a2d92bff7407b2fc0ca',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 10000,
//           value: 204000,
//           _id: '64228a2d92bff7407b2fc0cb',
//         },
//       ],
//       _id: '64228a2d92bff7407b2fc0be',
//     },
//     {
//       selectors: [
//         {
//           title: '스노우화이트-300g',
//           code: 'MAT:SNW-300',
//         },
//         {
//           title: '홀로그램코팅 도트-양면',
//           code: 'COT:HD-4',
//         },
//         {
//           title: '포토카드 86×54',
//           code: 'SIZ:PC-86X54',
//         },
//         {
//           title: '6㎜',
//           code: 'GDR:6',
//         },
//         {
//           title: '양면8도',
//           code: 'COL:44',
//         },
//       ],
//       price: [
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-700',
//           quantity: 100,
//           value: 9000,
//           _id: '64228a2d92bff7407b2fc0cd',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-500',
//           quantity: 200,
//           value: 9000,
//           _id: '64228a2d92bff7407b2fc0ce',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-1000',
//           quantity: 500,
//           value: 17500,
//           _id: '64228a2d92bff7407b2fc0cf',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-2200',
//           quantity: 1000,
//           value: 32000,
//           _id: '64228a2d92bff7407b2fc0d0',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-4600',
//           quantity: 2000,
//           value: 59000,
//           _id: '64228a2d92bff7407b2fc0d1',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5500',
//           quantity: 3000,
//           value: 86500,
//           _id: '64228a2d92bff7407b2fc0d2',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 4000,
//           value: 114000,
//           _id: '64228a2d92bff7407b2fc0d3',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-6000',
//           quantity: 5000,
//           value: 141500,
//           _id: '64228a2d92bff7407b2fc0d4',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-7000',
//           quantity: 6000,
//           value: 166000,
//           _id: '64228a2d92bff7407b2fc0d5',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-7000',
//           quantity: 7000,
//           value: 188000,
//           _id: '64228a2d92bff7407b2fc0d6',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 8000,
//           value: 210000,
//           _id: '64228a2d92bff7407b2fc0d7',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 9000,
//           value: 232000,
//           _id: '64228a2d92bff7407b2fc0d8',
//         },
//         {
//           todayTypes: {
//             switch: true,
//           },
//           sales: true,
//           calcValue: '-5000',
//           quantity: 10000,
//           value: 254000,
//           _id: '64228a2d92bff7407b2fc0d9',
//         },
//       ],
//       _id: '64228a2d92bff7407b2fc0cc',
//     },
//   ],
// };
import { CateType, CateItemType } from '@interfaces/site.interface';
import { FirstInput } from '@interfaces/tableCost.interfaces';
export const YUNcate: CateType[] = [];
