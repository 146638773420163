import { Dispatch, useState, useEffect, SetStateAction } from 'react';
import { NewAddwork, NewAddworkSelecters } from '@interfaces/addwork.interface';
import es from 'date-fns/esm/locale/es/index.js';
// import ReorderWindow from './reorder/reorder-window';
interface Props {
  closeModal: () => void;
  newItem: NewAddwork;
  setNewItem: Dispatch<SetStateAction<NewAddwork>>;
  modalIndex: number;
}

export default function AddworkCustom({
  closeModal,
  newItem,
  setNewItem,
  modalIndex,
}: Props) {
  const { selecters } = newItem;
  let newCopy = newItem.selecters[modalIndex]
    ? newItem.selecters[modalIndex]
    : {
        title: '커스텀옵션' + modalIndex + '-OnlyPrice',
        codeCategory: 'customCode' + modalIndex,
        type: 'OnlyPrice',
        select: [
          {
            code: '',
            title: '',
            options: {},
            class: [],
          },
        ],
      };
  const [customCalc, setCustomCalc] = useState<NewAddworkSelecters>(newCopy);
  /**S: Onchange 기능구현  */

  const changeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let copy = { ...customCalc };

    if (name !== 'title') {
      copy[name] = value;
    } else {
      copy[name] =
        value.replace('-OnlyPrice', '') +
        (customCalc.type === 'OnlyPrice' ? '-OnlyPrice' : '');
    }
    setCustomCalc({
      ...copy,
    });
  };
  const changeInput = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const { name, value } = e.target;
    let copy = { ...customCalc };
    copy.select[idx][name] = value;
    setCustomCalc({
      ...copy,
    });
  };
  const changeOnlyPrice = (type?: string) => {
    let newTitle = customCalc.title.replace('-OnlyPrice', '');
    if (type === 'OnlyPrice') {
      setCustomCalc({
        ...customCalc,
        title: newTitle,
        type: 'Code',
      });
    } else {
      setCustomCalc({
        ...customCalc,
        title: newTitle + '-OnlyPrice',
        type: 'OnlyPrice',
      });
    }
  };
  const addCustomArray = () => {
    setCustomCalc({
      ...customCalc,
      select: [
        ...customCalc.select,
        {
          code: '',
          title: '',
          options: {},
          class: [],
        },
      ],
    });
  };
  const delCustomArray = () => {
    let copy = { ...customCalc };
    let lengthNow = copy.select.length - 1;
    if (lengthNow > 0) {
      copy.select.splice(lengthNow, 1);
      setCustomCalc({
        ...copy,
      });
    } else {
      alert('최소 1줄은 필요합니다.');
    }
  };

  const delThis = (idx: number) => {
    let copy = { ...customCalc };
    let filt = copy.select.filter((a, i) => i !== idx);
    setCustomCalc({
      ...copy,
      select: filt,
    });
  };

  const customFinish = () => {
    let copyFinal = { ...newItem };
    if (modalIndex < selecters.length) {
      copyFinal.selecters[modalIndex] = { ...customCalc };
    } else {
      copyFinal.selecters = [...copyFinal.selecters, { ...customCalc }];
    }
    setNewItem(copyFinal);
    closeModal();
  };
  return (
    <>
      <div id="customModal-wrap">
        <div className="customModal-inner">
          <div className="customModal-cont">
            <h3>
              커스텀 옵션
              <span> - 생성</span>
            </h3>
            <div className="customModal-inputWrap">
              <div className="newoption-wrap form-group">
                <div className="row">
                  <div className="newoption-title cost-title col-md-3 col-sm-3 col-xs-12">
                    타이틀
                  </div>
                  <div className="newoption-cont cost-cont col-md-9 col-sm-9 col-xs-12">
                    <div className="input-group py-1">
                      <input
                        type="text"
                        className="form-control me-2"
                        name="title"
                        value={customCalc.title.replace('-OnlyPrice', '')}
                        onChange={(e) => changeTitle(e)}
                      />
                      <div className="customModal-checkbox">
                        <input
                          type="checkbox"
                          name="OnlyPrice"
                          id="OnlyPrice"
                          className="me-1"
                          checked={customCalc.type === 'OnlyPrice'}
                          onChange={() => changeOnlyPrice(customCalc.type)}
                        />
                        <label htmlFor="OnlyPrice"> 옵션숨김</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="newoption-wrap form-group">
                <div className="row">
                  <div className="newoption-title cost-title col-md-3 col-sm-3 col-xs-12">
                    코드
                  </div>
                  <div className="newoption-cont cost-cont col-md-9 col-sm-9 col-xs-12">
                    <div className="input-group py-1">
                      <input
                        type="text"
                        className="form-control"
                        name="codeCategory"
                        value={customCalc.codeCategory}
                        onChange={(e) => changeTitle(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="newoption-wrap form-group">
                <div className="row">
                  <div className="newoption-title cost-title col-md-3 col-sm-3 col-xs-12">
                    옵션미리보기
                  </div>
                  <div className="newoption-cont cost-cont col-md-9 col-sm-9 col-xs-12">
                    <div className="input-group py-1">
                      <select
                        name=""
                        className="form-control d-inline-block me-1"
                      >
                        {customCalc.select.map((code, idx) => {
                          return (
                            <option key={code.code + idx} value="">
                              {code.title}
                            </option>
                          );
                        })}
                      </select>
                      <div
                        className="input-group-btn input-plus-btn"
                        onClick={() => addCustomArray()}
                      >
                        <i className="fa fa-plus"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="newoption-wrap form-group">
                <div className="row">
                  <div className="col-md-3 col-sm-3 col-xs-12"></div>
                  <div className="newoption-cont newoption-custom-outer cost-cont col-md-9 col-sm-9 col-xs-12">
                    {customCalc.select.map((code, idx) => {
                      return (
                        <div
                          className="newoption-custom-wrap"
                          key={code.code + idx}
                        >
                          <div className="newoption-custom d-inline-block">
                            <div className="newoption-custom-title">타이틀</div>
                            <div className="newoption-custom-cont">
                              <input
                                key={idx}
                                type="text"
                                className="form-control quarterinput d-inline-block me-1"
                                name="title"
                                value={code.title}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  changeInput(e, idx);
                                }}
                              />
                            </div>
                          </div>
                          <div className="newoption-custom d-inline-block">
                            <div className="newoption-custom-title">코드</div>
                            <div className="newoption-custom-cont">
                              <input
                                key={idx}
                                type="text"
                                className="form-control quarterinput d-inline-block me-1"
                                name="code"
                                value={code.code}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  changeInput(e, idx);
                                }}
                                autoFocus
                              />
                            </div>
                          </div>
                          <div
                            className="newoption-delBtn d-inline-block ms-2 red"
                            onClick={() => {
                              delThis(idx);
                            }}
                          >
                            <i className="fa fa-times"></i>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="reorder-buttonWrap text-center">
            <div
              className="yBtn yBtn-xs gray me-2 d-inline-block"
              onClick={() => closeModal()}
            >
              이전으로
            </div>
            <div
              className="yBtn yBtn-md blue text-center d-inline-block"
              onClick={() => customFinish()}
            >
              선택완료
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
