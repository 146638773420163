import { useState, useEffect } from 'react';
import useFetch from '@utils/customHook/useFetch';
import moment from 'moment';
import axios from 'axios';
import { SERVER_URL } from '@config/path';
import { Notification } from '@interfaces/tableCost.interfaces';
import { useAuthState } from '@config/context';
import 'dayjs/locale/ko';
import { useLoading } from '@config/loadingContext';
import NotificationModal from '@components/modal/productmanager/notification-modal-inner';
interface ModalType {
  new: boolean;
  edit: boolean;
}
export default function setnotification() {
  moment.locale();
  /** 로딩 state*/
  const { loadingNow, loadingHandler } = useLoading();
  const { payload, error } = useFetch<Notification>(`notification`, loadingNow);

  /**S:사이트필터 */

  const [notificationList, setnotificationList] = useState<Notification[]>([]);
  const [notificationFilter, setnotificationFilter] = useState<string[]>();
  const [notificationFilterNow, setnotificationFilterNow] =
    useState<string>('전체');

  useEffect(() => {
    if (payload) {
      let copy = [...payload.data];
      setnotificationList(payload.data);
      let filt = copy
        .map((a) => {
          return a.appliedSites;
        })
        .flat()
        .filter(
          (thing: string, index: number, self: string[]) =>
            index === self.findIndex((t) => t === thing),
        );
      setnotificationFilter(filt);
      changeProductFilter(notificationFilterNow);
    } else {
      console.log('payload 실패');
    }
  }, [payload]);

  const changeProductFilter = (group: string) => {
    if (payload) {
      if (group !== '전체') {
        let copy = payload.data.filter((a) => a.appliedSites.includes(group));
        setnotificationList(copy);
      } else {
        let copy = [...payload.data];
        setnotificationList(copy);
      }
      setnotificationFilterNow(group);
    } else {
      console.log('payload yet');
    }
  };

  /** 모달 state*/
  const [modal, setModal] = useState<boolean>(false);

  const openModal = () => {
    setEditId('');
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  /**수정 */

  const [editId, setEditId] = useState<string | undefined>();

  const editnotification = (_id: string | undefined) => {
    setEditId(_id);
    setModal(true);
  };

  /**삭제 */
  const notificationDel = async (_id: string) => {
    loadingHandler(true);
    confirm('삭제 하시겠습니까?')
      ? await axios
          .delete(SERVER_URL + '/notification/' + _id)
          .then((res) => {
            alert('삭제 완료');
            loadingHandler(false);
          })
          .catch((err) => {
            alert('삭제 실패');
            loadingHandler(false);
          })
      : alert('삭제 취소');
    loadingHandler(false);
  };

  /** 테이블 sort 화살표 색상 */

  const [sortColor, setSortColor] = useState<string>('');

  /**테이블 Sort State */
  const [sortNow, setSortNow] = useState<{ [key: string]: boolean }>({
    appliedSites: false,
    title: false,
  });
  /**테이블 Sort Action */
  const sortBy = (key: string) => {
    let copy = [...notificationList];

    copy.sort((a, b) =>
      !sortNow[key]
        ? String(a[key]) < String(b[key])
          ? -1
          : 1
        : String(a[key]) > String(b[key])
        ? -1
        : 1,
    );
    setSortNow({ ...sortNow, [key]: !sortNow[key] });
    setnotificationList(copy);
    setSortColor(String([key]));
  };
  return (
    <>
      <div className="page-title">
        <div className="title_left">
          <h3>
            <i className="fa fa-cubes" />
            공지사항 등록 및 관리
            <div className="pull-right">
              <button className="goBackBtn" onClick={() => openModal()}>
                공지사항 등록
              </button>
            </div>
          </h3>
        </div>
      </div>
      <div className="clearfix" />
      <div className="row">
        <div className="col-12">
          <div className="contents-wrap x_panel mb-2">
            <div className="groupFilter">
              <h5>사이트필터</h5>
              <div className="groupFilter-wrap orange">
                <div className="groupFilter-inner">
                  <div
                    className={`groupFilter-item ${
                      notificationFilterNow == '전체' ? 'active' : ''
                    }`}
                    onClick={() => changeProductFilter('전체')}
                  >
                    전체
                  </div>
                  {notificationFilter?.map((filt) => {
                    let NAME =
                      filt == 'printcity'
                        ? '프린트시티'
                        : filt == 'ecard21'
                        ? '명함천국'
                        : filt == 'iamdesign'
                        ? '아이엠디자인'
                        : filt == 'megaprint'
                        ? '메가프린트'
                        : filt == 'speedcolor'
                        ? '스피드칼라'
                        : filt;
                    return (
                      <div
                        key={filt}
                        className={`groupFilter-item ${
                          notificationFilterNow == filt ? 'active' : ''
                        }`}
                        onClick={() => changeProductFilter(filt)}
                      >
                        {NAME}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="contents-wrap x_panel">
            <div className="x_content setprice-table-wrap notification-table-wrap">
              {notificationList && (
                <div className="react-bootstrap-table">
                  <table className="table table-hover table-bordered">
                    <thead>
                      <tr>
                        <th>번호</th>
                        <th
                          className={`title ${
                            sortColor === 'title' ? 'colorOn' : ''
                          }`}
                          onClick={() => {
                            sortBy('title');
                          }}
                        >
                          공지사항 제목{' '}
                          <span
                            className={`order-4 ${
                              sortNow['title'] === true ? 'setcolor' : 'nocolor'
                            }`}
                          ></span>
                        </th>
                        <th
                          className={`appliedSites ${
                            sortColor === 'appliedSites' ? 'colorOn' : ''
                          }`}
                          onClick={() => {
                            sortBy('appliedSites');
                          }}
                        >
                          적용 사이트{' '}
                          <span
                            className={`order-4 ${
                              sortNow['appliedSites'] === true
                                ? 'setcolor'
                                : 'nocolor'
                            }`}
                          ></span>
                        </th>
                        <th>생성/수정</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {notificationList.map((code, idx) => {
                        let time = moment(code.createdAt).format(
                          'YYYY-MM-DD (HH:mm)',
                        );
                        let time2 = moment(code.modifiedAt).format(
                          'YYYY-MM-DD (HH:mm)',
                        );
                        return (
                          <tr key={idx}>
                            <td>
                              <span>{idx + 1}</span>
                            </td>
                            <td>
                              {'[' + code.notificationType + ']' + code.title}
                            </td>
                            <td>
                              <div>
                                {code.appliedSites.map((a, i) => {
                                  let leng = code.appliedSites.length - 1;
                                  let name =
                                    a == 'printcity'
                                      ? '프린트시티'
                                      : a == 'ecard21'
                                      ? '명함천국'
                                      : a == 'iamdesign'
                                      ? '아이엠디자인'
                                      : a == 'megaprint'
                                      ? '메가프린트'
                                      : a == 'speedcolor'
                                      ? '스피드칼라'
                                      : a;
                                  let siteUrl =
                                    a == 'printcity'
                                      ? 'https://www.printcity.co.kr/'
                                      : a == 'ecard21'
                                      ? 'https://www.ecard21.co.kr/'
                                      : a == 'iamdesign'
                                      ? 'https://www.iamdgn.com/'
                                      : a == 'megaprint'
                                      ? 'https://www.megaprint.co.kr/'
                                      : a == 'speedcolor'
                                      ? 'https://www.speedcolor.co.kr/'
                                      : a;
                                  if (i !== leng) {
                                    return (
                                      <a href={siteUrl} key={a} target="blink">
                                        {name} ,{' '}
                                      </a>
                                    );
                                  } else {
                                    return (
                                      <a href={siteUrl} key={a} target="blink">
                                        {name}
                                      </a>
                                    );
                                  }
                                })}
                              </div>
                            </td>
                            <td>
                              <div className="editDate-wrap">
                                <div className="editDate-btn">보기</div>
                                <div className="editDate-view">
                                  <div className="editDate-line">
                                    <h5>생성:</h5>
                                    <p className="standardproductID">
                                      {code.createdBy}
                                    </p>
                                    <p className="standardproductTIME">
                                      {time}
                                    </p>
                                  </div>
                                  <div className="editDate-line">
                                    <h5>수정:</h5>
                                    <p className="standardproductID">
                                      {code.modifiedBy}
                                    </p>
                                    <p className="standardproductTIME">
                                      {time2}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                className="tableBtn tableBtn-skyblue btnW-70 setPriceDelEvents setpriceBtn"
                                onClick={() => editnotification(code._id)}
                              >
                                <span className="mobileSpan">
                                  <i
                                    className="fa fa-pencil"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span className="deskSpan">수정하기</span>
                              </button>
                              <button
                                className="tableBtn tableBtn-red btnW-70 setPriceDelEvents setpriceBtn"
                                onClick={() =>
                                  code._id && notificationDel(code._id)
                                }
                              >
                                <span className="mobileSpan">
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span className="deskSpan">삭제하기</span>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {modal && (
        <>
          <NotificationModal editId={editId} closeModal={closeModal} />
        </>
      )}
    </>
  );
}
