import { useState, useEffect, Component } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import Home from '@pages/home';
import '@styles/reset.css';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '@styles/font-awesome.css';
import '@styles/main.css';
import 'react-datepicker/dist/react-datepicker.css';
import '@styles/react-bootstrap-table2.min.css';
import '@styles/custom.css';
import '@styles/sitemanager.css';
import 'react-quill/dist/quill.snow.css';
import Header from '@components/header';
import Sidebar from '@components/sidebar';
import UseWindowDimensions from '@components/useWindowDimensions';
import { refresh, useAuthDispatch } from '@config/reducer.index';
import { loginRoutes, isntLogin } from '@config/route';
import Login from '@pages/login';
import { SidbarContext, useAuthState } from '@config/context';
import { Pages } from '@interfaces/globals.types';
import Cookies from 'universal-cookie';
import { LoadingProvider } from '@config/loadingContext';
import { getCookie } from '@config/cookies';

function App() {
  const [isLogin, setIsLogin] = useState(false);
  const [wideNav, setwideNav] = useState(false);
  const { user } = useAuthState();
  const dispatch = useAuthDispatch();
  const history = useHistory();
  const currentUrl = history.location.pathname;
  const loginhandler = (login: boolean) => setIsLogin(login);
  const pushNav = () => setwideNav(!wideNav);
  const { winWidth } = UseWindowDimensions();
  const [navOpen, setNavOpen] = useState<Pages>('home');
  // 가시성이 변경될 때마다 로그인 상태를 다시 확인합니다.
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible' && refreshToken) {
      refresh(dispatch, loginhandler, history);
    }
  };
  const refreshToken = getCookie('refreshToken');
  useEffect(() => {
    refresh(dispatch, loginhandler, history);

    if (currentUrl == '/') {
      history.push('/login');
    }
    if (user && currentUrl == '/login') {
      history.push('/home');
    }
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [user, currentUrl]);

  return (
    <SidbarContext.Provider value={{ navOpen, setNavOpen }}>
      {isLogin ? (
        <div className={winWidth < 900 || wideNav ? 'nav-sm' : 'nav-md'}>
          <div className="container body">
            <LoadingProvider>
              <div className="main_container">
                <Sidebar loginhandler={loginhandler} />
                <Header pushNav={pushNav} />
                <div className="right_col clearfix">
                  <Switch>
                    <Route exact path="/home" component={Home} />

                    {loginRoutes.map((route, index) => {
                      return (
                        route && (
                          <Route
                            exact
                            key={route.path}
                            path={route.path}
                            component={route.component}
                          />
                        )
                      );
                    })}
                  </Switch>
                </div>
              </div>
            </LoadingProvider>
          </div>
        </div>
      ) : (
        <>
          <Switch>
            <Route
              exact
              path="/login"
              render={(props) => (
                <Login loginhandler={loginhandler} {...props} />
              )}
            />
            {isntLogin.map((route, index) => (
              <Route
                exact
                key={index}
                path={route.path}
                component={route.component}
              />
            ))}
          </Switch>
        </>
      )}
    </SidbarContext.Provider>
  );
}

export default App;
