import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import CodeWrapTable from '@components/table/standardproduct/code-select/code-wrap-table';
import PriceInputWrap from '@components/table/standardproduct/price-input/price-input-wrap';
import PriceBtnWrap from '@components/table/standardproduct/price-input/price-btn-wrap';
import PreviewModal from '@components/modal/previewModal';
import { lazyFake, basicConst, emptyPrice } from '@dummydata/dummy';
import { ProductCalcTypes } from '@dummydata/productCalcTypes';
import { useInput } from '@utils/customHook/customHook';
import {
  FirstInput,
  MegaProduct,
  TableCostStep,
  LetCode,
} from '@interfaces/tableCost.interfaces';
import axios from 'axios';
import { SERVER_URL, MEGAProductsUrl } from '@config/path';
import { useSidbarContext } from '@config/context';
import { LOG } from '@utils/textMessage';
import { useLoading } from '@config/loadingContext';
import { deleteEverything } from '@utils/common';
import { YUNdummy } from '@dummydata/yundummy';

export default function Tablecost() {
  /**토큰 state*/
  /**페이지이동 state*/
  const history = useHistory();
  const { setNavOpen } = useSidbarContext();
  const myRef = useRef<HTMLDivElement>(null);
  const executeScroll = () => {
    setTimeout(() => {
      myRef.current !== null
        ? myRef.current.scrollIntoView({ behavior: 'smooth' })
        : console.log(myRef.current);
    }, 1000);
  };
  /**로딩 state*/
  const { loadingNow, loadingHandler } = useLoading();
  /**미리보기모달 state*/
  const [preview, setPreview] = useState(false);
  const previewhandler = (boolean: boolean) => setPreview(boolean);
  /**메인 state*/
  let basicCopy = JSON.parse(JSON.stringify(basicConst));
  let originCopy = JSON.parse(JSON.stringify(basicConst));
  const [firstInputs, onChange, setFirstInputs] = useInput<FirstInput>({
    ...basicCopy,
  }); //basicCopy
  /**변경시 백업 state*/
  const [originInputs, setOriginInputs] = useState<FirstInput>({
    ...originCopy,
  });
  /**메가 데이터 state*/
  const [megaProduct, setMegaProduct] = useState<MegaProduct[]>([]);
  /**가격 스텝 State*/
  const [priceStep, setPriceStep] = useState<TableCostStep>('preparing');
  /**가격비교여부 state*/
  const [readyToCompare, setReadyToCompare] = useState<boolean>(false);
  /**
   * 제한사항 보여주기
   * @param NewClass 제한사항 array
   */
  const [NewClass, setNewClass] = useState<LetCode>([]);
  const changeNewClass = (inputs: FirstInput) => {
    let preClass: LetCode = [];
    if (!inputs) {
      firstInputs.selecters.map((a) =>
        a.types.map((v) =>
          v.class.map((w, i) => {
            let NameW = firstInputs.selecters
              .find((a) => a.types.find((b) => b.code == w))
              ?.types.find((c) => c.code == w)?.title;
            return preClass.push({
              from: v.code,
              fromKind: a.kindCode,
              to: w,
              toName: NameW,
            });
          }),
        ),
      );
    } else {
      inputs.selecters.map((a) =>
        a.types.map((v) =>
          v.class.map((w, i) => {
            let NameW = firstInputs.selecters
              .find((a) => a.types.find((b) => b.code == w))
              ?.types.find((c) => c.code == w)?.title;
            return preClass.push({
              from: v.code,
              fromKind: a.kindCode,
              to: w,
              toName: NameW,
            });
          }),
        ),
      );
    }
    preClass.length > 0 ? setNewClass(preClass) : setNewClass([]);
  };
  useEffect(() => {
    changeNewClass(firstInputs);
  }, [firstInputs]);
  /**
   * 이미생성된 제품리스트 패치
   */
  const [onlyProduct, setOnlyProduct] = useState<boolean>(false);
  const changeOnlyProduct = (bool: boolean) => {
    setOnlyProduct(bool);
  };
  /**
   * input(카테고리, 상품옵션, 판매상태) onchange 이벤트
   * @param name input name
   * @param value input value
   */
  const handleChange = (name: string, value: string | boolean) => {
    name.slice(0, 12) === 'categoryName'
      ? setFirstInputs({
          ...firstInputs,
          category: [{ ...firstInputs.category[0], [name]: String(value) }],
        })
      : setFirstInputs({ ...firstInputs, [name]: value });
  };
  /**
   * 입력 단계변경 action
   * @param step 변경할 스텝
   * @constant priceStep
   */
  const changePriceStep = (step: TableCostStep) => setPriceStep(step);

  /**
   * 메가 데이터 다운로드
   * @constant megaProduct
   */
  useEffect(() => {
    getMegaProductData(MEGAProductsUrl);
  }, []);

  const getMegaProductData = async (url: string) => {
    loadingHandler(true);
    await axios
      .get(url)
      .then((res) => (setMegaProduct(res.data), loadingHandler(false)))
      .catch((err) => console.log(LOG.DATAGET + err));
  };
  /**
   * 저장, 완료 Action
   * @constant firstInput
   * @constant priceStep
   */
  const saveNow = async (action: string) => {
    let address = firstInputs.productNameEN;
    const id = firstInputs._id;
    loadingHandler(true);
    await axios
      .put(SERVER_URL + '/product/' + id, firstInputs)
      .then((res) => {
        if (action === 'save') {
          alert('저장완료');
        } else if (action === 'complete') {
          alert('상품생성 완료');
          setFirstInputs({ ...basicCopy });
          changePriceStep('preparing');
          setNavOpen('standardproduct');
          history.push('/standardproduct/standardproduct');
        }
      })
      .catch((err) => console.log('데이터 업로드에 실패 하였습니다.'));
    loadingHandler(false);
  };
  const goDummy = () => {
    let data = deleteEverything<FirstInput>(YUNdummy, [
      '_id',
      'createdAt',
      'createdBy',
      'modifiedAt',
      'modifiedBy',
      'catePriority',
      'subCatePriority',
      '__v',
    ]);
    setFirstInputs({ ...data });
  };
  return (
    <>
      {/* <button onClick={() => goDummy()}>고더미</button> */}
      <div className="page-title">
        <div className="title_left">
          <h3>
            <i className="fa fa-cube" />
            가격 입력형 <small>기준 상품 등록</small>
          </h3>
        </div>
      </div>
      <div className="clearfix"></div>
      <div className="row">
        <div className="col-12">
          <div className="contents-wrap x_panel">
            <div className="x_content">
              <CodeWrapTable
                firstInputs={firstInputs}
                setFirstInputs={setFirstInputs}
                handleChange={handleChange}
                megaProduct={megaProduct}
                onlyProduct={onlyProduct}
                changeOnlyProduct={changeOnlyProduct}
                NewClass={NewClass}
              />
            </div>
            <div
              id="block-box"
              className={`block-box ${priceStep !== 'preparing' && 'blockNow'}`}
            >
              <div className="blockBoxBg"></div>
              <div className="blockBoxTxt">
                <p className="text-center">
                  옵션 수정을 원하시면,
                  <br />
                  저장 혹은 수정완료 버튼을 누르고,
                  <br />
                  <u className="blue">기준상품 리스트</u>로 이동하셔서
                  수정하시기 바랍니다.
                </p>
                <button className="yBtn d-block py-2 px-4 mt-5 mx-auto text-center white nodrop">
                  가격 및 주문옵션 수정 불가
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="clearfix" ref={myRef}></div>
      <PriceInputWrap
        firstInputs={firstInputs}
        setFirstInputs={setFirstInputs}
        originInputs={originInputs}
        priceStep={priceStep}
        executeScroll={executeScroll}
        changePriceStep={changePriceStep}
        readyToCompare={readyToCompare}
        setReadyToCompare={setReadyToCompare}
        handleChange={handleChange}
        saveNow={saveNow}
        onlyProduct={onlyProduct}
        NewClass={NewClass}
      />
      {onlyProduct && priceStep == 'readyToMake' && (
        <>
          <div className="clearfix"></div>
          <PriceBtnWrap
            firstInputs={firstInputs}
            previewhandler={previewhandler}
            saveNow={saveNow}
          />
        </>
      )}
      {preview && (
        <PreviewModal
          from="standardPreview"
          previewProduct={firstInputs._id}
          previewhandler={previewhandler}
        />
      )}
    </>
  );
}
