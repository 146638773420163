import { useState, useEffect, Dispatch, SetStateAction, useRef } from 'react';
interface Props {
  delID: string;
  delEvents: (id: string) => void;
  changeModal: (key: string, bool: boolean) => void;
}
export default function deleteModal({ delID, delEvents, changeModal }: Props) {
  const [delTXT, setDelTXT] = useState<string>('');
  const delTXTHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setDelTXT(value);
  };
  const delConfirm = () => {
    delEvents(delID);
    changeModal('delete', false);
  };
  return (
    <>
      <div
        className="modalY-overlay"
        onClick={() => changeModal('delete', false)}
      ></div>
      <div className="preview-window preview-400">
        <div
          className="closeBtn"
          onClick={() => {
            changeModal('delete', false);
          }}
        >
          <i className="fa fa-times"></i>
        </div>
        <div id="delModal" className="bg-f7f7f7 p-4">
          <div className="delMessage">
            삭제를 원하면 "삭제"를 입력 후 삭제버튼을 클릭하세요.
          </div>
          <div className="delInput mt-2">
            <input
              className="d-inline-block"
              type="text"
              value={delTXT}
              onChange={(e) => delTXTHandler(e)}
            />
            <div
              id="delBtn"
              className={`tableBtn ${
                delTXT === '삭제' ? 'tableBtn-red' : 'disabled'
              }  btnW-70 d-inline-block`}
              onClick={() => delTXT === '삭제' && delConfirm()}
            >
              삭제
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
