import { Dispatch, SetStateAction } from 'react';
import { FirstInput } from '@interfaces/tableCost.interfaces';
import { MESSAGE } from '@utils/textMessage';
interface Props {
  firstInputs: FirstInput;
  previewhandler: (boolean: boolean) => void;
  saveNow: (action: string) => void;
}
export default function PriceBtnWrap({
  firstInputs,
  previewhandler,
  saveNow,
}: Props) {
  return (
    <>
      <div className="row  py-5">
        <div className="col-sm-12 text-center complete-btn-wrap">
          {/* <div
            className="btn yBtn yBtn-small orange btn btn-inline-block"
            data-toggle="tooltip"
            onClick={() =>
              firstInputs.productNameEN.length === 0
                ? alert(MESSAGE.SAVEPREVIEW)
                : previewhandler(true)
            }
          >
            미리보기
          </div> */}
          <div
            className="btn yBtn yBtn-small green btn btn-inline-block ms-2"
            data-toggle="tooltip"
            onClick={() => saveNow('save')}
          >
            임시저장
          </div>
        </div>
        <div className="col-sm-12  text-center complete-btn-wrap my-4">
          <div
            className="price-start-btn btn btn-inline-block"
            data-toggle="tooltip"
            onClick={() => {
              saveNow('complete');
            }}
          >
            <span>
              <i className="fa fa-check" aria-hidden="true"></i> 상품생성
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
