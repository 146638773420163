import Axios from 'axios';
import { useState, useEffect } from 'react';
import { SERVER_URL } from '@config/path';
export default function useFetch<T>(
  url: string,
  changeElement?:
    | number
    | string
    | (number | string | boolean)[]
    | boolean[]
    | boolean,
): {
  payload: { result: string; data: T[]; length: number } | null;
  loading: boolean;
  error: string;
} {
  const [payload, setPayload] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const callUrl = async () => {
    try {
      const { data } = await Axios.get(`${SERVER_URL}/${url}`, {
        withCredentials: true,
      });
      setPayload(data);
    } catch {
      setError('something is worng!');
    }
    setLoading(false);
  };
  useEffect(() => {
    callUrl();
  }, [url, changeElement]);
  return { payload, loading, error };
}
