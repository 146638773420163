import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react';
import {
  Order,
  FirstInput,
  TableCostStep,
  PriceCalcurateArr,
  LetCode,
} from '@interfaces/tableCost.interfaces';
import NumberFormat from 'react-number-format';
import { compare } from '@config/compare/compareBefore';
import { useLoading } from '@config/loadingContext';

interface Props {
  firstInputs: FirstInput;
  setFirstInputs: Dispatch<SetStateAction<FirstInput>>;
  originInputs: FirstInput;
  latestInput: Order[];
  setLatestInput: Dispatch<SetStateAction<Order[]>>;
  latestColor: Order[];
  priceStep: TableCostStep;
  readyToCompare: boolean;
  setReadyToCompare: Dispatch<SetStateAction<boolean>>;
  saveNow: (action: string) => void;
  NewClass: LetCode;
}

export default function PriceInputTable({
  firstInputs,
  setFirstInputs,
  originInputs,
  latestInput,
  latestColor,
  priceStep,
  readyToCompare,
  setReadyToCompare,
  saveNow,
  NewClass,
}: Props) {
  const { loadingHandler } = useLoading();
  /**전체선택 state*/
  const [idAll, setIdAll] = useState<string>('');
  const [positionN, setPositionN] = useState(false);
  const positionChange = () => setPositionN(!positionN);
  /**가격행 갯수 state*/
  let ipn: number = originInputs.productTypes
    ? originInputs.productTypes[0].price.length
    : 5;
  let colorLength: number = latestColor[0] && latestColor[0].types.length;
  const [initialPriceLength, setInitialPriceLength] = useState(ipn);
  /**
   * 페이지 랜더링시 옵션값계산하여 새배열 생성, 수정했을때는 이전배열과 새배열 합치기
   * @param firstInputs 새배열
   * @param originInputs 이전배열
   */
  useEffect(() => {
    loadingHandler(true);
    priceCalcurate()
      .then((result) => {
        if (result) {
          if (!readyToCompare) {
            //가격표 만들기
            let addClass = classFilter({
              ...firstInputs,
              productTypes: result,
            });
            setFirstInputs({ ...addClass });
          } else {
            //옵션 변화가 있을경우 비교 가격넣기
            let data = goCompare(
              { ...firstInputs, productTypes: result },
              { ...originInputs },
            );
            let addClass = classFilter({ ...data });
            setFirstInputs({ ...addClass });
            setReadyToCompare(false);
          }
        }
      })
      .catch(function (err) {
        console.log(err);
      });
    setTimeout(() => loadingHandler(false), 1000);
  }, [priceStep]);
  useEffect(() => document.getElementById(idAll)?.focus(), [idAll]);
  /**
   * 클래스 적용시키기
   * @param inputs 새데이터
   */
  const classFilter = (inputs: FirstInput) => {
    let copy = inputs.productTypes;
    let filterResult = copy.filter(
      (a) =>
        !NewClass.some(
          (c) =>
            a.selectors
              .map((b) => {
                return b.code;
              })
              .join()
              .toString()
              .includes(c.from) &&
            a.selectors
              .map((b) => {
                return b.code;
              })
              .join()
              .toString()
              .includes(c.to),
        ),
    );
    let result = { ...inputs, productTypes: filterResult };
    return result;
  };
  /**
   * 이전배열과 새배열 합치기
   * @param newArray 새배열
   * @param oldArray 이전배열
   */
  const goCompare = (newArray: FirstInput, oldArray: FirstInput) => {
    let newCopy = { ...newArray };
    let oldCopy = { ...oldArray };
    let finalCopy = compare(newCopy, oldCopy);
    return finalCopy;
  };

  /**
   * 재귀함수 여부 확인을 위한 Function
   * @return Number
   */
  let sum = [...latestInput, ...latestColor];
  let lateN = sum.length;
  const factorial = (n: number): number => {
    let fa = 1;
    if (n > 0) {
      return sum[n - 1].types.length * factorial(n - 1);
    }
    return fa;
  };
  const arrayFacto = (n: number): number => {
    let fb = 1;
    if (lateN > n + 1) {
      return sum[n + 1].types.length * arrayFacto(n + 1);
    }
    return fb;
  };
  /**
   * 재귀함수로 가격 배열 생성
   * @param initialPriceLength 수량 row갯수State
   * @return firstInputs.productTypes
   */
  const priceCalcurate = async () => {
    try {
      let total = factorial(lateN);
      if (firstInputs.productTypes[0].selectors.length > 0) {
        console.log('Data existed');
        return firstInputs.productTypes;
      } else {
        console.log('No Data existed');
        let newArray = [];
        let newArrayInner = [];
        let emptyPrice = [];
        for (let ei = 0; ei < initialPriceLength; ei++) {
          emptyPrice.push({
            quantity: 0,
            value: 0,
          });
        }
        let ini = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
        for (let i = 0; i < total; i++) {
          for (let i2 = 0; i2 < lateN; i2++) {
            newArrayInner.push({
              title: sum[i2].types[ini[i2] - 1].title,
              code: sum[i2].types[ini[i2] - 1].code,
            });
            if ((i + 1) % arrayFacto(i2) === 0) {
              ini[i2] === sum[i2].types.length ? (ini[i2] = 1) : ini[i2]++;
            }
          }
          /**S:가격표 필터링하여 생성안하는 로직 */
          let filterNow = newArrayInner.map((a) => {
            return a.code.toString();
          });
          let prepareA = NewClass.filter((a) => {
            return filterNow.includes(a.from);
          });
          let prepareB = prepareA.filter((B) => {
            return filterNow.includes(B.to);
          });
          if (prepareB.length < 1 && !filterNow.includes('SIZ:CUSTOM')) {
            newArray.push({
              selectors: newArrayInner,
              price: emptyPrice,
            });
          }
          /**E:가격표 필터링하여 생성안하는 로직 */
          newArrayInner = [];
        }
        return await newArray;
      }
    } catch (e) {
      console.error(e);
    }
  };
  /**
   * 금액입력 onchange Action
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, id } = e.target;
    let indexA = Number(id.split('-')[1]);
    let indexB = Number(id.split('-')[2]);
    let instant = { ...firstInputs.productTypes[indexA].price[indexB] };
    instant.value = Number(value.replace(/,/g, ''));
    setFirstInputs({
      ...firstInputs,
      productTypes: [
        ...firstInputs.productTypes.slice(0, indexA),
        {
          ...firstInputs.productTypes[indexA],
          price: [
            ...firstInputs.productTypes[indexA].price.slice(0, indexB),
            instant,
            ...firstInputs.productTypes[indexA].price.slice(indexB + 1),
          ],
        },
        ...firstInputs.productTypes.slice(indexA + 1),
      ],
    });
  };
  /**
   * 수량 onchange Action
   * @param onchange Target
   * @param v value
   */
  const handleChangeQty = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, id } = e.target;
    let indexA = Number(id.split('-')[1]);
    let indexB = Number(id.split('-')[2]);
    let ins = [...firstInputs.productTypes];
    let instant = JSON.parse(JSON.stringify(ins));
    instant[indexA].price[indexB].quantity = Number(value.replace(/,/g, ''));
    setFirstInputs({ ...firstInputs, productTypes: instant });
  };
  /**
   * 줄추가 액션
   * @param n 추가될줄 갯수number
   */
  const onCreate = (n: number) => {
    let newLength = {
      quantity: 0,
      value: 0,
    };
    let ins = [...firstInputs.productTypes];
    let instant = JSON.parse(JSON.stringify(ins));
    for (let i = 0; i < n; i++) {
      [...instant].forEach((e) => e.price.push(newLength));
    }
    let NewCopy = JSON.parse(JSON.stringify(instant));
    setInitialPriceLength(ipn);
    setFirstInputs({ ...firstInputs, productTypes: NewCopy });
  };
  /**
   * 개별 줄추가 액션
   * @param line 추가될줄 index number
   */
  const addLine = (idx: number, line: number) => {
    let newLength = {
      quantity: 0,
      value: 0,
    };
    let ins = [...firstInputs.productTypes];
    let instant = JSON.parse(JSON.stringify(ins));
    instant[idx].price.splice(line, 0, newLength);
    let NewCopy = JSON.parse(JSON.stringify(instant));
    setFirstInputs({ ...firstInputs, productTypes: NewCopy });
    setInitialPriceLength(ipn);
  };
  /**
   * 줄제거 액션
   * @param n 제거될줄 갯수number
   */
  const onRemove = (n: number) => {
    if (initialPriceLength > 1) {
      let instant = [...firstInputs.productTypes];
      instant.forEach((e) => e.price.pop());
      let NewCopy = JSON.parse(JSON.stringify(instant));
      setInitialPriceLength(ipn);
      setFirstInputs({ ...firstInputs, productTypes: NewCopy });
    } else {
      alert('최소 1줄의 가격이 있어야 합니다.');
    }
  };
  /**
   * 개별 줄제거 액션
   * @param line 제거될줄 index number
   */
  const delLine = (idx: number, line: number) => {
    let ins = [...firstInputs.productTypes];
    let instant = JSON.parse(JSON.stringify(ins));
    instant[idx].price.splice(line, 1);
    let NewCopy = JSON.parse(JSON.stringify(instant));
    setFirstInputs({ ...firstInputs, productTypes: NewCopy });
    setInitialPriceLength(ipn);
  };
  /** 복사 state */
  const [copiedArray, setCopiedArray] = useState<PriceCalcurateArr[]>([]);
  useEffect(() => {
    copiedArray.length > 0 && console.log('카피데이터있음');
  }, [copiedArray]);
  /**
   * 복사 액션
   * @param n 복사된 index string
   */
  const priceCopy = async (n: string) => {
    loadingHandler(true);
    let indexCopy = Number(n.split('-')[1]);
    let copyData = await firstInputs.productTypes[indexCopy];
    if (copyData) {
      setCopiedArray([copyData]);
      alert('데이터 복사성공!');
      loadingHandler(false);
    } else {
      alert('데이터 복사에 실패하였습니다. 다시 복사버튼을 눌러주세요.');
      loadingHandler(false);
    }
  };
  /**
   * 붙여넣기 액션
   * @param n 붙여넣을 index string
   * @constant firstInputs
   */
  const pricePaste = (n: string) => {
    loadingHandler(true);
    let indexCopy = Number(n.split('-')[1]);
    let copy = firstInputs.productTypes;
    copy[indexCopy].price = copiedArray[0].price;
    let NewCopy = JSON.parse(JSON.stringify(copy)); //Deep copy
    NewCopy &&
      setFirstInputs({
        ...firstInputs,
        productTypes: NewCopy,
      });
    loadingHandler(false);
  };
  /**
   * 리셋 액션
   * @param n 붙여넣을 index string
   * @constant firstInputs
   */
  const priceReset = (n: string) => {
    let indexCopy = Number(n.split('-')[1]);
    let copy = firstInputs.productTypes;
    copy[indexCopy].price.forEach((b) => {
      return (b.value = 0);
    });

    let NewCopy = JSON.parse(JSON.stringify(copy)); //Deep copy
    NewCopy &&
      setFirstInputs({
        ...firstInputs,
        productTypes: NewCopy,
      });
  };
  /**
   * 가격인푼에서의 키보드 액션
   * @param id 이동기준 index string
   * @param code 누른 키보드 key
   */
  const keyPressNow = (
    event: React.KeyboardEvent<HTMLElement>,
    id: string,
    code: string,
  ) => {
    let productTypes = firstInputs.productTypes.length - 1;
    let inputLength = firstInputs.productTypes[0].price.length - 1;
    let idA = String(id.split('-')[0]);
    let idB = Number(id.split('-')[1]);
    let idC = Number(id.split('-')[2]);
    if (code === 'NumpadEnter' || code === 'Enter') {
      if (idC < inputLength) {
        idC++;
      } else {
        alert('최하단입니다.');
        return;
      }
    } else if (code === 'ArrowUp') {
      if (!event.shiftKey) {
        if (idC > 0) {
          idC--;
        } else {
          alert('최상단입니다.');
          return;
        }
      }
    } else if (code === 'ArrowDown') {
      if (!event.shiftKey) {
        if (idC < inputLength) {
          idC++;
        } else {
          alert('최하단입니다.');
          return;
        }
      }
    } else if (code === 'ArrowLeft') {
      if (!event.shiftKey) {
        if (idB > 0) {
          if (idA !== 'qty') {
            idB--;
          } else {
            idA = 'val';
            idB--;
          }
        } else {
          if (idA !== 'qty') {
            idA = 'qty';
          } else {
            alert('첫 가격표입니다.');
          }
        }
      }
    } else if (code === 'ArrowRight') {
      if (!event.shiftKey) {
        if (idA !== 'qty') {
          if (idB < productTypes) {
            idB++;
          } else {
            alert('마지막 가격표입니다.');
          }
        } else {
          idA = 'val';
        }
      }
    }
    setIdAll(idA + '-' + idB + '-' + idC);
  };
  /** S:가격표 Length가 200이상일때 나눠서 뿌리는 로직 */
  const [displayNow, setDisplayNow] = useState<number>(0);
  const displayTotal = firstInputs.productTypes.length;
  /** E:가격표 Length가 200이상일때 나눠서 뿌리는 로직 */
  /** S:가격표 Infinite Scrolling */
  const targetWrap = useRef<HTMLDivElement>(null);
  const scrollCenter = (str: string) => {
    const wrapHeight = targetWrap.current ? targetWrap.current.offsetHeight : 0;
    const wrapScrollHeight = targetWrap.current
      ? targetWrap.current.scrollHeight
      : 0;
    const wrapWidth = targetWrap.current ? targetWrap.current.offsetWidth : 0;
    const wrapScrollWidth = targetWrap.current
      ? targetWrap.current.scrollWidth
      : 0;
    if (str == 'end') {
      targetWrap.current?.scrollTo({
        top: Number(wrapScrollHeight / 2) - wrapHeight,
        left: Number(wrapScrollWidth / 2) - wrapWidth,
        behavior: 'smooth',
      });
    } else {
      targetWrap.current?.scrollTo({
        top: Number(wrapScrollHeight / 2),
        left: Number(wrapScrollWidth / 2),
        behavior: 'smooth',
      });
    }
  };
  const handleTotal = (type: string) => {
    if (displayTotal > 200) {
      loadingHandler(true);
      if (type == 'start') {
        if (displayNow > 0) {
          setDisplayNow(displayNow - 100);
          scrollCenter('start');
        }
      } else if (type == 'end') {
        if (Number(displayNow + 100) < displayTotal) {
          setDisplayNow(displayNow + 100);
          scrollCenter('end');
        }
      }
      setTimeout(() => loadingHandler(false), 500);
    }
  };
  /** E:가격표 Infinite Scrolling */
  return (
    <>
      {positionN && <div className="position-bg"></div>}
      <div className={`position-wrap ${positionN ? 'fixed' : ''}`}>
        <div className="table-position-btn" onClick={() => positionChange()}>
          <i className={positionN ? 'fa fa-compress' : 'fa fa-expand'}></i>
        </div>
        <div className="price-table-wrap" ref={targetWrap}>
          <div className="pr-block-outer">
            {firstInputs.productTypes.map((codeA, idxA) => {
              if (idxA >= displayNow && idxA < Number(displayNow + 200)) {
                return (
                  <div className="pr-block-wrap" key={'selectors-' + idxA}>
                    {codeA.selectors.map((codeB, idxB) => {
                      if (idxB < codeA.selectors.length - 1) {
                        return (
                          <div
                            className={`pr-block ${idxB == 0 ? 'blue' : ''}`}
                            key={'selectors-' + idxA + '-' + idxB}
                          >
                            <p>
                              <span>{codeB.title}</span>
                            </p>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className="pr-table-wrap"
                            key={'selectors-' + idxA + '-' + idxB}
                          >
                            <table className="table table-striped yTable">
                              <thead>
                                <tr>
                                  <th>수량</th>
                                  <th>{codeB.title + ' 가격'}</th>
                                  <th>줄관리</th>
                                </tr>
                              </thead>
                              <tbody>
                                {codeA.price.map((codeP, idxP) => {
                                  return (
                                    <tr key={'tr-' + idxA + '-' + idxP}>
                                      <td>
                                        <NumberFormat
                                          id={'qty-' + idxA + '-' + idxP}
                                          className="qty-input"
                                          name="quantity"
                                          value={
                                            firstInputs.productTypes[idxA]
                                              .price[idxP].quantity !== 0
                                              ? firstInputs.productTypes[idxA]
                                                  .price[idxP].quantity
                                              : ''
                                          }
                                          onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>,
                                          ) => handleChangeQty(e)}
                                          onKeyDown={(
                                            event: React.KeyboardEvent<HTMLElement>,
                                          ) => {
                                            return keyPressNow(
                                              event,
                                              (event.target as HTMLElement).id,
                                              event.code,
                                            );
                                          }}
                                          autoComplete="off"
                                          thousandSeparator
                                        />
                                      </td>

                                      <td key={'td-' + idxA + '-' + idxP}>
                                        <NumberFormat
                                          id={'val-' + idxA + '-' + idxP}
                                          className="val-input"
                                          name="value"
                                          value={
                                            firstInputs.productTypes[idxA]
                                              .price[idxP].value !== 0
                                              ? firstInputs.productTypes[idxA]
                                                  .price[idxP].value
                                              : ''
                                          }
                                          onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>,
                                          ) => handleChange(e)}
                                          onKeyDown={(
                                            event: React.KeyboardEvent<HTMLElement>,
                                          ) => {
                                            return keyPressNow(
                                              event,
                                              (event.target as HTMLElement).id,
                                              event.code,
                                            );
                                          }}
                                          autoComplete="off"
                                          thousandSeparator
                                        />
                                      </td>
                                      <td>
                                        <div
                                          className="line-tools line-tool-plus"
                                          id={`plus-${idxP}`}
                                          onClick={() => addLine(idxA, idxP)}
                                        >
                                          <i className="fa fa-plus"></i>
                                        </div>
                                        <div
                                          className="line-tools line-tool-minus"
                                          id={`minus-${idxP}`}
                                          onClick={() => delLine(idxA, idxP)}
                                        >
                                          <i className="fa fa-minus"></i>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                                <tr>
                                  <td colSpan={3}>
                                    <div
                                      className="price-tools"
                                      id={'copyBtn-' + String(idxA)}
                                      onClick={(
                                        event: React.MouseEvent<HTMLElement>,
                                      ) =>
                                        priceCopy(
                                          (event.target as HTMLElement).id,
                                        )
                                      }
                                    >
                                      <i className="fa fa-clone"></i> 복사
                                    </div>
                                    <div
                                      id={'pasteBtn-' + String(idxA)}
                                      className="price-tools"
                                      onClick={(
                                        event: React.MouseEvent<HTMLElement>,
                                      ) =>
                                        pricePaste(
                                          (event.target as HTMLElement).id,
                                        )
                                      }
                                    >
                                      <i className="fa fa-paste"></i> 붙여넣기
                                    </div>
                                    <div
                                      id={'resetBtn-' + String(idxA)}
                                      className="price-tools"
                                      onClick={(
                                        event: React.MouseEvent<HTMLElement>,
                                      ) =>
                                        priceReset(
                                          (event.target as HTMLElement).id,
                                        )
                                      }
                                    >
                                      <i className="fa fa-undo"></i> 초기화
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        );
                      }
                    })}
                  </div>
                );
              } else {
                return;
              }
            })}
          </div>
        </div>
        {displayTotal > 200 && (
          <div className="price-table-filter-wrap">
            <div className="price-table-filter-total">
              (현재 {displayNow + 1}~ / 총 {displayTotal})
              <div className="total-btn-wrap">
                <div
                  className="line-tools"
                  onClick={() => handleTotal('start')}
                >
                  이전 <i className="fa fa-caret-up"></i>
                </div>
                <div className="line-tools" onClick={() => handleTotal('end')}>
                  다음 <i className="fa fa-caret-down"></i>
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className={`add-input-length ${
            positionN ? 'text-left' : 'text-center'
          } py-3`}
        >
          <div
            className="yBtn yBtn-xs blue text-center align-middle d-inline-block"
            onClick={() => onCreate(1)}
          >
            1줄 추가
          </div>
          <div
            className="yBtn yBtn-xs blue text-center align-middle d-inline-block"
            onClick={() => onCreate(5)}
          >
            5줄 추가
          </div>
          <div
            className="yBtn yBtn-xs blue text-center align-middle d-inline-block"
            onClick={() => onCreate(10)}
          >
            10줄 추가
          </div>

          <div
            className="yBtn yBtn-xs red text-center align-middle d-inline-block"
            onClick={() => onRemove(1)}
          >
            1줄 제거
          </div>
          {positionN && (
            <div className="floating-Btn pull-right">
              <div
                className="btn yBtn yBtn-small green btn-inline-block ms-2"
                data-toggle="tooltip"
                onClick={() => saveNow('save')}
              >
                임시저장
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
