import { useState, useEffect, Dispatch, SetStateAction, useRef } from 'react';
import axios from 'axios';
import {
  FirstInput,
  ProductListType,
  Order,
} from '@interfaces/tableCost.interfaces';
import { useLoading } from '@config/loadingContext';
import useFetch from '@utils/customHook/useFetch';
import { SERVER_URL } from '@config/path';
import { deleteEverything } from '@utils/common';
import { basicConst } from '@dummydata/dummy';

interface Props {
  firstInputs: FirstInput;
  setFirstInputs: Dispatch<SetStateAction<FirstInput>>;
  onLoad: (v: boolean) => void;
  optionChoose: boolean;
}

export default function OptionChoose({
  firstInputs,
  setFirstInputs,
  onLoad,
  optionChoose,
}: Props) {
  const { loadingNow, loadingHandler } = useLoading();
  const { payload, error } = useFetch<ProductListType>(`product`, loadingNow);

  const chooseProduct = async (productNameEN: string) => {
    loadingHandler(true);
    await axios
      .get(`${SERVER_URL}/product/productNameEN/${productNameEN}`)
      .then((res) => {
        let finalOption: Order[] = deleteEverything<Order[]>(
          [...res.data.selecters],
          [
            '_id',
            'adminType',
            'adminSelecters',
            'createdAt',
            'createdBy',
            'modifiedAt',
            'modifiedBy',
            '__v',
          ],
        );
        setFirstInputs((prev) => {
          return { ...prev, selecters: finalOption };
        });
        loadingHandler(false);
      })
      .catch((err) => {
        loadingHandler(false);
      });
  };

  const chooseDel = () => {
    setFirstInputs((prev) => {
      return { ...prev, selecters: basicConst.selecters };
    });
  };

  /** 가져올 상품 검색하기 */

  const [searchArr, setSearchArr] = useState<ProductListType[]>();

  const [optionSearch, setOptionSearch] = useState<string>('');

  const searchTarget = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOptionSearch(e.currentTarget.value);
  };

  useEffect(() => {
    const filt =
      optionSearch === ''
        ? payload?.data
        : payload?.data.filter(
            (b) => b.productNameKO.includes(optionSearch) === true,
          );
    setSearchArr(filt);
  }, [payload, optionSearch]);

  return (
    <>
      <div id="reorder-wrap" className="optionchoose-wrap">
        <div className="reorder-inner">
          <div className="reorder-cont">
            <h3>기준상품 옵션 가져오기</h3>
          </div>
          <input
            type="text"
            className="option_search"
            placeholder="검색할 상품명"
            value={optionSearch}
            onChange={(e) => searchTarget(e)}
          />
          <div className="reorder-choose">
            {searchArr &&
              searchArr.map((a, i) => {
                return (
                  <div className="option_list" key={i}>
                    <div className="left">{a.productNameKO}</div>
                    <div
                      className="right"
                      onClick={() => chooseProduct(a.productNameEN)}
                    >
                      선택
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="reorder-buttonWrap text-center">
            <div
              className="yBtn yBtn-xs gray me-2 d-inline-block"
              onClick={() => onLoad(false)}
            >
              닫기
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
