import React, { useState, useEffect, useCallback } from 'react';
import Modal from '@components/modal/ModalComp';
import UserModal from '@components/modal/userModal';
import { SERVER_URL } from '@config/path';
import moment from 'moment';
import { UserDummy } from '@dummydata/dummy';
import axios, { AxiosRequestConfig } from 'axios';
import { CONFIRM, MESSAGE, ERROR } from '@utils/textMessage';
import { useLoading } from '@config/loadingContext';
import { Tuser } from '@interfaces/globals.types';
import useFetchNoData from '@utils/customHook/useFetchNoData';
import { deleteEverything } from '@utils/common';
export default function CreateUser() {
  moment.locale();
  const { loadingNow, loadingHandler } = useLoading();
  const [userItem, setUserItem] = useState<Tuser>({ ...UserDummy });
  const [modal, setModal] = useState<{ [key: string]: boolean }>({
    userNew: false,
    edit: false,
  });

  const openModal = useCallback(
    (type: 'userNew' | 'userEdit', userId?: string) => {
      setModal({ ...modal, [type]: !modal[type] });
      if (userId) {
        let copy = [...userList].find((code) => code.userId === userId);
        copy && setUserItem(copy);
      }
    },
    [modal],
  );
  const { payload, error } = useFetchNoData<Tuser[]>(`user`, loadingNow);
  const [userList, setUserList] = useState<Tuser[]>([]);
  useEffect(() => {
    if (payload) {
      nsortBy(nsortNow.type, nsortNow.key, [...payload]);
    } else {
      console.log('payload yet!');
    }
  }, [payload]);

  const closeModal = (target: string) => {
    loadingHandler(true);
    setModal({ ...modal, [target]: false });
    setUserItem({ ...UserDummy });
    loadingHandler(false);
  };

  /** 테이블 sort 화살표 색상 */

  const [sortColor, setSortColor] = useState<string>('');

  /**테이블 Sort State */

  const [nsortNow, setNsortNow] = useState<{ type: boolean; key: string }>({
    type: false,
    key: 'createAt',
  });

  /**테이블 Sort Action */

  const [sortselect, setsortselect] = useState<boolean>(true);

  const nsortBy = (type: boolean, key: string, data: Tuser[]) => {
    setsortselect(!sortselect);
    setSortColor(String([key]));
    let copy = [...data];
    copy.sort((a, b) => {
      if (!type) {
        if (
          /^[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(String(a[key])) &&
          /^[a-zA-Z]/.test(String(b[key]))
        ) {
          return -1;
        }
        if (
          /^[a-zA-Z]/.test(String(a[key])) &&
          /^[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(String(b[key]))
        ) {
          return 1;
        }
        return String(a[key]) < String(b[key]) ? -1 : 1;
      } else {
        if (
          /^[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(String(a[key])) &&
          /^[a-zA-Z]/.test(String(b[key]))
        ) {
          return -1;
        }
        if (
          /^[a-zA-Z]/.test(String(a[key])) &&
          /^[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(String(b[key]))
        ) {
          return 1;
        }
        return String(a[key]) > String(b[key]) ? -1 : 1;
      }
    });
    setNsortNow({ type: type, key: key });
    setUserList(copy);
  };

  /** S: 후가공 API */
  const UseUserAPI = async (kind: 'new' | 'del' | 'edit', item: Tuser) => {
    loadingHandler(true);
    let userIDCopy = item._id;
    let finalPre = {
      ...item,
    };
    let finalCopy: Tuser = deleteEverything<Tuser>(finalPre, [
      '_id',
      'createdAt',
      'createdBy',
      'modifiedAt',
      'modifiedBy',
      '__v',
    ]);
    const requestOptions: AxiosRequestConfig =
      kind === 'new'
        ? {
            url: SERVER_URL + '/user/' + userIDCopy,
            data: finalPre,
            method: 'POST',
          }
        : kind === 'del'
        ? {
            url: SERVER_URL + '/addwork/pull?addworkId=' + userIDCopy,
            method: 'DELETE',
          }
        : {
            url: SERVER_URL + '/addwork/' + userIDCopy,
            data: finalCopy,
            method: 'PUT',
          };
    try {
      await axios(requestOptions);
      if (kind === 'del') {
        alert('삭제완료');
      } else {
        alert('저장완료');
        kind === 'new' ? closeModal('new') : closeModal('edit');
      }
      loadingHandler(false);
    } catch (e) {
      alert(`${kind !== 'del' ? '저장' : '삭제'} 실패`);
      loadingHandler(false);
      console.log(e);
    }
  };
  /** E: 후가공 API */
  return (
    <>
      <div className="page-title">
        <div className="title_left">
          <h3>
            <i className="fa fa-user" />
            계정생성 <small>계정 정보 등록</small>
            <div className="pull-right">
              <button
                className="topButtons purple"
                onClick={() => openModal('userNew')}
              >
                신규 관리자 등록
              </button>
            </div>
          </h3>
        </div>
      </div>
      <div className="clearfix"></div>
      <div className="row">
        <div className="col-12">
          <div className="contents-wrap x_panel setETC-table-wrap table-wrap-green">
            <div className="x_content priceList-table-wrap">
              <div className="react-bootstrap-table">
                <table
                  id="addwork-table"
                  className="table table-hover table-bordered"
                >
                  <thead>
                    <tr>
                      <th
                        className={`sortable ${
                          sortColor === 'userId' ? 'colorOn' : ''
                        }`}
                        onClick={() => {
                          nsortBy(!sortselect, 'userId', userList);
                        }}
                      >
                        아이디
                        <span
                          className={`order-4 ${
                            nsortNow.type === false ? 'setcolor' : 'nocolor'
                          }`}
                        ></span>
                      </th>
                      <th
                        className={`sortable ${
                          sortColor === 'userName' ? 'colorOn' : ''
                        }`}
                        onClick={() => {
                          nsortBy(!sortselect, 'userName', userList);
                        }}
                      >
                        이름
                        <span
                          className={`order-4 ${
                            nsortNow.type === false ? 'setcolor' : 'nocolor'
                          }`}
                        ></span>
                      </th>
                      <th
                        className={`sortable ${
                          sortColor === 'createdAt' ? 'colorOn' : ''
                        }`}
                        onClick={() => {
                          nsortBy(!sortselect, 'createdAt', userList);
                        }}
                      >
                        생성일
                        <span
                          className={`order-4 ${
                            nsortNow.type === false ? 'setcolor' : 'nocolor'
                          }`}
                        ></span>
                      </th>
                      <th
                        className={`sortable ${
                          sortColor === 'modifiedAt' ? 'colorOn' : ''
                        }`}
                        onClick={() => {
                          nsortBy(!sortselect, 'modifiedAt', userList);
                        }}
                      >
                        수정일
                        <span
                          className={`order-4 ${
                            nsortNow.type === false ? 'setcolor' : 'nocolor'
                          }`}
                        ></span>
                      </th>
                      <th className="">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList &&
                      userList.map((code, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{code.userId}</td>
                            <td>{code.userName}</td>
                            <td>
                              {' '}
                              {moment(code.createdAt).format(
                                'YYYY-MM-DD (HH:mm)',
                              )}
                            </td>
                            <td>
                              {' '}
                              {moment(code.modifiedAt).format(
                                'YYYY-MM-DD (HH:mm)',
                              )}
                            </td>

                            <td>
                              {code._id !== undefined ? (
                                <>
                                  <button
                                    className="tableBtn tableBtn-orange btnW-70"
                                    id="modify"
                                    onClick={() =>
                                      code._id &&
                                      openModal('userEdit', code._id)
                                    }
                                  >
                                    수정
                                  </button>
                                  <button
                                    className="tableBtn tableBtn-red btnW-70"
                                    id="del"
                                    onClick={() =>
                                      code._id && UseUserAPI('del', code)
                                    }
                                  >
                                    삭제
                                  </button>
                                </>
                              ) : (
                                <></>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            {modal.new && userItem && (
              <>
                <Modal
                  close={closeModal}
                  title={'신규 유저 등록'}
                  id={'smallModal'}
                >
                  <UserModal
                    userType="userNew"
                    userItem={userItem}
                    setUserItem={setUserItem}
                    UseUserAPI={UseUserAPI}
                    closeModal={closeModal}
                  />
                </Modal>
              </>
            )}
            {modal.edit && userItem && (
              <>
                <Modal close={closeModal} title={'유저 수정'} id={'userEdit'}>
                  <UserModal
                    userType="userEdit"
                    userItem={userItem}
                    setUserItem={setUserItem}
                    UseUserAPI={UseUserAPI}
                    closeModal={closeModal}
                  />
                </Modal>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
