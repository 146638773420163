import React, { useEffect, useState } from 'react';
import moment from 'moment';
import useFetch from '@utils/customHook/useFetch';
import ModalComp from '@components/modal/ModalComp';
import PaperModalInner from '@components/modal/calcCostComp/paper-modal';
import axios from 'axios';
import {
  CalPaper,
  CalculationProduct,
  BindSameKind,
} from '@interfaces/calculationProductModel.interface';
import { SERVER_URL } from '@config/path';
import { useLoading } from '@config/loadingContext';
import DeleteModal from '@components/modal/deleteModal';
import { filterProps } from 'recharts/types/util/types';

export default function Paper() {
  const { loadingNow, loadingHandler } = useLoading();
  const [modal, setModal] = React.useState<{
    [index: string]: boolean;
    new: boolean;
    edit: boolean;
  }>({
    new: false,
    edit: false,
  });
  const { payload, loading, error } = useFetch<CalculationProduct>(
    'calproduct',
    String(loadingNow) + String(Object.values(modal)),
  );
  const [selected, setSelected] = useState<boolean>(false);
  const [filtPaper, setFiltPaper] = useState<BindSameKind[]>([]);
  const [selectedPaper, setSelectedPaper] = useState<CalPaper[]>([]);
  const [paperKind, setPaperKind] = useState<string>();
  const [selectedItem, setSelectedItem] = useState<CalPaper>({
    paperName: '',
    code: '',
    'weight(g)': 0,
    'thickness(㎛)': 0,
    'gukJeon:price': 0,
    'gukJeon:discount': 0,
    '4*6:price': 0,
    '4*6:discount': 0,
    'smallGukJeon:price': 0,
    'smallGukJeon:discount': 0,
    discount: 0,
  });

  /**
   * 용지 별로 데이터를 합치는 작업
   * @param data 페치 받아온 데이터
   * @returns
   */
  useEffect(() => {
    if (payload) {
      let newPaper = bindSameKind(payload.data[0].paper);
      setFiltPaper(newPaper);
      groupnsortBy(groupnsortNow.type, groupnsortNow.key, newPaper);
      if (paperKind) {
        const filtCopy = newPaper.filter(
          (filt) => filt.paperName === paperKind,
        );
        if (filtCopy && filtCopy.length > 0) {
          setSelectedPaper(filtCopy[0].data);
          nsortBy(nsortNow.type, nsortNow.key, filtCopy[0].data);
        }
      }
    }
  }, [payload, paperKind]);
  const bindSameKind = (data?: CalPaper[]) => {
    if (!data) return [];
    let result = [];
    let innerObject: BindSameKind = {
      paperName: '',
      code: '',
      data: [],
    };
    for (let i = 0; i <= data.length - 1; i++) {
      let indexN = result.findIndex(
        (a) => a.paperName == data[i].paperName.split(' ')[0].split('-')[0],
      );
      if (indexN == -1) {
        innerObject.paperName = data[i].paperName.split(' ')[0].split('-')[0];
        innerObject.code = data[i].code.split('-')[0];
        innerObject.data.push(data[i]);
        result.push(innerObject);
        innerObject = { paperName: '', code: '', data: [] };
      } else {
        result[indexN].data.push(data[i]);
      }
    }
    return result;
  };
  const openModal = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const { id, value } = e.currentTarget;
      setModal({ ...modal, [id]: !modal[id] });
    },
    [modal],
  );

  const closeModal = (target: string) => {
    setModal({ ...modal, [target]: false });
    setSelectedItem({
      paperName: '',
      code: '',
      'weight(g)': 0,
      'thickness(㎛)': 0,
      'gukJeon:price': 0,
      'gukJeon:discount': 0,
      '4*6:price': 0,
      '4*6:discount': 0,
      'smallGukJeon:price': 0,
      'smallGukJeon:discount': 0,
      discount: 0,
    });
  };
  const delCost = async (idNow?: string) => {
    loadingHandler(true);
    if (payload) {
      confirm('삭제 하시겠습니까?')
        ? await axios
            .delete(
              SERVER_URL +
                '/calproduct?target=paper&id=' +
                payload.data[0]._id +
                '&innerId=' +
                idNow,
            )
            .then((res) => {
              res.data.result == 'OK' ? alert('삭제 완료') : alert('삭제 취소');
              loadingHandler(false);
            })
            .catch((err) => {
              alert('삭제 실패');
              console.log(err);
              loadingHandler(false);
            })
        : loadingHandler(false);
    }
  };

  /** 테이블 sort 화살표 색상 */

  const [sortColor, setSortColor] = useState<string>('');

  /**테이블 Sort State */

  const [groupnsortNow, setGroupNsortNow] = useState<{
    type: boolean;
    key: string;
  }>({
    type: false,
    key: 'paperName',
  });

  const [nsortNow, setNsortNow] = useState<{ type: boolean; key: string }>({
    type: false,
    key: 'paperName',
  });

  /**테이블 Sort Action */

  const [sortselect, setsortselect] = useState<boolean>(true);

  const groupnsortBy = (type: boolean, key: string, data: BindSameKind[]) => {
    setsortselect(!sortselect);
    setSortColor(String([key]));
    let copy = [...data];
    copy.sort((a, b) => {
      if (!type) {
        if (
          /^[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(String(a[key])) &&
          /^[a-zA-Z]/.test(String(b[key]))
        ) {
          return -1;
        }
        if (
          /^[a-zA-Z]/.test(String(a[key])) &&
          /^[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(String(b[key]))
        ) {
          return 1;
        }
        return String(a[key]) < String(b[key]) ? -1 : 1;
      } else {
        if (
          /^[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(String(a[key])) &&
          /^[a-zA-Z]/.test(String(b[key]))
        ) {
          return -1;
        }
        if (
          /^[a-zA-Z]/.test(String(a[key])) &&
          /^[ㄱ-ㅎㅏ-ㅣ가-힣]/.test(String(b[key]))
        ) {
          return 1;
        }
        return String(a[key]) > String(b[key]) ? -1 : 1;
      }
    });
    setFiltPaper(copy);

    setGroupNsortNow({ ...groupnsortNow, type: type, key: key });
  };

  const nsortBy = (type: boolean, key: string, data: CalPaper[]) => {
    setsortselect(!sortselect);
    setSortColor(String([key]));
    let copy = [...data];
    if (key !== 'paperName') {
      copy.sort((a, b) =>
        !type
          ? Number(a[key]) < Number(b[key])
            ? -1
            : 1
          : Number(a[key]) > Number(b[key])
          ? -1
          : 1,
      );
    } else {
      copy.sort((a, b) =>
        !type
          ? String(a[key]) < String(b[key])
            ? -1
            : 1
          : String(a[key]) > String(b[key])
          ? -1
          : 1,
      );
    }
    setSelectedPaper(copy);
    setNsortNow({ ...nsortNow, type: type, key: key });
  };

  /** 딜리트 모달 */

  const changeModal = (key: string, bool: boolean) => {
    setModal({ ...modal, [key]: bool });
  };

  const [delID, setDelID] = useState<string>();
  const openDelConfirm = (id: string | undefined) => {
    setDelID(id);
    changeModal('delete', true);
  };

  return (
    <>
      <div className="page-title">
        <div className="title_left">
          <h3>
            <i className="fa fa-star" />
            용지 <small>기준 판매 정보 등록</small>
            <div className="pull-right">
              <button
                id="new"
                className="topButtons purple"
                onClick={(e) => openModal(e)}
              >
                신규 용지 등록
              </button>
            </div>
          </h3>
        </div>

        {selected && (
          <>
            <button
              className="goBackBtn calcCostBtn"
              onClick={() => setSelected(false)}
            >
              이전으로
            </button>
          </>
        )}
      </div>
      <div className="clearfix"></div>
      <div className="row">
        <div className="col-12">
          {!selected ? (
            <div className="contents-wrap x_panel getProduct-table-wrap ">
              <div className="react-bootstrap-table">
                <table className="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th
                        className={`sortable ${
                          sortColor === 'paperName' ? 'colorOn' : ''
                        }`}
                        onClick={() => {
                          groupnsortBy(!sortselect, 'paperName', filtPaper);
                        }}
                      >
                        그룹용지명{' '}
                        <span
                          className={`order-4 ${
                            groupnsortNow.type === false
                              ? 'setcolor'
                              : 'nocolor'
                          }`}
                        ></span>
                      </th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filtPaper &&
                      filtPaper.map((code, idx) => {
                        return (
                          <tr key={idx}>
                            <td>
                              {code.paperName} ({code.code})
                              <p className="standardproductID ms-3">
                                {code.data.length}
                              </p>
                            </td>
                            <td>
                              <button
                                className="tableBtn tableBtn-orange btnW-70"
                                id="modify"
                                onClick={() => {
                                  setPaperKind(code.paperName);
                                  setSelected(true);
                                }}
                              >
                                선택
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="contents-wrap x_panel getProduct-table-wrap calcCost-tables">
              <div className="react-bootstrap-table">
                <table className="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th
                        className={`sortable ${
                          sortColor === 'paperName' ? 'colorOn' : ''
                        }`}
                        onClick={() => {
                          nsortBy(!sortselect, 'paperName', selectedPaper);
                        }}
                      >
                        용지명{' '}
                        <span
                          className={`order-4 ${
                            nsortNow.type === false ? 'setcolor' : 'nocolor'
                          }`}
                        ></span>
                      </th>
                      <th
                        className={`sortable ${
                          sortColor === 'weight(g)' ? 'colorOn' : ''
                        }`}
                        onClick={() => {
                          nsortBy(!sortselect, 'weight(g)', selectedPaper);
                        }}
                      >
                        평량{' '}
                        <span
                          className={`order-4 ${
                            nsortNow.type === false ? 'setcolor' : 'nocolor'
                          }`}
                        ></span>
                      </th>
                      <th
                        className={`sortable ${
                          sortColor === 'thickness(㎛)' ? 'colorOn' : ''
                        }`}
                        onClick={() => {
                          nsortBy(!sortselect, 'thickness(㎛)', selectedPaper);
                        }}
                      >
                        두께{' '}
                        <span
                          className={`order-4 ${
                            nsortNow.type === false ? 'setcolor' : 'nocolor'
                          }`}
                        ></span>
                      </th>
                      <th className="papercalcName">46절가</th>
                      <th>46절할인율</th>
                      <th className="papercalcName">국전가</th>
                      <th>국전할인율</th>
                      <th className="papercalcName">소국전가</th>
                      <th>소국전할인율</th>
                      <th>할인율</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedPaper.map((code, idx) => {
                      let createdAt = moment(code.createdAt).format(
                        'YYYY-MM-DD (HH:mm)',
                      );
                      let modifiedAt = moment(code.modifiedAt).format(
                        'YYYY-MM-DD (HH:mm)',
                      );
                      let modifiedBy = code.modifiedBy;
                      return (
                        <tr key={idx}>
                          <td>
                            <span>
                              {code.paperName} ({code.code})
                            </span>
                          </td>
                          <td>
                            <span>{code['weight(g)'].toLocaleString()} g</span>
                          </td>
                          <td>
                            <span>
                              {code['thickness(㎛)'].toLocaleString()} ㎛
                            </span>
                          </td>

                          <td>
                            <span>{code['4*6:price'].toLocaleString()} 원</span>
                          </td>
                          <td>{code['4*6:discount']}</td>
                          <td>
                            <span>
                              {code['gukJeon:price'].toLocaleString()} 원
                            </span>
                          </td>
                          <td>
                            <span>
                              {code['gukJeon:discount'].toLocaleString()}
                            </span>
                          </td>
                          <td>
                            {code['smallGukJeon:price'] === undefined ? (
                              <span>-</span>
                            ) : (
                              <span>
                                {code['smallGukJeon:price'].toLocaleString()} 원
                              </span>
                            )}
                          </td>
                          <td>
                            {code['smallGukJeon:discount'] === undefined ? (
                              <span>-</span>
                            ) : (
                              <span>
                                {code['smallGukJeon:discount'].toLocaleString()}
                              </span>
                            )}
                          </td>
                          <td>{code['discount']}</td>
                          <td>
                            <div className="log-trigger d-inline-block">
                              <button className="tableBtn tableBtn-purple btnW-80">
                                로그보기
                              </button>
                              <div className="log-hover">
                                <p>
                                  <span>생성일:</span> {createdAt}
                                </p>
                                <p>
                                  <span>수정일:</span> {modifiedAt}
                                </p>
                                <p>
                                  <span>수정한사람:</span>
                                  <span className="standardproductID ms-1">
                                    {modifiedBy}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <button
                              className="tableBtn tableBtn-orange btnW-70"
                              id="edit"
                              onClick={(e) => {
                                setSelectedItem(code), openModal(e);
                              }}
                            >
                              수정
                            </button>
                            <button
                              className="tableBtn tableBtn-red btnW-70"
                              id="del"
                              onClick={() => openDelConfirm(code._id)}
                            >
                              삭제
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {modal.new && (
            <>
              <PaperModalInner
                types="new"
                calcID={payload && payload.data[0]._id}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                closeModal={closeModal}
              />
            </>
          )}
          {modal.edit && (
            <>
              <PaperModalInner
                types="edit"
                calcID={payload && payload.data[0]._id}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                closeModal={closeModal}
              />
            </>
          )}
          {modal.delete && delID && (
            <DeleteModal
              delID={delID}
              delEvents={delCost}
              changeModal={changeModal}
            />
          )}
        </div>
      </div>
    </>
  );
}
