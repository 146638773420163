import type { Action } from '@interfaces/globals.types';

type authState = {
  user: string;
  loading: boolean;
  errorMessage: null | string;
};

export const initialState: authState = {
  user: '',
  loading: false,
  errorMessage: null,
};

export const AuthReducer = (
  initialState: authState,
  action: Action,
): authState => {
  switch (action.type) {
    case 'REQUEST_LOGIN':
      return {
        ...initialState,
        loading: true,
      };
    case 'LOGIN_SUCCESS':
      return {
        ...initialState,
        user: action.user,
        loading: false,
      };
    case 'LOGOUT':
      return {
        ...initialState,
        user: '',
      };
    case 'LOGIN_ERROR':
      return {
        ...initialState,
        loading: false,
        errorMessage: action.errorMessage,
      };
    default:
      throw new Error(`Unhandled action type`);
  }
};
