import CSS from 'csstype';

const Preview_STYLE: CSS.Properties = {
  position: 'fixed',
  width: '100%',
  height: '100%',
  maxWidth: 'calc(100% - 50px)',
  maxHeight: 'calc(100% - 50px)',
  overflow: 'auto',
  background: '#fff',
  padding: 0,
  zIndex: 100,
};
const OVERLAY_STYLE: CSS.Properties = {
  position: 'fixed',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  right: 0,
  background: 'rgba(0,0,0,.7)',
  zIndex: 100,
};
const CLOSE_STYLE: CSS.Properties = {
  position: 'fixed',
  width: '30px',
  height: '30px',
  textAlign: 'center',
  lineHeight: '20px',
  fontSize: '30px',
  top: '40px',
  right: '40px',
  padding: 0,
  zIndex: 100,
  border: 'none',
  background: 'none',
};
const IFRAME_STYLE: CSS.Properties = {
  width: '1px',
  display: 'block',
};
const simpleList_STYLE: CSS.Properties = {
  position: 'fixed',
  width: '100%',
  height: '100%',
  maxWidth: 'calc(100% - 50px)',
  maxHeight: 'calc(100% - 50px)',
  overflow: 'auto',
  background: '#fff',
  padding: 0,
  zIndex: 100,
};
export {
  Preview_STYLE,
  OVERLAY_STYLE,
  CLOSE_STYLE,
  IFRAME_STYLE,
  simpleList_STYLE,
};
