import React, { useState, Dispatch, SetStateAction } from 'react';
import { useLoading } from '@config/loadingContext';
interface IProps {
  paging: number;
  setPaging: Dispatch<SetStateAction<number>>;
  pageSize: number;
  pageLength: number;
}

export default function BoardPagination({
  paging,
  setPaging,
  pageSize,
  pageLength,
}: IProps) {
  const { loadingHandler } = useLoading();
  const changePage = (idx: number) => {
    loadingHandler(true);
    let numNow = 0;
    if (idx > pageLength) {
      numNow = pageLength;
    } else if (idx < 1) {
      numNow = 1;
    } else {
      numNow = idx;
    }
    setPaging(numNow);
    setTimeout(() => {
      loadingHandler(false);
    }, 1000);
  };

  const rangeMin = Math.floor((paging - 1) / pageSize) * pageSize + 1;
  return (
    <div className="pagedWrap">
      {rangeMin !== 1 ? (
        <button
          className="pageArrow"
          onClick={() => changePage(paging - pageSize)}
        >
          {'<<'}
        </button>
      ) : (
        <button className="pageArrow off">{'<<'}</button>
      )}

      {paging !== 1 ? (
        <button className="pageArrow" onClick={() => changePage(paging - 1)}>
          {'<'}
        </button>
      ) : (
        <button className="pageArrow off">{'<'}</button>
      )}
      {Array(pageSize)
        .fill(0)
        .map((a, i) => {
          return rangeMin + i;
        })
        .filter((b) => b > 0 && b <= pageLength)
        .map((code, idx) => {
          if (code === paging) {
            return (
              <button key={code} className="pageBtn on">
                {code}
              </button>
            );
          } else {
            return (
              <button
                key={code}
                className="pageBtn"
                onClick={() => changePage(code)}
              >
                {code}
              </button>
            );
          }
        })}
      {paging < pageLength ? (
        <button className="pageArrow" onClick={() => changePage(paging + 1)}>
          {'>'}
        </button>
      ) : (
        <button className="pageArrow off">{'>'}</button>
      )}
      {paging + pageSize < pageLength ? (
        <button
          className="pageArrow"
          onClick={() => changePage(paging + pageSize)}
        >
          {'>>'}
        </button>
      ) : (
        <button className="pageArrow off">{'>>'}</button>
      )}
    </div>
  );
}
