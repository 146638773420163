import React, {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useRef,
} from 'react';
import { SERVER_URL } from '@config/path';
import axios, { AxiosRequestConfig } from 'axios';
import { useLoading } from '@config/loadingContext';
import { ProductListType } from '@interfaces/tableCost.interfaces';
import {
  ICommonEachCalculation,
  TableName,
} from '@interfaces/calculationProductModel.interface';
import { CalcDummy, CalcItem } from '@dummydata/calcDummy';
import { CalcFakeDummy } from '@dummydata/calcDummyFake';
import CalcCostPrice from '@components/modal/calcCostComp/calcCost-price';
import CalcCostPriceRoll from '@components/modal/calcCostComp/calcCost-priceRoll';
import { deleteEverything } from '@utils/common';
interface IProps {
  calcType: string;
  newItem: ICommonEachCalculation;
  setNewItem: Dispatch<SetStateAction<ICommonEachCalculation>>;
  closeModal: (target: string) => void;
  productFilter?: ProductListType[];
  newTable: TableName[];
  setNewTable: Dispatch<SetStateAction<TableName[]>>;
}
export default function CalcCostModal({
  calcType,
  newItem,
  setNewItem,
  closeModal,
  productFilter,
  newTable,
  setNewTable,
}: IProps) {
  const { loadingHandler } = useLoading();
  const TitleNow = newItem.title;
  const SitedNow = newItem.site ? newItem.site : 'standard';
  /** 롤스티커 목록 */
  const RollList = [
    '롤스티커 계산셋팅',
    '롤스티커 폭조판관련표',
    '롤스티커 지대비',
    '롤스티커 코팅비',
    '롤스티커 난이도별 도무송비',
    '롤스티커 박인쇄비',
  ];
  const [editName, setEditname] = useState<boolean>(
    calcType === 'edit' ? false : true,
  );
  const changeName = (bool: boolean) => {
    setEditname(bool);
  };
  const ChangeItem = (e: React.ChangeEvent<HTMLInputElement>, NV?: string) => {
    const { name, value } = e.target;
    if (!NV) {
      setNewItem({
        ...newItem,
        [name]: value,
      });
    } else {
      setNewItem({
        ...newItem,
        [name]: NV,
      });
    }
  };

  /** S: 제품 연결관리 */
  const ChangeLinked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (!newItem.productId.includes(value)) {
      if (calcType === 'new') {
        let copyId = [...newItem.productId, value];
        setNewItem({ ...newItem, productId: copyId });
      } else {
        UseCalcAPI('add', { ...newItem }, value);
      }
    } else {
      if (calcType === 'new') {
        let copyId = newItem.productId.filter((a) => a !== value);
        setNewItem({ ...newItem, productId: copyId });
      } else {
        UseCalcAPI('del', { ...newItem }, value);
      }
    }
  };
  /** E: 제품 연결관리 */

  /** S: 데이터추가 */
  const AddCalcItem = () => {
    let copyItem = [...newItem.calcValue, { ...CalcItem }];
    setNewItem({ ...newItem, calcValue: [...copyItem] });
    let tableName = [
      ...newTable,
      {
        min: 0,
        max: 0,
        value: 0,
        siteValue: 0,
      },
    ];
    setNewTable(tableName);
  };
  /** E: 데이터추가 */
  /** S: 계산형 API */
  const UseCalcAPI = async (
    kind: 'add' | 'del' | 'post' | 'complete' | 'save',
    newarr: ICommonEachCalculation,
    productID?: string,
  ) => {
    loadingHandler(true);
    let calcIDCopy = newItem._id ? newItem._id : '';
    let finalPre = JSON.parse(JSON.stringify(newarr));
    let finalCopy: ICommonEachCalculation =
      deleteEverything<ICommonEachCalculation>(finalPre, [
        '_id',
        'adminType',
        'adminSelecters',
        'createdAt',
        'createdBy',
        'modifiedAt',
        'modifiedBy',
        '__v',
      ]);
    const requestOptions: AxiosRequestConfig =
      kind === 'add'
        ? {
            url:
              SERVER_URL +
              '/commonEachCalculation/push?id=' +
              calcIDCopy +
              '&targetId=' +
              productID,
            method: 'PUT',
          }
        : kind === 'del'
        ? {
            url:
              SERVER_URL +
              '/commonEachCalculation/pull?id=' +
              calcIDCopy +
              '&targetId=' +
              productID,
            method: 'PUT',
          }
        : kind === 'post'
        ? {
            url: SERVER_URL + '/commonEachCalculation',
            data: finalCopy,
            method: 'post',
          }
        : {
            url: SERVER_URL + '/commonEachCalculation/' + calcIDCopy,
            data: finalCopy,
            method: 'put',
          };
    try {
      const response = await axios(requestOptions);
      if (kind === 'post' || kind === 'complete') {
        alert('저장완료');
        setNewItem(JSON.parse(JSON.stringify(CalcDummy)));
        calcType === 'new'
          ? closeModal('new')
          : calcType === 'edit'
          ? closeModal('edit')
          : closeModal('sited');
      } else if (kind === 'add') {
        let copyId = productID ? [...newItem.productId, productID] : [];
        let result = JSON.parse(
          JSON.stringify({ ...newItem, productId: copyId }),
        );
        setNewItem(result);
      } else if (kind === 'del') {
        let copyId = newItem.productId.filter((a) => a !== productID);
        let result = JSON.parse(
          JSON.stringify({ ...newItem, productId: copyId }),
        );
        setNewItem(result);
      } else {
        response && setNewItem(response.data.data);
      }
      loadingHandler(false);
      return;
    } catch (e) {
      alert('저장실패!');
      loadingHandler(false);
      console.log(e);
    }
  };
  const godummy = () => {
    let NewCopy = newItem._id;
    let data = deleteEverything<ICommonEachCalculation>(CalcFakeDummy, [
      '_id',
      'createdAt',
      'createdBy',
      'modifiedAt',
      'modifiedBy',
      'catePriority',
      'subCatePriority',
      '__v',
    ]);
    setNewItem({ ...data, _id: NewCopy });
  };
  return (
    <>
      <div className="clearfix"></div>
      {/* <button onClick={() => godummy()}>고더미</button> */}
      <div className="row">
        <div className="col-12">
          <div className="addworkSelectWrap">
            <div className="calcItemTop newitem-bg contents-wrap x_panel p-4 mb-3">
              <div className="x_content">
                <div className="form-horizontal form-label-left">
                  <div className="newoption-wrap form-group">
                    <div className="row">
                      <div className="newoption-title cost-title col-md-2 col-sm-2 col-xs-12">
                        타이틀 (title)
                      </div>
                      <div className="newoption-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                        <div className="d-inline-block">
                          <input
                            type="text"
                            className="form-control halfinput"
                            name="title"
                            value={newItem.title}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              ChangeItem(e);
                            }}
                            disabled={!editName}
                          />
                        </div>
                        {calcType === 'edit' && (
                          <div className="d-inline-block ms-2">
                            <input
                              type="checkbox"
                              name="checkName"
                              id="checkName"
                              checked={editName}
                              onChange={() => changeName(!editName)}
                            />
                            <label htmlFor="checkName">수정</label>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="newoption-wrap form-group">
                    <div className="row">
                      <div className="newoption-title cost-title col-md-2 col-sm-2 col-xs-12">
                        종류 (type)
                      </div>
                      <div className="newoption-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                        <div className="newoption-radio">
                          <input
                            type="radio"
                            className="boxInputs"
                            id="typesingle"
                            name="type"
                            value={newItem.type}
                            checked={newItem.type == 'single'}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              ChangeItem(e, 'single');
                            }}
                          />
                          <label htmlFor="typesingle">개별</label>
                        </div>
                        <div className="newoption-radio">
                          <input
                            type="radio"
                            className="boxInputs"
                            id="typemulti"
                            name="type"
                            value={newItem.type}
                            checked={newItem.type == 'multi'}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              ChangeItem(e, 'multi');
                            }}
                          />
                          <label htmlFor="typemulti">공통</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="newoption-wrap form-group">
                    <div className="row">
                      <div className="newoption-title cost-title col-md-2 col-sm-2 col-xs-12">
                        데이터 타입 (infoType)
                      </div>
                      <div className="newoption-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                        <div className="newoption-radio">
                          <input
                            type="text"
                            className="form-control halfinput"
                            name="infoType"
                            value={newItem.infoType}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              ChangeItem(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="newoption-wrap form-group">
                    <div className="row">
                      <div className="newoption-title cost-title col-md-2 col-sm-2 col-xs-12">
                        제품 연결 (productId)
                      </div>
                      <div className="newoption-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                        <div className="newoption-radio">
                          {productFilter &&
                            productFilter.map((item) => {
                              return (
                                <div className="newoption-radio" key={item._id}>
                                  <input
                                    id={item._id}
                                    name="productId"
                                    type="checkbox"
                                    className="boxInputs"
                                    value={item._id}
                                    checked={
                                      newItem.productId &&
                                      newItem.productId.includes(item._id)
                                    }
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>,
                                    ) => ChangeLinked(e)}
                                  />
                                  <label htmlFor={item._id}>
                                    {item.productNameKO}
                                  </label>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix" />
          <div className="row mt-4">
            <h5 className="calcItem-title mb-4">
              <span>데이터</span>
            </h5>
            <div className="col-xs-12">
              {RollList && !RollList.includes(TitleNow) ? (
                <CalcCostPrice
                  SitedNow={SitedNow}
                  calcType={calcType}
                  newItem={newItem}
                  setNewItem={setNewItem}
                  newTable={newTable}
                  setNewTable={setNewTable}
                />
              ) : (
                <CalcCostPriceRoll
                  SitedNow={SitedNow}
                  calcType={calcType}
                  newItem={newItem}
                  setNewItem={setNewItem}
                  newTable={newTable}
                  setNewTable={setNewTable}
                />
              )}
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="row  pt-2 pb-3">
            <div className="col-sm-12 text-center complete-btn-wrap">
              <div
                className="btn yBtn yBtn-small blue btn btn-inline-block ms-2"
                data-toggle="tooltip"
                onClick={() => AddCalcItem()}
              >
                데이터 추가
              </div>
              {calcType === 'edit' && (
                <div
                  className="btn yBtn yBtn-small green btn btn-inline-block ms-2"
                  data-toggle="tooltip"
                  onClick={() => UseCalcAPI('save', { ...newItem })}
                >
                  임시 저장
                </div>
              )}
            </div>
            <div className="col-sm-12  text-center complete-btn-wrap my-4">
              <div
                className="price-start-btn btn btn-inline-block"
                data-toggle="tooltip"
                onClick={() => {
                  calcType === 'new'
                    ? UseCalcAPI('post', { ...newItem })
                    : UseCalcAPI('complete', { ...newItem });
                }}
              >
                <span>
                  <i className="fa fa-check me-1" aria-hidden="true"></i>
                  {calcType === 'new' ? '상품 생성' : '저장 후 닫기'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
