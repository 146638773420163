import React, { useState } from 'react';
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd';

export default function RestricStepTwo({
  secondColumns,
  setSecondColumns,
  restricNext,
  restricFinish,
}) {
  const [text, setText] = useState(true);
  const changeText = () => {
    setText(!text);
  };
  const onDragEnd = ({ source, destination }) => {
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null;

    // Make sure we're actually moving the item
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    )
      return null;

    // Set start and end variables
    const start = secondColumns[source.droppableId];
    const end = secondColumns[destination.droppableId];

    // If start is the same as end, we're in the same column
    if (start === end) {
      // Move the item within the list
      // Start by making a new list without the dragged item
      const newList = start.list.filter((_, idx) => idx !== source.index);
      const newCode = start.code.filter((_, idx) => idx !== source.index);

      // Then insert the item at the right location
      newList.splice(destination.index, 0, start.list[source.index]);
      newCode.splice(destination.index, 0, start.code[source.index]);

      // Then create a new copy of the column object
      const newCol = {
        id: start.id,
        title: start.title,
        type: start.type,
        list: newList,
        code: newCode,
      };

      // Update the state
      setSecondColumns((state) => ({ ...state, [newCol.id]: newCol }));
      return null;
    } else {
      // If start is different from end, we need to update multiple columns
      // Filter the start list like before
      const newStartList = start.list.filter((_, idx) => idx !== source.index);
      const newStartCode = start.code.filter((_, idx) => idx !== source.index);

      // Create a new start column
      const newStartCol = {
        id: start.id,
        title: start.title,
        type: start.type,
        list: newStartList,
        code: newStartCode,
      };

      // Make a new end list array
      const newEndList = end.list;
      const newEndCode = end.code;

      // Insert the item into the end list
      newEndList.splice(destination.index, 0, start.list[source.index]);
      newEndCode.splice(destination.index, 0, start.code[source.index]);

      // Create a new end column
      const newEndCol = {
        id: end.id,
        title: end.title,
        type: end.type,
        list: newEndList,
        code: newEndCode,
      };

      // Update the state
      setSecondColumns((state) => ({
        ...state,
        [newStartCol.id]: newStartCol,
        [newEndCol.id]: newEndCol,
      }));
      return null;
    }
  };
  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <div
          className={`StyledWrap ${
            Object.keys(secondColumns).length < 5 ? 'fourColum' : 'fifthColum'
          }`}
        >
          {Object.values(secondColumns).map((col) => (
            <Droppable droppableId={col.id} key={col.id} type={col.type}>
              {(provided) => (
                <div
                  className={`StyledColumn ${
                    col.type === 'typeA' ? 'StyledColumn2' : 'StyledColumn3'
                  }`}
                >
                  <h5>{col.title}</h5>
                  <ul
                    className={`StyledList ${
                      col.type === 'typeA' ? 'StyledList2' : 'StyledList3'
                    }`}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {col.code.map((code, i) => (
                      <Draggable key={code} draggableId={code} index={i}>
                        {(provided, snapshot) => (
                          <li
                            className={`StyledItem ${
                              snapshot.isDragging ? 'dragging' : ''
                            }
                                                        `}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div>{col.list[i]}</div>
                          </li>
                        )}
                      </Draggable>
                    ))}

                    {provided.placeholder}
                  </ul>
                </div>
              )}
            </Droppable>
          ))}
        </div>
      </DragDropContext>
      <div className="restrictTool my-3">
        <div className="restrictMemo-left">
          {secondColumns.to.list.length > 0 &&
            secondColumns.to2.list.length > 0 && (
              <>
                {text ? (
                  <>
                    {secondColumns.to &&
                      secondColumns.to.code.map((code, i) => {
                        if (code) {
                          return (
                            <span key={code} className="restrictBlue">
                              {i !== 0 ? ', ' : ''}
                              {secondColumns.to.list[i]}
                            </span>
                          );
                        } else {
                          return;
                        }
                      })}
                    <span> 선택 할 경우 </span>
                    {secondColumns.to2 &&
                      secondColumns.to2.code.map((code, i) => {
                        if (code) {
                          return (
                            <span key={code} className="restrictRed">
                              {i !== 0 ? ', ' : ''}
                              {secondColumns.to2.list[i]}
                            </span>
                          );
                        } else {
                          return;
                        }
                      })}
                    <span> 옵션이 나오지 않습니다.</span>
                  </>
                ) : (
                  <>
                    {secondColumns.to2 &&
                      secondColumns.to2.code.map((code, i) => {
                        if (code) {
                          return (
                            <span key={code} className="restrictRed">
                              {i !== 0 ? ', ' : ''}
                              {secondColumns.to2.list[i]}
                            </span>
                          );
                        } else {
                          return;
                        }
                      })}
                    <span> 선택 할 경우 </span>
                    {secondColumns.to &&
                      secondColumns.to.code.map((code, i) => {
                        if (code) {
                          return (
                            <span key={code} className="restrictBlue">
                              {i !== 0 ? ', ' : ''}
                              {secondColumns.to.list[i]}
                            </span>
                          );
                        } else {
                          return;
                        }
                      })}
                    <span> 옵션이 나오지 않습니다.</span>
                  </>
                )}
                <div
                  className="d-inline-block changeText"
                  onClick={() => changeText()}
                >
                  <i className="fa fa-arrows-h"></i>
                </div>
                <div className="clearfix"></div>
                <div className="restrictPreview-outer my-3">
                  <h3 className="mb-0">주문창 미리보기</h3>
                  <div className="restrictPreview-wrap p-3">
                    <div className="restrictPreview restrictPreviewA">
                      <h4 className="d-inline-block align-top">
                        {secondColumns.option1.title}
                      </h4>
                      <ul className="d-inline-block align-top">
                        {secondColumns.to.code.map((code, i) => {
                          if (code) {
                            return (
                              <li key={code}>{secondColumns.to.list[i]}</li>
                            );
                          } else {
                            return;
                          }
                        })}
                      </ul>
                    </div>
                    <div className="restrictPreview restrictPreviewB">
                      <h4 className="d-inline-block align-top">
                        {secondColumns.option2.title}
                      </h4>
                      <ul className="d-inline-block align-top">
                        {secondColumns.option2.code.map((code, i) => {
                          if (code) {
                            return (
                              <li key={code}>
                                {secondColumns.option2.list[i]}
                              </li>
                            );
                          } else {
                            return;
                          }
                        })}
                      </ul>
                    </div>
                    {secondColumns.option3 && (
                      <div className="restrictPreview restrictPreviewB">
                        <h4 className="d-inline-block align-top">
                          {secondColumns.option3.title}
                        </h4>
                        <ul className="d-inline-block align-top">
                          {secondColumns.option3.code.map((code, i) => {
                            if (code) {
                              return (
                                <li key={code}>
                                  {secondColumns.option3.list[i]}
                                </li>
                              );
                            } else {
                              return;
                            }
                          })}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
        </div>
        <div className="restrictMemo-right">
          <div className="restric-buttonWrap text-end mb-1">
            <div
              className="yBtn yBtn-md orange text-center d-inline-block"
              onClick={() => restricFinish()}
            >
              선택완료
            </div>
          </div>
          <div
            className="yBtn yBtn-xs d-inline-block text-center"
            onClick={() => restricNext()}
          >
            <i className="fa fa-refresh"></i>
            초기화
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    </>
  );
}
