import { useState, useEffect } from 'react';
import axios from 'axios';
import { useLoading } from '@config/loadingContext';
import { boardType } from '@interfaces/tableCost.interfaces';
import useFetchNoData from '@utils/customHook/useFetchNoData';
import { NEST_SERVER_URL } from '@config/path';

import ModalEmpty from '@components/modal/ModalEmpty';
import BardImgModal from '@components/modal/boardImgModal';

interface IProps {
  modalId: string | undefined;
  boardType: string | undefined;
  closeModal: () => void;
}

export default function BardAnswerModal({
  modalId,
  boardType,
  closeModal,
}: IProps) {
  const { payload } = useFetchNoData<boardType>(
    `board/${boardType}/${modalId}`,
    modalId,
  );
  useEffect(() => {
    if (payload) {
      setBoardInputs(payload);
    }
  }, [payload]);

  const [boardInputs, setBoardInputs] = useState<boardType>();

  const answerChange = (
    e: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value } = e.target;
    boardInputs && setBoardInputs({ ...boardInputs, [name]: value });
  };

  /* 답변 등록*/

  const { loadingNow, loadingHandler } = useLoading();

  const answerNow = async (_id: string) => {
    loadingHandler(true);
    if (boardInputs) {
      let answerCopy = {
        boardAnswer: boardInputs.boardAnswer,
        actionType: boardInputs.actionType,
      };

      try {
        const response = await axios.put(
          `${NEST_SERVER_URL}/board/${boardInputs.boardType}/${_id}?replyType=admin`,
          answerCopy,
        );

        if (response) {
          loadingHandler(false);
          alert('답변등록 완료');
          closeModal();
        }
      } catch (e) {
        console.log('답변 등록 실패');
        loadingHandler(false);
        alert('답변 및 처리여부를 작성해 주세요.');
      }
    }
  };

  //이미지 클릭시 이미지 모달

  const [imgModal, setImgModal] = useState<boolean>(false);
  const [imgscr, setImgscr] = useState<string>('');

  const imgModalopen = async (scr: string) => {
    setImgModal(true);
    setImgscr(scr);
  };

  const closeImgModal = () => {
    setImgModal(false);
  };

  return (
    <>
      {boardInputs && (
        <div className="boardwrite_wrap">
          <table className="boardwrite_table">
            <tbody>
              <tr>
                <th>문의유형</th>
                <td>
                  <input
                    type="text"
                    name="inquiry"
                    id="inquiry"
                    value={boardInputs.inquiry ? boardInputs.inquiry : ''}
                    disabled={true}
                    readOnly
                  />
                </td>
              </tr>
              {boardInputs.boardType === 'claim' ? (
                <>
                  <tr className="boardordernumber">
                    <th>주문번호</th>
                    <td>
                      <input
                        type="text"
                        id="orderNumber"
                        name="orderNumber"
                        value={
                          boardInputs.orderNumber ? boardInputs.orderNumber : ''
                        }
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>클레임 내용</th>
                    <td>
                      <input
                        type="text"
                        name="claimType"
                        id="claimType"
                        value={
                          boardInputs.claimType ? boardInputs.claimType : ''
                        }
                        disabled={true}
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>요청내용</th>
                    <td>
                      <input
                        type="text"
                        name="claimRequest"
                        id="claimRequest"
                        value={
                          boardInputs.claimRequest
                            ? boardInputs.claimRequest
                            : ''
                        }
                        disabled={true}
                        readOnly
                      />
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  <tr>
                    <th>제목</th>
                    <td>
                      <input
                        type="text"
                        name="claimRequest"
                        id="claimRequest"
                        value={boardInputs.title ? boardInputs.title : ''}
                        disabled={true}
                        readOnly
                      />
                    </td>
                  </tr>
                </>
              )}

              <tr>
                <th>상세내용</th>
                <td>
                  <textarea
                    name="boardDetail"
                    id="boardDetail"
                    value={
                      boardInputs.boardDetail ? boardInputs.boardDetail : ''
                    }
                    disabled={true}
                    readOnly
                  ></textarea>
                </td>
              </tr>
              <tr className="boardupimg">
                <th>첨부한 사진 </th>
                {boardInputs.boardType === 'claim' ? (
                  <td>
                    {boardInputs.boardFile[0] === undefined ? (
                      <span className="noImg">첨부한 이미지 없음</span>
                    ) : (
                      <div>
                        <img
                          src={
                            boardInputs.boardFile[0] &&
                            boardInputs.boardFile[0].url
                          }
                          alt="클레임 업로드 이미지1"
                          onClick={() =>
                            imgModalopen(boardInputs.boardFile[0].url)
                          }
                        />
                        <span>
                          {boardInputs.boardFile[0] &&
                            boardInputs.boardFile[0].fileName}
                        </span>
                      </div>
                    )}
                    {boardInputs.boardFile[1] === undefined ? (
                      <span className="noImg">첨부한 이미지 없음</span>
                    ) : (
                      <div>
                        <img
                          src={
                            boardInputs.boardFile[1] &&
                            boardInputs.boardFile[1].url
                          }
                          alt="클레임 업로드 이미지1"
                          onClick={() =>
                            imgModalopen(boardInputs.boardFile[1].url)
                          }
                        />
                        <span>
                          {boardInputs.boardFile[1] &&
                            boardInputs.boardFile[1].fileName}
                        </span>
                      </div>
                    )}
                    {boardInputs.boardFile[2] === undefined ? (
                      <span className="noImg">첨부한 이미지 없음</span>
                    ) : (
                      <div>
                        <img
                          src={
                            boardInputs.boardFile[2] &&
                            boardInputs.boardFile[2].url
                          }
                          alt="클레임 업로드 이미지1"
                          onClick={() =>
                            imgModalopen(boardInputs.boardFile[2].url)
                          }
                        />
                        <span>
                          {boardInputs.boardFile[2] &&
                            boardInputs.boardFile[2].fileName}
                        </span>
                      </div>
                    )}
                  </td>
                ) : (
                  <td>
                    {boardInputs.boardFile[0] === undefined ? (
                      <span className="noImg">첨부한 이미지 없음</span>
                    ) : (
                      <div>
                        <img
                          src={
                            boardInputs.boardFile[0] &&
                            boardInputs.boardFile[0].url
                          }
                          alt="클레임 업로드 이미지1"
                          onClick={() =>
                            imgModalopen(boardInputs.boardFile[0].url)
                          }
                        />
                        <span>
                          {boardInputs.boardFile[0] &&
                            boardInputs.boardFile[0].fileName}
                        </span>
                      </div>
                    )}
                  </td>
                )}
              </tr>
              <tr>
                <th>작성자</th>
                <td>
                  <input
                    type="text"
                    name="userName"
                    value={boardInputs.userName ? boardInputs.userName : ''}
                    readOnly
                  />
                </td>
              </tr>
              <tr className="boardnumber">
                <th>연락처</th>
                <td>
                  <input
                    type="text"
                    id="phoneAreaCode"
                    name="phoneAreaCode"
                    value={
                      boardInputs.phoneAreaCode && boardInputs.phoneAreaCode
                    }
                    readOnly
                  />
                  <span>-</span>
                  <input
                    type="text"
                    id="phonePrefix"
                    name="phonePrefix"
                    value={boardInputs.phonePrefix && boardInputs.phonePrefix}
                    readOnly
                  />
                  <span>-</span>
                  <input
                    type="text"
                    id="phoneSuffix"
                    maxLength={4}
                    name="phoneSuffix"
                    value={boardInputs.phoneSuffix && boardInputs.phoneSuffix}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>이메일</th>
                <td>
                  <input
                    type="text"
                    name="email"
                    value={boardInputs.email ? boardInputs.email : ''}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th colSpan={2} className="answer_area">
                  <div>담당자 답변 작성</div>
                </th>
              </tr>
              <tr>
                <th>담당자 답변</th>
                <td>
                  <textarea
                    name="boardAnswer"
                    id="boardAnswer"
                    onChange={(e) => answerChange(e)}
                    value={
                      boardInputs.boardAnswer ? boardInputs.boardAnswer : ''
                    }
                  ></textarea>
                </td>
              </tr>
              {boardInputs.boardType === 'claim' ? (
                <tr>
                  <th>처리여부</th>
                  <td>
                    <select
                      name="actionType"
                      id="actionType"
                      onChange={(e) => answerChange(e)}
                      value={
                        boardInputs.actionType ? boardInputs.actionType : ''
                      }
                    >
                      <option value="">내용을 선택해 주세요</option>
                      <option value="적립금환불">적립금 환불</option>
                      <option value="동일계좌환불">동일계좌 환불</option>
                      <option value="재작업진행">재작업 진행</option>
                      <option value="재작업불가">재작업 불가</option>
                      <option value="환불불가">환불 불가</option>
                      <option value="반품요청후진행">반품요청후 진행</option>
                      <option value="색상샘플확인후 진행">
                        색상샘플확인후 진행
                      </option>
                      <option value="전화상담후 처리">전화상담후 처리</option>
                      <option value="일반 처리">일반 처리</option>
                    </select>
                  </td>
                </tr>
              ) : (
                <></>
              )}
            </tbody>
          </table>
          <div className="boardedit_btn">
            {boardInputs.boardAnswer === undefined ? (
              <div onClick={() => answerNow(boardInputs._id)}>답변등록</div>
            ) : (
              <div onClick={() => answerNow(boardInputs._id)} className="pink">
                답변수정
              </div>
            )}

            <div onClick={() => closeModal()}>취소하기</div>
          </div>
        </div>
      )}
      {imgModal && (
        <ModalEmpty close={closeImgModal} id={'boardimgmodal'}>
          <BardImgModal
            modalId={modalId}
            imgsrc={imgscr}
            closeImgModal={closeImgModal}
          />
        </ModalEmpty>
      )}
    </>
  );
}
