import React, {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useRef,
} from 'react';
import { SERVER_URL } from '@config/path';
import axios, { AxiosRequestConfig } from 'axios';
import { CONFIRM, MESSAGE } from '@utils/textMessage';
import { useLoading } from '@config/loadingContext';
import { ProductListType } from '@interfaces/tableCost.interfaces';
import {
  ICommonEachCalculation,
  ICalcValue,
} from '@interfaces/calculationProductModel.interface';
interface IProps {
  siteName: string;
  newItem: ICommonEachCalculation;
  setNewItem: Dispatch<SetStateAction<ICommonEachCalculation>>;
  updateItem: ICommonEachCalculation;
  closeModal: (target: string) => void;
}
export default function CalcCostModal({
  siteName,
  newItem,
  setNewItem,
  updateItem,
  closeModal,
}: IProps) {
  const { loadingHandler } = useLoading();
  const [updateState, setUpdateState] = useState<string>('step1');
  useEffect(() => {
    setTimeout(() => startNewCalc(), 500);
  }, []);
  const startNewCalc = () => {
    loadingHandler(true);
    setUpdateState('step2');
    let bool = newItem.calcValue
      .map((codeA, idxA) => {
        let bool =
          codeA.calcTypeKO == updateItem.calcValue[idxA].calcTypeKO &&
          codeA.calcTypeEN == updateItem.calcValue[idxA].calcTypeEN;
        return bool;
      })
      .filter((codeF) => codeF == false);
    let result = bool.length > 0 ? false : true;
    const sumOf = (items: ICalcValue[]) => {
      let totalPrice = 0;
      items.forEach((a) => {
        a.option.forEach((b) => {
          totalPrice += a.option.length * b.value.length;
        });
      });
      return totalPrice;
    };
    let totalLengthA = sumOf(newItem.calcValue);
    let totalLengthB = sumOf(updateItem.calcValue);
    let result2 = totalLengthA === totalLengthB;
    result2 && console.log('옵션 갯수');

    result && result2
      ? setTimeout(() => {
          setUpdateState('step3');
          createSameCalc();
        }, 500)
      : setTimeout(() => {
          setUpdateState('step3');
          createDifferCalc();
        }, 500);
    loadingHandler(false);
  };
  const createSameCalc = () => {
    loadingHandler(true);
    let copy = {
      ...updateItem,
      _id: newItem._id,
      productId: newItem.productId,
      site: newItem.site,
      calcValue: newItem.calcValue.map((codeA, idxA) => {
        delete codeA._id;
        return {
          ...codeA,
          value: updateItem.calcValue[idxA].value,
          qty: updateItem.calcValue[idxA].qty,
          option: codeA.option.map((codeB, idxB) => {
            delete codeB._id;
            if (
              codeB.selectors.join('') ===
              updateItem.calcValue[idxA].option[idxB].selectors.join('')
            ) {
              return {
                ...codeB,
                value: updateItem.calcValue[idxA].option[idxB].value.map(
                  (codeC, idxC) => {
                    delete codeC._id;
                    if (codeC.thirdValue) {
                      return {
                        ...codeC,
                        siteValue: codeB.value[idxC].siteValue,
                        secondSiteValue: codeB.value[idxC].secondSiteValue,
                        thirdSiteValue: codeB.value[idxC].thirdSiteValue,
                      };
                    } else if (codeC.secondValue) {
                      return {
                        ...codeC,
                        siteValue: codeB.value[idxC].siteValue,
                        secondSiteValue: codeB.value[idxC].secondSiteValue,
                      };
                    } else {
                      return {
                        ...codeC,
                        siteValue: codeB.value[idxC].siteValue,
                      };
                    }
                  },
                ),
              };
            } else {
              return updateItem.calcValue[idxA].option[idxB];
            }
          }),
        };
      }),
    };
    copy && setNewItem(copy);
    setTimeout(() => setUpdateState('step4'), 500);
    loadingHandler(false);
  };
  const createDifferCalc = () => {
    loadingHandler(true);
    if (window.confirm(CONFIRM.DIFFERUP)) {
      let copy = {
        ...newItem,
        calcValue: updateItem.calcValue.map((codeA, idxA) => {
          delete codeA._id;
          return {
            ...codeA,
            option: codeA.option.map((codeB, idxB) => {
              delete codeB._id;
              return {
                ...codeB,
                value: codeB.value.map((codeC) => {
                  delete codeC._id;
                  return codeC;
                }),
              };
            }),
          };
        }),
      };
      delete copy.createdAt;
      delete copy.createdBy;
      delete copy.modifiedAt;
      delete copy.modifiedBy;
      delete copy.__v;
      copy && setNewItem(copy);
      setTimeout(() => setUpdateState('step4'), 500);
      loadingHandler(false);
    } else {
      alert('기준가격 업데이트 취소');
      setUpdateState('step1');
      closeModal('update');
      loadingHandler(false);
    }
    loadingHandler(false);
  };

  /**
   * 저장, 완료 Action
   */
  const saveNow = async () => {
    loadingHandler(true);
    let calcIDCopy = newItem._id;
    const requestOptions: AxiosRequestConfig = {
      url: SERVER_URL + '/commonEachCalculation/' + calcIDCopy,
      data: newItem,
      method: 'put',
    };
    try {
      const response = await axios(requestOptions);
      if (response) {
        alert(MESSAGE.SAVE);
        setUpdateState('step1');
        closeModal('update');
      }
      loadingHandler(false);
      return;
    } catch (e) {
      alert('저장실패!');
      setUpdateState('step1');
      closeModal('update');
      loadingHandler(false);
    }
  };
  return (
    <>
      <div
        className="modalY-overlay"
        onClick={() => closeModal('update')}
      ></div>
      <div id="standardproduct-window" className="preview-window preview-600">
        <div
          className="closeBtn"
          onClick={() => {
            closeModal('update');
          }}
        >
          <i className="fa fa-times"></i>
        </div>

        {updateItem && (
          <>
            <div className="bg-f7f7f7 p-5">
              <div className="contents-wrap x_panel">
                <div className="x_title">
                  <h4>
                    <span>
                      <i>{siteName}</i>
                    </span>{' '}
                    - {updateItem.title} 기준가격 관리
                  </h4>
                </div>
                <div className="clearfix"></div>
                <div className="x_content">
                  <div className="row standard-update">
                    <div
                      className={`col ${
                        updateState == 'step1' ? 'active' : 'complete'
                      }`}
                    >
                      <div className="standard-update-item">
                        <i className="fa fa-cloud-download"></i>
                        <p>
                          <span>업데이트된 기준가격 가져오기</span>
                        </p>
                      </div>
                    </div>
                    <div
                      className={`col ${
                        updateState == 'step1'
                          ? 'yet'
                          : updateState == 'step2'
                          ? 'active'
                          : 'complete'
                      }`}
                    >
                      <div className="standard-update-item">
                        <i className="fa fa-files-o"></i>
                        <p>
                          <span>가격표 기준 비교</span>
                        </p>
                      </div>
                    </div>
                    <div
                      className={`col ${
                        updateState == 'step3'
                          ? 'active'
                          : updateState == 'step4'
                          ? 'complete'
                          : 'yet'
                      }`}
                    >
                      <div className="standard-update-item">
                        <i className="fa fa-sort-numeric-asc"></i>
                        <p>
                          <span>사이트별 가격변경 값 적용</span>
                        </p>
                      </div>
                    </div>
                    {updateState == 'step4' && (
                      <div className="standard-update-complete">
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 130.2 130.2"
                        >
                          <circle
                            className="path circle"
                            fill="none"
                            stroke="#9a29fd"
                            strokeWidth="6"
                            strokeMiterlimit="10"
                            cx="65.1"
                            cy="65.1"
                            r="62.1"
                          />
                          <polyline
                            className="path check"
                            fill="none"
                            stroke="#9a29fd"
                            strokeWidth="6"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            points="100.2,40.2 51.5,88.8 29.8,67.5 "
                          />
                        </svg>{' '}
                        준비완료
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="row pt-2">
                <div className="col-sm-12  text-center complete-btn-wrap mt-4 mb-2">
                  {updateState == 'step4' ? (
                    <div
                      className="price-start-btn btn-inline-block"
                      data-toggle="tooltip"
                      onClick={() => {
                        saveNow();
                      }}
                    >
                      <span>
                        <i className="fa fa-check" aria-hidden="true"></i>
                        기준가격 업데이트
                      </span>
                    </div>
                  ) : (
                    <div className="price-start-btn btn-inline-block disabled">
                      <span>
                        <i className="fa fa-check" aria-hidden="true"></i>
                        기준가격 업데이트
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
