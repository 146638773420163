import { useState, useEffect } from 'react';
import useFetch from '@utils/customHook/useFetch';
import useFetchGetone from '@utils/customHook/useFetchGetone';
import moment from 'moment';
import axios from 'axios';
import { NEST_SERVER_URL, SERVER_URL } from '@config/path';
import { PopUp } from '@interfaces/tableCost.interfaces';
import 'dayjs/locale/ko';
import { useLoading } from '@config/loadingContext';
import PopupModal from '@components/modal/productmanager/popup-modal-inner';
import { popupDummy } from '@dummydata/dummy';
import useFetchNoData from '@utils/customHook/useFetchNoData';
interface ModalType {
  new: boolean;
  edit: boolean;
}
export default function Setpopup() {
  moment.locale();
  /** 로딩 state*/
  const { loadingNow, loadingHandler } = useLoading();
  const { payload, error } = useFetchNoData<PopUp[]>(`popup`, loadingNow);

  // 팝업 인풋
  const [popupInputs, setpopupInputs] = useState<PopUp>({
    ...popupDummy,
  });

  const [limitstyle, setlimitstyle] = useState<boolean>(false);

  /**S:사이트필터 */

  const [popupList, setpopupList] = useState<PopUp[]>([]);
  const [popupFilter, setpopupFilter] = useState<string[]>();
  const [popupFilterNow, setpopupFilterNow] = useState<string>('전체');

  useEffect(() => {
    if (payload) {
      let copy = [...payload];
      setpopupList(payload);
      let filt = copy
        .map((a) => {
          return a.applySite;
        })
        .flat()
        .filter(
          (thing: string, index: number, self: string[]) =>
            index === self.findIndex((t) => t === thing),
        );
      setpopupFilter(filt);
      changeProductFilter(popupFilterNow);
    } else {
      console.log('payload 실패');
    }
  }, [payload]);

  const changeProductFilter = (group: string) => {
    if (payload) {
      if (group !== '전체') {
        let copy = payload.filter((a) => a.applySite.includes(group));
        setpopupList(copy);
      } else {
        let copy = [...payload];
        setpopupList(copy);
      }
      setpopupFilterNow(group);
    } else {
      console.log('payload yet');
    }
  };

  /**수정 */

  const [editId, setEditId] = useState<string>();

  const { payload: editPayload } = useFetchGetone<PopUp>(
    `popup/${editId}`,
    editId,
  );

  useEffect(() => {
    if (editPayload) {
      setpopupInputs(editPayload.data);
    }
  }, [editPayload]);

  const EditPopupE = (_id: string | undefined) => {
    setEditId(_id);
    setModal({ ...modal, edit: true });
    popupList.filter((sdate) => sdate._id === _id)[0].sDate !== null
      ? setlimitstyle(true)
      : setlimitstyle(false);
  };

  const TotalPopupHandler = async (
    _id: string | undefined,
    turnPopup: boolean,
  ) => {
    loadingHandler(true);
    await axios
      .put(NEST_SERVER_URL + '/popup/' + _id, { isUsed: !turnPopup })
      .then(function (response) {
        loadingHandler(false);
      })
      .catch(function (error) {
        alert(error);
        loadingHandler(false);
      });
  };

  /**삭제 */
  const popupDel = async (_id: string) => {
    loadingHandler(true);
    confirm('삭제 하시겠습니까?')
      ? await axios
          .delete(NEST_SERVER_URL + '/popup/' + _id)
          .then((res) => {
            res.status == 200 ? alert('삭제 완료') : alert('삭제 취소');
            loadingHandler(false);
          })
          .catch((err) => {
            alert('삭제 실패');
            loadingHandler(false);
          })
      : '';
  };

  /** 테이블 sort 화살표 색상 */

  const [sortColor, setSortColor] = useState<string>('');

  /**테이블 Sort State */
  const [sortNow, setSortNow] = useState<{ [key: string]: boolean }>({
    appliedSites: false,
    title: false,
    isUsed: false,
  });
  /**테이블 Sort Action */
  const sortBy = (key: string) => {
    let copy = [...popupList];

    copy.sort((a, b) =>
      !sortNow[key]
        ? String(a[key]) < String(b[key])
          ? -1
          : 1
        : String(a[key]) > String(b[key])
        ? -1
        : 1,
    );
    setSortNow({ ...sortNow, [key]: !sortNow[key] });
    setpopupList(copy);
    setSortColor(String([key]));
  };
  /** 모달 state*/
  const [modal, setModal] = useState<ModalType>({ new: false, edit: false });
  const openModal = () => {
    setModal({ ...modal, new: true });
  };
  const closeModal = () => {
    setModal({ new: false, edit: false });
  };
  return (
    <>
      <div className="page-title">
        <div className="title_left">
          <h3>
            <i className="fa fa-cubes" />
            팝업등록 및 관리
            <div className="pull-right">
              <button className="goBackBtn" onClick={() => openModal()}>
                팝업 등록
              </button>
            </div>
          </h3>
        </div>
      </div>
      <div className="clearfix" />
      <div className="row">
        <div className="col-12">
          <div className="contents-wrap x_panel mb-2">
            <div className="groupFilter">
              <h5>사이트필터</h5>
              <div className="groupFilter-wrap purple">
                <div className="groupFilter-inner">
                  <div
                    className={`groupFilter-item ${
                      popupFilterNow == '전체' ? 'active' : ''
                    }`}
                    onClick={() => changeProductFilter('전체')}
                  >
                    전체
                  </div>
                  {popupFilter?.map((filt) => {
                    let NAME =
                      filt == 'printcity'
                        ? '프린트시티'
                        : filt == 'ecard21'
                        ? '명함천국'
                        : filt == 'iamdesign'
                        ? '아이엠디자인'
                        : filt == 'megaprint'
                        ? '메가프린트'
                        : filt;
                    return (
                      <div
                        key={filt}
                        className={`groupFilter-item ${
                          popupFilterNow == filt ? 'active' : ''
                        }`}
                        onClick={() => changeProductFilter(filt)}
                      >
                        {NAME}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="contents-wrap x_panel">
            <div className="x_content popup-table-wrap">
              {popupList && (
                <div className="react-bootstrap-table">
                  <table className="table table-hover table-bordered">
                    <thead>
                      <tr>
                        <th>번호</th>
                        <th
                          className={`sortable ${
                            sortColor === 'title' ? 'colorOn' : ''
                          }`}
                          onClick={() => {
                            sortBy('title');
                          }}
                        >
                          적용 사이트
                          <span
                            className={`order-4 ${
                              sortNow['title'] === true ? 'setcolor' : 'nocolor'
                            }`}
                          ></span>
                        </th>
                        <th
                          className={`sortable ${
                            sortColor === 'applySite' ? 'colorOn' : ''
                          }`}
                          onClick={() => {
                            sortBy('applySite');
                          }}
                        >
                          팝업 제목{' '}
                          <span
                            className={`order-4 ${
                              sortNow['applySite'] === true
                                ? 'setcolor'
                                : 'nocolor'
                            }`}
                          ></span>
                        </th>
                        <th>팝업 기간</th>
                        <th
                          className={`sortable ${
                            sortColor === 'isUsed' ? 'colorOn' : ''
                          }`}
                          onClick={() => {
                            sortBy('isUsed');
                          }}
                        >
                          진행상태{' '}
                          <span
                            className={`order-4 ${
                              sortNow['isUsed'] === true
                                ? 'setcolor'
                                : 'nocolor'
                            }`}
                          ></span>
                        </th>
                        <th>이미지</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {popupList.map((code, idx) => {
                        let time = moment(code.createdAt).format(
                          'YYYY-MM-DD (HH:mm)',
                        );
                        let time2 = moment(code.modifiedAt).format(
                          'YYYY-MM-DD (HH:mm)',
                        );
                        return (
                          <tr key={idx}>
                            <td>
                              <span>{idx + 1}</span>
                            </td>
                            <td>
                              <div>
                                {code.applySite.map((a, i) => {
                                  let leng = code.applySite.length - 1;
                                  let name =
                                    a == 'printcity'
                                      ? '프린트시티'
                                      : a == 'ecard21'
                                      ? '명함천국'
                                      : a == 'iamdesign'
                                      ? '아이엠디자인'
                                      : a == 'megaprint'
                                      ? '메가프린트'
                                      : a == 'speedcolor'
                                      ? '스피드칼라'
                                      : a;
                                  let siteUrl =
                                    a == 'printcity'
                                      ? 'https://www.printcity.co.kr/'
                                      : a == 'ecard21'
                                      ? 'https://www.ecard21.co.kr/'
                                      : a == 'iamdesign'
                                      ? 'https://www.iamdgn.com/'
                                      : a == 'megaprint'
                                      ? 'https://www.megaprint.co.kr/'
                                      : a == 'speedcolor'
                                      ? 'https://www.speedcolor.co.kr/'
                                      : a;
                                  if (i !== leng) {
                                    return (
                                      <a href={siteUrl} target="blink">
                                        {name} ,{' '}
                                      </a>
                                    );
                                  } else {
                                    return (
                                      <a href={siteUrl} target="blink">
                                        {name}
                                      </a>
                                    );
                                  }
                                })}
                              </div>
                            </td>
                            <td>{code.title}</td>
                            <td>
                              <div className="limitst">
                                {code.sDate == null && code.eDate == null ? (
                                  <span>기간 제한 없음</span>
                                ) : (
                                  <div className="limit-wrap">
                                    <div className="limit-btn">기간보기</div>
                                    <div className="limit-view">
                                      <span>
                                        {moment(code.sDate).format(
                                          'YYYY-MM-DD HH:mm',
                                        )}{' '}
                                        ~
                                      </span>
                                      <span>
                                        {moment(code.eDate).format(
                                          'YYYY-MM-DD HH:mm',
                                        )}{' '}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </td>
                            <td>
                              <label className="salesSwitch popupSwitch">
                                <input
                                  type="checkbox"
                                  checked={code.isUsed ? true : false}
                                  onChange={() =>
                                    TotalPopupHandler(code._id, code.isUsed)
                                  }
                                />
                                <span className="slider popupslider round"></span>
                                <p className={code.isUsed ? 'pNow' : 'pNowoff'}>
                                  {code.isUsed ? 'ON' : 'OFF'}
                                </p>
                              </label>
                            </td>
                            <td>
                              <div className="popup-imgWrap">
                                <button className="popup-imgBtn tableBtn tableBtn-green">
                                  팝업 미리보기
                                </button>
                                <div className="popup-img">
                                  <img
                                    src={code.imgUrl}
                                    alt={'팝업이미지' + idx}
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                className="tableBtn tableBtn-skyblue btnW-70 setPriceDelEvents setpriceBtn"
                                onClick={() => EditPopupE(code._id)}
                              >
                                <span className="mobileSpan">
                                  <i
                                    className="fa fa-pencil"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span className="deskSpan">수정하기</span>
                              </button>
                              <button
                                className="tableBtn tableBtn-red btnW-70 setPriceDelEvents setpriceBtn"
                                onClick={() => code._id && popupDel(code._id)}
                              >
                                <span className="mobileSpan">
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span className="deskSpan">삭제하기</span>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {modal.new && popupInputs && (
        <>
          <PopupModal
            type="new"
            popupInputs={popupInputs}
            setpopupInputs={setpopupInputs}
            closeModal={closeModal}
            limitstyle={limitstyle}
            setlimitstyle={setlimitstyle}
          />
        </>
      )}
      {modal.edit && popupInputs && (
        <>
          <PopupModal
            type="edit"
            popupInputs={popupInputs}
            setpopupInputs={setpopupInputs}
            closeModal={closeModal}
            limitstyle={limitstyle}
            setlimitstyle={setlimitstyle}
          />
        </>
      )}
    </>
  );
}
