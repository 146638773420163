import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import MegaModal from '@components/modal/megaModal';
import CodeReorder from '@components/table/standardproduct/reorder/code-reorder';
import OriginReorder from '@components/table/standardproduct/reorder/origin-reorder';
import OptionChoose from '@components/table/standardproduct/reorder/option-choose';
import Restriction from '@components/table/standardproduct/restriction/code-restriction';
import {
  Data,
  FirstInput,
  LetCode,
  ProductListType,
} from '@interfaces/tableCost.interfaces';
import RestricList from '@components/table/standardproduct/restriction/restric-list';
import { MESSAGE } from '@utils/textMessage';
import { useLoading } from '@config/loadingContext';
import Modal from '@components/modal/ModalComp';
import { title } from 'process';
import axios from 'axios';
import { MEGACodeUrl } from '@config/path';

interface Props {
  firstInputs: FirstInput;
  setFirstInputs: Dispatch<SetStateAction<FirstInput>>;
  NewClass: LetCode;
}
export default function CodeSelectTable({
  firstInputs,
  setFirstInputs,
  NewClass,
}: Props) {
  /** 모달오픈여부 state*/
  const [isOpen, setIsOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);
  /** 리오더오픈여부 state*/
  const [reOrder, setReOrder] = useState(false);
  const [reOrderIndex, setReOrderIndex] = useState(0);
  const [originReorder, setOriginReorder] = useState(false);
  /** 제한사항 state*/
  const [restrict, setRestrict] = useState(false);
  const { selecters } = firstInputs;

  const [cateBool, setCateBool] = useState<boolean[]>(
    selecters.map(() => {
      return false;
    }),
  );
  /**
   * 카테고리코드 onchange 액션
   * @param index selecter index
   */
  const cateBoolChange = (index: number) => {
    let copy = [...cateBool];
    copy[index] = !cateBool[index];
    setCateBool(copy);
  };
  /**
   * 옵션명 onchange 액션
   * @param index selecter index
   * @constant firstInput
   */
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const { name, value } = e.target;
    selecters[index][name] = value;
    setFirstInputs({ ...firstInputs, selecters });
  };
  /**
   * 옵션 제거 액션
   * @param index selecter index
   * @constant firstInput
   */
  const onRemove = (index: number) => {
    if (window.confirm('옵션을 삭제하시겠습니까?')) {
      selecters.splice(index, 1);
      setFirstInputs({ ...firstInputs, selecters });
      setCateBool(cateBool.splice(index, 1));
    }
  };
  /**
   * 옵션 순서 변경 액션
   * @param index selecter index
   * @constant firstInput
   */
  const onReorder = (index: number) => {
    if (selecters[index].types.length < 2) {
      alert('주문옵션이 2개이상이어야 합니다');
    } else {
      setReOrder(!reOrder);
      setReOrderIndex(index);
      setCateBool(
        selecters.map(() => {
          return false;
        }),
      );
    }
  };
  /**
   * 옵션 추가 액션
   * @constant firstInput
   */
  const nextId = selecters.length;
  const onCreate = () => {
    const inputChange = {
      priceOrder: nextId,
      originally: nextId,
      selecterName: '',
      kindCode: '',
      types: [
        {
          class: [],
          code: '',
          title: '',
          options: {},
        },
      ],
    };
    selecters.push(inputChange);
    setFirstInputs({ ...firstInputs, selecters });
    setCateBool([...cateBool, false]);
  };
  /**
   * 옵션순서 변경 액션
   * @constant restrict
   */
  const onSortOrign = (code: boolean) => {
    if (selecters.length < 2) {
      alert('주문옵션이 2개이상이어야 합니다');
    } else {
      setOriginReorder(code);
      setCateBool(
        selecters.map(() => {
          return false;
        }),
      );
    }
  };
  /**
   * 제한사항 추가 액션
   * @constant restrict
   */
  const onRestrict = () => {
    if (
      firstInputs.selecters.filter((item) => !item.selecterName).length > 0 ||
      firstInputs.selecters.filter((item) => item.types.length < 1).length > 0
    ) {
      alert(MESSAGE.EMPTYOPTION);
    } else {
      //제한사항 시작
      if (selecters.length < 2) {
        alert(MESSAGE.MORE2OPTION);
      } else {
        setRestrict(!restrict);
      }
    }
    //주문옵션 코드값 입력을 안한게 있을때의 경우를 추가해야함
  };

  /**
   * 메가모달 옵션준비
   */
  const [megaData, setMegaData] = useState<Data[]>([]);
  useEffect(() => {
    async function getMegaData() {
      await axios
        .get(MEGACodeUrl)
        .then(function (response) {
          setMegaData(response.data);
        })
        .catch(function (error) {
          console.log('메가 데이터 로드에 실패 하였습니다.');
        });
    }
    getMegaData();
  }, []);
  /**
   * 메가모달오픈 시 초기설정 액션
   * @constant megaNav
   */
  const [megaNav, setMegaNav] = useState<{
    kind: string;
    items: string[];
  } | null>(null);
  const changeMegaNav = (index: number) => {
    let bkind = String(firstInputs.selecters[index].kindCode);
    let bcode = firstInputs.selecters[index].types.map((b) => {
      return b.code;
    });
    setMegaNav({ kind: bkind, items: bcode });
  };

  /** 옵션 가져오기 액션 */
  const [optionChoose, setOptionChoose] = useState(false);

  const onLoad = (code: boolean) => {
    setOptionChoose(!optionChoose);
  };

  return (
    <>
      <table id="code-select-table" className="table yTable" cellSpacing="0">
        <thead>
          <tr>
            <th>옵션명</th>
            <th>카테고리코드</th>
            <th>주문옵션 코드값</th>
          </tr>
        </thead>
        <tbody>
          {selecters &&
            selecters.map((newCategoryMap, index) => {
              return (
                <tr key={index}>
                  <td className="col-2 col-sm-2  col-xs-12">
                    <input
                      type="text"
                      className="form-control megaModalTitle"
                      placeholder="옵션 타이틀입력해주세요"
                      name="selecterName"
                      value={newCategoryMap.selecterName || ''}
                      onChange={(e) => handleChange(e, index)}
                      required
                    />
                  </td>
                  <td className="col-2 col-sm-2 col-xs-12">
                    <input
                      type="text"
                      className="form-control cateBool d-inline-block"
                      placeholder="옵션 타이틀입력해주세요"
                      name="kindCode"
                      value={newCategoryMap.kindCode || ''}
                      onChange={(e) => handleChange(e, index)}
                      disabled={!cateBool[index]}
                      required
                    />
                    <input
                      className="form-control cateBoolCheck ms-1 d-inline-block"
                      type="checkbox"
                      checked={cateBool[index]}
                      onChange={() => cateBoolChange(index)}
                    />
                  </td>
                  <td className="col-8 col-sm-8 col-xs-12">
                    <div className="option-input-group-wrap ub">
                      <div className="input-group">
                        <div
                          className="form-control touchOnly text-start megaModalBtn"
                          onClick={() => [
                            setIsOpen(true),
                            setModalIndex(index),
                            changeMegaNav(index),
                          ]}
                          name="types"
                        >
                          {firstInputs.selecters[index].types === undefined ||
                          firstInputs.selecters[index].types.length === 0 ||
                          firstInputs.selecters[index].types[0].code === ''
                            ? '빈공간을 클릭하면 주문옵션 코드선택 창이 나옵니다.'
                            : firstInputs.selecters[index].types.map((b, i) => {
                                return (i ? ', ' : '') + b.title;
                              })}
                        </div>
                        {index !== 0 && (
                          <div
                            className="input-group-btn input-minus-btn"
                            onClick={() => onRemove(index)}
                          >
                            <i className="fa fa-minus"></i>
                          </div>
                        )}
                        <div
                          className="input-group-btn input-reorder-btn"
                          onClick={() => onReorder(index)}
                        >
                          <i className="fa fa-sort"></i>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {reOrder && (
        <CodeReorder
          firstInputs={firstInputs}
          setFirstInputs={setFirstInputs}
          reOrderIndex={reOrderIndex}
          setReOrder={setReOrder}
        />
      )}
      <div className="input-group-btn mt-3 mb-3 mx-auto d-block text-center align-middle">
        <div
          className="yBtn yBtn-small blue text-center d-inline-block"
          onClick={() => onCreate()}
        >
          <i className="fa fa-plus"></i> 옵션추가하기
        </div>
        <div
          className="yBtn yBtn-small green text-center d-inline-block ms-2"
          onClick={() => onSortOrign(!originReorder)}
        >
          <i className="fa fa-sort"></i> 옵션순서변경
        </div>
        <div
          className="yBtn yBtn-small orange text-center d-inline-block ms-2"
          onClick={() => onRestrict()}
        >
          <i className="fa fa-asterisk"></i> 제한사항
        </div>
        <div
          className="yBtn yBtn-small red text-center d-inline-block ms-2"
          onClick={() => onLoad(!optionChoose)}
        >
          <i className="fa fa-asterisk"></i> 옵션가져오기
        </div>
      </div>
      {originReorder && (
        <OriginReorder
          firstInputs={firstInputs}
          setFirstInputs={setFirstInputs}
          onSortOrign={onSortOrign}
        />
      )}
      {!restrict ? (
        <RestricList
          firstInputs={firstInputs}
          setFirstInputs={setFirstInputs}
          NewClass={NewClass}
        />
      ) : (
        <Restriction
          firstInputs={firstInputs}
          setFirstInputs={setFirstInputs}
          restrict={restrict}
          setRestrict={setRestrict}
          NewClass={NewClass}
        />
      )}
      <MegaModal
        open={isOpen}
        setIsOpen={setIsOpen}
        firstInputs={firstInputs}
        setFirstInputs={setFirstInputs}
        megaData={megaData}
        setMegaData={setMegaData}
        modalIndex={modalIndex}
        megaNav={megaNav}
      />
      {optionChoose && (
        <OptionChoose
          firstInputs={firstInputs}
          setFirstInputs={setFirstInputs}
          onLoad={onLoad}
          optionChoose={optionChoose}
        />
      )}
    </>
  );
}
