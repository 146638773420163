import { Site } from '@interfaces/site.interface';
import { equal } from 'assert';
/**
 * @param inputs Site 같은 배열
 * @returns
 */
export default function sitealertCategorize(
  inputs: Site,
) {
  return !inputs.topSeller || inputs.topSeller==='empty'
    ? alert('최상위 판매업체를 선택해주세요')

    : inputs.sellerName.length < 1
    ? alert('업체명을 적어주세요')

    : inputs.sellerNameEN.length < 1 
    ? alert('업체영문명을 적어주세요')

    : inputs.siteUrl.length < 1
    ? alert('사이트 url을 적어주세요')

    : inputs.adminName.length < 1
    ? alert('관리자 이름을 적어주세요')

    : !inputs.adminPosition || inputs.adminPosition==='empty'
    ? alert('관리자 직급을 선택해주세요') 

    : inputs.IdNumber.length < 1
    ? alert('사원번호를 적어주세요')

    : inputs.pw.length < 1
    ? alert('비밀번호를 적어주세요')
    
    : true;
}

