import { useState } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { PriceCalcurateArr } from '@interfaces/tableCost.interfaces';
import { useLoading } from '@config/loadingContext';
import { ICouponData } from '@interfaces/tableCost.interfaces';

interface IProps {
  useFilter: string;
  buyFilter: string;
  useridNow: string;
  useProductFilter: string;
}

export const ExcelDownloadCoupon = ({
  useFilter,
  buyFilter,
  useridNow,
  useProductFilter,
}: IProps): JSX.Element => {
  const { loadingHandler } = useLoading();
  //const headers = ['쿠폰번호'];

  const getTransactionData = async () => {
    loadingHandler(true);
    try {
      const response = await axios.get(
        `https://price-api.dtp21.com/v2/event-coupon?partialMatch=true${
          useridNow !== '' ? '&userId=' + useridNow : ''
        }${
          useFilter === 'all'
            ? ''
            : useFilter === 'used'
            ? '&isUsed=true'
            : '&isUsed=false'
        }${
          buyFilter === 'all'
            ? ''
            : buyFilter === 'used'
            ? '&isPaid=true'
            : '&isPaid=false'
        }${
          useProductFilter !== 'all' ? '&productName=' + useProductFilter : ''
        }`,
        // ${
        //   useFilter === 'all'
        //     ? ''
        //     : useridNow === ''
        //     ? useFilter === 'used'
        //       ? '?isUsed=true'
        //       : '?isUsed=false'
        //     : useFilter === 'used'
        //     ? '&isUsed=true'
        //     : '&isUsed=false'
        // }${
        //   buyFilter === 'all'
        //     ? ''
        //     : useridNow === '' && useFilter === 'all'
        //     ? buyFilter === 'used'
        //       ? '?isPaid=true'
        //       : '?isPaid=false'
        //     : buyFilter === 'used'
        //     ? '&isPaid=true'
        //     : '&isPaid=false'
        // }${
        //   useProductFilter === 'all'
        //     ? ''
        //     : useridNow === '' && useFilter === 'all' && buyFilter === 'all'
        //     ? '?productName=' + useProductFilter
        //     : '&productName=' + useProductFilter
        // }
      );

      const responseData = response.data.data;

      generateExcel(responseData);
      loadingHandler(false);
    } catch (error) {
      console.log(error);
      loadingHandler(false);
    }
  };

  const generateExcel = (couponNum: ICouponData[]) => {
    const data = couponNum?.map((e) => {
      return { 쿠폰번호: e.couponCode };
    });

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data || []); //{ header: headers }
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'buffer',
      bookSST: false,
    });

    const bom = ''; // BOM (Byte Order Mark) for ANSI encoding
    const blob = new Blob([bom, excelBuffer], {
      type: 'application/octet-stream',
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `쿠폰리스트${new Date(Date.now()).toLocaleString()}.xlsx`,
    );
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <div className="d-block" onClick={getTransactionData}>
        쿠폰리스트 엑셀 다운로드
      </div>
    </>
  );
};
