import React, { useState, useEffect } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { CONFIRM, MESSAGE, ERROR } from '@utils/textMessage';
import { SERVER_URL } from '@config/path';
import { FirstInput } from '@interfaces/tableCost.interfaces';
import { AutoDiscountType } from '@interfaces/site.interface';
import { useLoading } from '@config/loadingContext';
import {
  ICommonEachCalculation,
  ICalcValue,
} from '@interfaces/calculationProductModel.interface';
import { deleteEverything } from '@utils/common';
import NumberFormat from 'react-number-format';

type QtyListType = {
  _id?: string;
  qty: number;
  rate: number;
};

interface Props {
  type: string;
  selectedAuto?: AutoDiscountType;
  firstInputs: FirstInput;
  autoProductId: string;
  autoProductType: string;
  changeModal: (type: string, bool: boolean) => void;
}

export default function SPAutoDiscount({
  type,
  selectedAuto,
  firstInputs,
  autoProductId,
  autoProductType,
  changeModal,
}: Props) {
  const { loadingHandler } = useLoading();
  const [qtyList, setQtyList] = useState<QtyListType[]>([]); //수량별 할인율 State
  useEffect(() => {
    if (type === 'new') {
      if (firstInputs.typeOfPriceDetermintion === 'priceComplete') {
        getTableQty();
      } else {
        getCalcQty();
      }
    } else {
      getExistQty();
    }
  }, []);
  const getTableQty = () => {
    let copy = firstInputs.productTypes[0].price.map((a) => {
      return { qty: a.quantity ? a.quantity : 0, rate: 0 };
    });
    setQtyList(copy);
  };
  const getCalcQty = async () => {
    loadingHandler(true);
    await axios
      .get(`${SERVER_URL}/commonEachCalculation?productId=${autoProductId}`)
      .then((res) => {
        let id = res.data.data.filter(
          (a: ICommonEachCalculation) => a.infoType === 'qtyMargin',
        )[0]?._id;
        id &&
          axios
            .get(`${SERVER_URL}/commonEachCalculation/${id}`)
            .then((res) => {
              let newQty = res.data.data.calcValue
                .filter((a: ICalcValue) => a.calcTypeEN == 'qty')[0]
                .qty.map((b: number[]) => {
                  return { qty: b, rate: 0 };
                });
              setQtyList(newQty);
              loadingHandler(false);
            })
            .catch((error) => {
              window.alert(`${ERROR.SERVER} ${error}`);
              loadingHandler(false);
            });
      })
      .catch((error) => {
        window.alert(`${ERROR.SERVER} ${error}`);
        loadingHandler(false);
      });
  };
  const getExistQty = async () => {
    if (selectedAuto) {
      setQtyList([...selectedAuto.discount]);
    }
  };
  const qtyListChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number,
  ) => {
    let { value } = e.target;
    let copy = [...qtyList];
    copy[idx].rate = Number(value.replace(/,/g, ''));
    setQtyList(copy);
  };
  const saveNow = async () => {
    loadingHandler(true);
    let saveBody = deleteEverything<AutoDiscountType>(
      {
        relatedProducts: [autoProductId],
        discount: [...qtyList],
      },
      ['_id', '__v'],
    );
    const requestOptions: AxiosRequestConfig = {
      url:
        type === 'edit' && selectedAuto
          ? `${SERVER_URL}/discount/${selectedAuto._id}`
          : `${SERVER_URL}/discount`,
      data: saveBody,
      method: type === 'edit' ? 'put' : 'post',
    };
    try {
      const response = await axios(requestOptions);
      if (response) {
        alert(MESSAGE.SAVE);
        type = 'edit';
      }
      loadingHandler(false);
      return;
    } catch (error) {
      alert('저장실패!');
      loadingHandler(false);
      console.log(MESSAGE.ERROR);
    }
  };
  const delNow = async () => {
    loadingHandler(true);
    if (selectedAuto) {
      const requestOptions: AxiosRequestConfig = {
        url: `${SERVER_URL}/discount?discountId=${selectedAuto._id}`,
        method: 'delete',
      };
      try {
        const response = await axios(requestOptions);
        if (response) {
          alert(MESSAGE.DELDONE);
          type = 'new';
        }
        loadingHandler(false);
        return;
      } catch (error) {
        loadingHandler(false);
        console.log(MESSAGE.ERROR);
      }
    } else {
      loadingHandler(false);
    }
  };
  return (
    <>
      <div
        className="modalY-overlay"
        onClick={() => changeModal('autoDiscount', false)}
      ></div>
      <div id="standardproduct-window" className="preview-window preview-600">
        <div
          className="closeBtn"
          onClick={() => {
            changeModal('autoDiscount', false);
          }}
        >
          <i className="fa fa-times"></i>
        </div>

        <div className="bg-f7f7f7 p-5">
          {firstInputs && (
            <>
              <div className="contents-wrap x_panel">
                <div className="x_title">
                  <h4>
                    <span>
                      <i>{firstInputs.productNameKO}</i>
                    </span>{' '}
                    - 자동점검 할인율
                  </h4>
                </div>
                <div className="clearfix"></div>
                <div className="x_content">
                  <form
                    id="firstForm"
                    className="form-horizontal form-label-left"
                  >
                    <div className="form-group">
                      <div className="cost-cont">
                        <table
                          id="code-select-table"
                          className="table yTable autoDiscount-table"
                          cellSpacing="0"
                        >
                          <thead>
                            <tr>
                              <th>수량</th>
                              <th>할인율</th>
                              <th>수량</th>
                              <th>할인율</th>
                            </tr>
                          </thead>
                          <tbody>
                            {qtyList.map((code, idx) => {
                              if (idx % 2 == 0) {
                                return (
                                  <tr key={idx}>
                                    <td>{qtyList[idx].qty.toLocaleString()}</td>
                                    <td>
                                      <NumberFormat
                                        name="rate"
                                        type="text"
                                        value={qtyList[idx].rate}
                                        onChange={(e) => qtyListChange(e, idx)}
                                        autoComplete="off"
                                        thousandSeparator
                                      />
                                      {' %'}
                                    </td>
                                    {qtyList[idx + 1] && (
                                      <>
                                        <td>
                                          {qtyList[
                                            idx + 1
                                          ].qty.toLocaleString()}
                                        </td>
                                        <td>
                                          <NumberFormat
                                            name="rate"
                                            type="text"
                                            value={qtyList[idx + 1].rate}
                                            onChange={(e) =>
                                              qtyListChange(e, idx + 1)
                                            }
                                            autoComplete="off"
                                            thousandSeparator
                                          />
                                          {' %'}
                                        </td>
                                      </>
                                    )}
                                  </tr>
                                );
                              } else {
                                return (
                                  <React.Fragment key={idx}></React.Fragment>
                                );
                              }
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="row pt-2">
                <div className="col-sm-12  text-center complete-btn-wrap mt-4 mb-2">
                  {/* <div
                    className="tableBtn tableBtn-red btnW-100 d-inline-block mb-3"
                    data-toggle="tooltip"
                    onClick={() => {
                      delNow();
                    }}
                  >
                    <span>
                      <i className="fa fa-check" aria-hidden="true"></i>{' '}
                      삭제하기
                    </span>
                  </div> */}
                  <div
                    className="price-start-btn btn-inline-block"
                    data-toggle="tooltip"
                    onClick={() => {
                      saveNow();
                    }}
                  >
                    <span>
                      <i className="fa fa-check" aria-hidden="true"></i>{' '}
                      {type === 'new' ? '생성' : '수정'}완료
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
