import React from 'react';
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd';

export default function RestricStepOne({
  columns,
  setColumns,
  copyData,
  POLL,
  setPOLL,
}) {
  const onDragEnd = ({ source, destination }) => {
    // Make sure we have a valid destination
    if (destination === undefined || destination === null) return null;

    // Make sure we're actually moving the item
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    )
      return null;

    // Set start and end variables
    const start = columns[source.droppableId];
    const end = columns[destination.droppableId];

    // If start is the same as end, we're in the same column
    if (start === end) {
      // Move the item within the list
      // Start by making a new list without the dragged item
      const newList = start.list.filter((_, idx) => idx !== source.index);

      // Then insert the item at the right location
      newList.splice(destination.index, 0, start.list[source.index]);

      // Then create a new copy of the column object
      const newCol = {
        id: start.id,
        title: start.title,
        isDropDisabled: start.isDropDisabled,
        list: newList,
      };

      // Update the state
      setColumns((state) => ({ ...state, [newCol.id]: newCol }));
      return null;
    } else {
      // If start is different from end, we need to update multiple columns
      // Filter the start list like before
      const newStartList = start.list.filter((_, idx) => idx !== source.index);

      // Create a new start column
      const newStartCol = {
        id: start.id,
        title: start.title,
        isDropDisabled: start.isDropDisabled,
        list: newStartList,
      };

      // Make a new end list array
      const newEndList = end.list;

      // Insert the item into the end list
      newEndList.splice(destination.index, 0, start.list[source.index]);

      // Create a new end column
      const newEndCol = {
        id: end.id,
        title: end.title,
        isDropDisabled: end.isDropDisabled,
        list: newEndList,
      };

      // Update the state
      setColumns((state) => ({
        ...state,
        [newStartCol.id]: newStartCol,
        [newEndCol.id]: newEndCol,
      }));
      return null;
    }
  };
  const changePollNow = (text) => {
    let copy = { ...columns };
    let copyfrom = copy.from.list.filter((a) => a !== text);
    let copyto = [...copy.to.list, text];
    let copytoFilter = copyto.filter((element, index) => {
      return copyto.indexOf(element) === index;
    });
    setPOLL(text);
    setColumns({
      from: { ...columns.from, list: copyfrom },
      to: { ...columns.to, list: copytoFilter },
    });
  };
  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="StyledWrap twoColum">
          {Object.values(columns).map((col, index) => (
            <Droppable
              droppableId={col.id}
              key={index}
              isDropDisabled={col.isDropDisabled}
            >
              {(provided) => (
                <div className="StyledColumn">
                  <h5>{col.title}</h5>
                  {/* {!readyPOLL &&
                    col.id === 'to' &&
                    copyData
                      .filter((a) => {
                        return a.types[0].code.startsWith('MAT');
                      })
                      .map((v) => {
                        return (
                          <div
                            key={v.selecterName}
                            className="StyledPlaceHolder isDragDisabled"
                          >
                            <div
                              className={POLL === v.selecterName ? 'poll' : ''}
                            >
                              {v.selecterName}
                            </div>
                          </div>
                        );
                      })} */}
                  <ul
                    className="StyledList"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {col.list.map((text, index) => (
                      <Draggable key={text} draggableId={text} index={index}>
                        {(provided, snapshot) => (
                          <li
                            className={`StyledItem ${
                              snapshot.isDragging ? 'dragging' : ''
                            } 
                                ${POLL === text ? 'poll' : ''}`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            onClick={() => changePollNow(text)}
                          >
                            <div>{text}</div>
                          </li>
                        )}
                      </Draggable>
                    ))}

                    {provided.placeholder}
                  </ul>
                </div>
              )}
            </Droppable>
          ))}
        </div>
      </DragDropContext>
    </>
  );
}
