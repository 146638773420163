/* typescript-eslint-disable no-implicit-any */
import { useRef } from 'react';
import AWS, { S3 } from 'aws-sdk';
import { resolve } from 'path';
import React, { useState } from 'react';

interface IProps {
  TypeNow?: string;
  subCategories: 'notice' | 'product' | 'popup' | 'notification';
  getImgUrl: (res: S3.ManagedUpload.SendData, str?: string) => void;
}
/**
 * 프롭스로 데이터를 받아 s3안에 자동 정렬
 * @props siteName :"printcity" | "imadesign"
 * @props subCategories :"notice" | "product"
 * @props broadCategory :"background" | "main" | "small" | "medium" | undefined
 * @returns 저장된 url,파일이름등
 */
export default function s3ImageLoader({
  TypeNow,
  subCategories,
  getImgUrl,
}: IProps) {
  const fileInput = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File>();
  const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
  const REGION = process.env.REACT_APP_S3_REGION;
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  });
  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });
  const clearFile = () => {
    if (fileInput.current) {
      fileInput.current.value = '';
    }
  };
  const handleFileInput = (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    setSelectedFile(file);
    uploadFile(file);
    target.value = '';
  };

  const submitHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    if (!selectedFile) {
      alert('파일을 먼저 선택하세요.');
      return;
    }
    uploadFile(selectedFile);
  };
  const uploadFile = (file: File) => {
    const params = {
      ACL: 'private',
      Body: file,
      Bucket: S3_BUCKET != undefined ? S3_BUCKET : '',
      Key: `images/${subCategories}/ADMIN_${new Date().getTime()}_${file.name}`,
      ContentType: file.type,
      //=> 어떤 이름으로 서버에 저장할것인가? 동적으로 받아오게 규격화 할필요가 있음
      //ex: https://printcity-image.s3.ap-northeast-2.amazonaws.com/imadesign/product/main/namecard_product_1649402543003.png
    };

    myBucket
      .upload(params)
      .promise()
      .then((data) => {
        if (subCategories === 'notice') {
          alert('파일이 업로드 되었습니다.');
          getImgUrl(data, TypeNow);
        } else {
          alert('파일이 업로드 되었습니다.');
          getImgUrl(data);
        }
      })
      .catch((err) => {
        alert('파일 업로드 실패했습니다. 관리자에게 문의하세요.');
        clearFile();
        console.log(err);
      });
  };
  if (subCategories === 'notice') {
    return (
      <>
        <span className="filename">
          {!selectedFile ? '파일을 업로드해주세요' : selectedFile.name}
        </span>
        <label htmlFor={'s3btn' + TypeNow} className="s3btn-label">
          <input
            ref={fileInput}
            id={'s3btn' + TypeNow}
            className="S3-btn"
            type="file"
            accept="image/*"
            onChange={handleFileInput}
          />
        </label>
      </>
    );
  } else {
    return (
      <>
        <span className="filename">
          {!selectedFile ? '파일을 업로드해주세요' : selectedFile.name}
        </span>
        <label htmlFor={'s3btn'} className="s3btn-label">
          <input
            id={'s3btn'}
            className="S3-btn"
            type="file"
            accept="image/*"
            onChange={handleFileInput}
          />
        </label>
      </>
    );
  }
}
