import { useState, useEffect, SetStateAction, Dispatch } from 'react';
import axios from 'axios';
import { SERVER_URL } from '@config/path';
import { FirstInput, OptGroupType } from '@interfaces/tableCost.interfaces';
import { groupDummy } from '@dummydata/dummy';
import { useLoading } from '@config/loadingContext';
import GroupReorder from '@components/table/standardproduct/reorder/group-reorder';
interface Props {
  firstInputs: FirstInput;
  setFirstInputs: Dispatch<SetStateAction<FirstInput>>;
  allModalClose: () => void;
}
export default function OptgroupModal({
  firstInputs,
  setFirstInputs,
  allModalClose,
}: Props) {
  const { loadingHandler } = useLoading();
  /**용지Arr*/
  const materialArr = firstInputs.selecters.filter(
    (a) => a.kindCode == 'materialCode',
  )[0].types;
  /**그룹Arr*/
  const [groups, setGroups] = useState<OptGroupType[]>([
    JSON.parse(JSON.stringify(groupDummy)),
  ]);
  /**용지모달*/
  const [modal, setModal] = useState<{ [key: string]: boolean }>({
    item: false,
    sortGroup: false,
    sortItem: false,
  });
  const groupModalClose = () => {
    setModal({
      item: false,
      sortGroup: false,
      sortItem: false,
    });
  };
  const openModal = (key: string) => {
    setModal({
      ...modal,
      [key]: true,
    });
  };
  /**선택된그룹 인덱스*/
  const [groupIdx, setGroupIdx] = useState<number>(0);
  /**용지 체크박스*/
  const [exist, setExist] = useState<boolean[]>(
    materialArr.map((a) => {
      return false;
    }),
  );
  /**용지선택 주의메시지 */
  const [haveAll, setHaveAll] = useState<boolean>(false); //선택안된용지
  const [haveElse, setHaveElse] = useState<boolean>(false); //삭제된용지포함
  useEffect(() => {
    if (firstInputs.optionGroup && firstInputs.optionGroup.length > 0) {
      let copy = firstInputs.optionGroup.map((a) => {
        return { ...a, count: a.title.includes('@') };
      });
      setGroups(copy);
    }
  }, [firstInputs.optionGroup]);
  /**
   * 옵션 추가 액션
   * @constant firstInput
   */
  const addGroup = () => {
    setGroups([...groups, JSON.parse(JSON.stringify(groupDummy))]);
  };
  /**
   * 옵션명 onchange 액션
   * @param index selecter index
   * @constant groups
   */
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const { name } = e.target;
    let copy = [...groups];
    if (name == 'title') {
      const { value } = e.target;
      copy[index].title = copy[index].count
        ? value + '@'
        : value.replace(/@/g, '');
    } else {
      const { checked } = e.target;
      copy[index].count = checked;
      copy[index].title = copy[index].count
        ? copy[index].title + '@'
        : copy[index].title.replace(/@/g, '');
    }
    setGroups(copy);
  };
  /**
   * 닫기 액션
   */
  const closeNoSave = () => {
    if (confirm('저장없이 창을 닫으시겠습니까?')) {
      allModalClose();
    }
  };
  /**
   * 옵션선택 액션
   * @param index selecter index
   * @constant firstInput
   */
  const openSelectModal = (index: number) => {
    setGroupIdx(index);
    setExist(
      materialArr.map((a) => {
        return groups[index].items.includes(a.code);
      }),
    );
    openModal('item');
  };
  /**
   * 용지선택 액션
   * @param checked 용지 체크박스 체크여부
   * @param index 옵션 index
   */
  const checkCheck = (checked: boolean, index: number) => {
    let copy = [...exist];
    copy[index] = checked;
    setExist(copy);
  };
  /**
   * 용지선택완료 액션
   */
  const selectComplete = () => {
    let code = materialArr
      .filter((a, i) => exist[i])
      .map((b) => {
        return b.code;
      });
    let data = [...groups];
    data[groupIdx].items = code;
    setGroups(data);
    code && data && groupModalClose();
    // setFirstInputs({ ...firstInputs, optionGroup: groups });
  };
  /**
   * 용지옵션 sort 액션
   */
  const sortGroupItem = (idx: number) => {
    setGroupIdx(idx);
    openModal('sortItem');
  };
  /**
   * 그룹 sort 액션
   */
  const sortGroup = () => {
    openModal('sortGroup');
  };

  /**메세지 이벤트 */
  useEffect(() => {
    let copyA = materialArr.map((a) => {
      return a.code;
    });
    let copyB = groups
      .map((a) => {
        return a.items.map((b) => {
          return b;
        });
      })
      .flat()
      .filter(
        (thing: string, index: number, self: string[]) =>
          index === self.findIndex((t) => t === thing),
      );
    if (copyA && copyB) {
      let compareA = copyA.some((a) => !copyB.includes(a));
      let compareB = copyB.some((a) => !copyA.includes(a));
      setHaveAll(compareA);
      setHaveElse(compareB);
    }
  }, [groups]);
  /**
   * 그룹 삭제 액션
   * @param idx 그룹인덱스
   */
  const delGroup = async (idx: number) => {
    loadingHandler(true);
    let newId = firstInputs._id;
    let optId = groups[idx]._id;
    let copy = [...groups];
    copy.splice(idx, 1);
    try {
      const response = await axios.delete(
        SERVER_URL + `/productbysite/${newId}/option-group?optionId=${optId}`,
      );
      if (response) {
        alert('삭제성공');
        setGroups(copy);
        setFirstInputs({ ...firstInputs, optionGroup: copy });
        loadingHandler(false);
      }
    } catch (e) {
      loadingHandler(false);
      alert('삭제실패');
      console.log(e);
    }
  };

  /**
   * 완료저장 액션
   */
  const complete = async () => {
    loadingHandler(true);
    let newId = firstInputs._id;
    let newBody: OptGroupType[] = [...groups].map((code) => {
      let result = code;
      delete result._id;
      delete result.count;
      return result;
    });
    if (newBody.length > 0) {
      try {
        const response = await axios.put(
          SERVER_URL + '/productbysite/' + newId,
          {
            optionGroup: newBody,
          },
        );
        if (response) {
          alert('등록성공');
          setFirstInputs({ ...firstInputs, optionGroup: newBody });
          allModalClose();
          loadingHandler(false);
        }
      } catch (e) {
        loadingHandler(false);
        alert('등록실패');
        console.log(e);
      }
    } else {
      alert('저장할 그룹이 없습니다.');
      loadingHandler(false);
      allModalClose();
    }
    loadingHandler(false);
  };

  return (
    <>
      <div className="option-modal group-modal">
        <div className="option-modal-top">
          <div className="option-modal-title pull-left">용지 옵션 그룹수정</div>
          <div
            className="option-modal-close pull-right"
            onClick={() => closeNoSave()}
          >
            <i className="fa fa-times"></i>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="option-modal-txt">
          {haveAll && <span>그룹에 포함되지 않은 용지가 남아있습니다.</span>}
          {haveElse && <span>삭제된 용지가 그룹에 포함되어 있습니다.</span>}
        </div>
        <div className="option-modal-cont">
          <table className="table yTable" cellPadding="0">
            <thead>
              <tr>
                <th style={{ width: '35px' }}></th>
                <th style={{ width: '35px' }}>순위</th>
                <th style={{ width: '150px' }}>타이틀</th>
                <th style={{ width: 'calc(100% - 320px)' }}>용지옵션 그룹</th>
                <th style={{ width: '100px' }}>action</th>
              </tr>
            </thead>
            <tbody>
              {groups.map((code, idx) => {
                return (
                  <tr key={idx}>
                    <td className="tds">
                      <span>{idx + 1}</span>
                    </td>
                    <td className="tds">
                      <input
                        type="checkbox"
                        name="count"
                        checked={code.count}
                        onChange={(e) => handleChange(e, idx)}
                      />
                    </td>
                    <td className="tds">
                      <input
                        type="text"
                        name="title"
                        value={code.title.replace(/@/g, '')}
                        onChange={(e) => handleChange(e, idx)}
                      />
                    </td>
                    <td className="tds">
                      <div
                        className="touchOnly openSelectBtn"
                        onClick={() => openSelectModal(idx)}
                      >
                        {code.items && code.items.length > 0
                          ? code.items
                              .map((a) => {
                                let finded = materialArr.filter(
                                  (b) => b.code == a,
                                );
                                let result =
                                  finded.length > 0
                                    ? finded[0].title
                                    : '삭제된 용지';
                                return result;
                              })
                              .join(', ')
                          : '선택된 용지없음'}
                      </div>
                    </td>
                    <td className="tds px-0">
                      <div
                        className="inline-block input-group-btn input-reorder-btn ms-0"
                        onClick={() => sortGroupItem(idx)}
                      >
                        <i className="fa fa-sort"></i>
                      </div>
                      <div
                        className="inline-block input-group-btn input-minus-btn ms-0"
                        onClick={() => delGroup(idx)}
                      >
                        <i className="fa fa-minus"></i>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="option-modal-foot">
          <div
            className="yBtn yBtn-small green text-center pull-left"
            onClick={() => addGroup()}
          >
            <i className="fa fa-plus"></i> 옵션추가하기
          </div>
          <div
            className="yBtn yBtn-small orange ms-1 text-center pull-left"
            onClick={() => sortGroup()}
          >
            <i className="fa fa-sort"></i> 그룹순서
          </div>
          <div
            className="yBtn yBtn-small blue text-center pull-right"
            onClick={() => complete()}
          >
            <i className="fa fa-save"></i> 저장
          </div>
          <div className="clearfix"></div>
          {modal.item && (
            <div className="groupSelectModal">
              <h2>
                {groupIdx + 1} 그룹 용지선택
                <div className="pull-right">
                  <button
                    className="yBtn white-s pull-left"
                    onClick={() => groupModalClose()}
                  >
                    <i className="fa fa-times"></i> 닫기
                  </button>
                  <button
                    className="yBtn purple text-center ms-1 pull-left"
                    onClick={() => selectComplete()}
                  >
                    <i className="fa fa-save"></i> 저장
                  </button>
                  <div className="clear-fix"></div>
                </div>
              </h2>
              <table>
                <thead>
                  <tr>
                    <th className="text-center">체크</th>
                    <th className="text-center">용지명(코드)</th>
                  </tr>
                </thead>
                <tbody>
                  {materialArr &&
                    materialArr.map((codeB, idxB) => {
                      return (
                        <tr
                          key={idxB}
                          onClick={() => checkCheck(!exist[idxB], idxB)}
                        >
                          <td className="tds text-center">
                            <input
                              type="checkbox"
                              name={codeB.code}
                              checked={exist[idxB]}
                              readOnly
                            />
                          </td>
                          <td className="tds">
                            <div className="text-start ps-1">{codeB.title}</div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )}
          {modal.sortItem && (
            <GroupReorder
              type={'items'}
              materialArr={materialArr}
              groups={groups}
              setGroups={setGroups}
              closeModal={groupModalClose}
              itemIdx={groupIdx}
            />
          )}
          {modal.sortGroup && (
            <GroupReorder
              type={'groups'}
              materialArr={materialArr}
              groups={groups}
              setGroups={setGroups}
              closeModal={groupModalClose}
              itemIdx={groupIdx}
            />
          )}
        </div>
      </div>
    </>
  );
}
