import { Dispatch, SetStateAction, useState } from 'react';
import {
  FirstInput,
  PriceCalcurateArr,
} from '@interfaces/tableCost.interfaces';
import * as XLSX from 'xlsx';

import { useLoading } from '@config/loadingContext';
interface Props {
  firstInputs: FirstInput;
  setFirstInputs: Dispatch<SetStateAction<FirstInput>>;
}
const processData = (data: Record<string, string | undefined>[]) => {
  let converted: PriceCalcurateArr[] = [];
  let obj: PriceCalcurateArr = {
    selectors: [],
    price: [],
  };

  for (let i = 0; i < data.length; i++) {
    if (!data[i]['title'] && !data[i]['code'] && data[i]['value'] !== null) {
      obj.price.push({
        value: Number(data[i]['value']),
        quantity: Number(data[i]['quantity']),
      });
    }
    if (
      data[i]['title'] &&
      data[i]['title'] !== 'title' &&
      data[i]['code'] &&
      data[i]['code'] !== 'code'
    ) {
      obj.selectors.push({ title: data[i].title, code: data[i].code } as {
        title: string;
        code: string;
      });
    }

    if (
      data[i]['title'] === 'title' &&
      data[i]['code'] === 'code' &&
      data[i]['value'] === 'value'
    ) {
      converted.push(obj);
      obj = {
        selectors: [],
        price: [],
      };
    }
  }

  return converted;
};
export default function ExcelUpload({ firstInputs, setFirstInputs }: Props) {
  const { loadingHandler } = useLoading();
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      loadingHandler(true);
      let reader = new FileReader();
      reader.onload = (evt) => {
        const data = new Uint8Array(evt.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {});
        /**
         * 엑셀의 형태에 따라 로직 변경을 해야됨
         */
        const res = processData(
          jsonData as Record<string, string | undefined>[],
        );
        if (firstInputs.productTypes.length == res.length) {
          setFirstInputs({ ...firstInputs, productTypes: res });
          alert('업로드 완료.');
          loadingHandler(false);
        } else {
          alert('가격표의 기준이 다릅니다. 확인부탁드립니다.');
          loadingHandler(false);
        }
      };

      reader.readAsArrayBuffer(file);
      loadingHandler(false);
    }
    e.target.value = '';
  };

  return (
    <>
      <input
        type="file"
        accept=".xlsx"
        onChange={handleFileChange}
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          opacity: 0,
        }}
      />
      엑셀파일 업로드
    </>
  );
}
