import { FirstInput, Order } from '@interfaces/tableCost.interfaces';
interface Props {
  firstInputs: FirstInput;
  OptionIndex: number;
  OptionNameArr: Order[];
  handleChange: (
    type: string,
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => void;
  delOptionNow: (idxA: number, idxB: number) => void;
  hideOptionNow: (idxA: number, idxB: number, bool: boolean) => void;
  allModalClose: () => void;
}
export default function RenameModal({
  firstInputs,
  OptionIndex,
  OptionNameArr,
  handleChange,
  delOptionNow,
  hideOptionNow,
  allModalClose,
}: Props) {
  return (
    <>
      <div className="option-modal">
        <div className="option-modal-top">
          <div className="option-modal-title pull-left">
            {firstInputs.selecters[OptionIndex].selecterName} 옵션 수정 & 삭제
          </div>
          <div
            className="option-modal-close pull-right"
            onClick={() => allModalClose()}
          >
            <i className="fa fa-times"></i> 닫기
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="option-modal-cont">
          <table className="table table-striped yTable">
            <thead>
              <tr>
                <th>용지명</th>
                <th>용지변경</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {firstInputs.selecters[OptionIndex].types.map((opt, i) => {
                return (
                  <tr className="option-details" key={opt.code + '-' + i}>
                    <td className="option-detail">{opt.title}</td>
                    <td className="option-detail">
                      <input
                        type="text"
                        name="title"
                        value={OptionNameArr[OptionIndex].types[i].title}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChange('option', e, i)
                        }
                      />
                    </td>
                    <td className="option-detail">
                      <button
                        className="tableBtn tableBtn-red btnW-60 setPriceDelEvents"
                        onClick={() => delOptionNow(OptionIndex, i)}
                      >
                        <i className="fa fa-trash"></i> 삭제
                      </button>
                      {opt.isHide === undefined || opt.isHide !== true ? (
                        <button
                          className="tableBtn tableBtn-orange btnW-60 setPriceDelEvents"
                          onClick={() => hideOptionNow(OptionIndex, i, true)}
                        >
                          <i className="fa fa-eye"></i> 숨김
                        </button>
                      ) : (
                        <button
                          className="tableBtn tableBtn-gray btnW-60 setPriceDelEvents"
                          onClick={() => hideOptionNow(OptionIndex, i, false)}
                        >
                          <i className="fa fa-eye"></i> 재활성화
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="option-modal-foot">
          <div
            className="yBtn yBtn-md green text-center"
            onClick={() => allModalClose()}
          >
            확인
          </div>
        </div>
      </div>
    </>
  );
}
