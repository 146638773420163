import { useState, useEffect, Dispatch, SetStateAction, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import CSS from 'csstype';
import axios from 'axios';
import { SERVER_URL, MEGAProductsUrl } from '@config/path';
import CodeWrapTableEd from '@components/table/standardproduct/code-select/code-wrap-table-edit';
import PriceSelectOrder from '@components/table/standardproduct/price-input/price-select-order';
import PriceInputTable from '@components/table/standardproduct/price-input/price-input-table';
import alertCategorize from '@utils/alertFunc';
import alertCategorizeMatrix from '@utils/alertFunc-matrix';
import {
  FirstInput,
  EditInput,
  TableCostStep,
  Order,
  MegaProduct,
  LetCode,
  PriceCalcurateArr,
} from '@interfaces/tableCost.interfaces';
import { useAuthState } from '@config/context';
import { emptyPrice } from '@dummydata/dummy';
// import { compare } from '@config/compare/compareBefore';
import { CONFIRM, MESSAGE } from '@utils/textMessage';
import { checkPriceNull, getLatest } from '@utils/common';
import { useLoading } from '@config/loadingContext';
import { ExcelDownload } from '@components/excel/download';
import ExcelUpload from '@components/excel/upload';
import { YUNdummy } from '@dummydata/yundummy';
import { deleteEverything } from '@utils/common';
interface Props {
  firstInputs: FirstInput;
  setFirstInputs: Dispatch<SetStateAction<FirstInput>>;
  originInputs: FirstInput;
  setOriginInputs: Dispatch<SetStateAction<FirstInput>>;
  changeModal: (type: string, bool: boolean) => void;
}

export default function EditModal({
  firstInputs,
  setFirstInputs,
  originInputs,
  setOriginInputs,
  changeModal,
}: Props) {
  const { loadingNow, loadingHandler } = useLoading();
  /**ref*/
  const myRef = useRef<HTMLDivElement>(null);
  const executeScroll = () => {
    setTimeout(() => {
      myRef.current !== null
        ? myRef.current.scrollIntoView({ behavior: 'smooth' })
        : console.log(myRef.current);
    }, 1000);
  };
  /**제품분리(도수외) state*/
  const [latestInput, setLatestInput] = useState<Order[]>([]);
  /**제품분리(도수) state*/
  const [latestColor, setLatestColor] = useState<Order[]>([]);
  /**가격표없는옵션분리state*/
  const [noPriceInput, setNoPriceInput] = useState<Order[]>([]);
  /**가격 스텝 State*/
  const [priceStep, setPriceStep] = useState<TableCostStep>('readyToMake');
  /**메가 데이터 state*/
  const [megaProduct, setMegaProduct] = useState<MegaProduct[]>([]);
  /**가격비교여부 state*/
  const [readyToCompare, setReadyToCompare] = useState<boolean>(false);
  /**
   * 제한사항 보여주기
   * @param NewClass 제한사항 array
   */
  const [NewClass, setNewClass] = useState<LetCode>([]);
  const changeNewClass = (inputs: FirstInput) => {
    let preClass: LetCode = [];
    if (!inputs) {
      firstInputs.selecters.map((a) =>
        a.types.map((v) =>
          v.class.map((w, i) => {
            let NameW = firstInputs.selecters
              .find((a) => a.types.find((b) => b.code == w))
              ?.types.find((c) => c.code == w)?.title;
            return preClass.push({
              from: v.code,
              fromKind: a.kindCode,
              to: w,
              toName: NameW,
            });
          }),
        ),
      );
    } else {
      inputs.selecters.map((a) =>
        a.types.map((v) =>
          v.class.map((w, i) => {
            let NameW = firstInputs.selecters
              .find((a) => a.types.find((b) => b.code == w))
              ?.types.find((c) => c.code == w)?.title;
            return preClass.push({
              from: v.code,
              fromKind: a.kindCode,
              to: w,
              toName: NameW,
            });
          }),
        ),
      );
    }
    preClass.length > 0 ? setNewClass(preClass) : setNewClass([]);
  };
  useEffect(() => {
    changeNewClass(firstInputs);
  }, [firstInputs]);
  /**
   * 메가 데이터 다운로드
   * @constant megaProduct
   */
  useEffect(() => {
    loadingHandler(true);
    async function getMegaProductData() {
      await axios
        .get(MEGAProductsUrl)
        .then(function (response) {
          setMegaProduct(response.data);
        })
        .catch(function (error) {
          console.log('메가 데이터 로드에 실패 하였습니다.');
        });
    }
    executeScroll();
    getMegaProductData();
    getLatest(firstInputs, setLatestInput, setLatestColor, setNoPriceInput);
    loadingHandler(false);
  }, []);
  /**
   * input(카테고리, 상품옵션, 판매상태) onchange 이벤트
   * @param name input name
   * @param value input value
   */
  const handleChange = (name: string, value: string | boolean) => {
    name.slice(0, 12) === 'categoryName'
      ? setFirstInputs({
          ...firstInputs,
          category: [{ ...firstInputs.category[0], [name]: String(value) }],
        })
      : setFirstInputs({ ...firstInputs, [name]: value });
  };
  /**
   * 옵션 단계로 변경 action
   * @constant originInputs
   * @constant readyToCompare
   * @constant priceStep
   * @constant firstInputs
   */
  const priceRemoveCheck = () => {
    loadingHandler(true);
    let Copy = { ...firstInputs };
    let NewCopy = JSON.parse(JSON.stringify(Copy));
    confirm(CONFIRM.INIT)
      ? (priceStep !== 'preparing' && setOriginInputs(NewCopy),
        changePriceStep('preparing'),
        loadingHandler(false),
        setReadyToCompare(true),
        emptyPrice &&
          setFirstInputs({ ...firstInputs, productTypes: emptyPrice }))
      : loadingHandler(false);
  };
  /**
   * 입력 단계변경 action
   * @param step 변경할 스텝
   * @constant priceStep
   */
  const changePriceStep = async (step: TableCostStep) => {
    setPriceStep(step);
  };
  /**
   * priceStep 변함의 따라 생기는 분기가 복잡, alertCategorize 라는 함수에 각 인풋별 경고창 분기
   * @param step 현재의 priceStep
   * @param inputs fistinput 같은 post 시 파라미터
   */
  const gotoPriceInput = async (
    step: TableCostStep,
    inputs: FirstInput,
    remake?: boolean,
  ) => {
    if (step === 'holdOrder' && remake) {
      changePriceStep('selectOrder');
    }
    if (step === 'holdOrder' && !remake) {
      if (inputs.typeOfPriceDetermintion === 'priceComplete') {
        alertCategorize(inputs, changePriceStep) &&
          (getLatest(
            firstInputs,
            setLatestInput,
            setLatestColor,
            setNoPriceInput,
          ),
          changePriceStep('readyToMake'),
          executeScroll());
      } else {
        alertCategorizeMatrix(inputs, changePriceStep) &&
          (getLatest(
            firstInputs,
            setLatestInput,
            setLatestColor,
            setNoPriceInput,
          ),
          changePriceStep('readyToMake'),
          executeScroll());
      }
    }
    if (step === 'holdToMake') {
      let temI = 0;
      const reOrder = latestInput.map((v, i) => {
        if (v.priceOrder !== -1) {
          v = { ...v, priceOrder: temI };
          temI++;
          return v;
        } else {
          return v;
        }
      });

      const sum = [...reOrder, ...latestColor];
      sum.sort((a, b) => a.originally - b.originally);
      // inputs.selectors.map((f, i) => f);
      inputs.selecters = sum;
      setFirstInputs({ ...firstInputs, selecters: inputs.selecters });
      getLatest(
        { ...firstInputs, selecters: inputs.selecters },
        setLatestInput,
        setLatestColor,
        setNoPriceInput,
      );
      changePriceStep('readyToMake');
    }
    if (firstInputs.typeOfPriceDetermintion === 'priceCalculation') {
      emptyPrice &&
        setFirstInputs({ ...firstInputs, productTypes: emptyPrice });
    }
  };
  /**
   * 저장, 완료 Action
   * @constant firstInput
   * @constant priceStep
   */
  const saveNow = async (action: string) => {
    loadingHandler(true);
    let address = firstInputs.productNameEN;
    let id = firstInputs._id;
    // if (checkPriceNull(firstInputs)) {
    //   loadingHandler(false);
    //   return alert('수정사항에 이상이 있어 저장이 불가합니다.\n관리자에게 연락바랍니다.');
    // }
    /**title: 'title'일때 데이터 정상화 시키기 */
    // let copy = { ...firstInputs };
    // let newProductTypes: PriceCalcurateArr[] = firstInputs.productTypes.map(
    //   (a) => {
    //     return {
    //       price: a.price,
    //       selectors: a.selectors.filter((b) => b.title !== 'title'),
    //     };
    //   },
    // );
    // copy.productTypes = newProductTypes;
    await axios
      .put(SERVER_URL + '/product/' + id, firstInputs)
      .then(function (response) {
        loadingHandler(false);
        setFirstInputs(response.data);
        alert(MESSAGE.SAVE);
        if (action === 'complete') {
          changeModal('price', false);
        }
      })
      .catch(function (error) {
        alert(error.response.message);
        loadingHandler(false);
      });
  };
  const goDummy = () => {
    let copy = deleteEverything<FirstInput>({ ...YUNdummy }, [
      '_id',
      'createdAt',
      'createdBy',
      'modifiedAt',
      'modifiedBy',
      '__v',
    ]);
    setFirstInputs({ ...firstInputs, ...copy });
  };
  return (
    <>
      <div
        className="modalY-overlay"
        onClick={() => changeModal('price', false)}
      ></div>
      <div className="previewY preview-window">
        <div
          className="closeBtn"
          onClick={() => {
            changeModal('price', false);
          }}
        >
          <i className="fa fa-times"></i>
        </div>

        {firstInputs && (
          <>
            {/* <button onClick={() => goDummy()}>고더미</button> */}
            <div className="bg-f7f7f7 p-5">
              <div className="contents-wrap x_panel">
                <div className="x_title">
                  <h4>
                    가격 입력 <small>Data Inputs</small>
                  </h4>
                </div>
                <div className="clearfix"></div>
                <div className="x_content">
                  <CodeWrapTableEd
                    firstInputs={firstInputs}
                    setFirstInputs={setFirstInputs}
                    handleChange={handleChange}
                    NewClass={NewClass}
                  />
                </div>
                <div
                  id="block-box"
                  className={`block-box ${
                    priceStep === 'preparing' ? '' : 'blockNow'
                  }`}
                >
                  <div className="blockBoxBg"></div>
                  <div className="blockBoxTxt">
                    <p>아래 버튼을 눌러 수정이 가능합니다.</p>
                    <div
                      className="yBtn d-block py-2 px-4 mt-5 mx-auto text-center white"
                      onClick={() => {
                        priceRemoveCheck();
                      }}
                    >
                      가격 및 주문옵션 수정
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix" ref={myRef}></div>
              <div className="row py-5">
                <div className="col-xs-12 text-center">
                  <div
                    id="holdOrder-btn"
                    className={`yBtn yBtn-xl purple btn-inline-block ${
                      priceStep === 'preparing'
                        ? ''
                        : priceStep === 'holdOrder'
                        ? 'loading'
                        : 'inactive'
                    }`}
                    onClick={() => {
                      priceStep === 'preparing' &&
                        gotoPriceInput('holdOrder', firstInputs);
                    }}
                  >
                    <span>
                      <i className="fa fa-cube" aria-hidden="true"></i> 가격
                      생성
                    </span>
                    <div className="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="row">
                <div className="col-12">
                  <div className="contents-wrap x_panel">
                    <div className="x_content">
                      <form className="form-horizontal form-label-left">
                        <div className="form-group">
                          <div className="row">
                            <label className="cost-title col-md-2 col-sm-2 col-xs-12">
                              상품 가격 입력
                            </label>
                            <div className="cost-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                              {priceStep === 'preparing' && (
                                <div className="price-table-wrap">
                                  <span>
                                    옵션을 선택하고 가격 생성을 클릭 하세요.
                                  </span>
                                </div>
                              )}
                              {priceStep === 'selectOrder' &&
                                firstInputs.typeOfPriceDetermintion ==
                                  'priceComplete' && (
                                  <PriceSelectOrder
                                    firstInputs={firstInputs}
                                    latestInput={latestInput}
                                    setLatestInput={setLatestInput}
                                    noPriceInput={noPriceInput}
                                    gotoPriceInput={gotoPriceInput}
                                  />
                                )}
                              {priceStep == 'readyToMake' &&
                              firstInputs.typeOfPriceDetermintion ==
                                'priceComplete' ? (
                                <>
                                  <PriceInputTable
                                    firstInputs={firstInputs}
                                    setFirstInputs={setFirstInputs}
                                    originInputs={originInputs}
                                    latestInput={latestInput}
                                    setLatestInput={setLatestInput}
                                    latestColor={latestColor}
                                    priceStep={priceStep}
                                    readyToCompare={readyToCompare}
                                    setReadyToCompare={setReadyToCompare}
                                    saveNow={saveNow}
                                    NewClass={NewClass}
                                  />
                                </>
                              ) : (
                                <div className="">
                                  <h5 className="vertical-middle mb-0 p-1">
                                    계산형 상품입니다
                                  </h5>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <label className="cost-title col-md-2 col-sm-2 col-xs-12">
                              가격표 타입
                            </label>
                            <div className="cost-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                              <div className="iradio_flat-green inl-bl">
                                <input
                                  type="radio"
                                  id="priceComplete"
                                  className="inl-bl"
                                  name="typeOfPriceDetermintion"
                                  value="priceComplete"
                                  checked={
                                    firstInputs.typeOfPriceDetermintion ===
                                    'priceComplete'
                                  }
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) =>
                                    handleChange(e.target.name, 'priceComplete')
                                  }
                                  disabled={true}
                                />
                                <label htmlFor="priceComplete">완성형</label>
                              </div>
                              <div className="iradio_flat-green inl-bl">
                                <input
                                  type="radio"
                                  id="priceCalculation"
                                  className="flat inl-bl"
                                  name="typeOfPriceDetermintion"
                                  value="priceCalculation"
                                  checked={
                                    firstInputs.typeOfPriceDetermintion ===
                                    'priceCalculation'
                                  }
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) =>
                                    handleChange(
                                      e.target.name,
                                      'priceCalculation',
                                    )
                                  }
                                  disabled={true}
                                />
                                <label htmlFor="priceCalculation">계산형</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <label className="cost-title col-md-2 col-sm-2 col-xs-12">
                              판매상태
                            </label>
                            <div className="cost-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                              <div className="iradio_flat-green inl-bl">
                                <input
                                  type="radio"
                                  id="sales"
                                  className="inl-bl"
                                  name="saleStatus"
                                  value="true"
                                  checked={firstInputs.saleStatus === true}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) => handleChange(e.target.name, true)}
                                />
                                <label htmlFor="sales">판매함</label>
                              </div>
                              <div className="iradio_flat-green inl-bl">
                                <input
                                  type="radio"
                                  id="nsales"
                                  className="flat inl-bl"
                                  name="saleStatus"
                                  value="false"
                                  checked={firstInputs.saleStatus === false}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) => handleChange(e.target.name, false)}
                                />
                                <label htmlFor="nsales">판매안함</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {priceStep === 'readyToMake' && (
                <>
                  <div className="clearfix"></div>
                  <div className="row pt-2">
                    <div className="floating-Btn pull-right">
                      <div className="col-sm-12  text-center complete-btn-wrap mt-4 mb-2">
                        {firstInputs.typeOfPriceDetermintion ===
                          'priceComplete' && (
                          <>
                            <div className="btn yBtn yBtn-small blue btn-inline-block mx-2">
                              {firstInputs._id && (
                                <ExcelDownload
                                  id={firstInputs._id}
                                  producttype={'기준상품'}
                                  category={
                                    firstInputs.category[0].categoryName3rd
                                  }
                                  productName={firstInputs.productNameKO}
                                />
                              )}
                            </div>
                            <div className="btn yBtn yBtn-small orange  btn-inline-block mx-2">
                              <ExcelUpload
                                firstInputs={firstInputs}
                                setFirstInputs={setFirstInputs}
                              />
                            </div>
                          </>
                        )}
                        <div
                          className="btn yBtn yBtn-small green btn-inline-block mx-2"
                          onClick={() => saveNow('save')}
                        >
                          임시저장
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12  text-center complete-btn-wrap mt-4 mb-2">
                      <div
                        className="price-start-btn btn-inline-block"
                        data-toggle="tooltip"
                        onClick={() => {
                          saveNow('complete');
                        }}
                      >
                        <span>
                          <i className="fa fa-check" aria-hidden="true"></i>
                          수정완료
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}
