import Axios, { AxiosResponse } from 'axios';
import { loginUser } from '@config/reducer.index';
import { UserLoginPayload, AuthDispatch } from '@interfaces/globals.types';
import { RouteComponentProps } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { MESSAGE } from './textMessage';
import { SERVER_URL } from '@config/path';
import axios from 'axios';
import { removeCookie } from '@config/cookies';
const cookies = new Cookies();

const JWT_EXPIRY_TIME = 3 * 60 * 60 * 1000; // 만료 시간 (3시간 밀리 초로 표현)

let UserData: { id: string; pwd: string };

export const onLogin = async (
  dispatch: AuthDispatch,
  payload: UserLoginPayload,
  history: RouteComponentProps['history'],
  loginhandler: (login: boolean) => void,
) => {
  const { id, pwd } = payload;
  UserData = payload;
  if (!id || !pwd) {
    alert(MESSAGE.CONFIRMVALUE);
    return;
  }
  try {
    const response = await loginUser(dispatch, payload, history);
    if (!response) return;
    loginhandler(true);
    history.push('/home');
  } catch (e) {
    console.error(e);
  }
};

export const onSilentRefresh = async () => {
  if (confirm('로그인 연장하시겠습니까?')) {
    await Axios.post(`${SERVER_URL}/auth/signin`, UserData)
      .then((res) => {
        onLoginSuccess(res);
      })
      .catch((error) => {
        console.error(error);
        alert('토큰 연장이 필요합니다. 다시 로그인해주세요');
        removeCookie('refreshToken');
        location.href = `${location.origin}/login`;
      });
  } else {
    removeCookie('refreshToken');
    location.href = `${location.origin}/login`;
  }
};

export const onLoginSuccess = (response: AxiosResponse) => {
  const { accessToken } = response.data;

  // accessToken 설정
  Axios.defaults.headers.common['Authorization'] = `${accessToken}`;
  //accessToken 만료하기 10초 전에 로그인 연장

  setTimeout(onSilentRefresh, JWT_EXPIRY_TIME - 10000);

  return response.data;
};
