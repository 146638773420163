import { useState } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { PriceCalcurateArr } from '@interfaces/tableCost.interfaces';
import { useLoading } from '@config/loadingContext';
import { SERVER_URL } from '@config/path';

interface IProps {
  id: string;
  producttype: string;
  category: string;
  productName: string;
}

export const ExcelDownload = ({
  id,
  producttype,
  category,
  productName,
}: IProps): JSX.Element => {
  const { loadingHandler } = useLoading();
  const headers = ['title', 'code', 'quantity', 'value'];

  const getTransactionData = async () => {
    loadingHandler(true);
    try {
      const response = await axios.get(`${SERVER_URL}/product/${id}`);

      const responseData = response.data.productTypes;

      loadingHandler(false);

      generateExcel(responseData);
    } catch (error) {
      console.log(error);
    }
  };

  const generateExcel = (fetchData: PriceCalcurateArr[]) => {
    console.log(fetchData);
    const data = fetchData

      ?.map((e) => [
        e.selectors.map((e) => {
          return { title: e.title, code: e.code };
        }),
        e.price.map((e) => {
          return { quantity: e.quantity, value: e.value };
        }),
        {},
        {
          title: 'title',
          code: 'code',
          quantity: 'quantity',
          value: 'value',
        },
      ])
      .flat(2);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data || [], { header: headers });
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'buffer',
      bookSST: false,
    });

    const bom = ''; // BOM (Byte Order Mark) for ANSI encoding
    const blob = new Blob([bom, excelBuffer], {
      type: 'application/octet-stream',
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `${producttype}_${productName}(${category})${new Date(
        Date.now(),
      ).toLocaleString()}.xlsx`,
    );
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <div className="d-block" onClick={getTransactionData}>
        엑셀다운
      </div>
    </>
  );
};
