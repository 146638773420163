import React, { useEffect } from 'react';
import { SERVER_URL } from '@config/path';
import { useAuthState, useAuthDispatch } from '@config/reducer.index';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';
const cookies = new Cookies();

const Notfound = () => {
  const history = useHistory();

  useEffect(() => {
    history.push('/login');
  }, []);
  return (
    <div>
      <h1>인증정보를 확인중입니다.</h1>
    </div>
  );
};

export default Notfound;
