import { Dispatch, useState, useEffect } from 'react';
import axios from 'axios';
import { SERVER_URL } from '@config/path';
import { CateType, CateItemType } from '@interfaces/site.interface';
import { Site } from '@interfaces/site.interface';
import { useLoading } from '@config/loadingContext';
import { CONFIRM, MESSAGE, ERROR } from '@utils/textMessage';
import CategoryModal from '@components/modal/category/category-modal';
import CategoryReorder from '@components/modal/category/category-reorder';
import { deleteEverything } from '@utils/common';
import { YUNcate } from '@dummydata/yundummy';

interface Props {
  selectedSite: Site;
  sellerNameEN: string;
  GPmodalhandler: (boolean: boolean) => void;
}
export default function SetcategoryInner({
  selectedSite,
  sellerNameEN,
  GPmodalhandler,
}: Props) {
  const { loadingNow, loadingHandler } = useLoading();
  const [allCate, setAllCate] = useState<CateType[]>(
    selectedSite.menuCategory
      ? selectedSite.menuCategory.map((code) => {
          return {
            ...code,
            subCateBool: code.subCateName !== '',
            subCateOption: false,
          };
        })
      : [],
  );
  /** S:모달 state*/
  const [modal, setModal] = useState<{ [index: string]: boolean }>({
    new: false,
    edit: false,
    mainOrder: false,
    subOrder: false,
    itemOrder: false,
  });
  const changeModal = (key: string, bool: boolean) => {
    setModal({ ...modal, [key]: bool });
  };
  const [modalIndexNow, setModalIndexNow] = useState<number>(0);
  const editModalOpen = (idx: number) => {
    setModalIndexNow(idx);
    changeModal('edit', true);
  };
  /** E:모달 state*/
  const changeCate = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const { name, value } = e.target;
    let copy = [...allCate];
    copy[idx][name] = value;
    setAllCate(copy);
  };
  const checkRow = (cate: string, val: string, idx: number) => {
    let i = 1;
    let again = (cate: string, val: string, idx: number) => {
      if (allCate[idx + 1] && val == allCate[idx + 1].cateName) {
        i++;
        idx++;
        again(cate, val, idx);
      } else {
        return i;
      }
    };
    again(cate, val, idx);
    return i;
  };
  const salesHandler = (idx: number) => {
    let copy = [...allCate];
    if (!copy[idx].subCateBool) {
      copy[idx].subCateName = '';
    }
    copy[idx].subCateBool = !copy[idx].subCateBool;
    setAllCate(copy);
  };
  const optionHandler = (idx: number) => {
    let copy = [...allCate];
    copy[idx].subCateOption = !copy[idx].subCateOption;
    setAllCate(copy);
  };
  const [checkList, setCheckList] = useState<boolean[]>(
    allCate.map((code) => {
      return false;
    }),
  );
  const handleOnCheck = (idx: number) => {
    let copy = [...checkList];
    copy[idx] = !copy[idx];
    setCheckList(copy);
  };
  /**
   * 옵션순서 변경 액션
   * @constant restrict
   */
  const onSortOrign = (type: string, idx: number) => {
    if (
      (type == 'mainOrder' && allCate.length < 2) ||
      (type == 'subOrder' &&
        allCate.filter((code) => code.cateName == allCate[idx].cateName)
          .length < 2) ||
      (type == 'itemOrder' && allCate[idx].cateItems.length < 2)
    ) {
      alert('순서변경할 아이템이 2개이상이어야 합니다');
      return;
    } else {
      setModalIndexNow(idx);
      changeModal(type, true);
    }
  };
  /**카테고리 삭제Action */
  const delCate = () => {
    loadingHandler(true);
    if (checkList.filter((code) => code).length > 0) {
      if (window.confirm(CONFIRM.DEL)) {
        let copy = [...allCate];
        let result = copy.filter((code, idx) => !checkList[idx]);
        copy && setAllCate(result);
        setCheckList(
          result.map((code) => {
            return false;
          }),
        );
      }
    } else {
      alert('삭제할 카테고리를 선택하세요.');
    }
    loadingHandler(false);
  };
  /**완료 Action */
  const complete = async () => {
    loadingHandler(true);
    let result = [...allCate].map((code) => {
      let returnNow = deleteEverything<CateType>(code, [
        '_id',
        'createdAt',
        'createdBy',
        'modifiedAt',
        'modifiedBy',
        'subCateBool',
        'subCateOption',
      ]);
      return returnNow;
    });
    await axios
      .post(SERVER_URL + '/site/menu-category?siteId=' + selectedSite._id, {
        menuCategory: result,
      })
      .then((res) => {
        console.log(res);
        alert('저장완료');
      })
      .catch((error) => window.alert(`${ERROR.SERVER} ${error}`));
    loadingHandler(false);
  };
  const goDummy = () => {
    let data = deleteEverything<CateType[]>(YUNcate, [
      '_id',
      'createdAt',
      'createdBy',
      'modifiedAt',
      'modifiedBy',
      'catePriority',
      'subCatePriority',
      '__v',
    ]);
    setAllCate([...data]);
  };
  return (
    <>
      <div className="fixedContainer bg-f7f7f7">
        <div className="page-title">
          {/* <button className="pull-right my-3 py-2" onClick={() => goDummy()}>
            GO 더미
          </button> */}
          <div className="title_left category-title_left">
            <h3>
              <i className="fa fa-cubes" />
              {selectedSite.sellerName} 카테고리{' '}
              <small>사이트별 카테고리</small>
              <div className="pull-right">
                <button className="topButtons red" onClick={() => delCate()}>
                  카테고리 삭제
                </button>
                <button
                  className="topButtons green"
                  onClick={() => changeModal('new', true)}
                >
                  카테고리 추가
                </button>
                <button
                  className="topButtons purple"
                  onClick={() => complete()}
                >
                  카테고리 저장 완료
                </button>
                <button
                  className="goBackBtn  ms-3"
                  onClick={() => GPmodalhandler(false)}
                >
                  이전으로
                </button>
              </div>
            </h3>
          </div>
        </div>
        <div className="clearfix"></div>
        <div className="row">
          <div className="col-12">
            <div className="contents-wrap setcate-table-wrap x_panel">
              <div className="x_content setting">
                {allCate && (
                  <div className="react-bootstrap-table">
                    <table className="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th>선택</th>
                          <th>대카테고리</th>
                          <th>중카테고리</th>
                          <th>노출상품</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allCate.map((codeA, idxA) => {
                          let rowNow = checkRow(
                            'cateName',
                            codeA.cateName,
                            idxA,
                          );
                          return (
                            <tr key={idxA}>
                              <td onClick={() => handleOnCheck(idxA)}>
                                <input
                                  type="checkbox"
                                  id={'checkList' + idxA}
                                  className="setcate-align"
                                  name="checkList"
                                  checked={checkList[idxA]}
                                  readOnly
                                />
                              </td>
                              {(!allCate[idxA - 1] ||
                                codeA.cateName !==
                                  allCate[idxA - 1].cateName) && (
                                <td className="" rowSpan={rowNow}>
                                  <input
                                    type="text"
                                    className="setCateInput-t setcate-align"
                                    name="cateName"
                                    value={allCate[idxA].cateName}
                                    onChange={(e) => {
                                      changeCate(e, idxA);
                                    }}
                                  />

                                  <div
                                    className="cate-reorder-btn setcate-align"
                                    onClick={() =>
                                      onSortOrign('mainOrder', idxA)
                                    }
                                  >
                                    <i className="fa fa-sort"></i>
                                  </div>
                                </td>
                              )}
                              <td className="">
                                <div className="subCateWrap position-relative">
                                  {codeA.subCateBool ? (
                                    <input
                                      type="text"
                                      className="setCateInput setcate-align"
                                      value={allCate[idxA].subCateName}
                                      disabled={!codeA.subCateBool}
                                      name="subCateName"
                                      onChange={(e) => {
                                        changeCate(e, idxA);
                                      }}
                                    />
                                  ) : (
                                    <div className="setCateInput setcate-align">
                                      미설정
                                    </div>
                                  )}
                                  <label className="salesSwitch  setcate-align">
                                    <input
                                      type="checkbox"
                                      checked={codeA.subCateBool}
                                      onChange={() => {
                                        salesHandler(idxA);
                                      }}
                                    />
                                    <span className="slider round"></span>
                                    <p className="pNow">
                                      {!codeA.subCateBool
                                        ? '사용안함'
                                        : '사용중'}
                                    </p>
                                  </label>

                                  <div
                                    className="cate-reorder-btn setcate-align"
                                    onClick={() =>
                                      onSortOrign('subOrder', idxA)
                                    }
                                  >
                                    <i className="fa fa-sort"></i>
                                  </div>
                                  <div
                                    className="tableBtn tableBtn-purple btnW-40 setcate-align"
                                    onClick={() => optionHandler(idxA)}
                                  >
                                    EX
                                  </div>
                                  {(allCate[idxA].subCateType ||
                                    allCate[idxA].subCateCode) && (
                                    <div className="hiddenToolTip">
                                      {allCate[idxA].subCateType
                                        ? allCate[idxA].subCateType + ', '
                                        : ''}
                                      {allCate[idxA].subCateCode
                                        ? allCate[idxA].subCateCode
                                        : ''}
                                    </div>
                                  )}
                                  {codeA.subCateOption && (
                                    <div className="subCateOption-window">
                                      <h5>
                                        {allCate[idxA].cateName +
                                          ' / ' +
                                          allCate[idxA].subCateName +
                                          ' 옵션수정'}
                                      </h5>
                                      <div className="option-block">
                                        <label htmlFor={'type' + String(idxA)}>
                                          중메인 타입
                                        </label>
                                        <input
                                          type="text"
                                          id={'type' + String(idxA)}
                                          className="setcate-align"
                                          value={allCate[idxA].subCateType}
                                          name="subCateType"
                                          onChange={(e) => {
                                            changeCate(e, idxA);
                                          }}
                                        />
                                      </div>
                                      <div className="option-block">
                                        <label htmlFor={'code' + String(idxA)}>
                                          이미지 명 (영문코드)
                                        </label>
                                        <input
                                          type="text"
                                          id={'code' + String(idxA)}
                                          className="setcate-align"
                                          value={allCate[idxA].subCateCode}
                                          name="subCateCode"
                                          onChange={(e) => {
                                            changeCate(e, idxA);
                                          }}
                                        />
                                      </div>
                                      {allCate[idxA].subCateCode && (
                                        <div className="option-block">
                                          {'/menu/product_menu_'}
                                          <span className="blue">
                                            {allCate[idxA].subCateCode}
                                          </span>
                                          {'.png'}
                                        </div>
                                      )}
                                      <div className="option-block">
                                        <div
                                          className="tableBtn tableBtn-skyblue btnW-60 setcate-align"
                                          onClick={() => optionHandler(idxA)}
                                        >
                                          확인
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className="text-start">
                                  <div
                                    className="tableBtn tableBtn-orange btnW-60 setcate-align"
                                    onClick={() => editModalOpen(idxA)}
                                  >
                                    수정
                                  </div>
                                  <div className="cateItemBatch setcate-align">
                                    {codeA.cateItems.length}
                                  </div>
                                  <div className="cateLinkedProducts setcate-align">
                                    <div className="cateLinkedProducts-inner">
                                      {codeA.cateItems.map((code, idx) => {
                                        if (idx == 0) {
                                          return (
                                            <span key={idx}>{code.title}</span>
                                          );
                                        } else {
                                          return (
                                            <span key={idx}>
                                              {', ' + code.title}
                                            </span>
                                          );
                                        }
                                      })}
                                    </div>
                                  </div>

                                  <div
                                    className="cate-reorder-btn setcate-align"
                                    onClick={() =>
                                      onSortOrign('itemOrder', idxA)
                                    }
                                  >
                                    <i className="fa fa-sort"></i>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {modal.new && (
        <CategoryModal
          modalType="new"
          modalIndex={allCate.length}
          selectedSite={selectedSite}
          allCate={allCate}
          setAllCate={setAllCate}
          changeModal={changeModal}
        />
      )}
      {modal.edit && (
        <CategoryModal
          modalType="edit"
          modalIndex={modalIndexNow}
          selectedSite={selectedSite}
          allCate={allCate}
          setAllCate={setAllCate}
          changeModal={changeModal}
        />
      )}
      {modal.mainOrder && (
        <CategoryReorder
          modalType="mainOrder"
          modalIndex={modalIndexNow}
          allCate={allCate}
          setAllCate={setAllCate}
          changeModal={changeModal}
        />
      )}
      {modal.subOrder && (
        <CategoryReorder
          modalType="subOrder"
          modalIndex={modalIndexNow}
          allCate={allCate}
          setAllCate={setAllCate}
          changeModal={changeModal}
        />
      )}
      {modal.itemOrder && (
        <CategoryReorder
          modalType="itemOrder"
          modalIndex={modalIndexNow}
          allCate={allCate}
          setAllCate={setAllCate}
          changeModal={changeModal}
        />
      )}
    </>
  );
}
