import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import CSS from 'csstype';
import axios from 'axios';
import { SERVER_URL } from '@config/path';
import { FirstInput, Order } from '@interfaces/tableCost.interfaces';
import { MESSAGE } from '@utils/textMessage';
import { useLoading } from '@config/loadingContext';
import CodeReorder from '@components/table/standardproduct/reorder/code-reorder';
import OriginReorder from '@components/table/standardproduct/reorder/origin-reorder';
interface Props {
  indexNow: number;
  idNow: string;
  valueNow: string;
  closeName: () => void;
}

export default function SPNameModal({
  indexNow,
  idNow,
  valueNow,
  closeName,
}: Props) {
  const { loadingHandler } = useLoading();
  const [nameNow, setNameNow] = useState<string>(valueNow);
  /**
   * 옵션명 onchange 액션
   * @param index selecter index
   * @constant firstInput
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setNameNow(value);
  };
  /**
   * 저장, 완료 Action
   * @constant firstInput
   * @constant priceStep
   */
  const completeName = async () => {
    loadingHandler(true);
    await axios
      .put(SERVER_URL + '/productbysite/' + idNow, {
        productNameKO: nameNow,
      })
      .then(function (response) {
        closeName();
        loadingHandler(false);
        alert(MESSAGE.SAVE);
      })
      .catch(function (error) {
        console.log('데이터 업로드에 실패 하였습니다.');
        loadingHandler(false);
      });
  };

  return (
    <>
      {nameNow && (
        <>
          <div className="nameChangerWindow">
            <div className="nameChangerInput">
              <input
                type="text"
                name="title"
                value={nameNow}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e)
                }
              />
            </div>
            <div className="nameChangerButton">
              <div
                className="tableBtn tableBtn-green"
                onClick={() => completeName()}
              >
                확인
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
