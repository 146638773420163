import axios, { AxiosResponse } from 'axios';
import { SERVER_URL } from '@config/path';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
  ChangeEvent,
  useEffect,
} from 'react';
type Event = ChangeEvent<HTMLInputElement | HTMLSelectElement>;

function useInput<T>(
  initialData: T,
): [T, (e: Event) => void, Dispatch<SetStateAction<T>>] {
  const [state, setState] = useState<T>(initialData);
  const onChange = useCallback((event: Event): void => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value,
    });
  }, []);
  return [state, onChange, setState];
}

const postToken = async () => {
  let url = '/auth/verifyaccesstoken';
  return await axios.post(url);
};

export { useInput, postToken };
