import { Dispatch, SetStateAction, useState, useEffect } from 'react';
// import { Dispatch, useState, useEffect } from 'react';
// import ReactDom from 'react-dom';
// import { MEGACodeUrl } from '@config/path';
// import axios from 'axios';
// import { MegaAddworkData } from '@interfaces/tableCost.interfaces';
import { Data, Dataitems } from '@interfaces/tableCost.interfaces';
import { CalPaper } from '@interfaces/calculationProductModel.interface';

interface IProps {
  megaData: Data[];
  setMegaData: Dispatch<SetStateAction<Data[]>>;
  selectedItem: CalPaper;
  setSelectedItem: Dispatch<SetStateAction<CalPaper>>;
  closeModal: () => void;
}
export default function StandardPriceModal({
  megaData,
  setMegaData,
  selectedItem,
  setSelectedItem,
  closeModal,
}: IProps) {
  /**
   * 메가 필터링 state
   */
  const [FilterTXT, setFilterTXT] = useState('');
  /**
   * 메가 옵션 체크후 확인시 액션, 선택 code selectedItem 전달
   * @constant firstInputs
   */
  const sendSelected = (code: string) => {
    let selected = megaData[0].items.find((codeA) => codeA.code === code);
    selected &&
      selected.text &&
      setSelectedItem({
        ...selectedItem,
        paperName: selected.text,
        code: selected.code,
        'weight(g)': selected.options.weight
          ? Number(selected.options.weight)
          : 0,
      });
    closeModal();
  };

  const SearchCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFilterTXT(value);
  };
  return (
    <>
      <div className="modalY-overlay" onClick={() => closeModal()}></div>
      <div id="standardproduct-window" className="preview-window preview-800">
        <div
          className="closeBtn"
          onClick={() => {
            closeModal();
          }}
        >
          <i className="fa fa-times"></i>
        </div>

        <div id="standardPriceTable" className="bg-f7f7f7 p-5">
          <div className="contents-wrap x_panel">
            <div className="x_content">
              {megaData.map((e, i) => {
                let innerMap = e.items;
                if (FilterTXT !== '') {
                  innerMap = e.items.filter(
                    (a) =>
                      a.code.toLowerCase().includes(FilterTXT.toLowerCase()) ||
                      a.text.toLowerCase().includes(FilterTXT.toLowerCase()) ||
                      a.options.weight
                        ?.toLowerCase()
                        .includes(FilterTXT.toLowerCase()),
                  );
                }
                return (
                  <div className="active">
                    <div className="code-detail-title">
                      <h4 className="pull-left">
                        {megaData[0].text} ({megaData[0].code}) 선택
                      </h4>
                      <div className="code-detail-search pull-right">
                        <span className="mega-seach-title">
                          <i className="fa fa-search"></i>
                        </span>
                        <input
                          className="mega-seach-input"
                          type="text"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            SearchCode(e)
                          }
                        />
                      </div>
                      <div className="clearfix"></div>
                    </div>
                    <div className="cot-code-wrap">
                      <div className="code-detail-table">
                        <table
                          id="mega-code-table"
                          className="table table-striped"
                          cellSpacing="0"
                        >
                          <thead>
                            <tr>
                              <th>NO.</th>
                              <th>코드</th>
                              <th>코드명</th>
                              <th>두께</th>
                              <th>선택</th>
                            </tr>
                          </thead>
                          <tbody>
                            {innerMap.map((e, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{e.code}</td>
                                  <td>{e.text}</td>
                                  <td>{e.options.weight} g</td>
                                  <td>
                                    <button
                                      className="tableBtn tableBtn-orange btnW-70"
                                      onClick={() => sendSelected(e.code)}
                                    >
                                      선택
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="clearfix" />
    </>
  );
}
