import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import CSS from 'csstype';
import axios from 'axios';
import { SERVER_URL } from '@config/path';
import {
  FirstInput,
  TodaySelectType,
  PriceInner,
} from '@interfaces/tableCost.interfaces';
import { MESSAGE } from '@utils/textMessage';
import { Range, getTrackBackground } from 'react-range';
import SliderTotal from '@components/table/productmanager/slider/pm-today-slider-total';
import SliderSingle from '@components/table/productmanager/slider/pm-today-slider-single';
import { useLoading } from '@config/loadingContext';

interface Props {
  siteName: string;
  firstInputs: FirstInput;
  setFirstInputs: Dispatch<SetStateAction<FirstInput>>;
  closeModal: () => void;
}

export default function TodayModal({
  siteName,
  firstInputs,
  setFirstInputs,
  closeModal,
}: Props) {
  const productIdNow: string = firstInputs._id ? firstInputs._id : '';
  const { loadingHandler } = useLoading();
  const timeFormat = (val: string) => {
    let valA = Number(val.split('.')[0]);
    let valB: number | string = Number(val.split('.')[1]);
    if (valB === 5) {
      valB = 30;
    } else {
      valB = '00';
    }
    if (valA <= 12) {
      return '오전 ' + valA + ':' + valB;
    } else {
      return '오후 ' + (valA - 12) + ':' + valB;
    }
  };
  const [TODAYSelect, setTODAYSelect] = useState<TodaySelectType>({
    code: [
      ...firstInputs.selecters.map((a) => {
        return [];
      }),
    ],
    quantity: [
      firstInputs.productTypes[0].price[0].quantity
        ? firstInputs.productTypes[0].price[0].quantity
        : 0,
    ],
  });
  /** 제한사항 */
  const limited: { from: string; to: string }[] = firstInputs.selecters
    .map((a) =>
      a.types
        .map((b) =>
          b.class.map((c) => {
            return { from: b.code, to: c };
          }),
        )
        .flat(),
    )
    .flat();
  /**
   * 옵션명 onchange 액션
   * @param index selecter index
   * @constant firstInput
   */
  const handleChange = (code: string, index: number) => {
    let copyCode = [...TODAYSelect.code];
    if (copyCode[index].includes(code)) {
      let copyCodeA = copyCode[index].filter((b) => b !== code);
      copyCode[index] = copyCodeA;
    } else {
      //재한사항 확인로직 추가
      let possiblelyLimited = limited.filter((a) =>
        TODAYSelect.code.some((b) => b.includes(a.from) || b.includes(a.to)),
      );
      if (possiblelyLimited.length > 0) {
        let finalLimited = possiblelyLimited.filter(
          (a) => a.from.includes(code) || a.to.includes(code),
        );
        if (finalLimited.length > 0) {
          alert(
            finalLimited
              .map((code) => {
                return (
                  code.from +
                  '는(은) ' +
                  code.to +
                  '와 함께 선택될 수 없습니다.'
                );
              })
              .join('\n'),
          );
          return;
        }
      }
      copyCode[index].push(code);
    }
    if (copyCode) setTODAYSelect({ ...TODAYSelect, code: copyCode });
  };
  const handleQty = (qty: number) => {
    let copyQty = [...TODAYSelect.quantity];
    if (TODAYSelect.quantity.includes(qty)) {
      let filterQtyA = copyQty.filter((b) => b !== qty);
      setTODAYSelect({ ...TODAYSelect, quantity: filterQtyA });
    } else {
      copyQty.push(qty);
      let filterQtyB = copyQty.filter(function (a, i, self) {
        return self.indexOf(a) === i;
      });
      setTODAYSelect({ ...TODAYSelect, quantity: filterQtyB });
    }
  };
  const singleDeleteQty = async (ID: string | undefined) => {
    let filterProduct = firstInputs.productTypes.filter((a) =>
      a.price.some((b) => b._id === ID),
    )[0];
    let productTypesIdNow = filterProduct._id;
    if (productIdNow && productTypesIdNow && ID) {
      loadingHandler(true);
      await axios
        .delete(
          SERVER_URL +
            `/productbysite/${productIdNow}/todaytime?productTypesId=${productTypesIdNow}&priceId=${ID}`,
        )
        .then(function (response) {
          loadingHandler(false);
          setFirstInputs(response.data.data);
        })
        .catch(function (error) {
          console.log('데이터 업로드에 실패 하였습니다.');
          loadingHandler(false);
        });
    } else {
      alert('다시 시도해 주시기 바랍니다.');
    }
  };
  const [TimeStamp, setTimeStamp] = useState<number[]>([]);
  const [TimeStampSUB, setTimeStampSUB] = useState<number[]>([]);
  const toTimestamp = (values: number[]) => {
    let result = values.map((time) => {
      if (Number.isInteger(time)) {
        let strDate = '01/01/2222 ' + time + ':00:00';
        let datum = Date.parse(strDate) / 1000;
        return datum;
      } else {
        let strDate = '01/01/2222 ' + Math.floor(time) + ':30:00';
        let datum = Date.parse(strDate) / 1000;
        return datum;
      }
    });
    return result;
  };
  const TotalTodayHandler = async () => {
    let turnNow = !firstInputs.todayPrint;
    if (productIdNow) {
      loadingHandler(true);
      await axios
        .put(`${SERVER_URL}/productbysite/${productIdNow}`, {
          todayPrint: turnNow,
        })
        .then(function (response) {
          loadingHandler(false);
          setFirstInputs((prev) => {
            return { ...prev, todayPrint: turnNow };
          });
        })
        .catch(function (error) {
          console.log('데이터 업로드에 실패 하였습니다.');
          loadingHandler(false);
        });
    } else {
      alert('다시 시도해 주시기 바랍니다.');
    }
  };
  /**
   * 저장, 완료 Action
   * @constant firstInputs
   * @constant priceStep
   */
  const saveNow = async () => {
    if (TODAYSelect.code.some((a) => a.length < 1)) {
      alert('옵션당 최소 1개의 옵션을 선택 해주세요.');
      return;
    } else {
      //loadingHandler(true);
      let filterProduct = firstInputs.productTypes.filter((a) =>
        a.selectors.every((b) =>
          TODAYSelect.code.some((c) => c.includes(b.code)),
        ),
      );
      let filterBODY = filterProduct.map((a) => {
        return {
          _id: a._id,
          price: a.price.map((b) => {
            if (TODAYSelect.quantity.includes(Number(b.quantity))) {
              return {
                calcValue: b.calcValue,
                quantity: b.quantity,
                sales: b.sales,
                todayTypes: {
                  switch: b.todayTypes?.switch,
                  time: {
                    from: TimeStamp[0].toString(),
                    to: TimeStamp[1].toString(),
                  },
                },
                value: b.value,
              };
            } else {
              return {
                calcValue: b.calcValue,
                quantity: b.quantity,
                sales: b.sales,
                todayTypes: b.todayTypes,
                value: b.value,
              };
            }
          }),
        };
      });
      if (productIdNow && filterBODY) {
        loadingHandler(true);
        await axios
          .put(SERVER_URL + `/productbysite/${productIdNow}/todaytime`, {
            data: filterBODY,
          })
          .then(function (response) {
            loadingHandler(false);
            setFirstInputs(response.data.data);
            console.log(MESSAGE.SAVE);
          })
          .catch(function (error) {
            console.log('데이터 업로드에 실패 하였습니다.');
            loadingHandler(false);
          });
      } else {
        alert('다시 시도해 주시기 바랍니다.');
      }
    }
  };

  const saveNowSUB = async (
    productTypesIdSingle: string | undefined,
    _idSingle: string | undefined,
  ) => {
    if (productIdNow && productTypesIdSingle && _idSingle && TimeStampSUB) {
      loadingHandler(true);
      await axios
        .put(
          SERVER_URL +
            `/productbysite/${productIdNow}/todayTimeSub?productTypesId=${productTypesIdSingle}&priceId=${_idSingle}`,
          {
            from: TimeStampSUB[0].toString(),
            to: TimeStampSUB[1].toString(),
          },
        )
        .then(function (response) {
          loadingHandler(false);
          setFirstInputs(response.data.data);
          console.log(MESSAGE.SAVE);
        })
        .catch(function (error) {
          console.log('데이터 업로드에 실패 하였습니다.');
          loadingHandler(false);
        });
    } else {
      alert('다시 시도해 주시기 바랍니다.');
    }
  };
  const timeConvert = (num: number | undefined) => {
    if (num) {
      let time = new Date(num * 1000);
      let newHour = time.getHours();
      let newMinute = time.getMinutes();
      if (newMinute == 0) {
        return newHour + ':00';
      } else {
        return newHour + ':30';
      }
    }
  };
  const [EditModal, setEditModal] = useState(false);
  const [EditModalID, setEditModalID] = useState<[string, number, number]>([
    '',
    0,
    0,
  ]);
  const [EditModalArr, setEditModalArr] = useState<PriceInner>(
    firstInputs.productTypes[EditModalID[1]].price[EditModalID[2]],
  );

  const OpenEditModal = (id: string | undefined) => {
    if (!EditModal && id !== undefined) {
      setEditModal(true);
      let index1 = firstInputs.productTypes.findIndex((a) =>
        a.price.some((b) => b._id === id),
      );
      let index2 = firstInputs.productTypes[index1].price.findIndex(
        (c) => c._id === id,
      );
      let ArrCopy = firstInputs.productTypes[index1].price[index2];
      setEditModalArr(ArrCopy);
      setEditModalID([id, index1, index2]);
    } else {
      setEditModal(false);
    }
  };
  return (
    <>
      <div className="modalY-overlay" onClick={() => closeModal()}></div>
      <div id="standardproduct-window" className="preview-window">
        <div
          className="closeBtn"
          onClick={() => {
            closeModal();
          }}
        >
          <i className="fa fa-times"></i>
        </div>

        {firstInputs && (
          <>
            <div className="bg-f7f7f7 p-5">
              <div className="contents-wrap x_panel">
                <div className="x_title">
                  <h4>
                    <span>
                      <i>{siteName}</i>
                    </span>{' '}
                    - {firstInputs.productNameKO} 당일판 설정
                  </h4>
                </div>
                <div className="clearfix"></div>
                <div className="x_content">
                  <form
                    id="firstForm"
                    className="form-horizontal form-label-left"
                  >
                    <div className="form-group">
                      <div className="cost-cont">
                        <table
                          id="code-select-table"
                          className="table yTable"
                          cellSpacing="0"
                        >
                          <thead>
                            <tr>
                              <th>옵션명</th>
                              <th>주문옵션 코드값</th>
                            </tr>
                          </thead>
                          <tbody>
                            {firstInputs &&
                              firstInputs.selecters.map(
                                (newCategoryMap, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="todayInputs-title align-middle">
                                        <div className="selecterName">
                                          {newCategoryMap.selecterName}
                                        </div>
                                      </td>
                                      <td className="todayInputs-content">
                                        <div className="option-input-group-wrap ub">
                                          <div className="input-group">
                                            {firstInputs.selecters[
                                              index
                                            ].types.map((b, i) => {
                                              return (
                                                <React.Fragment key={b.code}>
                                                  <input
                                                    id={`${b.code}-input`}
                                                    className="todayInputs"
                                                    name={`${firstInputs.selecters[index].selecterName}-input`}
                                                    type="checkbox"
                                                    checked={
                                                      TODAYSelect.code[
                                                        index
                                                      ].includes(b.code)
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={() =>
                                                      handleChange(
                                                        b.code,
                                                        index,
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    htmlFor={`${b.code}-input`}
                                                  >
                                                    {b.title}
                                                  </label>
                                                </React.Fragment>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                },
                              )}
                            <tr>
                              <td className="todayInputs-title align-middle">
                                <div className="selecterName">
                                  수량
                                  <i className="red">여러개 선택가능</i>
                                </div>
                              </td>
                              <td className="todayInputs-content">
                                <div className="option-input-group-wrap ub">
                                  <div className="input-group">
                                    {firstInputs &&
                                      firstInputs.productTypes[0].price.map(
                                        (type, index) => {
                                          return (
                                            <React.Fragment
                                              key={`${type.quantity}-key-${index}`}
                                            >
                                              <input
                                                id={`${type.quantity}-input`}
                                                className="todayInputs"
                                                name={'qty-todayInput'}
                                                type="checkbox"
                                                checked={TODAYSelect.quantity.includes(
                                                  Number(type.quantity),
                                                )}
                                                onChange={() =>
                                                  handleQty(
                                                    Number(type.quantity),
                                                  )
                                                }
                                              />
                                              <label
                                                htmlFor={`${type.quantity}-input`}
                                              >
                                                {type.quantity}
                                              </label>
                                            </React.Fragment>
                                          );
                                        },
                                      )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="todayInputs-title align-middle">
                                <div className="selecterName">당일판 시간</div>
                              </td>
                              <td className="todayInputs-content">
                                <div className="option-input-group-wrap ub">
                                  <div className="input-group todayRangeInputs py-2">
                                    <SliderTotal
                                      setTimeStamp={setTimeStamp}
                                      toTimestamp={toTimestamp}
                                      timeFormat={timeFormat}
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="row py-1">
                <div className="col-sm-12  text-center complete-btn-wrap my-3">
                  <div
                    className="yBtn yBtn-xl purple btn-inline-block"
                    data-toggle="tooltip"
                    onClick={() => {
                      saveNow();
                    }}
                  >
                    <span>
                      <i className="fa fa-plus" aria-hidden="true"></i> 등록하기
                    </span>
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
              {firstInputs.todayPrint !== undefined && (
                <>
                  <div className="contents-wrap x_panel">
                    <div className="x_content">
                      <div className="todayView-item-total">
                        <div className="todayView-title align-middle d-inline-block">
                          <div className="selecterName">당일판 on/off</div>
                        </div>
                        <div className="todayView-content d-inline-block">
                          <label className="salesSwitch">
                            <input
                              type="checkbox"
                              checked={firstInputs.todayPrint ? true : false}
                              onChange={() => TotalTodayHandler()}
                            />
                            <span className="slider round"></span>
                            <p className="pNow">
                              {firstInputs.todayPrint ? '작동중' : '작동중지'}
                            </p>
                          </label>
                        </div>
                      </div>
                      {firstInputs.productTypes.filter((a) => {
                        return a.price.some(
                          (b) =>
                            b.todayTypes && b.todayTypes.time !== undefined,
                        );
                      }).length > 0 && (
                        <div className="todayView-item-wrap mt-2">
                          <div className="row">
                            {firstInputs.productTypes
                              .filter((a) => {
                                return a.price.some(
                                  (b) =>
                                    b.todayTypes &&
                                    b.todayTypes.time !== undefined,
                                );
                              })
                              .map((c) => {
                                return (
                                  <div
                                    className="todayView-item col-sm-12 col-md-12 col-lg-6"
                                    key={c._id}
                                  >
                                    <div className="todayView-item-inner">
                                      <div className="todayView-options">
                                        {c.selectors.map((d, i) => {
                                          return (
                                            <p
                                              className="d-inline-block"
                                              key={d.title}
                                            >
                                              {i !== 0 && (
                                                <span className="px-1 devider">
                                                  /
                                                </span>
                                              )}
                                              <span>{d.title}</span>
                                            </p>
                                          );
                                        })}
                                      </div>
                                      <div className="clearfix"></div>
                                      <div className="todayView-qty mt-2">
                                        {c.price
                                          .filter(
                                            (f) =>
                                              f.todayTypes &&
                                              f.todayTypes.time !== undefined,
                                          )
                                          .map((g, index) => {
                                            return (
                                              <div
                                                className="todayView-qty-item"
                                                key={`${g.quantity}-key-${index}`}
                                              >
                                                <div
                                                  id={`${g.quantity}-viewqty`}
                                                  className={`todayqtyNow ${
                                                    g.todayTypes?.switch
                                                      ? 'selected'
                                                      : ''
                                                  }`}
                                                  name={'qty-todayInput'}
                                                  // onChange={() =>
                                                  //   singlehandleQty(
                                                  //     g._id,
                                                  //     g.todayTypes?.switch,
                                                  //   )
                                                  // }
                                                >
                                                  {g.quantity}
                                                </div>
                                                <p className="todayInputs-time d-inline-block ms-2">
                                                  {timeConvert(
                                                    g.todayTypes?.time?.from,
                                                  )}
                                                  {' ~ '}
                                                  {timeConvert(
                                                    g.todayTypes?.time?.to,
                                                  )}
                                                </p>
                                                <div className="todayInputs-icons-wrap pull-right">
                                                  <p
                                                    className="todayInputs-icons todayInputs-open "
                                                    onClick={() =>
                                                      OpenEditModal(g._id)
                                                    }
                                                  >
                                                    <i className="fa fa-cog"></i>
                                                  </p>
                                                  <p
                                                    className="todayInputs-icons todayInputs-del ms-1"
                                                    onClick={() =>
                                                      singleDeleteQty(g._id)
                                                    }
                                                  >
                                                    <i className="fa fa-times"></i>
                                                  </p>
                                                </div>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    </div>
                                    {/* <label className="salesSwitch">
                            <input
                              type="checkbox"
                              checked={firstInputs.todayPrint ? true : false}
                              onChange={() => TotalTodayHandler()}
                            />
                            <span className="slider round"></span>
                            <p className="pNow">
                              {firstInputs.todayPrint ? '작동중' : '작동중지'}
                            </p>
                          </label> */}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </>
              )}
              <div className="row py-1">
                <div className="col-sm-12  text-center complete-btn-wrap mt-3">
                  <div
                    className="btn yBtn yBtn-small green btn btn-inline-block"
                    data-toggle="tooltip"
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    <span>
                      <i className="fa fa-check" aria-hidden="true"></i> 완료
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {EditModal && (
        <>
          <div
            className="modalY-overlay"
            onClick={() => setEditModal(false)}
          ></div>
          <div className="item-edit-window item-edit-window-ver1 d-inline-block">
            <div className="todayView-options">
              {firstInputs.productTypes[EditModalID[1]].selectors.map(
                (c, i) => {
                  return (
                    <p className="d-inline-block" key={c.title}>
                      {i !== 0 && <span className="px-1 devider">/</span>}
                      <span>{c.title}</span>
                    </p>
                  );
                },
              )}
            </div>
            <div className="modal-wrap">
              <div>{EditModalArr.quantity}</div>
              <div className="todayInputs-time">
                {timeConvert(EditModalArr.todayTypes?.time?.from)}
                {' ~ '}
                {timeConvert(EditModalArr.todayTypes?.time?.to)}
                <SliderSingle
                  fromSingle={timeConvert(EditModalArr.todayTypes?.time?.from)}
                  toSingle={timeConvert(EditModalArr.todayTypes?.time?.to)}
                  setTimeStampSUB={setTimeStampSUB}
                  toTimestamp={toTimestamp}
                  timeFormat={timeFormat}
                />
              </div>
              <div className="text-center">
                <div
                  className="btn yBtn yBtn-small btn btn-inline-block"
                  data-toggle="tooltip"
                  onClick={() => {
                    setEditModal(false);
                  }}
                >
                  <span>
                    <i className="fa fa-times" aria-hidden="true"></i> 닫기
                  </span>
                </div>
                <div
                  className="btn yBtn yBtn-small red btn btn-inline-block"
                  data-toggle="tooltip"
                  onClick={() =>
                    singleDeleteQty(
                      firstInputs.productTypes[EditModalID[1]].price[
                        EditModalID[2]
                      ]._id,
                    )
                  }
                >
                  <span>
                    <i className="fa fa-trash" aria-hidden="true"></i> 삭제하기
                  </span>
                </div>
                <div
                  className="btn yBtn yBtn-small purple btn btn-inline-block"
                  data-toggle="tooltip"
                  onClick={() => {
                    saveNowSUB(
                      firstInputs.productTypes[EditModalID[1]]._id,
                      firstInputs.productTypes[EditModalID[1]].price[
                        EditModalID[2]
                      ]._id,
                    );
                  }}
                >
                  <span>
                    <i className="fa fa-plus" aria-hidden="true"></i> 등록하기
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
