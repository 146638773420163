import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import axios from 'axios';
import { MEGACodeUrl } from '@config/path';
import { SERVER_URL } from '@config/path';
import { useLoading } from '@config/loadingContext';
import { CalPaper } from '@interfaces/calculationProductModel.interface';
import NumberFormat from 'react-number-format';
import { Data, Dataitems } from '@interfaces/tableCost.interfaces';
import StandardPriceModal from '@components/modal/megaModal-standardprice';

interface Props {
  types: string;
  calcID?: string | null;
  selectedItem: CalPaper;
  setSelectedItem: Dispatch<SetStateAction<CalPaper>>;
  closeModal: (str: string) => void;
}

export default function PaperModal({
  types,
  calcID,
  selectedItem,
  setSelectedItem,
  closeModal,
}: Props) {
  const { loadingHandler } = useLoading();
  /**
   * 옵션명 onchange 액션
   * @constant selectedItem
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let copySelecters = {
      ...selectedItem,
      [name]: name !== 'paperName' ? Number(value.replace(/,/g, '')) : value,
    };
    setSelectedItem(copySelecters);
  };
  /**
   * 저장, 완료 Action
   * @constant selectedItem
   * @constant types
   */
  const saveNow = async () => {
    loadingHandler(true);
    let DataCopy = { ...selectedItem };
    delete DataCopy.modifiedAt;
    delete DataCopy.modifiedBy;
    delete DataCopy.createdAt;
    try {
      const response =
        types === 'new'
          ? await axios.post(
              SERVER_URL + '/calproduct/push?target=paper&id=' + calcID,
              { beSaved: [DataCopy] },
            )
          : await axios.put(
              SERVER_URL + `/calproduct/serveral?target=paper&id=${calcID}`,
              {
                beSaved: [DataCopy],
              },
            );
      response && (loadingHandler(false), closeModal(types));
      return;
    } catch (e) {
      loadingHandler(false);
      alert('Error');
      console.log(e);
    }
  };
  /**
   * 메가모달
   * @constant paper 메가에서 불러오기
   */

  const [megaPaper, setMegaPaper] = useState<Data[]>([]);
  useEffect(() => {
    async function getMegaData() {
      await axios
        .get(MEGACodeUrl)
        .then(function (response) {
          let filtered = response.data.filter(
            (data: Data) => data.code === 'materialCode',
          );
          setMegaPaper(filtered);
        })
        .catch(function (error) {
          console.log('메가 데이터 로드에 실패 하였습니다.');
        });
    }
    getMegaData();
  }, []);
  const [megaPaperModal, setMegaPaperModal] = useState(false);
  const openMegaModal = () => {
    setMegaPaperModal(true);
  };
  const closeMegaPaperModal = () => {
    setMegaPaperModal(false);
  };

  return (
    <>
      <div className="modalY-overlay" onClick={() => closeModal(types)}></div>
      <div
        id="standardproduct-window"
        className="preview-window preview-600 anitop-50"
      >
        <div
          className="closeBtn"
          onClick={() => {
            closeModal(types);
          }}
        >
          <i className="fa fa-times"></i>
        </div>

        {selectedItem && (
          <>
            <div className="bg-f7f7f7 p-5">
              <div className="contents-wrap x_panel">
                <div className="x_content">
                  <div className="text-center">
                    <div
                      className="yBtn yBtn-xl orange text-center d-inline-block mb-3"
                      onClick={() => openMegaModal()}
                    >
                      <i className="fa fa-download"></i> 데이터 불러오기
                    </div>
                  </div>
                  <form
                    id="firstForm"
                    className="form-horizontal form-label-left"
                  >
                    <div className="form-group">
                      <div className="cost-cont">
                        <table
                          id="code-select-table"
                          className="table yTable rename"
                          cellSpacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Item</th>
                              <th>Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="col-3 col-sm-4 col-xs-12">
                                용지명
                              </td>
                              <td className="col-9 col-sm-8 col-xs-12">
                                <input
                                  type="text"
                                  className="form-control megaModalTitle"
                                  placeholder="용지명을 입력해주세요"
                                  name="paperName"
                                  value={selectedItem.paperName || ''}
                                  onChange={(e) => handleChange(e)}
                                  required
                                  autoComplete="off"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-3 col-sm-4 col-xs-12">코드</td>
                              <td className="col-9 col-sm-8 col-xs-12">
                                <input
                                  type="text"
                                  className="form-control megaModalTitle"
                                  placeholder="코드명을 입력해주세요"
                                  name="code"
                                  value={selectedItem.code || ''}
                                  onChange={(e) => handleChange(e)}
                                  required
                                  autoComplete="off"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-3 col-sm-4 col-xs-12">평량</td>
                              <td className="col-9 col-sm-8 col-xs-12">
                                <NumberFormat
                                  type="text"
                                  className="form-control megaModalTitle"
                                  placeholder="평량을 입력해주세요"
                                  name="weight(g)"
                                  value={selectedItem['weight(g)'] || ''}
                                  onChange={(e) => handleChange(e)}
                                  required
                                  autoComplete="off"
                                  thousandSeparator
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-3 col-sm-4 col-xs-12">
                                실제두께
                              </td>
                              <td className="col-9 col-sm-8 col-xs-12">
                                <NumberFormat
                                  type="text"
                                  className="form-control megaModalTitle"
                                  placeholder="두께를 입력해주세요"
                                  name="thickness(㎛)"
                                  value={selectedItem['thickness(㎛)'] || ''}
                                  onChange={(e) => handleChange(e)}
                                  required
                                  autoComplete="off"
                                  thousandSeparator
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-3 col-sm-4 col-xs-12">
                                46절 가격
                              </td>
                              <td className="col-9 col-sm-8 col-xs-12">
                                <NumberFormat
                                  type="text"
                                  className="form-control megaModalTitle"
                                  placeholder="46절가를 입력해주세요"
                                  name="4*6:price"
                                  value={selectedItem['4*6:price'] || ''}
                                  onChange={(e) => handleChange(e)}
                                  required
                                  autoComplete="off"
                                  thousandSeparator
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-3 col-sm-4 col-xs-12">
                                46절 할인율
                              </td>
                              <td className="col-9 col-sm-8 col-xs-12">
                                <NumberFormat
                                  type="text"
                                  className="form-control megaModalTitle"
                                  placeholder="46절할인가를 입력해주세요"
                                  name="4*6:discount"
                                  value={selectedItem['4*6:discount'] || ''}
                                  onChange={(e) => handleChange(e)}
                                  required
                                  autoComplete="off"
                                  thousandSeparator
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-3 col-sm-4 col-xs-12">
                                국전 가격
                              </td>
                              <td className="col-9 col-sm-8 col-xs-12">
                                <NumberFormat
                                  type="text"
                                  className="form-control megaModalTitle"
                                  placeholder="국전가를 입력해주세요"
                                  name="gukJeon:price"
                                  value={selectedItem['gukJeon:price'] || ''}
                                  onChange={(e) => handleChange(e)}
                                  required
                                  autoComplete="off"
                                  thousandSeparator
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-3 col-sm-4 col-xs-12">
                                국전 할인율
                              </td>
                              <td className="col-9 col-sm-8 col-xs-12">
                                <NumberFormat
                                  type="text"
                                  className="form-control megaModalTitle"
                                  placeholder="국전할인가를 입력해주세요"
                                  name="gukJeon:discount"
                                  value={selectedItem['gukJeon:discount'] || ''}
                                  onChange={(e) => handleChange(e)}
                                  required
                                  autoComplete="off"
                                  thousandSeparator
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-3 col-sm-4 col-xs-12">
                                소국전 가격
                              </td>
                              <td className="col-9 col-sm-8 col-xs-12">
                                <NumberFormat
                                  type="text"
                                  className="form-control megaModalTitle"
                                  placeholder="소국전가를 입력해주세요"
                                  name="smallGukJeon:price"
                                  value={
                                    selectedItem['smallGukJeon:price'] || ''
                                  }
                                  onChange={(e) => handleChange(e)}
                                  required
                                  autoComplete="off"
                                  thousandSeparator
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-3 col-sm-4 col-xs-12">
                                소국전 할인율
                              </td>
                              <td className="col-9 col-sm-8 col-xs-12">
                                <NumberFormat
                                  type="text"
                                  className="form-control megaModalTitle"
                                  placeholder="소국전할인가를 입력해주세요"
                                  name="smallGukJeon:discount"
                                  value={
                                    selectedItem['smallGukJeon:discount'] || ''
                                  }
                                  onChange={(e) => handleChange(e)}
                                  required
                                  autoComplete="off"
                                  thousandSeparator
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="col-3 col-sm-4 col-xs-12">
                                할인율
                              </td>
                              <td className="col-9 col-sm-8 col-xs-12">
                                <NumberFormat
                                  type="text"
                                  className="form-control megaModalTitle"
                                  placeholder="할인율을 입력해주세요"
                                  name="discount"
                                  value={selectedItem.discount || ''}
                                  onChange={(e) => handleChange(e)}
                                  required
                                  autoComplete="off"
                                  thousandSeparator
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="clearfix" />
              <div className="row pt-2">
                <div className="col-sm-12  text-center complete-btn-wrap mt-4 mb-2">
                  <div
                    className="price-start-btn btn-inline-block"
                    data-toggle="tooltip"
                    onClick={() => {
                      saveNow();
                    }}
                  >
                    <span>
                      <i className="fa fa-check" aria-hidden="true"></i>{' '}
                      수정완료
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {megaPaperModal && (
        <>
          <StandardPriceModal
            megaData={megaPaper}
            setMegaData={setMegaPaper}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            closeModal={closeMegaPaperModal}
          />
        </>
      )}
    </>
  );
}
