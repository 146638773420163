import { Dispatch, useState, SetStateAction } from 'react';
import { Code, OptGroupType } from '@interfaces/tableCost.interfaces';
import {
  Draggable,
  Droppable,
  DragDropContext,
  DragUpdate,
} from 'react-beautiful-dnd';
// import ReorderWindow from './reorder/reorder-window';
interface Props {
  type: string;
  materialArr: Code;
  groups: OptGroupType[];
  setGroups: Dispatch<SetStateAction<OptGroupType[]>>;
  closeModal: () => void;
  itemIdx: number;
}

export default function GroupReorder({
  type,
  materialArr,
  groups,
  setGroups,
  closeModal,
  itemIdx,
}: Props) {
  const [reOrderGroups, setReOrderGroups] = useState<OptGroupType[]>([
    ...groups,
  ]);
  const [reOrderItems, setReOrderItems] = useState<string[]>([
    ...groups[itemIdx].items,
  ]);
  const reOrderFinish = () => {
    if (type === 'groups') {
      setGroups([...reOrderGroups]);
    } else {
      let copy = [...groups];
      copy[itemIdx].items = [...reOrderItems];
      setGroups(copy);
    }
    closeModal();
  };
  const onDragEnd = ({ source, destination }: DragUpdate) => {
    if (destination === undefined || destination === null) return null;
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    )
      return null;
    if (type === 'groups') {
      let newList = reOrderGroups.filter((_, idx) => idx !== source.index);
      newList.splice(destination.index, 0, reOrderGroups[source.index]);
      setReOrderGroups(newList);
    } else {
      let newItemList = reOrderItems.filter((_, idx) => idx !== source.index);
      newItemList.splice(destination.index, 0, reOrderItems[source.index]);
      setReOrderItems(newItemList);
    }
    return null;
  };
  return (
    <>
      <div id="reorder-wrap">
        <div className="reorder-inner">
          <div className="reorder-cont">
            <h3>
              주문페이지 옵션
              <span> - 순서변경</span>
            </h3>
            <DragDropContext onDragEnd={onDragEnd}>
              <div className="ReorderStyledWrap mb-3">
                <Droppable droppableId="reorderId" key="reorderkey">
                  {(provided) => (
                    <div className="StyledColumn">
                      <ul
                        className="StyledList"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {reOrderGroups && reOrderItems && type === 'groups'
                          ? reOrderGroups.map((a, index) => (
                              <Draggable
                                key={a.title}
                                draggableId={a.title}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <li
                                    className={`StyledItem ${
                                      snapshot.isDragging ? 'dragging' : ''
                                    }
                                                            `}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div>{a.title}</div>
                                  </li>
                                )}
                              </Draggable>
                            ))
                          : reOrderItems.map((a, index) => {
                              let finded = materialArr.filter(
                                (b) => b.code == a,
                              );
                              let result =
                                finded.length > 0
                                  ? finded[0].title
                                  : '삭제된 용지';
                              return (
                                <Draggable
                                  key={a}
                                  draggableId={a}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <li
                                      className={`StyledItem ${
                                        snapshot.isDragging ? 'dragging' : ''
                                      }
                                                            `}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div>{result}</div>
                                    </li>
                                  )}
                                </Draggable>
                              );
                            })}
                        {provided.placeholder}
                      </ul>
                    </div>
                  )}
                </Droppable>
              </div>
            </DragDropContext>
          </div>
          <div className="reorder-buttonWrap text-center">
            <div
              className="yBtn yBtn-xs gray me-2 d-inline-block"
              onClick={() => closeModal()}
            >
              이전으로
            </div>
            <div
              className="yBtn yBtn-md green text-center d-inline-block"
              onClick={() => reOrderFinish()}
            >
              선택완료
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
