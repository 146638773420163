import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { FirstInput, LetCode } from '@interfaces/tableCost.interfaces';
import CodeSelectTable from '@components/table/standardproduct/code-select/code-select-table';
interface Props {
  firstInputs: FirstInput;
  setFirstInputs: Dispatch<SetStateAction<FirstInput>>;
  handleChange: (name: string, value: string | boolean) => void;
  NewClass: LetCode;
}
export default function CodeWrapTable({
  firstInputs,
  setFirstInputs,
  handleChange,
  NewClass,
}: Props) {
  return (
    <>
      <form id="firstForm" className="form-horizontal form-label-left">
        <div className="form-group">
          <div className="row">
            <label className="cost-title col-md-2 col-sm-2 col-xs-12">
              상품카테고리
            </label>
            <div className="cost-cont cost-cont col-md-10 col-sm-10 col-xs-12">
              <div className="row">
                <div className="col-md-4">
                  <div className="fakeSelect">
                    {firstInputs && firstInputs.category[0].categoryName1st}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="fakeSelect">
                    {firstInputs && firstInputs.category[0].categoryName2nd}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="fakeSelect">
                    {firstInputs && firstInputs.productNameKO}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <label className="cost-title col-md-2 col-sm-2 col-xs-12">
              상품명
            </label>
            <div className="cost-cont cost-cont col-md-10 col-sm-10 col-xs-12">
              <input
                type="text"
                className="form-control"
                placeholder="상품명"
                name="productNameKO"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                value={(firstInputs && firstInputs.productNameKO) || ''}
                required
                //disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <label className="cost-title col-md-2 col-sm-2 col-xs-12">
              영문상품명(URL)
            </label>
            <div className="cost-cont cost-cont col-md-10 col-sm-10 col-xs-12">
              <input
                type="text"
                className="form-control"
                placeholder="영문 상품명"
                name="productNameEN"
                //onChange={(e) => handleChange(e.target.name, e.target.value)}
                value={(firstInputs && firstInputs.productNameEN) || ''}
                required
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <label className="cost-title col-md-2 col-sm-2 col-xs-12">
              상품 주문옵션코드 선택
            </label>
            <div className="cost-cont col-md-10 col-sm-10 col-xs-12">
              <CodeSelectTable
                firstInputs={firstInputs}
                setFirstInputs={setFirstInputs}
                NewClass={NewClass}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
