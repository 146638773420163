import { useState, useEffect, Dispatch, SetStateAction, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import CSS from 'csstype';
import axios from 'axios';
import { SERVER_URL, MEGAProductsUrl } from '@config/path';
import CodeWrapTableEd from '@components/table/standardproduct/code-select/code-wrap-table-edit';
import SPPriceTable from '@components/table/productmanager/price-manager-table';
import PriceBtnWrap from '@components/table/standardproduct/price-input/price-btn-wrap';
import alertCategorize from '@utils/alertFunc';
import {
  FirstInput,
  EditInput,
  TableCostStep,
  Order,
  MegaProduct,
  TotalCalc,
  LetCode,
} from '@interfaces/tableCost.interfaces';
import { useAuthState } from '@config/context';
import { emptyPrice } from '@dummydata/dummy';
// import { compare } from '@config/compare/compareBefore';
import { CONFIRM, MESSAGE } from '@utils/textMessage';
import { getLatest } from '@utils/common';
import { useLoading } from '@config/loadingContext';
interface Props {
  firstInputs: FirstInput;
  setFirstInputs: Dispatch<SetStateAction<FirstInput>>;
  originInputs: FirstInput;
  setOriginInputs: Dispatch<SetStateAction<FirstInput>>;
  changeSPModal: (bool: boolean) => void;
  previewhandler: (boolean: boolean) => void;
}
export default function ViewModal({
  firstInputs,
  setFirstInputs,
  originInputs,
  setOriginInputs,
  changeSPModal,
  previewhandler,
}: Props) {
  /**토큰 state*/
  const { loadingNow, loadingHandler } = useLoading();
  /**ref*/
  const myRef = useRef<HTMLDivElement>(null);
  /**제품분리(도수외) state*/
  const [latestInput, setLatestInput] = useState<Order[]>([]);
  /**제품분리(도수) state*/
  const [latestColor, setLatestColor] = useState<Order[]>([]);
  /**가격표없는옵션분리state*/
  const [noPriceInput, setNoPriceInput] = useState<Order[]>([]);
  const [editInputs, setEditInputs] = useState<EditInput>({
    _id: firstInputs._id,
    totalPriceCalc: firstInputs.totalPriceCalc,
    productTypes: firstInputs.productTypes,
  });
  /**가격 스텝 State*/
  const [priceStep, setPriceStep] = useState<TableCostStep>('readyToMake');
  /**가격비교여부 state*/
  const [readyToCompare, setReadyToCompare] = useState<boolean>(false);
  const [roundDownNow, setRoundDownNow] = useState<number>(0);
  /**
   * 제한사항 보여주기
   * @param NewClass 제한사항 array
   */
  const [NewClass, setNewClass] = useState<LetCode>([]);
  const changeNewClass = (inputs: FirstInput) => {
    let preClass: LetCode = [];
    if (!inputs) {
      firstInputs.selecters.map((a) =>
        a.types.map((v) =>
          v.class.map((w, i) => {
            let NameW = firstInputs.selecters
              .find((a) => a.types.find((b) => b.code == w))
              ?.types.find((c) => c.code == w)?.title;
            return preClass.push({
              from: v.code,
              fromKind: a.kindCode,
              to: w,
              toName: NameW,
            });
          }),
        ),
      );
    } else {
      inputs.selecters.map((a) =>
        a.types.map((v) =>
          v.class.map((w, i) => {
            let NameW = firstInputs.selecters
              .find((a) => a.types.find((b) => b.code == w))
              ?.types.find((c) => c.code == w)?.title;
            return preClass.push({
              from: v.code,
              fromKind: a.kindCode,
              to: w,
              toName: NameW,
            });
          }),
        ),
      );
    }
    preClass.length > 0 && setNewClass(preClass);
  };
  useEffect(() => {
    changeNewClass(firstInputs);
  }, [firstInputs]);
  const [totalCalc, setTotalCalc] = useState<TotalCalc>({
    unit: '',
    amount: 0,
  });
  useEffect(() => {
    getLatest(firstInputs, setLatestInput, setLatestColor, setNoPriceInput);
  }, []);
  /**
   * input(카테고리, 상품옵션, 판매상태) onchange 이벤트
   * @param name input name
   * @param value input value
   */
  const handleChange = (name: string, value: string | boolean) => {
    name.slice(0, 12) === 'categoryName'
      ? setFirstInputs({
          ...firstInputs,
          category: [{ ...firstInputs.category[0], [name]: String(value) }],
        })
      : setFirstInputs({ ...firstInputs, [name]: value });
  };
  /**
   * 옵션 단계로 변경 action
   * @constant originInputs
   * @constant readyToCompare
   * @constant priceStep
   * @constant firstInputs
   */
  const priceRemoveCheck = () => {
    let Copy = { ...firstInputs };
    let NewCopy = JSON.parse(JSON.stringify(Copy));
    confirm(CONFIRM.INIT) &&
      priceStep !== 'preparing' &&
      setOriginInputs(NewCopy),
      changePriceStep('preparing'),
      setReadyToCompare(true),
      emptyPrice &&
        setFirstInputs({ ...firstInputs, productTypes: emptyPrice });
  };
  /**
   * 입력 단계변경 action
   * @param step 변경할 스텝
   * @constant priceStep
   */
  const changePriceStep = async (step: TableCostStep) => {
    setPriceStep(step);
  };
  /**
   * priceStep 변함의 따라 생기는 분기가 복잡, alertCategorize 라는 함수에 각 인풋별 경고창 분기
   * @param step 현재의 priceStep
   * @param inputs fistinput 같은 post 시 파라미터
   */
  const gotoPriceInput = async (step: TableCostStep, inputs: FirstInput) => {
    loadingHandler(true);
    if (step === 'holdOrder') {
      alertCategorize(inputs, changePriceStep)
        ? (getLatest(
            firstInputs,
            setLatestInput,
            setLatestColor,
            setNoPriceInput,
          ),
          changePriceStep('readyToMake'),
          loadingHandler(false))
        : loadingHandler(false);
    }
  };
  /**
   * 저장, 완료 Action
   * @constant firstInput
   * @constant priceStep
   */
  const saveNow = async (action: string) => {
    loadingHandler(true);
    let address = firstInputs._id;
    await axios
      .put(SERVER_URL + '/product/' + address, firstInputs)
      .then(function (response) {
        if (action === 'save') {
          alert('저장완료');
        } else if (action === 'complete') {
          changeSPModal(false);
        }
        loadingHandler(false);
        alert(MESSAGE.SAVE);
      })
      .catch(function (error) {
        console.log('데이터 업로드에 실패 하였습니다.');
        loadingHandler(false);
      });
  };
  return (
    <>
      <div
        className="modalY-overlay"
        onClick={() => changeSPModal(false)}
      ></div>
      <div className="previewY preview-window">
        <div
          className="closeBtn"
          onClick={() => {
            changeSPModal(false);
          }}
        >
          <i className="fa fa-times"></i>
        </div>

        {firstInputs && (
          <>
            <div id="viewModal" className="bg-f7f7f7 p-5">
              <div className="contents-wrap x_panel">
                <div className="x_title">
                  <h4>
                    가격 입력 <small>Data Inputs</small>
                  </h4>
                </div>
                <div className="clearfix"></div>
                <div className="x_content">
                  <CodeWrapTableEd
                    firstInputs={firstInputs}
                    setFirstInputs={setFirstInputs}
                    handleChange={handleChange}
                    NewClass={NewClass}
                  />
                </div>
                <div
                  id="block-box"
                  className={`block-box ${
                    priceStep === 'preparing' ? '' : 'blockNow'
                  }`}
                >
                  <div className="blockBoxBg opacity-0"></div>
                </div>
              </div>
              <div className="clearfix py-3"></div>
              <div className="row">
                <div className="col-12">
                  <div className="contents-wrap x_panel">
                    <div className="x_content">
                      <form className="form-horizontal form-label-left">
                        <div className="form-group">
                          <div className="row">
                            <label className="cost-title col-md-2 col-sm-2 col-xs-12">
                              상품 가격 입력
                            </label>
                            <div className="cost-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                              {priceStep === 'preparing' && (
                                <div className="price-table-wrap">
                                  <span>
                                    옵션을 선택하고 가격 생성을 클릭 하세요.
                                  </span>
                                </div>
                              )}
                              {priceStep === 'readyToMake' && (
                                <SPPriceTable
                                  from="productList"
                                  totalCalc={totalCalc}
                                  roundDownNow={roundDownNow}
                                  latestColor={latestColor}
                                  editInputs={editInputs}
                                  setEditInputs={setEditInputs}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
