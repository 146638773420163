import { useState, useEffect } from 'react';
import { useLoading } from '@config/loadingContext';
import {
  estimatePayload,
  estimateType,
} from '@interfaces/tableCost.interfaces';
import moment from 'moment';
import ModalEmpty from '@components/modal/ModalEmpty';
import EstimateReqAnswerModal from '@components/modal/estimatereqAnswerModal';
import useFetchNoData from '@utils/customHook/useFetchNoData';
import BoardPagination from '@utils/boardPagination';
import DatePicker, { registerLocale } from 'react-datepicker';
import ko from 'date-fns/locale/ko';
import axios from 'axios';
import { NEST_SERVER_URL } from '@config/path';

export interface boardDateFixed {
  start: string;
  end: string;
}

export default function SetEstimate() {
  /* 모달 */
  const [boardModal, setBoardModal] = useState<boolean>(false);
  const closeModal = () => {
    setBoardModal(false);
  };
  /** 로딩 state*/
  const { loadingNow, loadingHandler } = useLoading();

  /* 사이트 필터 */
  const changeSiteFilter = async (str: string) => {
    setSiteFilterNow(str === '' ? '전체' : str);
    setReload(false);
    setPaging(1);
  };

  const [siteFilterNow, setSiteFilterNow] = useState<string>('all');

  /* 유저 ID 검색 */
  /* 인풋 */
  const [userIdsearch, setUserIdsearch] = useState<string>('');
  /* ID 확정 검색용 */
  const [userIdNow, setUserIdNow] = useState<string>('');

  const idSearchHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setUserIdsearch(value);
  };

  const [paging, setPaging] = useState<number>(1); //현재 페이지
  const [reload, setReload] = useState<boolean>(false);

  /* 날짜 검색*/

  const [dateCustom, setDateCustom] = useState<boardDateFixed>({
    start: moment().format('yyyy-MM-DD 00:00:00.000'),
    end: moment().format('yyyy-MM-DD 23:59:59.599'),
  });

  const [dateFixed, setDateFixed] = useState<boardDateFixed>({
    start: '',
    end: '',
  });

  const [dateCal, setDateCal] = useState<boolean>(false);

  const ChangeDate = (types: string, date: Date | null) => {
    date !== null &&
      setDateCustom({
        ...dateCustom,
        [types]: moment(date).format(
          `yyyy-MM-DDT${types === 'start' ? '00:00' : '23:59'}`,
        ),
      });
    setDateCal(true);
  };

  const confirmDate = () => {
    setDateFixed({ ...dateCustom });
  };

  /* 견적의뢰 리스트 부르기 */

  const payloadURL = `estimate-req?page=${paging}&limit=20${
    siteFilterNow === 'all' ? '' : '&applySite=' + siteFilterNow
  }${userIdNow ? '&userId=' + userIdNow : ''}${
    dateFixed.start !== ''
      ? '&start=' +
        moment(dateFixed.start).format('yyyy-MM-DD HH:mm:ss.sss') +
        '&end=' +
        moment(dateFixed.end).format('yyyy-MM-DD HH:mm:ss.sss')
      : ''
  }`;

  const { payload, error } = useFetchNoData<estimatePayload>(
    payloadURL,
    String(reload) + String(boardModal) + userIdNow + dateFixed,
  );

  const [estimateList, setestimateList] = useState<estimateType[]>([]);

  useEffect(() => {
    if (payload) {
      setestimateList(payload.data);
    }
    if (payload === null) {
      setestimateList([]);
    }
  }, [payload, error]);

  const idSearchClick = () => {
    const newUserId = JSON.parse(JSON.stringify(userIdsearch));
    setUserIdNow(newUserId !== '' ? newUserId : undefined);
    setReload(!reload);
  };

  /* 페이지 */

  const pageSize = 10; //최대 페이지

  const item = payload?.data;

  const totalPage = Math.ceil(payload ? payload.total / 20 : 0);

  /* 답변 모달 */

  const [modalId, setModalId] = useState<string>();

  const answerEstimate = async (id: string) => {
    setModalId(id);
    setBoardModal(true);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  /* 견적의뢰 삭제 */
  const estimateDelete = async (_id: string) => {
    if (window.confirm('삭제하시겠습니까?')) {
      loadingHandler(true);
      await axios
        .delete(`${NEST_SERVER_URL}/estimate-req/${_id}`)
        .then(function (response) {
          loadingHandler(false);
          response && setReload(!reload);
        })
        .catch(function (error) {
          console.log('error', error);
          alert('삭제 실패');
          loadingHandler(false);
        });
    }
  };

  return (
    <>
      <div className="page-title">
        <div className="title_left board_title ">
          <h3 className="col-6">
            <i className="fa fa-cubes" /> 견적의뢰 관리
          </h3>
          <div className="col-6 board_search">
            <div className="col-6 board_id_search">
              <h5>고객 ID 검색</h5>
              <div className="board_id_search_input">
                <input
                  type="text"
                  value={userIdsearch}
                  onChange={(e) => idSearchHandle(e)}
                />
                <div
                  className="board_id_search_btn board_search_btn"
                  onClick={idSearchClick}
                >
                  검색
                </div>
              </div>
            </div>
            <div className="col-6 board_search board_date_search">
              <div className="board_date_search_wrap">
                <h5>날짜 검색</h5>
                <div className="date_search_picker date_start_picker">
                  <DatePicker
                    selected={
                      dateCal === false ? undefined : new Date(dateCustom.start)
                    }
                    onChange={(date: Date | null) => {
                      ChangeDate('start', date);
                    }}
                    fixedHeight
                    selectsStart
                    locale={ko}
                    dateFormat={'yyyy-MM-dd'}
                  />
                </div>
                <span className="date_search_wave">~</span>

                <div className="date_search_picker date_end_picker">
                  <DatePicker
                    selected={
                      dateCal === false ? undefined : new Date(dateCustom.end)
                    }
                    onChange={(date: Date | null) => {
                      ChangeDate('end', date);
                    }}
                    fixedHeight
                    selectsEnd
                    locale={ko}
                    dateFormat={'yyyy-MM-dd'}
                  />
                </div>
                <div className="board_search_btn" onClick={() => confirmDate()}>
                  검색
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="clearfix" />

      <div className="col-12">
        <div className="contents-wrap x_panel mb-2 boardfilter_wrap">
          <div className="groupFilter col-6">
            <h5>사이트필터</h5>
            <div className="groupFilter-wrap orange">
              <div className="groupFilter-inner">
                <div
                  className={`groupFilter-item ${
                    siteFilterNow == 'all' ? 'active' : ''
                  }`}
                  onClick={() => changeSiteFilter('all')}
                >
                  전체
                </div>
                <div
                  className={`groupFilter-item ${
                    siteFilterNow == 'iamdesign' ? 'active' : ''
                  }`}
                  onClick={() => changeSiteFilter('iamdesign')}
                >
                  아이엠 디자인
                </div>
                <div
                  className={`groupFilter-item ${
                    siteFilterNow == 'speedcolor' ? 'active' : ''
                  }`}
                  onClick={() => changeSiteFilter('speedcolor')}
                >
                  스피드칼라
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contents-wrap x_panel">
          <div className="x_content setboard-table-wrap">
            <div className="x_content site-table-wrap">
              {estimateList && (
                <div className="react-bootstrap-table">
                  <table className="table table-hover table-bordered GW-cont-head boardget_table">
                    <thead>
                      <tr>
                        <th>문의사이트</th>
                        <th>고객ID</th>
                        <th>고객명</th>
                        <th>회사명</th>
                        <th>의뢰일</th>
                        <th>상태</th>
                        <th>ACTION</th>
                        <th>삭제</th>
                      </tr>
                    </thead>
                    <tbody>
                      {estimateList.length > 0 ? (
                        estimateList.map((a, idxA) => {
                          let siteUrl =
                            a.applySite == 'printcity'
                              ? 'https://www.printcity.co.kr/'
                              : a.applySite == 'ecard21'
                              ? 'https://www.ecard21.co.kr/'
                              : a.applySite == 'iamdesign'
                              ? 'https://www.iamdgn.com/'
                              : a.applySite == 'megaprint'
                              ? 'https://www.megaprint.co.kr/'
                              : a.applySite == 'speedcolor'
                              ? 'https://www.speedcolor.co.kr/'
                              : a;
                          return (
                            <tr
                              key={idxA}
                              //onClick={() => EditBoard}
                              className="answertr"
                            >
                              <td>
                                <a href={String(siteUrl)} target="blink">
                                  {a.applySite}
                                </a>
                              </td>
                              <td>{a.userId}</td>
                              <td>{a.userName}</td>
                              <td>{a.companyName}</td>
                              <td>
                                {moment(a.createdAt).format(
                                  'YYYY년 MM월 DD일 HH시 mm분',
                                )}
                              </td>
                              <td>
                                {a.estimateType === ''
                                  ? '확인전'
                                  : a.estimateType}
                              </td>
                              <td>
                                {a.estimateMemo === '' &&
                                a.estimateType === '' ? (
                                  <button className="tableBtn tableBtn-skyblue btnW-70 setPriceDelEvents setpriceBtn">
                                    <span className="mobileSpan">
                                      <i
                                        className="fa fa-times"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                    <span
                                      className="deskSpan"
                                      onClick={() => answerEstimate(a._id)}
                                    >
                                      견적답변
                                    </span>
                                  </button>
                                ) : (
                                  <button className="tableBtn tableBtn-pink btnW-70 setPriceDelEvents setpriceBtn">
                                    <span className="mobileSpan">
                                      <i
                                        className="fa fa-times"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                    <span
                                      className="deskSpan"
                                      onClick={() => answerEstimate(a._id)}
                                    >
                                      답변수정
                                    </span>
                                  </button>
                                )}
                              </td>
                              <td>
                                <button className="tableBtn tableBtn-red btnW-70 setPriceDelEvents setpriceBtn">
                                  <span onClick={() => estimateDelete(a._id)}>
                                    삭제하기
                                  </span>
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={8} className="boardNodata">
                            데이터가 없습니다.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="boardPagination">
          {item && item.length > 0 && (
            <BoardPagination
              paging={paging}
              setPaging={setPaging}
              pageSize={pageSize}
              pageLength={totalPage}
            />
          )}
        </div>
      </div>
      <div className="boardModal">
        {boardModal && (
          <ModalEmpty close={closeModal} id={'boardlist'}>
            <EstimateReqAnswerModal modalId={modalId} closeModal={closeModal} />
          </ModalEmpty>
        )}
      </div>
    </>
  );
}
