import {
  OVERLAY_STYLE,
  simpleList_STYLE,
  CLOSE_STYLE,
} from '@styles/modal.css';

interface IModalProps {
  close: (target: string) => void;
  children: JSX.Element;
  title: string;
  id: string;
}

export default function Modal(props: IModalProps) {
  const { close, title, id } = props;
  return (
    <>
      <div className="modalY-overlay" onClick={(e) => close(id)}></div>
      <div className="previewY preview-window" id={id} style={simpleList_STYLE}>
        <div className="closeBtn" onClick={(e) => close(id)}>
          <i className="fa fa-times"></i>
        </div>
        <div style={{ padding: '30px 50px' }}>
          <div className="page-title">
            <h3>
              {title}
              <small>- Modal window</small>
            </h3>
          </div>
          <div className="clearfix"></div>
          {props.children}
        </div>
      </div>
    </>
  );
}
