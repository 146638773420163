import React, { useState, useEffect } from 'react';
import { useLoading } from '@config/loadingContext';
import HomeComponent from '@components/home/homeComponent';
import { useFetchMega } from '@utils/customHook/useFetchCustom';
import moment from 'moment';
import 'moment/locale/ko';

moment.locale('ko');
import {
  IHomeData,
  ISiteTotalSales,
  ISiteNewCustomers,
} from '@interfaces/home.interface';
import Cookies from 'universal-cookie';
import { getCookie } from '@config/cookies';

export default function Home() {
  const userClass = getCookie('userClass');
  const orderStatusKeys: string[] = [
    'Ready', //대기상태
    'FileError',
    'FileChecking', // 주문내역 및 데이터 확인중
    'FileReuploaded', // 손님이 파일을 재업로드함
    'FileCheckingRetry', // 재입고데이터 데이터 확인중
    'FileCheckCompleted', // 파일점검완료
    'DesignRequested', // 디자인요청
    'DesignStarted', // 디자인중
    'DesignSubmitted', // 디자인제출
    'DesignRejected', // 디자인재요청
    'DesignConfirmed', // 디자인완료
    'ProofRequested', // 교정요청
    'ProofStarted', // 교정중
    'ProofRejected', // 교정재요청
    'ProofSubmitted', // 교정제출
    'ProofConfirmed', // 교정완료
    'Making', // 제작중
    'ImpositionReady', // 조판대기
    'ImpositionProcessing', // 조판진행중
    'ImpositionCompleted', // 조판완료
    'EngravingReady', // 출력대기
    'EngravingCompleted', // 출력완료
    'PrintCompleted', // 인쇄완료
    'CuttingCompleted', // 재단완료
    'CoatingCompleted', // 코팅완료
    'Enveloping', // 봉투가공중
    'AddedWorking', // 후가공중
    'FoldingCompleted', // 접지완료
    'OsiCompleted', // 오시/미싱완료
    'GuidoCompleted', // 귀도리완료
    'BakCompleted', // 박완료
    'DomusongCompleted', // 도무송완료
    'ExternalAddedWorking', // 외주후가공중-후가공A
    'ProductionCompleted', // 제품생산완료
    'PackagingCompleted', // 포장완료
    'DeliveryReady', // 발송대기
    'Delivering', // 배송중
    'DeliveryCompleted', // 배송완료
    'ErrorImpositionDeferred',
  ];

  const DataMakingKinds: string[] = [
    'WebFile', // 홈페이지접수
    'VisitFile', // 방문접수
    'Shooter',
    'Design',
    'DIY', // do-it-yourself
    'WebTemplar', // 홈페이지 자동점검 (Templar)
  ];
  const DataMakingAuto: string[] = [
    'DIY', // do-it-yourself
    'WebTemplar', // 홈페이지 자동점검 (Templar)
  ];
  const DataMakingManual: string[] = [
    'WebFile', // 홈페이지접수
    'VisitFile', // 방문접수
  ];
  const { loadingHandler } = useLoading();
  const present = new Date(); //현재
  const year = present.getFullYear(); // 년
  const month = present.getMonth(); // 월
  const day = present.getDate(); // 일
  const hours = present.getHours(); // 일
  //어제부터 오늘
  // const TodayStart = moment(new Date().setHours(0, 0, 0, 0)).format(
  //   'yyyy-MM-DDTHH:mm',
  // );
  const TodayStart = moment(present.setHours(0, 0, 0, 0)).format(
    'yyyy-MM-DDTHH:mm',
  );
  const TodayEnd = moment(present.setHours(0, 0, 0, 0))
    .add(1, 'days')
    .format('yyyy-MM-DDTHH:mm');
  const TodayNow = moment(present).format('yyyy-MM-DDTHH:mm');
  const Yesterday = moment(
    new Date(year, month, day - 1).setHours(0, 0, 0, 0),
  ).format('yyyy-MM-DDTHH:mm');
  const YesterdayNow = moment(new Date(year, month, day - 1)).format(
    'yyyy-MM-DDTHH:mm',
  );
  const AWeekAgo = moment(new Date().setHours(0, 0, 0, 0))
    .weekday(1)
    .format('yyyy-MM-DDTHH:mm');
  const TwoWeekAgo = moment(new Date(year, month, day - 7).setHours(0, 0, 0, 0))
    .weekday(1)
    .format('yyyy-MM-DDTHH:mm');
  const AMonthAgo = moment(new Date().setHours(0, 0, 0, 0))
    .startOf('month')
    .format('yyyy-MM-DDTHH:mm');
  const TwoMonthAgo = moment(
    new Date(year, month - 1, day).setHours(0, 0, 0, 0),
  )
    .startOf('month')
    .format('yyyy-MM-DDTHH:mm');
  const AYearAgo = moment(new Date().setHours(0, 0, 0, 0))
    .startOf('year')
    .format('yyyy-MM-DDTHH:mm');
  const TwoYearAgo = moment(new Date(year - 1, month, day).setHours(0, 0, 0, 0))
    .startOf('year')
    .format('yyyy-MM-DDTHH:mm');

  const { payload: totalToday } = useFetchMega<ISiteTotalSales>(
    `api/StatApi/SalesSummary?from=${TodayStart}&to=${TodayEnd}&orderStatuses=&dataMakingKinds=&metaProductIds=&isRework=false`,
  );
  const { payload: totalTodayAuto } = useFetchMega<ISiteTotalSales>(
    `api/StatApi/SalesSummary?from=${TodayStart}&to=${TodayEnd}&orderStatuses=&dataMakingKinds=${DataMakingAuto.join(
      ',',
    )}&metaProductIds=&isRework=false`,
  );

  const { payload: totalYesterday } = useFetchMega<ISiteTotalSales>(
    `api/StatApi/SalesSummary?from=${Yesterday}&to=${TodayStart}&orderStatuses=&dataMakingKinds=&metaProductIds=&isRework=false`,
  );
  const { payload: totalTodayNow } = useFetchMega<ISiteTotalSales>(
    `api/StatApi/SalesSummary?from=${TodayStart}&to=${TodayNow}&orderStatuses=&dataMakingKinds=&metaProductIds=&isRework=false`,
  );
  const { payload: totalYesterdayNow } = useFetchMega<ISiteTotalSales>(
    `api/StatApi/SalesSummary?from=${Yesterday}&to=${YesterdayNow}&orderStatuses=&dataMakingKinds=&metaProductIds=&isRework=false`,
  );
  const { payload: totalWeek } = useFetchMega<ISiteTotalSales>(
    `api/StatApi/SalesSummary?from=${AWeekAgo}&to=${TodayEnd}&orderStatuses=&dataMakingKinds=&metaProductIds=&isRework=false`,
  );
  const { payload: totalWeekAuto } = useFetchMega<ISiteTotalSales>(
    `api/StatApi/SalesSummary?from=${AWeekAgo}&to=${TodayEnd}&orderStatuses=&dataMakingKinds=${DataMakingAuto.join(
      ',',
    )}&metaProductIds=&isRework=false`,
  );
  const { payload: totalWeekBefore } = useFetchMega<ISiteTotalSales>(
    `api/StatApi/SalesSummary?from=${TwoWeekAgo}&to=${AWeekAgo}&orderStatuses=&dataMakingKinds=&metaProductIds=&isRework=false`,
  );
  const { payload: totalMonth } = useFetchMega<ISiteTotalSales>(
    `api/StatApi/SalesSummary?from=${AMonthAgo}&to=${TodayEnd}&orderStatuses=&dataMakingKinds=&metaProductIds=&isRework=false`,
  );
  const { payload: totalMonthAuto } = useFetchMega<ISiteTotalSales>(
    `api/StatApi/SalesSummary?from=${AMonthAgo}&to=${TodayEnd}&orderStatuses=&dataMakingKinds=${DataMakingAuto.join(
      ',',
    )}&metaProductIds=&isRework=false`,
  );
  const { payload: totalMonthBefore } = useFetchMega<ISiteTotalSales>(
    `api/StatApi/SalesSummary?from=${TwoMonthAgo}&to=${AMonthAgo}&orderStatuses=&dataMakingKinds=&metaProductIds=&isRework=false`,
  );
  const { payload: totalYear } = useFetchMega<ISiteTotalSales>(
    `api/StatApi/SalesSummary?from=${AYearAgo}&to=${TodayEnd}&orderStatuses=&dataMakingKinds=&metaProductIds=&isRework=false`,
  );
  const { payload: totalYearAuto } = useFetchMega<ISiteTotalSales>(
    `api/StatApi/SalesSummary?from=${AYearAgo}&to=${TodayEnd}&orderStatuses=&dataMakingKinds=${DataMakingAuto.join(
      ',',
    )}&metaProductIds=&isRework=false`,
  );
  const { payload: totalYearBefore } = useFetchMega<ISiteTotalSales>(
    `api/StatApi/SalesSummary?from=${TwoYearAgo}&to=${AYearAgo}&orderStatuses=&dataMakingKinds=&metaProductIds=&isRework=false`,
  );
  const { payload: customerToday } = useFetchMega<ISiteNewCustomers>(
    `api/StatApi/NewCustomers?from=${TodayStart}&to=${TodayEnd}`,
  );
  const { payload: customerYesterday } = useFetchMega<ISiteNewCustomers>(
    `api/StatApi/NewCustomers?from=${Yesterday}&to=${TodayStart}`,
  );

  const { payload: customerTodayOrder } = useFetchMega<ISiteNewCustomers>(
    `api/OrderApi/GetGroup?from=${Yesterday}&to=${TodayStart}`,
  );

  const TodayIssue: IHomeData[] = [
    {
      title: 'Today 주문건수',
      amount: totalToday?.totalSalesCount,
      amountAuto: totalTodayAuto?.totalSalesCount,
      unit: '건',
      subTitle: '전일대비',
      subAmount:
        (totalToday ? totalToday.totalSalesCount : 0) -
        (totalYesterday ? totalYesterday.totalSalesCount : 0),
      icon: 'fa-cubes',
      col: 3,
    },
    {
      title: 'Today 매출',
      amount: totalToday?.totalSalesAmount,
      amountAuto: totalTodayAuto?.totalSalesAmount,
      unit: '원',
      subTitle: '전일대비',
      subAmount:
        (totalToday ? totalToday.totalSalesAmount : 0) -
        (totalYesterday ? totalYesterday.totalSalesAmount : 0),
      icon: 'fa-money',
      col: 3,
      custom: 'byDay',
    },
    {
      title: '시간당 주문건수',
      amount: totalTodayNow
        ? Math.round(totalTodayNow.totalSalesCount / hours)
        : 0,
      unit: '건',
      subTitle: '전일대비',
      subAmount:
        (totalTodayNow
          ? Math.round(totalTodayNow.totalSalesCount / hours)
          : 0) -
        (totalYesterdayNow
          ? Math.round(totalYesterdayNow.totalSalesCount / hours)
          : 0),
      icon: 'fa-cubes',
      col: 3,
    },
    {
      title: '시간당 매출',
      amount: totalTodayNow
        ? Math.round(totalTodayNow.totalSalesAmount / hours)
        : 0,
      unit: '원',
      subTitle: '전일대비',
      subAmount:
        (totalTodayNow
          ? Math.round(totalTodayNow.totalSalesAmount / hours)
          : 0) -
        (totalYesterdayNow
          ? Math.round(totalYesterdayNow.totalSalesAmount / hours)
          : 0),
      icon: 'fa-money',
      col: 3,
      custom: 'byTime',
    },
  ];

  const cumulativeSales: IHomeData[] = [
    {
      title: '금주 누적매출',
      amount: totalWeek ? totalWeek.totalSalesAmount : 0,
      amountAuto: totalWeekAuto ? totalWeekAuto.totalSalesAmount : 0,
      unit: '원',
      subTitle: '전주대비',
      subAmount:
        (totalWeek ? totalWeek.totalSalesAmount : 0) -
        (totalWeekBefore ? totalWeekBefore.totalSalesAmount : 0),
      icon: 'fa-money',
      col: 4,
    },
    {
      title: '이번달 누적매출',
      amount: totalMonth ? totalMonth.totalSalesAmount : 0,
      amountAuto: totalMonthAuto ? totalMonthAuto.totalSalesAmount : 0,
      unit: '원',
      subTitle: '전달대비',
      subAmount:
        (totalMonth ? totalMonth.totalSalesAmount : 0) -
        (totalMonthBefore ? totalMonthBefore.totalSalesAmount : 0),
      icon: 'fa-money',
      col: 4,
    },
    {
      title: '이번년도 누적매출',
      amount: totalYear ? totalYear.totalSalesAmount : 0,
      amountAuto: totalYearAuto ? totalYearAuto.totalSalesAmount : 0,
      unit: '원',
      subTitle: '전년대비',
      subAmount:
        (totalYear ? totalYear.totalSalesAmount : 0) -
        (totalYearBefore ? totalYearBefore.totalSalesAmount : 0),
      icon: 'fa-money',
      col: 4,
    },
  ];

  const newAccount: IHomeData[] = [
    {
      title: 'Today 회원가입수',
      amount: customerToday ? customerToday.newCustomersCount : 0,
      unit: '명',
      subTitle: '전일대비',
      subAmount:
        (customerToday ? customerToday.newCustomersCount : 0) -
        (customerYesterday ? customerYesterday.newCustomersCount : 0),
      icon: 'fa-user',
      col: 3,
    },
    {
      title: '총 회원수',
      amount: customerToday?.allCustomersCount,
      unit: '명',
      icon: 'fa-users',
      col: 3,
    },
    {
      title: 'Today 회원가입 리스트',
      users: customerToday?.newCustomers,
      col: 6,
      custom: 'byToday',
    },
  ];
  return (
    <>
      <div className="page-title">
        <div className="title_left">
          <h3>
            <i className="fa fa-home" />
            관리자 홈 <small>Dashboard</small>
          </h3>
        </div>
      </div>
      <div className="clearfix"></div>
      <div className="row">
        {userClass === 'firstDegreeManager' ? (
          <div className="col-12 homeDashboard">
            <div className="clearfix"></div>
            <div className="row">
              <div className="col-12">
                <h3>Today 이슈</h3>
                <div className="row">
                  {TodayIssue.map((code, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        <HomeComponent dataNow={code} idx={idx} />
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <h3>누적 매출 통계</h3>
                <div className="row">
                  {cumulativeSales.map((code, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        <HomeComponent dataNow={code} idx={idx} />
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <h3>회원가입 통계</h3>
                <div className="row">
                  {newAccount.map((code, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        <HomeComponent dataNow={code} idx={idx} />
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
