import React, { useState, Dispatch, SetStateAction } from 'react';
import { CateType, CateItemType } from '@interfaces/site.interface';
import { ProductListType } from '@interfaces/tableCost.interfaces';
interface Props {
  newCate: CateType;
  setNewCate: Dispatch<SetStateAction<CateType>>;
  productList: ProductListType[];
  changeProductsModal: (bool: boolean) => void;
}

export default function CategoryModalProducts({
  newCate,
  setNewCate,
  productList,
  changeProductsModal,
}: Props) {
  const selectedNames = newCate.cateItems.map((code) => {
    return code.productId;
  });
  const [sortedProducts, setSortedProducts] = useState<ProductListType[]>(
    [...productList]
      .sort(function (x, y) {
        return selectedNames.includes(x._id) ? -1 : 1;
      })
      .map((code) => {
        return { ...code, checkbox: selectedNames.includes(code._id) };
      }),
  );
  /**테이블 Sort State */
  const [sortNow, setSortNow] = useState<{ [key: string]: boolean }>({
    checkbox: false,
    categoryName1st: false,
    productNameKO: false,
  });
  /**테이블 Sort Action */
  const sortBy = (key: string) => {
    let copy = [...sortedProducts];
    if (key !== 'categoryName1st') {
      copy.sort((a, b) =>
        !sortNow[key]
          ? String(a[key]) < String(b[key])
            ? -1
            : 1
          : String(a[key]) > String(b[key])
          ? -1
          : 1,
      );
    } else {
      copy.sort((a, b) =>
        !sortNow[key]
          ? a.category[0].categoryName1st < b.category[0].categoryName1st
            ? -1
            : 1
          : a.category[0].categoryName1st > b.category[0].categoryName1st
          ? -1
          : 1,
      );
    }
    setSortNow({ ...sortNow, [key]: !sortNow[key] });
    setSortedProducts(copy);
  };
  const handleOnCheck = (idx: number) => {
    let copy = [...sortedProducts];
    sortedProducts[idx].checkbox = !sortedProducts[idx].checkbox;
    setSortedProducts(copy);
  };
  const selectComplete = () => {
    let i = 0;
    let copy: CateItemType[] = sortedProducts
      .filter((codeA) => codeA.checkbox)
      .map((codeB, idx) => {
        return {
          productId: codeB._id,
          title: codeB.productNameKO,
          itemPriority: idx,
        };
      });
    setNewCate({ ...newCate, cateItems: copy });
    copy && changeProductsModal(false);
  };
  return (
    <>
      <div
        className="modalY-overlay"
        onClick={() => changeProductsModal(false)}
      ></div>
      <div
        id="standardproduct-window"
        className="preview-window preview-800 categoryProductsModal"
      >
        <div
          className="closeBtn"
          onClick={() => {
            changeProductsModal(false);
          }}
        >
          <i className="fa fa-times"></i>
        </div>

        <div className="bg-f7f7f7 p-5">
          <div className="contents-wrap x_panel">
            <div className="x_title">
              <h4>
                카테고리 추가하기
                {/* <span>
                      <i>{siteName}</i>
                    </span>{' '}
                    - {firstInputs.productNameKO} 옵션 변경 */}
              </h4>
            </div>
            <div className="clearfix"></div>
            <div className="x_content">
              <div className="react-bootstrap-table">
                <table className="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th
                        style={{ width: '10%' }}
                        onClick={() => {
                          sortBy('checkbox');
                        }}
                      >
                        <i className="fa fa-sort ms-2"></i>
                      </th>
                      <th
                        onClick={() => {
                          sortBy('categoryName1st');
                        }}
                      >
                        대분류<i className="fa fa-sort ms-2"></i>
                      </th>
                      <th
                        onClick={() => {
                          sortBy('productNameKO');
                        }}
                      >
                        제품명<i className="fa fa-sort ms-2"></i>
                      </th>
                      <th>선택</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedProducts.map((code, idx) => {
                      return (
                        <tr key={idx}>
                          <td style={{ width: '10%' }}>
                            <input
                              type="checkbox"
                              id={`${code.productNameEN}_code`}
                              name={`${code.productNameEN}`}
                              checked={code.checkbox}
                              onChange={() => handleOnCheck(idx)}
                            />
                          </td>
                          <td>{code.category[0].categoryName1st}</td>
                          <td>{code.productNameKO}</td>
                          <td>
                            <div
                              className="tableBtn tableBtn-orange btnW-90 m-auto"
                              onClick={() => handleOnCheck(idx)}
                            >
                              선택
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="row pt-2">
            <div className="col-sm-12  text-center complete-btn-wrap mt-4 mb-2">
              <div
                className="price-start-btn btn-inline-block"
                data-toggle="tooltip"
                onClick={() => {
                  selectComplete();
                }}
              >
                <span>
                  <i className="fa fa-check" aria-hidden="true"></i> 완료
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
