import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import PriceSelectOrder from '@components/table/standardproduct/price-input/price-select-order';
import PriceInputTable from '@components/table/standardproduct/price-input/price-input-table';
import { SERVER_URL } from '@config/path';
import {
  Order,
  FirstInput,
  TableCostStep,
  ProductListType,
  LetCode,
} from '@interfaces/tableCost.interfaces';
import { LOG } from '@utils/textMessage';
import alertCategorize from '@utils/alertFunc';
import { getLatest } from '@utils/common';
import { useLoading } from '@config/loadingContext';
interface Props {
  firstInputs: FirstInput;
  setFirstInputs: Dispatch<SetStateAction<FirstInput>>;
  originInputs: FirstInput;
  priceStep: TableCostStep;
  changePriceStep: (step: TableCostStep) => void;
  executeScroll: () => void;
  readyToCompare: boolean;
  setReadyToCompare: Dispatch<SetStateAction<boolean>>;
  handleChange: (name: string, value: string | boolean) => void;
  saveNow: (action: string) => void;
  onlyProduct: boolean;
  NewClass: LetCode;
}
export default function PriceInputWrap({
  firstInputs,
  setFirstInputs,
  originInputs,
  priceStep,
  changePriceStep,
  executeScroll,
  readyToCompare,
  setReadyToCompare,
  handleChange,
  saveNow,
  onlyProduct,
  NewClass,
}: Props) {
  /**제품분리(도수외) state*/
  const [latestInput, setLatestInput] = useState<Order[]>([]);
  /**제품분리(도수) state*/
  const [latestColor, setLatestColor] = useState<Order[]>([]);
  /**가격표없는옵션분리state*/
  const [noPriceInput, setNoPriceInput] = useState<Order[]>([]);

  useEffect(() => {
    getLatest(firstInputs, setLatestInput, setLatestColor, setNoPriceInput);
  }, []);
  /**
   * 중복 되는 http 통신 부분 함수화
   * @param url api 주소
   * @param inputs fistinput 같은 post 시 파라미터
   * @param headerEle 헤더 ex : 토큰이나 기타 방식 option
   * @param nextStep 자료를 불러온후 떨어지 priceStep
   */

  const postProductDate = async (
    url: string,
    inputs: FirstInput,
    nextStep: TableCostStep,
  ) => {
    await axios
      .post(url, inputs)
      .then((res) => {
        setFirstInputs(res.data); //데이터 업데이트
        getLatest(firstInputs, setLatestInput, setLatestColor, setNoPriceInput);
        changePriceStep(nextStep);
      })
      .catch((err) => {
        changePriceStep('preparing');
        alert(LOG.DATAPOST);
      });
  };
  const putProductDate = async (
    url: string,
    inputs: FirstInput,
    nextStep: TableCostStep,
  ) => {
    await axios
      .put(url, inputs)
      .then((res) => {
        setFirstInputs(res.data); //데이터 업데이트
        getLatest(firstInputs, setLatestInput, setLatestColor, setNoPriceInput);
        changePriceStep(nextStep);
      })
      .catch((err) => {
        changePriceStep('preparing');
        alert(LOG.DATAPOST);
      });
  };
  /**
   * priceStep 변함의 따라 생기는 분기가 복잡, alertCategorize 라는 함수에 각 인풋별 경고창 분기
   * @param step 현재의 priceStep
   * @param inputs fistinput 같은 post 시 파라미터
   */
  const gotoPriceInput = async (step: TableCostStep, inputs: FirstInput) => {
    if (step === 'holdOrder') {
      alertCategorize(inputs, changePriceStep) &&
        (await postProductDate(SERVER_URL + '/product', inputs, 'selectOrder'));
    } else if (step === 'holdToMake') {
      let temI = 0;
      const reOrder = latestInput.map((v, i) => {
        if (v.priceOrder !== -1) {
          v = { ...v, priceOrder: temI };
          temI++;
          return v;
        } else {
          return v;
        }
      });

      const sum = [...reOrder, ...latestColor];
      sum.sort((a, b) => a.originally - b.originally);
      // inputs.selectors.map((f, i) => f);
      inputs.selecters = sum;
      const address = inputs.productNameEN;
      const id = inputs._id;
      let putUrl = SERVER_URL + '/product/' + id;
      await putProductDate(putUrl, inputs, 'readyToMake');
      executeScroll();
    }
  };

  return (
    <>
      <div className="row py-5">
        <div className="col-xs-12 text-center">
          {onlyProduct ? (
            <div
              id="holdOrder-btn"
              className={`yBtn yBtn-xl purple btn-inline-block ${
                priceStep !== 'preparing' &&
                (priceStep === 'holdOrder' ? 'loading' : 'inactive')
              }`}
              onClick={() => {
                priceStep === 'preparing' &&
                  gotoPriceInput('holdOrder', firstInputs);
              }}
            >
              <span>
                <i className="fa fa-cube" aria-hidden="true"></i> 가격 생성
              </span>
              <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : (
            <div
              id="holdOrder-btn"
              className="yBtn yBtn-xl purple btn-inline-block inactive"
            >
              <span>
                {firstInputs.category[0].categoryName3rd == ''
                  ? '카테고리를 선택해주세요'
                  : '제품이 이미 존재합니다'}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="clearfix"></div>
      <div className="row">
        <div className="col-12">
          <div className="contents-wrap x_panel">
            <div className="x_content">
              <form className="form-horizontal form-label-left">
                <div className="form-group">
                  <div className="row">
                    <label className="cost-title col-md-2 col-sm-2 col-xs-12">
                      상품 가격 입력
                    </label>
                    <div className="cost-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                      {priceStep === 'preparing' && (
                        <div className="price-table-wrap">
                          <span>옵션을 선택하고 가격 생성을 클릭 하세요.</span>
                        </div>
                      )}
                      {priceStep === 'selectOrder' && (
                        <PriceSelectOrder
                          firstInputs={firstInputs}
                          latestInput={latestInput}
                          setLatestInput={setLatestInput}
                          noPriceInput={noPriceInput}
                          gotoPriceInput={gotoPriceInput}
                        />
                      )}
                      {priceStep === 'readyToMake' && (
                        <PriceInputTable
                          firstInputs={firstInputs}
                          setFirstInputs={setFirstInputs}
                          originInputs={originInputs}
                          latestInput={latestInput}
                          setLatestInput={setLatestInput}
                          latestColor={latestColor}
                          priceStep={priceStep}
                          readyToCompare={readyToCompare}
                          setReadyToCompare={setReadyToCompare}
                          saveNow={saveNow}
                          NewClass={NewClass}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <label className="cost-title col-md-2 col-sm-2 col-xs-12">
                      판매상태
                    </label>
                    <div className="cost-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                      <div className="iradio_flat-green inl-bl">
                        <input
                          type="radio"
                          id="sales"
                          className="inl-bl"
                          name="saleStatus"
                          value="true"
                          checked={firstInputs.saleStatus === true}
                          onChange={(e) => handleChange(e.target.name, true)}
                        />
                        <label htmlFor="sales">판매함</label>
                      </div>
                      <div className="iradio_flat-green inl-bl">
                        <input
                          type="radio"
                          id="nsales"
                          className="flat inl-bl"
                          name="saleStatus"
                          value="false"
                          checked={firstInputs.saleStatus === false}
                          onChange={(e) => handleChange(e.target.name, false)}
                        />
                        <label htmlFor="nsales">판매안함</label>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
