import React, {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useRef,
} from 'react';
import useFetch from '@utils/customHook/useFetch';
import { SERVER_URL } from '@config/path';
import axios, { AxiosRequestConfig } from 'axios';
import { useLoading } from '@config/loadingContext';
import { ProductListType, Data } from '@interfaces/tableCost.interfaces';
import AddWorkSelect from '@components/modal/addworkComp/addwork-select';
import AddWorkPrice from '@components/modal/addworkComp/addwork-price';
import { AddworkDummy } from '@dummydata/dummy';
import {
  NewAddwork,
  NewAddworkSelecters,
  TableName,
} from '@interfaces/addwork.interface';
import { YAddworkDummy } from '@dummydata/yunAddworkDummy';
import { deleteEverything } from '@utils/common';
interface IProps {
  AddworkType: string;
  newItem: NewAddwork;
  setNewItem: Dispatch<SetStateAction<NewAddwork>>;
  closeModal: (target: string) => void;
}
export default function AddWorkModal({
  AddworkType,
  newItem,
  setNewItem,
  closeModal,
}: IProps) {
  const myRef = useRef<HTMLDivElement>(null);
  const executeScroll = () => {
    setTimeout(() => {
      myRef.current !== null
        ? myRef.current.scrollIntoView({ behavior: 'smooth' })
        : console.log(myRef.current);
    }, 1000);
  };
  const { loadingNow, loadingHandler } = useLoading();

  const [productList, setProductList] = useState<ProductListType[]>([]);
  const [productFilter, setProductFilter] = useState<string[]>();
  const [productFilterNow, setProductFilterNow] = useState<string>('전체');
  const { payload, error } = useFetch<ProductListType>(
    AddworkType === 'new' || AddworkType === 'edit'
      ? `product`
      : `productbysite?target=${AddworkType}`,
    loadingNow,
  );
  useEffect(() => {
    if (payload) {
      let copy: ProductListType[] = [...payload.data];
      setProductList([...copy]); //, ...copy2]);
      let filt = copy
        .filter((a) => a.category[0].categoryName1st)
        .map((b) => {
          return b.category[0].categoryName1st;
        })
        .filter(
          (thing: string, index: number, self: string[]) =>
            index === self.findIndex((t) => t === thing),
        );
      setProductFilter(filt);
    } else {
      console.log('payload yet!');
    }
    changeProductFilter(productFilterNow);
  }, [payload]);

  const changeProductFilter = (group: string) => {
    if (payload) {
      if (group !== '전체') {
        let copy = payload.data.filter(
          (a) => a.category[0].categoryName1st === group,
        );
        // let copy2: ProductListType[] = [...newItem.productId]
        //   .filter(
        //     (a) =>
        //       !copy
        //         .map((b) => {
        //           return b._id;
        //         })
        //         .includes(a),
        //   )
        //   .map((c) => {
        //     return {
        //       category: [
        //         {
        //           categoryName1st: '',
        //           categoryName2nd: '',
        //           categoryName3rd: '',
        //         },
        //       ],
        //       createdAt: '',
        //       createdBy: '',
        //       modifiedAt: '',
        //       modifiedBy: '',
        //       productNameEN: '',
        //       productNameKO: c,
        //       saleStatus: false,
        //       totalPriceCalc: '',
        //       typeOfPriceDetermintion: '',
        //       _id: c,
        //     };
        //   });
        setProductList([...copy]); //, ...copy2]);
      } else {
        let copy = [...payload.data];
        // let copy2: ProductListType[] = [...newItem.productId]
        //   .filter(
        //     (a) =>
        //       !copy
        //         .map((b) => {
        //           return b._id;
        //         })
        //         .includes(a),
        //   )
        //   .map((c) => {
        //     return {
        //       category: [
        //         {
        //           categoryName1st: '',
        //           categoryName2nd: '',
        //           categoryName3rd: '',
        //         },
        //       ],
        //       createdAt: '',
        //       createdBy: '',
        //       modifiedAt: '',
        //       modifiedBy: '',
        //       productNameEN: '',
        //       productNameKO: c,
        //       saleStatus: false,
        //       totalPriceCalc: '',
        //       typeOfPriceDetermintion: '',
        //       _id: c,
        //     };
        //   });
        setProductList([...copy]); //, ...copy2]);
      }
      setProductFilterNow(group);
    } else {
      console.log('payload yet');
    }
  };
  const [TName, setTName] = useState<string[]>([
    'min',
    'max',
    'value',
    'calcValue',
  ]);
  const [newTable, setNewTable] = useState<TableName>({});
  const [Tready, setTready] = useState<boolean>(false);
  const UseExistTableName = () => {
    let tnameCopy = Object.keys({ ...newItem.price[0].value[0] });
    setTName(tnameCopy);
    let init: { [index: string]: number } = {};
    const tnameResult = tnameCopy.reduce((acc, curr) => {
      acc[curr] = 0;
      return acc;
    }, init);
    delete tnameResult._id;
    setNewTable(tnameResult);
  };
  useEffect(() => {
    UseExistTableName();
  }, []);

  /** S: 후가공 옵션준비 */
  useEffect(() => {
    AddworkType !== 'new' && setPriceReady(true);
  }, []);
  /** E: 후가공 옵션준비 */

  /** S: 가격표 만들기 */
  const alertNow = (inputs: NewAddwork) => {
    return inputs.groupCode == ''
      ? alert('후가공종류를 선택하세요.')
      : inputs.workType == ''
      ? alert('후가공 영문코드를 입력하세요.')
      : inputs.workTypeKO == ''
      ? alert('후가공 명을 입력하세요.')
      : !Tready
      ? alert('가격표타이틀을 확정 하세요.')
      : true;
  };
  const [priceReady, setPriceReady] = useState<boolean>(false);
  const [comparison, setComparison] = useState<NewAddworkSelecters[]>();
  const createPriceTable = () => {
    let finalSelecter = newItem.selecters.filter(
      (a) => !a.type || a.type === 'Code' || a.type === 'OnlyPrice',
    );
    let finalFilter = newItem.selecters.filter(
      (a) =>
        (!a.type || a.type === 'Code' || a.type === 'OnlyPrice') &&
        !a.title.includes('-OnlySelect'),
    );
    let lateN = finalFilter.length;
    const factorial = (n: number): number => {
      let fa = 1;
      if (n > 0) {
        return finalFilter[n - 1].select.length * factorial(n - 1);
      }
      return fa;
    };
    const arrayFacto = (n: number): number => {
      let fb = 1;
      if (lateN > n + 1) {
        return finalFilter[n + 1].select.length * arrayFacto(n + 1);
      }
      return fb;
    };
    let total = factorial(lateN);
    let NT = JSON.parse(JSON.stringify(newTable));
    let newArray = [];
    let newArrayInner = [];
    let emptyPrice = [];
    for (let ei = 0; ei < 5; ei++) {
      emptyPrice.push(NT);
    }
    let ini = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
    for (let i = 0; i < total; i++) {
      for (let i2 = 0; i2 < lateN; i2++) {
        newArrayInner.push(finalFilter[i2].select[ini[i2] - 1].code);
        if ((i + 1) % arrayFacto(i2) === 0) {
          ini[i2] === finalFilter[i2].select.length ? (ini[i2] = 1) : ini[i2]++;
        }
      }
      newArray.push({
        code: [...newArrayInner],
        value: [...emptyPrice],
      });
      newArrayInner = [];
    }
    let resultprice = {
      ...newItem,
      selecters: finalSelecter,
      price: newArray,
    };
    setNewItem(resultprice);
    return resultprice;
  };
  const compareSelecters = (
    toBeChangedArr: NewAddworkSelecters[],
    originalArr: NewAddworkSelecters[],
  ) => {
    const toBeChangedStr = toBeChangedArr
      .filter((a) => !a.title.includes('-OnlySelect'))
      .map((e) => e.select.map((e) => e.code).join(''))
      .join('');
    const originalStr = originalArr
      .filter((a) => !a.title.includes('-OnlySelect'))
      .map((e) => e.select.map((e) => e.code).join(''))
      .join('');
    if (toBeChangedStr === originalStr) {
      return true;
    } else {
      return false;
    }
  };
  const PreparePrice = () => {
    loadingHandler(true);
    let bool = alertNow(newItem);
    if (!bool) {
      setPriceReady(false);
      loadingHandler(false);
    } else {
      if (!comparison) {
        let result = createPriceTable();
        if (result.price.length > 0) {
          UseAddworkAPI('post', result);
          setPriceReady(true);
          loadingHandler(false);
        } else {
          alert('가격생성 실패.');
          setPriceReady(false);
          loadingHandler(false);
        }
      } else {
        let boolNow = compareSelecters(newItem.selecters, comparison);
        if (!boolNow) {
          let result = createPriceTable();
          result && setPriceReady(true), loadingHandler(false);
        } else {
          setPriceReady(true), loadingHandler(false);
        }
      }
      executeScroll();
    }
  };
  const BacktoOption = () => {
    setPriceReady(false);
    let copy = JSON.parse(JSON.stringify(newItem.selecters));
    setComparison(copy);
  };
  /** E: 가격표 만들기 */
  /** S: 후가공 API */
  const UseAddworkAPI = async (
    kind: string,
    newarr: NewAddwork,
    productCopy?: string,
  ) => {
    loadingHandler(true);
    let addworkIDCopy = newItem._id;
    let finalPre = {
      ...newarr,
      site: newarr.site ? newarr.site : 'standard',
    };
    let finalCopy: NewAddwork = deleteEverything<NewAddwork>(finalPre, [
      '_id',
      'createdAt',
      'createdBy',
      'modifiedAt',
      'modifiedBy',
      '__v',
    ]);
    const requestOptions: AxiosRequestConfig =
      kind === 'add'
        ? {
            url:
              SERVER_URL +
              '/addwork/push?addworkId=' +
              addworkIDCopy +
              '&productId=' +
              productCopy,
            method: 'PUT',
          }
        : kind === 'del'
        ? {
            url:
              SERVER_URL +
              '/addwork/pull?addworkId=' +
              addworkIDCopy +
              '&productId=' +
              productCopy,
            method: 'PUT',
          }
        : kind === 'post'
        ? {
            url: SERVER_URL + '/addwork',
            data: newarr,
            method: 'post',
          }
        : {
            url: SERVER_URL + '/addwork/' + addworkIDCopy,
            data: finalCopy,
            method: 'put',
          };
    try {
      const response = await axios(requestOptions);
      if (kind === 'post') {
        response && setNewItem(response.data.data);
      } else if (kind === 'save') {
        alert('저장완료');
        response && setNewItem(response.data.data);
      } else if (kind === 'complete') {
        alert('저장완료');
        setNewItem({ ...AddworkDummy });
        AddworkType === 'new'
          ? closeModal('new')
          : AddworkType === 'edit'
          ? closeModal('edit')
          : closeModal('sited');
      }
      loadingHandler(false);
      return;
    } catch (e) {
      alert('저장실패!');
      loadingHandler(false);
      console.log(e);
    }
  };
  /** E: 후가공 API */
  /** S: 제품 연결관리 */
  const ChangeLinked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (!newItem.productId.includes(value)) {
      let copyId = [...newItem.productId, value];
      setNewItem({ ...newItem, productId: copyId });
      UseAddworkAPI('add', newItem, value);
    } else {
      let copyId = newItem.productId.filter((a) => a !== value);
      setNewItem({ ...newItem, productId: copyId });
      UseAddworkAPI('del', newItem, value);
    }
  };
  /** E: 제품 연결관리 */
  const goDummy = () => {
    let _idcopy = newItem._id;
    let copy = deleteEverything<NewAddwork>(YAddworkDummy, [
      '_id',
      'createdAt',
      'createdBy',
      'modifiedAt',
      'modifiedBy',
      '__v',
    ]);
    setNewItem({ ...copy, _id: _idcopy });
  };
  return (
    <>
      <div className="clearfix"></div>
      <div className="row">
        <div className="col-12">
          <button onClick={() => goDummy()}>Dummy GOGO</button>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <AddWorkSelect
            AddworkType={AddworkType}
            newItem={newItem}
            setNewItem={setNewItem}
            TName={TName}
            setTName={setTName}
            newTable={newTable}
            setNewTable={setNewTable}
            Tready={Tready}
            setTready={setTready}
            priceReady={priceReady}
            BacktoOption={BacktoOption}
            executeScroll={executeScroll}
          />
          <div className="clearfix" ref={myRef}></div>
          <div className="row my-4">
            <div className="col-12 text-center">
              {!priceReady ? (
                <div
                  id="holdOrder-btn"
                  className="yBtn yBtn-xl purple btn-inline-block"
                  onClick={() => {
                    PreparePrice();
                  }}
                >
                  <span>
                    <i className="fa fa-cube" aria-hidden="true"></i> 가격 생성
                  </span>
                  <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              ) : (
                <div
                  id="holdOrder-btn"
                  className="yBtn yBtn-xl orange btn-inline-block"
                  onClick={() => {
                    BacktoOption();
                  }}
                >
                  <span>
                    <i className="fa fa-edit" aria-hidden="true"></i> 옵션수정
                  </span>
                  <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {priceReady && (
            <>
              <div className="clearfix"></div>
              <div className="contents-wrap x_panel mb-3">
                <div className="x_content">
                  <div className="form-horizontal form-label-left">
                    <div className="newoption-wrap form-group">
                      <div className="row">
                        <div className="newoption-title cost-title col-md-2 col-sm-2 col-xs-12">
                          기준제품 선택
                        </div>
                        <div className="newoption-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                          <div className="groupFilter-wrap blue mb-2">
                            <div className="groupFilter-inner">
                              <div
                                className={`groupFilter-item ${
                                  productFilterNow == '전체' ? 'active' : ''
                                }`}
                                onClick={() => changeProductFilter('전체')}
                              >
                                전체
                              </div>
                              {productFilter?.map((filt) => {
                                return (
                                  <div
                                    key={filt}
                                    className={`groupFilter-item ${
                                      productFilterNow == filt ? 'active' : ''
                                    }`}
                                    onClick={() => changeProductFilter(filt)}
                                  >
                                    {filt}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div className="wrap">
                            {productList &&
                              productList.map((item) => {
                                return (
                                  <div
                                    className="newoption-radio"
                                    key={item._id}
                                  >
                                    <input
                                      id={item._id}
                                      value={item._id}
                                      name="productId"
                                      type="checkbox"
                                      className="boxInputs"
                                      checked={newItem.productId.includes(
                                        item._id,
                                      )}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>,
                                      ) => ChangeLinked(e)}
                                    />
                                    <label htmlFor={item._id}>
                                      {item.productNameKO}
                                    </label>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
              <AddWorkPrice
                AddworkType={AddworkType}
                newItem={newItem}
                setNewItem={setNewItem}
                newTable={newTable}
              />
              <div className="clearfix"></div>
              <div className="row  py-5">
                <div className="col-sm-12 text-center complete-btn-wrap">
                  <div
                    className="btn yBtn yBtn-small green btn btn-inline-block ms-2"
                    data-toggle="tooltip"
                    onClick={() => UseAddworkAPI('save', newItem)}
                  >
                    임시 저장
                  </div>
                </div>
                <div className="col-sm-12  text-center complete-btn-wrap my-4">
                  <div
                    className="price-start-btn btn btn-inline-block"
                    data-toggle="tooltip"
                    onClick={() => {
                      UseAddworkAPI('complete', newItem);
                    }}
                  >
                    <span>
                      <i className="fa fa-check me-1" aria-hidden="true"></i>
                      {AddworkType === 'new' ? '상품 생성' : '저장 후 닫기'}
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
