import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from '@config/reducer.index';
import App from './App';

const rooNode = document.getElementById('root');

if (!rooNode) throw new Error('Failed to find the root element');

ReactDOM.createRoot(rooNode).render(
  <AuthProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </AuthProvider>,
);
