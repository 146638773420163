import { Dispatch, SetStateAction, useEffect } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import {
  FirstInput,
  TableCostStep,
  Order,
} from '@interfaces/tableCost.interfaces';
interface Props {
  firstInputs: FirstInput;
  latestInput: Order[];
  setLatestInput: Dispatch<SetStateAction<Order[]>>;
  noPriceInput: Order[];
  gotoPriceInput: (step: TableCostStep, inputs: FirstInput) => void;
}

export default function PriceInputTable({
  firstInputs,
  latestInput,
  setLatestInput,
  noPriceInput,
  gotoPriceInput,
}: Props) {
  /**
   * 가격표 기준 순서변경 Action
   * @param result 순서바꾼 배열
   * @constant latestInput
   */
  useEffect(() => {
    setLatestInput([...latestInput, ...noPriceInput]);
  }, []);
  const handleChange = (result: DropResult) => {
    if (!result.destination) return;
    const items = [...latestInput];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setLatestInput(items);
  };
  const noPrice = (name: string, index: number) => {
    const npcopy = [...latestInput];
    npcopy.map((v) => {
      if (v.selecterName === name) {
        if (v.priceOrder === -1) {
          v.priceOrder = index;
        } else {
          v.priceOrder = -1;
        }
        return v;
      } else {
        return v;
      }
    });
    setLatestInput(npcopy);
  };

  return (
    <>
      <div className="price-table-wrap">
        <div className="price-title-wrap">
          <p>가격틀 만들기</p>
        </div>
        <div className="dnd-number-wrap">
          {latestInput.map((latestInput, index) => (
            <div className="dnd-number" key={index}>
              <p>{index + 1}</p>
            </div>
          ))}
        </div>
        <DragDropContext onDragEnd={handleChange}>
          <Droppable droppableId="latestInput">
            {(provided) => (
              <ul
                className="latestInput"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {latestInput.map((latestInput, index) => (
                  <Draggable
                    key={index}
                    draggableId={`${Number(index)}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                        className={`item ${
                          snapshot.isDragging ? 'dragging' : ''
                        } ${latestInput.priceOrder === -1 ? 'noPrice' : ''}`}
                      >
                        <p>{latestInput.selecterName}</p>
                        <div
                          className="no-price-btn"
                          onClick={() =>
                            noPrice(latestInput.selecterName, index)
                          }
                        >
                          {latestInput.priceOrder === -1 ? (
                            <i className="fa fa-plus"></i>
                          ) : (
                            <i className="fa fa-minus"></i>
                          )}
                        </div>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
        <div className="latestInput-btn-group">
          <div
            className="latestInput-btn yBtn yBtn-small blue my-3 mx-auto text-center"
            onClick={() => {
              gotoPriceInput('holdToMake', firstInputs);
            }}
          >
            <i className="fa fa-check"></i>
            선택완료
          </div>
        </div>
      </div>
    </>
  );
}
