import { useState } from 'react';
import axios from 'axios';
import useFetch from '@utils/customHook/useFetch';
import { SiteaddIn } from '@dummydata/dummy';
import { ProductListType, SitedList } from '@interfaces/tableCost.interfaces';
import { IAddwork } from '@interfaces/addwork.interface';
import { Site } from '@interfaces/site.interface';
import { SERVER_URL } from '@config/path';
import { useAuthState } from '@config/context';
import GetProductModal from '@components/modal/getProductModal';
import GetAddworkModal from '@components/modal/getAddworkModal';
import { useLoading } from '@config/loadingContext';

export default function Productlist() {
  const { loadingNow, loadingHandler } = useLoading();
  const [GPmodalNow, setGPmodalNow] = useState(false);
  const [GFmodalNow, setGFmodalNow] = useState(false);
  const changeGFmodalNow = (bool: boolean) => {
    setGFmodalNow(bool);
  };
  const [selectedSite, setSelectedSite] = useState<Site>({
    ...SiteaddIn,
  });
  const [productListNow, setProductListNow] = useState<ProductListType[]>([]);
  const [filterNow, setFilterNow] = useState<ProductListType[]>([]);
  const [UPList, setUPList] = useState<string[]>([]);
  const { payload, loading, error } = useFetch<Site>(`site`, loadingNow);
  const [addworkList, setAddworkList] = useState<IAddwork[]>([]);
  const [addworkSited, setAddworkSited] = useState<string[]>([]);
  const [addworkUnique, setAddworkUnique] = useState<string[]>([]);
  /** 사이트리스트 array*/
  const SelectEvents = async (row: Site, type: string) => {
    setSelectedSite(row);
    if (type === 'product') {
      await axios
        .get(`${SERVER_URL}/site/${row._id}`)
        .then((res) => {
          ChangeSelected(row, res.data.data.productId);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await axios
        .get(`${SERVER_URL}/addwork/all`)
        .then((res) => {
          let copy = res.data.data;
          let selectedProductCopy = copy
            .filter((item: IAddwork) => item.site === 'standard')
            .map((item: IAddwork) => {
              return { ...item, checkbox: false };
            });
          let selectedAddCopy = copy
            .filter((item: IAddwork) => item.site === row.sellerNameEN)
            .map((item: IAddwork) => {
              return item.originId;
            });

          let UniqueAddCopy = selectedProductCopy
            .filter((a: IAddwork) => a.groupCode)
            .map((b: IAddwork) => {
              return b.groupCode;
            })
            .filter(
              (thing: string, index: number, self: string[]) =>
                index === self.findIndex((t) => t === thing),
            );

          setAddworkList(selectedProductCopy);
          setAddworkSited(selectedAddCopy);
          setAddworkUnique(UniqueAddCopy);
          setGFmodalNow(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const ChangeSelected = async (row: Site, newSited: SitedList[]) => {
    await axios
      .get(`${SERVER_URL}/product/`)
      .then((res) => {
        let Copy = res.data.data;
        let NewCopy = JSON.parse(JSON.stringify(Copy));
        let NewContCopy = NewCopy.map((u: ProductListType) => {
          if (
            newSited.filter((a) => a.productNameEN === u.productNameEN).length >
            0
          ) {
            return (u = { ...u, checkbox: false, siteId: true });
          } else {
            return (u = { ...u, checkbox: false, siteId: false });
          }
        });
        setProductListNow(NewContCopy);
        setFilterNow(NewContCopy);
        let MakeList = Copy.map((ar: ProductListType) => {
          return ar.category[0].categoryName1st;
        });
        let uniqueList = MakeList.filter((element: string, index: number) => {
          return MakeList.indexOf(element) === index;
        });
        setUPList(uniqueList);

        setGPmodalNow(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className="page-title">
        <div className="title_left">
          <h3>
            <i className="fa fa-cubes" />
            기준상품 가져오기 <small>상품관리</small>
          </h3>
        </div>
      </div>
      <div className="clearfix"></div>
      <div className="row">
        <div className="col-12">
          <div className="contents-wrap x_panel">
            <div className="x_content getProduct-table-wrap productlist-table-wrap">
              <div className="react-bootstrap-table">
                <table className="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th>판매업체</th>
                      <th>사이트url</th>
                      <th>관리자</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payload &&
                      payload.data.map((code, idx) => {
                        return (
                          <tr key={idx}>
                            <td>
                              {code.sellerName} &lt;{code.topSeller}&gt;
                            </td>
                            <td>
                              <a
                                href={'https://' + code.siteUrl}
                                target="blink"
                              >
                                {code.siteUrl}
                              </a>
                            </td>

                            <td>
                              {code.adminName} &lt;{code.adminPosition}&gt;
                            </td>
                            <td>
                              <button
                                className="tableBtn tableBtn-skyblue btnW-120"
                                onClick={() => SelectEvents(code, 'product')}
                              >
                                기준 상품 가져오기
                              </button>
                              <button
                                className="tableBtn tableBtn-green btnW-120"
                                onClick={() => SelectEvents(code, 'addwork')}
                              >
                                기준 후가공 가져오기
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {GPmodalNow && (
        <GetProductModal
          selectedSite={selectedSite}
          productListNow={productListNow}
          setProductListNow={setProductListNow}
          setGPmodalNow={setGPmodalNow}
          filterNow={filterNow}
          setFilterNow={setFilterNow}
          UPList={UPList}
          setUPList={setUPList}
        />
      )}
      {GFmodalNow && (
        <GetAddworkModal
          selectedSite={selectedSite}
          addworkList={addworkList}
          setAddworkList={setAddworkList}
          addworkSited={addworkSited}
          setAddworkSited={setAddworkSited}
          addworkUnique={addworkUnique}
          changeGFmodalNow={changeGFmodalNow}
        />
      )}
    </>
  );
}
