import { Dispatch, useState, SetStateAction } from 'react';
import { FirstInput, LetCode } from '@interfaces/tableCost.interfaces';
interface Props {
  firstInputs: FirstInput;
  setFirstInputs: Dispatch<SetStateAction<FirstInput>>;
  NewClass: LetCode;
}
interface GroupClass {
  kind: string;
  from: string;
  fromK: string;
}
export default function RestricList({
  firstInputs,
  setFirstInputs,
  NewClass,
}: Props) {
  let classLength = NewClass.length;
  const delClass = (from: string, to: string, fromKind: string) => {
    let copy = [...firstInputs.selecters];
    let result = copy.map((a) => {
      if (a.kindCode === fromKind) {
        return {
          ...a,
          types: a.types.map((b) => {
            if (b.code === from) {
              return {
                ...b,
                class: b.class.filter((c) => c !== to),
              };
            } else {
              return b;
            }
          }),
        };
      } else {
        return a;
      }
    });
    setFirstInputs({ ...firstInputs, selecters: result });
  };
  const groupClass: GroupClass[] = NewClass.map((codeA) => {
    return codeA.from + '&' + codeA.fromKind;
  })
    .filter(
      (thing: string, index: number, self: string[]) =>
        index === self.findIndex((t) => t === thing),
    )
    .map((codeB) => {
      let codeSplit = codeB.split('&');
      let NameW = firstInputs.selecters
        .find((a) => a.kindCode == codeSplit[1])
        ?.types.find((c) => c.code == codeSplit[0])?.title;
      if (NameW) {
        return { kind: codeSplit[1], from: codeSplit[0], fromK: NameW };
      } else {
        return { kind: codeSplit[1], from: codeSplit[0], fromK: codeSplit[0] };
      }
    });
  return (
    <>
      {firstInputs.selecters.length > 1 && classLength > 0 && (
        <div className="restric-list text-end">
          <h5>
            <span>제한사항 목록</span>
          </h5>
          <ul className="d-inline-block">
            {groupClass.map((codeA, index) => {
              return (
                <li className="rest-wrap" key={index}>
                  <div
                    className="rest-paper d-inline-block"
                    name={String(index)}
                  >
                    {codeA.fromK}
                  </div>
                  {NewClass.filter(
                    (codeB) =>
                      codeB.from == codeA.from && codeB.fromKind == codeA.kind,
                  ).map((w, i) => {
                    return (
                      <div className="d-inline-block" key={i}>
                        <p className="rest-class" name={w.from + '-' + w.to}>
                          <span>{w.toName}</span>
                          <span
                            onClick={() => delClass(w.from, w.to, w.fromKind)}
                          >
                            <i className="fa fa-times"></i>
                          </span>
                        </p>
                      </div>
                    );
                  })}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
}
