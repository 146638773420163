import { useState, useEffect } from 'react';
import axios from 'axios';
import useFetch from '@utils/customHook/useFetch';
import { SERVER_URL } from '@config/path';
import { FirstInput, ProductListType } from '@interfaces/tableCost.interfaces';
import { Site } from '@interfaces/site.interface';
import { basicConst } from '@dummydata/dummy';
import LinkedProductModal from '@components/modal/productmanager/linkedproduct-modal-inner';
import { useLoading } from '@config/loadingContext';
import moment from 'moment';

interface Props {
  selectedSite: Site;
  sellerNameEN: string;
  GPmodalhandler: (bool: boolean) => void;
  PRmodalhandler: (boolean: boolean) => void;
}
export default function SetPriceInner({
  selectedSite,
  sellerNameEN,
  GPmodalhandler,
  PRmodalhandler,
}: Props) {
  moment.locale();
  const { loadingNow, loadingHandler } = useLoading();
  const [LISTTYPE, setLISTTYPE] = useState<string>('LP');
  const changeLISTTYPE = (str: string) => {
    setLISTTYPE(str);
    if (str === 'LP') {
      GPmodalhandler(false);
      PRmodalhandler(true);
    } else {
      GPmodalhandler(true);
      PRmodalhandler(false);
    }
  };

  const { payload, loading, error } = useFetch<ProductListType>(
    `productbysite?target=${sellerNameEN}`,
    loadingNow,
  );

  /**S:제품필터 */
  const [productList, setProductList] = useState<ProductListType[]>([]);
  const [productFilter, setProductFilter] = useState<string[]>();
  const [productFilterNow, setProductFilterNow] = useState<string>('전체');
  useEffect(() => {
    if (payload) {
      let copy = [...payload.data];
      setProductList(copy);
      let filt = copy
        .filter((a) => a.category[0].categoryName1st)
        .map((b) => {
          return b.category[0].categoryName1st;
        })
        .filter(
          (thing: string, index: number, self: string[]) =>
            index === self.findIndex((t) => t === thing),
        );
      setProductFilter(filt);
    } else {
      console.log('payload yet!');
    }
    changeProductFilter(productFilterNow);
  }, [payload]);
  const changeProductFilter = (group: string) => {
    if (payload) {
      if (group !== '전체') {
        let copy = payload.data.filter(
          (a) => a.category[0].categoryName1st === group,
        );
        setProductList(copy);
      } else {
        let copy = [...payload.data];
        setProductList(copy);
      }
    } else {
      console.log('payload yet');
    }
    setProductFilterNow(group);
  };
  /**E:제품필터 */
  const siteName = selectedSite.sellerName;
  let basicCopy = JSON.parse(JSON.stringify(basicConst));
  const [firstInputs, setFirstInputs] = useState<FirstInput>({
    ...basicCopy,
  });
  /** 모달 state*/
  const [LPModal, setLPModal] = useState<boolean>(false);
  const changeLPModal = (bool: boolean) => {
    setLPModal(bool);
  };

  const EditLinkedPR = async (TARGET: string, PRODUCT: string) => {
    await axios
      .get(`${SERVER_URL}/productbysite?target=${TARGET}&product=${PRODUCT}`)
      .then((res) => {
        setFirstInputs(res.data.data[0]); //데이터 업데이트
        setLPModal(true);
        loadingHandler(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  /**테이블 Sort State */
  const [sortNow, setSortNow] = useState<{ [key: string]: boolean }>({
    checkbox: false,
    categoryName1st: false,
    productNameKO: false,
  });
  /**테이블 Sort Action */
  const sortBy = (key: string) => {
    let copy = [...productList];
    if (key !== 'categoryName1st') {
      copy.sort((a, b) =>
        !sortNow[key]
          ? String(a[key]) < String(b[key])
            ? -1
            : 1
          : String(a[key]) > String(b[key])
          ? -1
          : 1,
      );
    } else {
      copy.sort((a, b) =>
        !sortNow[key]
          ? a.category[0].categoryName1st < b.category[0].categoryName1st
            ? -1
            : 1
          : a.category[0].categoryName1st > b.category[0].categoryName1st
          ? -1
          : 1,
      );
    }
    setSortNow({ ...sortNow, [key]: !sortNow[key] });
    setProductList(copy);
  };
  return (
    <>
      <div className="fixedContainer bg-f7f7f7">
        <div className="page-title">
          <div className="title_left">
            <h3>
              {selectedSite.sellerName} 후가공 <small>상품기준</small>
              <div className="pull-right">
                <div className="perKind-wrap me-3">
                  <div className="newoption-radio">
                    <input
                      type="radio"
                      name="perKind"
                      id="perAddwork"
                      className="boxInputs"
                      checked={LISTTYPE == 'GP'}
                      onChange={() => changeLISTTYPE('GP')}
                    />
                    <label htmlFor="perAddwork">후가공 기준</label>
                  </div>
                  <div className="newoption-radio">
                    <input
                      type="radio"
                      name="perKind"
                      id="perProduct"
                      className="boxInputs"
                      checked={LISTTYPE == 'LP'}
                      onChange={() => changeLISTTYPE('LP')}
                    />
                    <label htmlFor="perProduct">상품 기준</label>
                  </div>
                </div>
                <button
                  className="goBackBtn"
                  onClick={() => PRmodalhandler(false)}
                >
                  이전으로
                </button>
              </div>
            </h3>
          </div>
        </div>
        <div className="clearfix"></div>
        <div className="row">
          <div className="col-12">
            <div className="contents-wrap x_panel mb-2">
              <div className="groupFilter">
                <h5>후가공그룹</h5>
                <div className="groupFilter-wrap green">
                  <div className="groupFilter-inner">
                    <div
                      className={`groupFilter-item ${
                        productFilterNow == '전체' ? 'active' : ''
                      }`}
                      onClick={() => changeProductFilter('전체')}
                    >
                      전체
                    </div>
                    {productFilter?.map((filt) => {
                      return (
                        <div
                          key={filt}
                          className={`groupFilter-item ${
                            productFilterNow == filt ? 'active' : ''
                          }`}
                          onClick={() => changeProductFilter(filt)}
                        >
                          {filt}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="contents-wrap x_panel">
              <div className="x_content setETC-table-wrap table-wrap-green">
                {productList && (
                  <div className="react-bootstrap-table">
                    <table className="table table-hover table-bordered GW-cont-head">
                      <thead>
                        <tr>
                          <th
                            className="sortable cont-w150"
                            onClick={() => {
                              sortBy('categoryName1st');
                            }}
                          >
                            분류 <span className="order-4"></span>
                          </th>
                          <th
                            className="sortable"
                            onClick={() => {
                              sortBy('productNameKO');
                            }}
                          >
                            제품명 <span className="order-4"></span>
                          </th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productList.map((code, idx) => {
                          let time = moment(code.createdAt).format(
                            'YYYY-MM-DD (HH:mm)',
                          );
                          let time2 = moment(code.modifiedAt).format(
                            'YYYY-MM-DD (HH:mm)',
                          );
                          return (
                            <tr key={idx}>
                              <td>
                                {code.category[0].categoryName1st} /{' '}
                                {code.category[0].categoryName2nd}
                              </td>
                              <td>{code.productNameKO} </td>
                              <td>
                                <button
                                  className="tableBtn tableBtn-green btnW-90 setpriceBtn"
                                  onClick={() =>
                                    EditLinkedPR(
                                      sellerNameEN,
                                      code.productNameEN,
                                    )
                                  }
                                >
                                  <span className="mobileSpan">
                                    <i
                                      className="fa fa-pencil"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <span className="deskSpan">
                                    후가공 연결하기
                                  </span>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {LPModal && (
        <LinkedProductModal
          siteName={siteName}
          sellerNameEN={sellerNameEN}
          firstInputs={firstInputs}
          changeLPModal={changeLPModal}
        />
      )}
    </>
  );
}
