import { NewAddwork } from '@interfaces/addwork.interface';
export const YAddworkDummy: NewAddwork = {
  productId: [],
  site: 'standard',
  groupCode: '넘버링',
  workType: 'numbering',
  workTypeKO: '넘버링-칼라NCR',
  selecters: [
    {
      title: '넘버링-개수-OnlySelect',
      codeCategory: 'numberingCountCode',
      type: 'Code',
      select: [
        {
          code: 'NBC:1',
          title: '1개',
          options: {
            defaultQuantity: '1000',
            defaultPrice: '30000',
            overPrice: '5',
          },
          class: [],
          isHide: false,
        },
      ],
    },
    {
      title: '넘버링-종류-OnlySelect',
      codeCategory: 'numberingKindCode',
      type: 'Code',
      select: [
        {
          code: 'NUK:NORMAL',
          title: '일반넘버링',
          options: {},
          class: [],
          isHide: false,
        },
      ],
    },
    {
      title: 'ncr-조당매수-OnlyPrice',
      codeCategory: 'ncrSetOfSheetCode',
      select: [
        {
          code: 'SEO:TW',
          title: '2매',
          class: [],
          isHide: false,
          _id: '667a6917f0beac2a8d211cb4',
        },
        {
          code: 'SEO:TR',
          title: '3매',
          class: [],
          isHide: false,
          _id: '667a6917f0beac2a8d211cb5',
        },
        {
          code: 'SEO:FR',
          title: '4매',
          class: [],
          isHide: false,
          _id: '667a6917f0beac2a8d211cb6',
        },
      ],
      _id: '667a6917f0beac2a8d211cb3',
    },
    {
      title: '사이즈-OnlyPrice',
      codeCategory: 'sizeCode',
      select: [
        {
          code: 'SIZ:NCM-190x130',
          title: '32절 130*190(NCR)',
          class: [],
          isHide: false,
          _id: '667a6917f0beac2a8d211cb8',
        },
        {
          code: 'SIZ:NCM-190x260',
          title: '16절 190*260(NCR)',
          class: [],
          isHide: false,
          _id: '667a6917f0beac2a8d211cb9',
        },
        {
          code: 'SIZ:NCM-210x148',
          title: 'A5 148*210(NCR)',
          class: [],
          isHide: false,
          _id: '667a6917f0beac2a8d211cba',
        },
        {
          code: 'SIZ:NCM-210x297',
          title: 'A4 210*297(NCR)',
          class: [],
          isHide: false,
          _id: '667a6917f0beac2a8d211cbb',
        },
      ],
      _id: '667a6917f0beac2a8d211cb7',
    },
  ],
  price: [
    {
      code: ['SEO:TW', 'SIZ:NCM-190x130'],
      value: [
        { min: 20, max: 39, value: 60000, calcValue: 0 },
        { min: 40, max: 59, value: 75000, calcValue: 0 },
        { min: 60, max: 79, value: 90000, calcValue: 0 },
        { min: 80, max: 99, value: 105000, calcValue: 0 },
        { min: 100, max: 119, value: 119000, calcValue: 0 },
        { min: 120, max: 139, value: 132000, calcValue: 0 },
        { min: 140, max: 159, value: 147000, calcValue: 0 },
        { min: 160, max: 179, value: 161000, calcValue: 0 },
        { min: 180, max: 199, value: 174000, calcValue: 0 },
        { min: 200, max: 219, value: 188000, calcValue: 0 },
        { min: 220, max: 239, value: 206000, calcValue: 0 },
        { min: 240, max: 259, value: 213000, calcValue: 0 },
        { min: 260, max: 279, value: 227000, calcValue: 0 },
        { min: 280, max: 299, value: 239000, calcValue: 0 },
        { min: 300, max: 999999, value: 251000, calcValue: 0 },
      ],
    },
    {
      code: ['SEO:TW', 'SIZ:NCM-190x260'],
      value: [
        { min: 10, max: 19, value: 60000, calcValue: 0 },
        { min: 20, max: 29, value: 75000, calcValue: 0 },
        { min: 30, max: 39, value: 90000, calcValue: 0 },
        { min: 40, max: 49, value: 105000, calcValue: 0 },
        { min: 50, max: 59, value: 119000, calcValue: 0 },
        { min: 60, max: 69, value: 132000, calcValue: 0 },
        { min: 70, max: 79, value: 147000, calcValue: 0 },
        { min: 80, max: 89, value: 161000, calcValue: 0 },
        { min: 90, max: 99, value: 174000, calcValue: 0 },
        { min: 100, max: 119, value: 188000, calcValue: 0 },
        { min: 120, max: 139, value: 213000, calcValue: 0 },
        { min: 140, max: 159, value: 239000, calcValue: 0 },
        { min: 160, max: 179, value: 263000, calcValue: 0 },
        { min: 180, max: 199, value: 287000, calcValue: 0 },
        { min: 200, max: 999999, value: 309000, calcValue: 0 },
      ],
    },
    {
      code: ['SEO:TW', 'SIZ:NCM-210x148'],
      value: [
        { min: 20, max: 39, value: 60000, calcValue: 0 },
        { min: 40, max: 59, value: 75000, calcValue: 0 },
        { min: 60, max: 79, value: 90000, calcValue: 0 },
        { min: 80, max: 99, value: 105000, calcValue: 0 },
        { min: 100, max: 119, value: 119000, calcValue: 0 },
        { min: 120, max: 139, value: 132000, calcValue: 0 },
        { min: 140, max: 159, value: 147000, calcValue: 0 },
        { min: 160, max: 179, value: 161000, calcValue: 0 },
        { min: 180, max: 199, value: 174000, calcValue: 0 },
        { min: 200, max: 219, value: 188000, calcValue: 0 },
        { min: 220, max: 239, value: 206000, calcValue: 0 },
        { min: 240, max: 259, value: 213000, calcValue: 0 },
        { min: 260, max: 279, value: 227000, calcValue: 0 },
        { min: 280, max: 299, value: 239000, calcValue: 0 },
        { min: 300, max: 999999, value: 251000, calcValue: 0 },
      ],
    },
    {
      code: ['SEO:TW', 'SIZ:NCM-210x297'],
      value: [
        { min: 10, max: 19, value: 60000, calcValue: 0 },
        { min: 20, max: 29, value: 75000, calcValue: 0 },
        { min: 30, max: 39, value: 90000, calcValue: 0 },
        { min: 40, max: 49, value: 105000, calcValue: 0 },
        { min: 50, max: 59, value: 119000, calcValue: 0 },
        { min: 60, max: 69, value: 132000, calcValue: 0 },
        { min: 70, max: 79, value: 147000, calcValue: 0 },
        { min: 80, max: 89, value: 161000, calcValue: 0 },
        { min: 90, max: 99, value: 174000, calcValue: 0 },
        { min: 100, max: 119, value: 188000, calcValue: 0 },
        { min: 120, max: 139, value: 213000, calcValue: 0 },
        { min: 140, max: 159, value: 239000, calcValue: 0 },
        { min: 160, max: 179, value: 263000, calcValue: 0 },
        { min: 180, max: 199, value: 287000, calcValue: 0 },
        { min: 200, max: 999999, value: 309000, calcValue: 0 },
      ],
    },
    {
      code: ['SEO:TR', 'SIZ:NCM-190x130'],
      value: [
        { min: 20, max: 39, value: 60000, calcValue: 0 },
        { min: 40, max: 59, value: 75000, calcValue: 0 },
        { min: 60, max: 79, value: 90000, calcValue: 0 },
        { min: 80, max: 99, value: 105000, calcValue: 0 },
        { min: 100, max: 119, value: 119000, calcValue: 0 },
        { min: 120, max: 139, value: 132000, calcValue: 0 },
        { min: 140, max: 159, value: 147000, calcValue: 0 },
        { min: 160, max: 179, value: 161000, calcValue: 0 },
        { min: 180, max: 199, value: 174000, calcValue: 0 },
        { min: 200, max: 219, value: 188000, calcValue: 0 },
        { min: 220, max: 239, value: 206000, calcValue: 0 },
        { min: 240, max: 259, value: 213000, calcValue: 0 },
        { min: 260, max: 279, value: 227000, calcValue: 0 },
        { min: 280, max: 299, value: 239000, calcValue: 0 },
        { min: 300, max: 999999, value: 251000, calcValue: 0 },
      ],
    },
    {
      code: ['SEO:TR', 'SIZ:NCM-190x260'],
      value: [
        { min: 10, max: 19, value: 60000, calcValue: 0 },
        { min: 20, max: 29, value: 75000, calcValue: 0 },
        { min: 30, max: 39, value: 90000, calcValue: 0 },
        { min: 40, max: 49, value: 105000, calcValue: 0 },
        { min: 50, max: 59, value: 119000, calcValue: 0 },
        { min: 60, max: 69, value: 132000, calcValue: 0 },
        { min: 70, max: 79, value: 147000, calcValue: 0 },
        { min: 80, max: 89, value: 161000, calcValue: 0 },
        { min: 90, max: 99, value: 174000, calcValue: 0 },
        { min: 100, max: 119, value: 188000, calcValue: 0 },
        { min: 120, max: 139, value: 213000, calcValue: 0 },
        { min: 140, max: 159, value: 239000, calcValue: 0 },
        { min: 160, max: 179, value: 263000, calcValue: 0 },
        { min: 180, max: 199, value: 287000, calcValue: 0 },
        { min: 200, max: 999999, value: 309000, calcValue: 0 },
      ],
    },
    {
      code: ['SEO:TR', 'SIZ:NCM-210x148'],
      value: [
        { min: 20, max: 39, value: 60000, calcValue: 0 },
        { min: 40, max: 59, value: 75000, calcValue: 0 },
        { min: 60, max: 79, value: 90000, calcValue: 0 },
        { min: 80, max: 99, value: 105000, calcValue: 0 },
        { min: 100, max: 119, value: 119000, calcValue: 0 },
        { min: 120, max: 139, value: 132000, calcValue: 0 },
        { min: 140, max: 159, value: 147000, calcValue: 0 },
        { min: 160, max: 179, value: 161000, calcValue: 0 },
        { min: 180, max: 199, value: 174000, calcValue: 0 },
        { min: 200, max: 219, value: 188000, calcValue: 0 },
        { min: 220, max: 239, value: 206000, calcValue: 0 },
        { min: 240, max: 259, value: 213000, calcValue: 0 },
        { min: 260, max: 279, value: 227000, calcValue: 0 },
        { min: 280, max: 299, value: 239000, calcValue: 0 },
        { min: 300, max: 999999, value: 251000, calcValue: 0 },
      ],
    },
    {
      code: ['SEO:TR', 'SIZ:NCM-210x297'],
      value: [
        { min: 10, max: 19, value: 60000, calcValue: 0 },
        { min: 20, max: 29, value: 75000, calcValue: 0 },
        { min: 30, max: 39, value: 90000, calcValue: 0 },
        { min: 40, max: 49, value: 105000, calcValue: 0 },
        { min: 50, max: 59, value: 119000, calcValue: 0 },
        { min: 60, max: 69, value: 132000, calcValue: 0 },
        { min: 70, max: 79, value: 147000, calcValue: 0 },
        { min: 80, max: 89, value: 161000, calcValue: 0 },
        { min: 90, max: 99, value: 174000, calcValue: 0 },
        { min: 100, max: 119, value: 188000, calcValue: 0 },
        { min: 120, max: 139, value: 213000, calcValue: 0 },
        { min: 140, max: 159, value: 239000, calcValue: 0 },
        { min: 160, max: 179, value: 263000, calcValue: 0 },
        { min: 180, max: 199, value: 287000, calcValue: 0 },
        { min: 200, max: 999999, value: 309000, calcValue: 0 },
      ],
    },
    {
      code: ['SEO:FR', 'SIZ:NCM-190x130'],
      value: [
        { min: 20, max: 39, value: 60000, calcValue: 0 },
        { min: 40, max: 59, value: 75000, calcValue: 0 },
        { min: 60, max: 79, value: 90000, calcValue: 0 },
        { min: 80, max: 99, value: 105000, calcValue: 0 },
        { min: 100, max: 119, value: 119000, calcValue: 0 },
        { min: 120, max: 139, value: 132000, calcValue: 0 },
        { min: 140, max: 159, value: 147000, calcValue: 0 },
        { min: 160, max: 179, value: 161000, calcValue: 0 },
        { min: 180, max: 199, value: 174000, calcValue: 0 },
        { min: 200, max: 219, value: 188000, calcValue: 0 },
        { min: 220, max: 239, value: 206000, calcValue: 0 },
        { min: 240, max: 259, value: 213000, calcValue: 0 },
        { min: 260, max: 279, value: 227000, calcValue: 0 },
        { min: 280, max: 299, value: 239000, calcValue: 0 },
        { min: 300, max: 999999, value: 251000, calcValue: 0 },
      ],
    },
    {
      code: ['SEO:FR', 'SIZ:NCM-190x260'],
      value: [
        { min: 10, max: 19, value: 60000, calcValue: 0 },
        { min: 20, max: 29, value: 75000, calcValue: 0 },
        { min: 30, max: 39, value: 90000, calcValue: 0 },
        { min: 40, max: 49, value: 105000, calcValue: 0 },
        { min: 50, max: 59, value: 119000, calcValue: 0 },
        { min: 60, max: 69, value: 132000, calcValue: 0 },
        { min: 70, max: 79, value: 147000, calcValue: 0 },
        { min: 80, max: 89, value: 161000, calcValue: 0 },
        { min: 90, max: 99, value: 174000, calcValue: 0 },
        { min: 100, max: 119, value: 188000, calcValue: 0 },
        { min: 120, max: 139, value: 213000, calcValue: 0 },
        { min: 140, max: 159, value: 239000, calcValue: 0 },
        { min: 160, max: 179, value: 263000, calcValue: 0 },
        { min: 180, max: 199, value: 287000, calcValue: 0 },
        { min: 200, max: 999999, value: 309000, calcValue: 0 },
      ],
    },
    {
      code: ['SEO:FR', 'SIZ:NCM-210x148'],
      value: [
        { min: 20, max: 39, value: 60000, calcValue: 0 },
        { min: 40, max: 59, value: 75000, calcValue: 0 },
        { min: 60, max: 79, value: 90000, calcValue: 0 },
        { min: 80, max: 99, value: 105000, calcValue: 0 },
        { min: 100, max: 119, value: 119000, calcValue: 0 },
        { min: 120, max: 139, value: 132000, calcValue: 0 },
        { min: 140, max: 159, value: 147000, calcValue: 0 },
        { min: 160, max: 179, value: 161000, calcValue: 0 },
        { min: 180, max: 199, value: 174000, calcValue: 0 },
        { min: 200, max: 219, value: 188000, calcValue: 0 },
        { min: 220, max: 239, value: 206000, calcValue: 0 },
        { min: 240, max: 259, value: 213000, calcValue: 0 },
        { min: 260, max: 279, value: 227000, calcValue: 0 },
        { min: 280, max: 299, value: 239000, calcValue: 0 },
        { min: 300, max: 999999, value: 251000, calcValue: 0 },
      ],
    },
    {
      code: ['SEO:FR', 'SIZ:NCM-210x297'],
      value: [
        { min: 10, max: 19, value: 60000, calcValue: 0 },
        { min: 20, max: 29, value: 75000, calcValue: 0 },
        { min: 30, max: 39, value: 90000, calcValue: 0 },
        { min: 40, max: 49, value: 105000, calcValue: 0 },
        { min: 50, max: 59, value: 119000, calcValue: 0 },
        { min: 60, max: 69, value: 132000, calcValue: 0 },
        { min: 70, max: 79, value: 147000, calcValue: 0 },
        { min: 80, max: 89, value: 161000, calcValue: 0 },
        { min: 90, max: 99, value: 174000, calcValue: 0 },
        { min: 100, max: 119, value: 188000, calcValue: 0 },
        { min: 120, max: 139, value: 213000, calcValue: 0 },
        { min: 140, max: 159, value: 239000, calcValue: 0 },
        { min: 160, max: 179, value: 263000, calcValue: 0 },
        { min: 180, max: 199, value: 287000, calcValue: 0 },
        { min: 200, max: 999999, value: 309000, calcValue: 0 },
      ],
    },
  ],
  class: '',
};
