import { useState, useEffect } from 'react';
import { useLoading } from '@config/loadingContext';
import { boardPayload, boardType } from '@interfaces/tableCost.interfaces';
import moment from 'moment';
import ModalEmpty from '@components/modal/ModalEmpty';
import BardAnswerModal from '@components/modal/boardAnswerModal';
import useFetchNoData from '@utils/customHook/useFetchNoData';
import BoardPagination from '@utils/boardPagination';
import DatePicker, { registerLocale } from 'react-datepicker';
import ko from 'date-fns/locale/ko';
import axios from 'axios';
import { NEST_SERVER_URL } from '@config/path';

export interface boardDateFixed {
  start: string;
  end: string;
}

export default function SetBoard() {
  /* 모달 */
  const [boardModal, setBoardModal] = useState<boolean>(false);
  const closeModal = () => {
    setBoardModal(false);
  };
  /** 로딩 state*/
  const { loadingNow, loadingHandler } = useLoading();

  /* 보드 필터 */
  const changeBoardFilter = async (str: string) => {
    setboardFilterNow(str === '' ? '전체' : str);
    setPaging(1);
  };

  const [boardFilterNow, setboardFilterNow] = useState<string>('전체');

  /* 사이트 필터 */
  const changeSiteFilter = async (str: string) => {
    setSiteFilterNow(str === '' ? '전체' : str);
    setPaging(1);
  };

  const [siteFilterNow, setSiteFilterNow] = useState<string>('all');

  /* 유저 ID 검색 */
  /* 인풋 */
  const [userIdsearch, setUserIdsearch] = useState<string>('');
  /* ID 확정 검색용 */
  const [userIdNow, setUserIdNow] = useState<string>('');

  const idSearchHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setUserIdsearch(value);
  };

  const [paging, setPaging] = useState<number>(1); //현재 페이지
  const [reload, setReload] = useState<boolean>(false);

  /* 날짜 검색*/

  const [dateCustom, setDateCustom] = useState<boardDateFixed>({
    start: moment().format('yyyy-MM-DD 00:00:00.000'),
    end: moment().format('yyyy-MM-DD 23:59:59.599'),
  });

  const [dateFixed, setDateFixed] = useState<boardDateFixed>({
    start: '',
    end: '',
  });

  const [dateCal, setDateCal] = useState<boolean>(false);

  const ChangeDate = (types: string, date: Date | null) => {
    date !== null &&
      setDateCustom({
        ...dateCustom,
        [types]: moment(date).format(
          `yyyy-MM-DDT${types === 'start' ? '00:00' : '23:59'}`,
        ),
      });
    setDateCal(true);
  };

  const confirmDate = () => {
    setDateFixed({ ...dateCustom });
  };

  const payloadURL = `board/${
    boardFilterNow !== '전체' ? boardFilterNow : 'all'
  }?page=${paging}&limit=20${
    dateFixed.start !== ''
      ? '&start=' +
        moment(dateFixed.start).format('yyyy-MM-DD HH:mm:ss.sss') +
        '&end=' +
        moment(dateFixed.end).format('yyyy-MM-DD HH:mm:ss.sss')
      : ''
  }${siteFilterNow == 'all' ? '' : '&applySite=' + siteFilterNow}${
    userIdNow ? '&userId=' + userIdNow : ''
  }`;

  /* 보드 리스트 부르기 */
  const { payload, error } = useFetchNoData<boardPayload>(
    payloadURL,
    String(reload) + String(boardModal) + userIdNow + dateFixed,
  );

  const idSearchClick = () => {
    const newUserId = JSON.parse(JSON.stringify(userIdsearch));
    setUserIdNow(newUserId !== '' ? newUserId : undefined);
    setReload(!reload);
  };

  const [boardList, setBoardList] = useState<boardType[]>([]);

  /* 페이지 */

  const pageSize = 10; //최대 페이지

  const item = payload?.board;

  const totalPage = Math.ceil(payload ? payload.total / 20 : 0);

  /* 보드 수정 */

  useEffect(() => {
    // payload ? setBoardList(payload.board) : setBoardList([]);

    if (payload) {
      setBoardList(payload.board);
    }
    if (error === 'error') {
      setBoardList([]);
    }
  }, [payload, error]);

  const [modalId, setModalId] = useState<string>();
  const [boardType, setBoardType] = useState<string>();

  const EditBoard = async (_id: string, boardType: string) => {
    setModalId(_id);
    setBoardType(boardType);
    setBoardModal(true);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  /* 보드 삭제 */

  const boardDelete = async (boardType: string, _id: string) => {
    if (window.confirm('삭제하시겠습니까?')) {
      loadingHandler(true);
      await axios
        .delete(`${NEST_SERVER_URL}/board/${boardType}/${_id}`)
        .then(function (response) {
          loadingHandler(false);
          response && setReload(!reload);
        })
        .catch(function (error) {
          console.log('error', error);
          alert('삭제 실패');
          loadingHandler(false);
        });
    }
  };

  return (
    <>
      <div className="page-title">
        <div className="title_left board_title ">
          <h3 className="pull-left">
            <i className="fa fa-cubes" /> 클레임 , Q&A 게시판 관리
          </h3>
          <div className="pull-right board_search py-3">
            <div className="board_id_search pe-4">
              <h5>고객 ID 검색</h5>
              <div className="board_id_search_input">
                <input
                  type="text"
                  value={userIdsearch}
                  onChange={(e) => idSearchHandle(e)}
                />
                <div
                  className="board_id_search_btn board_search_btn"
                  onClick={idSearchClick}
                >
                  검색
                </div>
              </div>
            </div>
            <div className="board_search board_date_search">
              <div className="board_date_search_wrap">
                <h5>날짜 검색</h5>
                <div className="date_search_picker date_start_picker">
                  <DatePicker
                    selected={
                      dateCal === false ? undefined : new Date(dateCustom.start)
                    }
                    onChange={(date: Date | null) => {
                      ChangeDate('start', date);
                    }}
                    fixedHeight
                    selectsStart
                    locale={ko}
                    dateFormat={'yyyy-MM-dd'}
                  />
                </div>
                <span className="date_search_wave">~</span>

                <div className="date_search_picker date_end_picker">
                  <DatePicker
                    selected={
                      dateCal === false ? undefined : new Date(dateCustom.end)
                    }
                    onChange={(date: Date | null) => {
                      ChangeDate('end', date);
                    }}
                    fixedHeight
                    selectsEnd
                    locale={ko}
                    dateFormat={'yyyy-MM-dd'}
                  />
                </div>
                <div className="board_search_btn" onClick={() => confirmDate()}>
                  검색
                </div>
              </div>
            </div>
          </div>
          <div className="clear-fix" />
        </div>
      </div>
      <div className="clearfix" />

      <div className="col-12">
        <div className="contents-wrap x_panel mb-2 boardfilter_wrap">
          <div className="groupFilter col-6">
            <h5>사이트필터</h5>
            <div className="groupFilter-wrap orange">
              <div className="groupFilter-inner">
                <div
                  className={`groupFilter-item ${
                    siteFilterNow == 'all' ? 'active' : ''
                  }`}
                  onClick={() => changeSiteFilter('all')}
                >
                  전체
                </div>
                <div
                  className={`groupFilter-item ${
                    siteFilterNow == 'iamdesign' ? 'active' : ''
                  }`}
                  onClick={() => changeSiteFilter('iamdesign')}
                >
                  아이엠 디자인
                </div>
                <div
                  className={`groupFilter-item ${
                    siteFilterNow == 'speedcolor' ? 'active' : ''
                  }`}
                  onClick={() => changeSiteFilter('speedcolor')}
                >
                  스피드칼라
                </div>
              </div>
            </div>
          </div>
          <div className="groupFilter col-6">
            <h5>게시판 필터</h5>
            <div className="groupFilter-wrap orange">
              <div className="groupFilter-inner">
                <div
                  className={`groupFilter-item ${
                    boardFilterNow == '전체' ? 'active' : ''
                  }`}
                  onClick={() => changeBoardFilter('')}
                >
                  전체
                </div>
                <div
                  className={`groupFilter-item ${
                    boardFilterNow == 'claim' ? 'active' : ''
                  }`}
                  onClick={() => changeBoardFilter('claim')}
                >
                  클레임 게시판
                </div>
                <div
                  className={`groupFilter-item ${
                    boardFilterNow == 'qna' ? 'active' : ''
                  }`}
                  onClick={() => changeBoardFilter('qna')}
                >
                  Q&A게시판
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contents-wrap x_panel">
          <div className="x_content setboard-table-wrap">
            <div className="x_content site-table-wrap">
              {boardList && (
                <div className="react-bootstrap-table">
                  <table className="table table-hover table-bordered GW-cont-head boardget_table">
                    <thead>
                      <tr>
                        <th>문의사이트</th>
                        <th>고객ID</th>
                        <th>게시판종류</th>
                        <th>문의유형</th>
                        <th className="answerth">내용</th>
                        <th>문의일자</th>
                        <th>답변상태</th>
                        <th>처리여부</th>
                        <th>ACTION</th>
                        <th>삭제</th>
                      </tr>
                    </thead>
                    <tbody>
                      {boardList.length > 0 ? (
                        boardList.map((a, idxA) => {
                          let siteUrl =
                            a.applySite == 'printcity'
                              ? 'https://www.printcity.co.kr/'
                              : a.applySite == 'ecard21'
                              ? 'https://www.ecard21.co.kr/'
                              : a.applySite == 'iamdesign'
                              ? 'https://www.iamdgn.com/'
                              : a.applySite == 'megaprint'
                              ? 'https://www.megaprint.co.kr/'
                              : a.applySite == 'speedcolor'
                              ? 'https://www.speedcolor.co.kr/'
                              : a;
                          return (
                            <tr
                              key={idxA}
                              onClick={() => EditBoard}
                              className="answertr"
                            >
                              <td>
                                <a href={String(siteUrl)} target="blink">
                                  {a.applySite}
                                </a>
                              </td>
                              <td>{a.userId}</td>
                              <td>
                                {a.boardType === 'qna' ? 'Q&A' : '클래임문의'}
                              </td>
                              <td>{a.inquiry}</td>
                              <td>
                                {boardFilterNow === '전체' ? (
                                  <>
                                    {a.claimRequest && (
                                      <>
                                        <span className="blue">
                                          {a.claimRequest}
                                        </span>
                                        {' | '}
                                      </>
                                    )}
                                    {a.claimType && (
                                      <>
                                        <span className="blue">
                                          {a.claimType}
                                        </span>
                                      </>
                                    )}
                                    {a.title && (
                                      <>
                                        <span className="blue">{a.title}</span>
                                      </>
                                    )}
                                  </>
                                ) : boardFilterNow === 'claim' ? (
                                  <>
                                    <span className="blue">
                                      {a.claimRequest}
                                    </span>
                                    {' | '}
                                    <span className="blue">{a.claimType}</span>
                                  </>
                                ) : (
                                  <span className="blue">{a.title}</span>
                                )}
                              </td>
                              <td>
                                {moment(a.createdAt).format(
                                  'YYYY년 MM월 DD일 HH시 mm분',
                                )}
                              </td>
                              <td>
                                {a.boardAnswer === undefined ? (
                                  <span className="deskSpan">답변전</span>
                                ) : (
                                  <span className="deskSpan pink">
                                    답변완료
                                  </span>
                                )}
                              </td>
                              <td>
                                {a.boardType && a.boardType === 'claim'
                                  ? a.actionType === undefined
                                    ? '처리전'
                                    : a.actionType
                                  : 'Q&A는 해당 없음'}
                              </td>
                              <td>
                                {a.boardAnswer === undefined ? (
                                  <button className="tableBtn tableBtn-skyblue btnW-70 setPriceDelEvents setpriceBtn">
                                    <span className="mobileSpan">
                                      <i
                                        className="fa fa-times"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                    <span
                                      className="deskSpan"
                                      onClick={() =>
                                        EditBoard(a._id, a.boardType)
                                      }
                                    >
                                      답변하기
                                    </span>
                                  </button>
                                ) : (
                                  <button className="tableBtn tableBtn-pink btnW-70 setPriceDelEvents setpriceBtn">
                                    <span className="mobileSpan">
                                      <i
                                        className="fa fa-times"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                    <span
                                      className="deskSpan"
                                      onClick={() =>
                                        EditBoard(a._id, a.boardType)
                                      }
                                    >
                                      답변확인
                                    </span>
                                  </button>
                                )}
                              </td>
                              <td>
                                <button className="tableBtn tableBtn-red btnW-70 setPriceDelEvents setpriceBtn">
                                  <span
                                    onClick={() =>
                                      boardDelete(a.boardType, a._id)
                                    }
                                  >
                                    삭제하기
                                  </span>
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={10} className="boardNodata">
                            데이터가 없습니다.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="boardPagination">
          {item && item.length > 0 && (
            <BoardPagination
              paging={paging}
              setPaging={setPaging}
              pageSize={pageSize}
              pageLength={totalPage}
            />
          )}
        </div>
      </div>
      <div className="boardModal">
        {boardModal && (
          <ModalEmpty close={closeModal} id={'boardlist'}>
            <BardAnswerModal
              modalId={modalId}
              boardType={boardType}
              closeModal={closeModal}
            />
          </ModalEmpty>
        )}
      </div>
    </>
  );
}
