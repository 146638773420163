import { useState } from 'react';
import axios from 'axios';
import { useLoading } from '@config/loadingContext';
import { NEST_SERVER_URL } from '@config/path';

interface Props {
  couponSite?: string;
  reload: boolean;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
  onLoad: (v: boolean) => void;
}

export default function CouponSetting({
  couponSite,
  reload,
  setReload,
  onLoad,
}: Props) {
  const { loadingNow, loadingHandler } = useLoading();

  /** 쿠폰 개수 설정 */
  const [couponQuantity, setCouponQuantity] = useState<number>(100);

  const couponQtyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setCouponQuantity(Number(value));
  };

  /** 만료날짜 설정 */

  const today = new Date();

  const [form, setForm] = useState({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate(),
  });

  let year = [];
  for (let m = 2024; m <= 2050; m += 1) {
    year.push(m.toString());
  }

  let month = [];
  for (let m = 1; m <= 12; m += 1) {
    month.push(m.toString());
  }

  let days = [];
  let date = new Date(form.year, form.month, 0).getDate();
  for (let d = 1; d <= date; d += 1) {
    days.push(d.toString());
  }

  const coupon_make = async () => {
    loadingHandler(true);
    let couponCopy = {
      amount: couponQuantity,
      expiration: `${form.year}-${form.month}-${form.day}`,
      siteCode: couponSite,
    };
    await axios
      .post(`${NEST_SERVER_URL}/event-coupon`, couponCopy)
      .then((res) => {
        loadingHandler(false);
        setReload(!reload);
        alert('쿠폰생성 완료');
        couponQuantity === 0 && setCouponQuantity(100);
      })
      .catch((err) => {
        loadingHandler(false);
        console.log('fail', err);
        alert('쿠폰생성 실패');
      });
  };

  return (
    <>
      <div id="reorder-wrap" className="couponsetting-wrap">
        <div className="reorder-inner">
          <div className="reorder-cont">
            <h3>쿠폰 생성하기</h3>
          </div>
          <div className="coupon_enddate">
            <div className="form-group">
              <div className="row">
                <label className="cost-title col-md-3">만료날짜</label>
                <div className="cost-cont cost-cont col-md-9">
                  <select
                    name="year"
                    id="year"
                    className="coupon_date_select"
                    value={form.year}
                    onChange={(e) =>
                      setForm({ ...form, year: Number(e.target.value) })
                    }
                  >
                    {year.map((a) => {
                      return (
                        <option value={a} key={a}>
                          {a}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    name="month"
                    id="month"
                    className="coupon_date_select"
                    value={form.month}
                    onChange={(e) =>
                      setForm({ ...form, month: Number(e.target.value) })
                    }
                  >
                    {month.map((a) => {
                      return (
                        <option value={a} key={a}>
                          {a}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    name="day"
                    id="day"
                    className="coupon_date_select"
                    value={form.day}
                    onChange={(e) =>
                      setForm({ ...form, day: Number(e.target.value) })
                    }
                  >
                    {days.map((a, i) => {
                      return (
                        <option value={a} key={a}>
                          {a}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <label className="cost-title col-md-3">쿠폰수량</label>
                <div className="cost-cont cost-cont col-md-9">
                  <input
                    type="text"
                    value={couponQuantity}
                    onChange={(e) => couponQtyChange(e)}
                    className="coupon_date_select coupon_qty"
                    placeholder="수량입력 안할시 100개 생성"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="yBtn yBtn-xs gray me-2 d-inline-block"
            onClick={() => onLoad(false)}
          >
            닫기
          </div>
          <div
            className="yBtn yBtn-xs blue me-2 d-inline-block"
            onClick={coupon_make}
          >
            쿠폰생성
          </div>
        </div>
      </div>
    </>
  );
}
