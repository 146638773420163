import { Dispatch, useState, SetStateAction } from 'react';
import { FirstInput } from '@interfaces/tableCost.interfaces';
import {
  Draggable,
  Droppable,
  DragDropContext,
  DragUpdate,
} from 'react-beautiful-dnd';
// import ReorderWindow from './reorder/reorder-window';
interface Props {
  firstInputs: FirstInput;
  setFirstInputs: Dispatch<SetStateAction<FirstInput>>;
  onSortOrign: (v: boolean) => void;
}

export default function OriginReorder({
  firstInputs,
  setFirstInputs,
  onSortOrign,
}: Props) {
  const [reOrderInputs, setReOrderInputs] = useState([
    ...firstInputs.selecters,
  ]);
  const reOrderFinish = () => {
    let resetOrder = reOrderInputs.map((a, i) => {
      return (a = { ...a, originally: i });
    });
    let temp = { ...firstInputs };
    temp.selecters = resetOrder;
    setFirstInputs(temp);
    onSortOrign(false);
  };
  const onDragEnd = ({ source, destination }: DragUpdate) => {
    if (destination === undefined || destination === null) return null;
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    )
      return null;
    const newList = reOrderInputs.filter((_, idx) => idx !== source.index);
    newList.splice(destination.index, 0, reOrderInputs[source.index]);
    setReOrderInputs(newList);
    return null;
  };
  return (
    <>
      <div id="reorder-wrap">
        <div className="reorder-inner">
          <div className="reorder-cont">
            <h3>
              주문페이지 옵션
              <span> - 순서변경</span>
            </h3>
            {reOrderInputs && (
              <DragDropContext onDragEnd={onDragEnd}>
                <div className="ReorderStyledWrap mb-3">
                  <Droppable droppableId="reorderId" key="reorderkey">
                    {(provided) => (
                      <div className="StyledColumn">
                        <ul
                          className="StyledList"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {reOrderInputs.map((a, index) => (
                            <Draggable
                              key={a.selecterName}
                              draggableId={a.selecterName}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <li
                                  className={`StyledItem ${
                                    snapshot.isDragging ? 'dragging' : ''
                                  }
                                                            `}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div>{a.selecterName}</div>
                                </li>
                              )}
                            </Draggable>
                          ))}

                          {provided.placeholder}
                        </ul>
                      </div>
                    )}
                  </Droppable>
                </div>
              </DragDropContext>
            )}
          </div>
          <div className="reorder-buttonWrap text-center">
            <div
              className="yBtn yBtn-xs gray me-2 d-inline-block"
              onClick={() => onSortOrign(false)}
            >
              이전으로
            </div>
            <div
              className="yBtn yBtn-md green text-center d-inline-block"
              onClick={() => reOrderFinish()}
            >
              선택완료
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
