import { Dispatch, SetStateAction } from 'react';
import { Order, FirstInput } from '@interfaces/tableCost.interfaces';

export const stringNumberFunc = (stringNumber: string) => {
  return parseFloat(stringNumber.replace(/,/g, ''));
};
export const roundNumberFunc = (rounNumber: number) => {
  return Math.round(rounNumber);
};
export const floorNumberFunc = (value: number, rounNumber: number) => {
  if (rounNumber != 0) {
    return Math.floor(value / (rounNumber * 10)) * (rounNumber * 10);
  } else {
    return value;
  }
};

export const getLatest = (
  firstInputs: FirstInput,
  setLatestInput: Dispatch<SetStateAction<Order[]>>,
  setLatestColor: Dispatch<SetStateAction<Order[]>>,
  setNoPriceInput: Dispatch<SetStateAction<Order[]>>,
) => {
  let orderArray = [...firstInputs.selecters]; //가격순서를 위한 데이터복사
  let colorSliceIndex = orderArray.findIndex(
    (element) =>
      element.kindCode == 'colorCode' || element.kindCode == 'ncrColorCode',
  ); //색상코드 인덱스 확인
  let colorArray = orderArray.splice(colorSliceIndex, 1); //색상코드 인덱스 추출
  let NPSliceIndex = orderArray.filter((element) => element.priceOrder === -1); //가격표필요없는 배열필터
  orderArray = orderArray
    .filter((element) => element.priceOrder !== -1)
    .sort((a, b) => a.priceOrder - b.priceOrder); //배열정보로 Sorting

  let orderCopy = JSON.parse(JSON.stringify(orderArray));
  let colorCopy = JSON.parse(JSON.stringify(colorArray));
  let NPCopy = JSON.parse(JSON.stringify(NPSliceIndex));
  setLatestInput(orderCopy);
  setLatestColor(colorCopy);
  setNoPriceInput(NPCopy);
};

export const idToTime = (id: string) =>
  new Date(parseInt(id.substring(0, 8), 16) * 1000);

export function deleteKey<T>(obj: T, key: string) {
  let index;
  for (let prop in obj) {
    switch (typeof obj[prop]) {
      case 'string':
        index = key.indexOf(prop);
        if (index > -1) {
          delete obj[prop];
        }
        break;
      case 'object':
        index = key.indexOf(prop);
        if (index > -1) {
          delete obj[prop];
        } else {
          deleteKey(obj[prop], key);
        }
        break;
    }
  }
}

export function deleteEverything<T>(obj: T, keys: string[]) {
  keys.forEach((code) => {
    deleteKey<T>(obj, code);
  });
  return obj;
}

export function checkPriceNull(obj: FirstInput): boolean {
  let result = obj.productTypes.some((a) =>
    a.price.some((b) => b.value === null),
  );
  return result;
}
