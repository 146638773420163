import React, { useState, useEffect } from 'react';
import useFetchNoData from '@utils/customHook/useFetchNoData';
import { useLoading } from '@config/loadingContext';
import BoardPagination from '@utils/boardPagination';
import { ICoupon, ICouponData } from '@interfaces/tableCost.interfaces';
import axios from 'axios';
import CouponSetting from '@components/table/standardproduct/reorder/coupon-setting';
import moment from 'moment';
import { SERVER_URL, NEST_SERVER_URL } from '@config/path';
import { ExcelDownloadCoupon } from '@components/excel/download-coupon';

moment.locale('ko');

export default function Setevent() {
  const { loadingNow, loadingHandler } = useLoading();

  const [reload, setReload] = useState<boolean>(false);

  /** 필터 */

  const [useFilter, setUseFilter] = useState<string>('all');

  const changeCuseFilter = async (str: string) => {
    setUseFilter(str === '' ? 'all' : str);
    setReload(true);
  }; // 사용여부

  const [buyFilter, setBuyFilter] = useState<string>('all');

  const changeCbuyFilter = async (str: string) => {
    setBuyFilter(str === '' ? 'all' : str);
  }; // 구매여부

  const [useProductFilter, setUseProductFilter] = useState<string>('all');

  const changeCproducFilter = async (str: string) => {
    setUseProductFilter(str === '' ? 'all' : str);
  }; // 상품필터

  /** 아이디 , 쿠폰번호 검색 */

  //검색 셀렉트
  const [userSelect, setUserSelect] = useState<string>('userId');
  const changeSelect = (val: string) => {
    setUserSelect(val);
    setUseridSearch('');
    setUseridNow('');
    setCouponCodeSearch('');
    setCouponcodeNow('');
  };

  //id 검색용
  const [useridSearch, setUseridSearch] = useState<string>('');

  const [useridNow, setUseridNow] = useState<string>('');

  //쿠폰코드 검색용
  const [couponCodeSearch, setCouponCodeSearch] = useState<string>('');

  const [couponcodeNow, setCouponcodeNow] = useState<string>('');

  //검색버튼 클릭 액션

  const changeIdsearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    userSelect === 'userId'
      ? setUseridSearch(value)
      : setCouponCodeSearch(value);
    setPaging(1);
  };

  const clickIdsearch = () => {
    userSelect === 'userId'
      ? setUseridNow(useridSearch.trim())
      : setCouponcodeNow(couponCodeSearch.replace('-', '').trim());
    setPaging(1);
    setReload(false);
  };

  /** 쿠폰등록 모달 액션 */
  const [couponsetting, setCouponsetting] = useState(false);

  const onLoad = (code: boolean) => {
    setCouponsetting(!couponsetting);
    setPaging(1);
  };

  /** 사이트 설정 */

  const [couponSite, setCouponSite] = useState<string>('iamdesign'); //사이트 코드 담는곳

  /** 쿠폰리스트 부르기 */

  const [paging, setPaging] = useState<number>(1); //시작 페이지

  const payloadURL = `event-coupon?page=${paging}&limit=20&partialMatch=true${
    useridNow || couponcodeNow !== ''
      ? userSelect === 'userId'
        ? '&userId=' + useridNow
        : '&couponCode=' + couponcodeNow
      : ''
  }${
    useFilter === 'all'
      ? ''
      : useFilter === 'used'
      ? '&isUsed=true'
      : '&isUsed=false'
  }${
    buyFilter === 'all'
      ? ''
      : buyFilter === 'used'
      ? '&isPaid=true'
      : '&isPaid=false'
  }${useProductFilter !== 'all' ? '&productName=' + useProductFilter : ''}`;

  const { payload, error } = useFetchNoData<ICoupon>(
    payloadURL,
    useFilter + String(reload),
  );

  /** 페이징 */

  const pageSize = 10; //최대 페이지

  const item = payload?.data;

  const totalPage = Math.ceil(payload ? payload.total / 20 : 0);

  /** 쿠폰 한개 삭제 */

  const couponDel = async (couponid?: string) => {
    loadingHandler(true);
    if (payload) {
      confirm('삭제 하시겠습니까?');
      await axios
        .delete(`${NEST_SERVER_URL}/event-coupon/${couponid}`)
        .then((res) => {
          console.log(res);
          setReload(true);
        })
        .catch((err) => {
          console.log(err);
          alert('삭제실패');
        });
      loadingHandler(false);
    }
  };

  /** 쿠폰 여러개 삭제 */
  //당장 사용하는건 아님

  const [couponsDel, setCouponsDel] = useState<string[]>([]);

  const changeDelinput = (checked: boolean, id: string) => {
    if (checked) {
      setCouponsDel((prev) => [...prev, id]);
    } else {
      setCouponsDel(couponsDel.filter((a) => a !== id));
    }
    return;
  };

  const changeDelinputAll = (checked: boolean) => {
    if (checked) {
      const checkdeArr: string[] = [];
      payload?.data
        .map((a) => {
          return a._id;
        })
        .forEach((b) => checkdeArr.push(b));
      setCouponsDel(checkdeArr);
    } else {
      setCouponsDel([]);
    }
  };

  return (
    <>
      <div className="page-title">
        <div className="title_left">
          <div className="row">
            <h3 className="col-4">
              <i className="fa fa-cubes" /> 이벤트설정{' '}
              <small>일러스트페어 쿠폰</small>
            </h3>
            <div className=" col-4 coupon_id_search">
              <select
                className="couponsearchselect"
                name=""
                id=""
                onChange={(e) => changeSelect(e.target.value)}
              >
                <option value="userId">고객 id검색</option>
                <option value="couponCode">쿠폰번호 검색</option>
              </select>
              <div className="coupon_id_search_input">
                <input
                  value={
                    userSelect === 'userId' ? useridSearch : couponCodeSearch
                  }
                  onChange={(e) => changeIdsearch(e)}
                  type="text"
                  placeholder={
                    userSelect === 'userId'
                      ? '검색할 아이디를 입력하세요.'
                      : '검색할 쿠폰번호를 입력하세요.'
                  }
                />
                <div className="coupon_id_search_btn" onClick={clickIdsearch}>
                  검색
                </div>
              </div>
            </div>
            <div className="col-4 coupon_setting">
              <button
                className="topButtons blue"
                onClick={() => onLoad(!couponsetting)}
              >
                쿠폰생성
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="clearfix" />
      <div className="row">
        <div className="contents-wrap x_panel mb-2 boardfilter_wrap couponfilter_wrap">
          <div className="groupFilter col-3">
            <h5>사용여부</h5>
            <div className="groupFilter-wrap orange">
              <div className="groupFilter-inner">
                <div
                  className={`groupFilter-item ${
                    useFilter == 'all' ? 'active' : ''
                  }`}
                  onClick={() => changeCuseFilter('all')}
                >
                  전체
                </div>
                <div
                  className={`groupFilter-item ${
                    useFilter == 'used' ? 'active' : ''
                  }`}
                  onClick={() => changeCuseFilter('used')}
                >
                  사용
                </div>
                <div
                  className={`groupFilter-item ${
                    useFilter == 'unused' ? 'active' : ''
                  }`}
                  onClick={() => changeCuseFilter('unused')}
                >
                  미사용
                </div>
              </div>
            </div>
          </div>
          <div className="groupFilter col-3">
            <h5>구매여부</h5>
            <div className="groupFilter-wrap orange">
              <div className="groupFilter-inner">
                <div
                  className={`groupFilter-item ${
                    buyFilter == 'all' ? 'active' : ''
                  }`}
                  onClick={() => changeCbuyFilter('all')}
                >
                  전체
                </div>
                <div
                  className={`groupFilter-item ${
                    buyFilter == 'used' ? 'active' : ''
                  }`}
                  onClick={() => changeCbuyFilter('used')}
                >
                  구매
                </div>
                <div
                  className={`groupFilter-item ${
                    buyFilter == 'unused' ? 'active' : ''
                  }`}
                  onClick={() => changeCbuyFilter('unused')}
                >
                  미구매
                </div>
              </div>
            </div>
          </div>
          <div className="groupFilter col-6">
            <h5>제품분류</h5>
            <div className="groupFilter-wrap orange">
              <div className="groupFilter-inner">
                <div
                  className={`groupFilter-item ${
                    useProductFilter === 'all' ? 'active' : ''
                  }`}
                  onClick={() => changeCproducFilter('all')}
                >
                  전체
                </div>
                <div
                  className={`groupFilter-item ${
                    useProductFilter === '이벤트 포토카드' ? 'active' : ''
                  }`}
                  onClick={() => changeCproducFilter('이벤트 포토카드')}
                >
                  포토카드
                </div>
                <div
                  className={`groupFilter-item ${
                    useProductFilter === '이벤트 판스티커' ? 'active' : ''
                  }`}
                  onClick={() => changeCproducFilter('이벤트 판스티커')}
                >
                  판스티커
                </div>
                <div
                  className={`groupFilter-item ${
                    useProductFilter === '이벤트 디자인포토판스티커'
                      ? 'active'
                      : ''
                  }`}
                  onClick={() =>
                    changeCproducFilter('이벤트 디자인포토판스티커')
                  }
                >
                  디자인포토판스티커
                </div>
                <div
                  className={`groupFilter-item ${
                    useProductFilter === '이벤트 포토코스터' ? 'active' : ''
                  }`}
                  onClick={() => changeCproducFilter('이벤트 포토코스터')}
                >
                  포토코스터
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contents-wrap x_panel">
          <div className="x_content getProduct-table-wrap productlist-table-wrap">
            <div className="react-bootstrap-table">
              <table className="table table-hover table-bordered coupon_table">
                <thead>
                  <tr>
                    <th className="coupon_num">쿠폰번호</th>
                    <th className="coupon_enddate">만료날짜</th>
                    <th className="coupon_id">유저ID</th>
                    <th>제품</th>
                    <th className="coupon_whether">사용여부</th>
                    <th>사용날짜</th>
                    <th className="coupon_whether">구매여부</th>
                    <th>구매날짜</th>
                    <th className="coupon_del">삭제</th>
                    {/* <th className="coupon_delchoice">
                      <span>전체</span>
                      <input
                        type="checkbox"
                        onChange={(e) => changeDelinputAll(e.target.checked)}
                      />
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {payload && payload.data.length > 0 ? (
                    payload.data.map((a, i) => {
                      let endDate = moment(a.expirationDate).format(
                        'YYYY-MM-DD',
                      );
                      let useDate = moment(a.usedAt).format('YYYY-MM-DD');
                      let paidDate = moment(a.paidAt).format('YYYY-MM-DD');
                      return (
                        <tr key={i}>
                          <td>
                            {a.couponCode.slice(0, 4)}-
                            {a.couponCode.slice(4, 8)}{' '}
                          </td>
                          <td>{endDate}</td>
                          <td
                            className={`${
                              a.userId && a.userId !== '' ? 'on' : 'off'
                            }`}
                          >
                            {a.userId && a.userId !== ''
                              ? a.userId
                              : '할당 ID 없음'}
                          </td>
                          <td
                            className={`${
                              a.productName && a.productName !== ''
                                ? 'on'
                                : 'off'
                            }`}
                          >
                            {a.productName && a.productName !== ''
                              ? a.productName.replace('이벤트 ', '')
                              : '구매상품 없음'}
                          </td>
                          <td className={`${a.isUsed === true ? 'on' : 'off'}`}>
                            {a.isUsed === true ? '사용' : '미사용'}
                          </td>
                          <td
                            className={`${
                              a.usedAt && a.usedAt !== undefined ? 'on' : 'off'
                            }`}
                          >
                            {a.usedAt && a.usedAt !== undefined
                              ? useDate
                              : '사용 날짜 없음'}
                          </td>
                          <td className={`${a.isPaid === true ? 'on' : 'off'}`}>
                            {a.isPaid === true ? '구매' : '미구매'}
                          </td>
                          <td
                            className={`${
                              a.paidAt && a.paidAt !== undefined ? 'on' : 'off'
                            }`}
                          >
                            {a.paidAt && a.paidAt !== undefined
                              ? paidDate
                              : '구매 날짜 없음'}
                          </td>
                          <td>
                            <button
                              onClick={() => couponDel(a._id)}
                              className="tableBtn tableBtn-red btnW-70"
                            >
                              삭제
                            </button>
                          </td>
                          {/* <td>
                            <input
                              type="checkbox"
                              checked={
                                couponsDel.includes(a._id) ? true : false
                              }
                              onChange={(e) =>
                                changeDelinput(e.target.checked, a._id)
                              }
                            />
                          </td> */}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={9} className="couponNodata">
                        데이터가 없습니다.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="couponLise_down">
          {payload && payload.data.length > 0 ? (
            <button className="topButtons blue couponLise_down_btn">
              {payload && (
                <ExcelDownloadCoupon
                  useFilter={useFilter}
                  buyFilter={buyFilter}
                  useridNow={useridNow}
                  useProductFilter={useProductFilter}
                />
              )}
            </button>
          ) : (
            <button className="topButtons blue couponLise_down_btn">
              다운로드 받을 데이터가 없습니다.
            </button>
          )}
        </div>
        <div className="boardPagination">
          {item && item.length > 0 && (
            <BoardPagination
              paging={paging}
              setPaging={setPaging}
              pageSize={pageSize}
              pageLength={totalPage}
            />
          )}
        </div>
      </div>
      {couponsetting && (
        <CouponSetting
          couponSite={couponSite}
          reload={reload}
          setReload={setReload}
          onLoad={onLoad}
        />
      )}
    </>
  );
}
