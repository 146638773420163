import { Dispatch, useState, SetStateAction } from 'react';
import {
  ICommonEachCalculation,
  NewCalcSelect,
} from '@interfaces/calculationProductModel.interface';
import {
  Draggable,
  Droppable,
  DragDropContext,
  DragUpdate,
} from 'react-beautiful-dnd';
// import ReorderWindow from './reorder/reorder-window';
interface Props {
  newItem: ICommonEachCalculation;
  setNewItem: Dispatch<SetStateAction<ICommonEachCalculation>>;
  reOrderColumns: NewCalcSelect[];
  setReOrderColumns: Dispatch<SetStateAction<NewCalcSelect[]>>;
  setReOrder: Dispatch<SetStateAction<boolean>>;
  reOrderIndex: number[];
}

export default function CalcReorder({
  newItem,
  setNewItem,
  reOrderColumns,
  setReOrderColumns,
  setReOrder,
  reOrderIndex,
}: Props) {
  const reOrderFinish = () => {
    let temp = { ...newItem };
    let result = temp.calcValue.map((codeA, idxA) => {
      if (idxA === reOrderIndex[0]) {
        return {
          ...codeA,
          adminSelecters: codeA.adminSelecters?.map((codeB, idxB) => {
            if (idxB === reOrderIndex[1]) {
              return { ...codeB, select: reOrderColumns };
            } else {
              return codeB;
            }
          }),
        };
      } else {
        return codeA;
      }
    });
    // temp.calcValue[reOrderIndex[0]].adminSelecters&&temp.calcValue[reOrderIndex[0]].adminSelecters[reOrderIndex[1]].select =
    //   reOrderColumns;
    setNewItem({ ...newItem, calcValue: result });
    setReOrder(false);
  };
  const onDragEnd = ({ source, destination }: DragUpdate) => {
    if (destination === undefined || destination === null) return null;
    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    )
      return null;
    const newList = reOrderColumns.filter((_, idx) => idx !== source.index);
    newList.splice(destination.index, 0, reOrderColumns[source.index]);
    setReOrderColumns(newList);
    return null;
  };
  return (
    <>
      <div id="reorder-wrap">
        <div className="reorder-inner">
          <div className="reorder-cont">
            <h3>
              계산식 옵션
              <span> - 순서변경</span>
            </h3>
            {reOrderColumns && (
              <DragDropContext onDragEnd={onDragEnd}>
                <div className="ReorderStyledWrap mb-3">
                  <Droppable droppableId="reorderId" key="reorderkey">
                    {(provided) => (
                      <div className="StyledColumn">
                        <ul
                          className="StyledList"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {reOrderColumns.map((a, index) => (
                            <Draggable
                              key={a.code}
                              draggableId={a.code}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <li
                                  className={`StyledItem ${
                                    snapshot.isDragging ? 'dragging' : ''
                                  }
                                                            `}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div>{a.title}</div>
                                </li>
                              )}
                            </Draggable>
                          ))}

                          {provided.placeholder}
                        </ul>
                      </div>
                    )}
                  </Droppable>
                </div>
              </DragDropContext>
            )}
          </div>
          <div className="reorder-buttonWrap text-center">
            <div
              className="yBtn yBtn-xs gray me-2 d-inline-block"
              onClick={() => setReOrder(false)}
            >
              이전으로
            </div>
            <div
              className="yBtn yBtn-md green text-center d-inline-block"
              onClick={() => reOrderFinish()}
            >
              선택완료
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
