import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { Delta } from 'quill'; // Delta 타입 임포트

interface TexteditorProps {
  newedit: string;
  noticeChange: (
    event: { target: { name: string; value: string } },
    str: string,
  ) => void;
  descriptiondata?: string;
}

// 사용자 정의 툴바 옵션 설정
const modules = {
  toolbar: [
    [{ size: [] }],
    [{ color: [] }, { background: [] }], // 텍스트 색상 및 배경 색상 옵션
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    [{ align: 'center' }, { align: 'right' }],
  ],
};

const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'color',
  'background',
  'align',
];

function Texteditor({
  noticeChange,
  descriptiondata,
  newedit,
}: TexteditorProps) {
  const [value, setValue] = useState<string>(
    descriptiondata ? descriptiondata : '',
  );

  useEffect(() => {
    if (descriptiondata) setValue(descriptiondata);
  }, [descriptiondata]);

  const handleChange = (content: string, delta: Delta, source: string) => {
    setValue(content);
    noticeChange({ target: { name: 'description', value: content } }, newedit);
  };

  return (
    <React.Fragment>
      <div>
        <ReactQuill
          value={value}
          onChange={handleChange}
          modules={modules}
          formats={formats}
        />
      </div>
    </React.Fragment>
  );
}

export default Texteditor;
