import React, { useState, useEffect } from 'react';
import { useFetchMega } from '@utils/customHook/useFetchCustom';
import CountUp from 'react-countup';
import moment from 'moment';
import {
  IHomeData,
  ISiteTotalSales,
  ICustomDate,
} from '@interfaces/home.interface';
import DatePicker, { registerLocale } from 'react-datepicker';
import ko from 'date-fns/locale/ko';
registerLocale('ko', ko);
interface IProps {
  customType: string;
  customDate: ICustomDate;
  ChangeDate: (types: string, date: Date | null, kind?: string) => void;
}
export default function homeComponentCustom({
  customType,
  customDate,
  ChangeDate,
}: IProps) {
  const present = new Date(); //현재
  const TodayStart = moment(present.setHours(0, 0, 0, 0)).format(
    'yyyy-MM-DDTHH:mm',
  );
  const TodayNow = moment(present).format('yyyy-MM-DDTHH:mm');
  const [customSetting, setCustomSetting] = useState<boolean>(false);
  const changeCustomSetting = () => {
    setCustomSetting(!customSetting);
  };

  const [customDateFixed, setCustomDateFixed] = useState<ICustomDate>({
    from: TodayStart,
    to: TodayNow,
  });
  const { payload: totalCustom } = useFetchMega<ISiteTotalSales>(
    `api/StatApi/SalesSummary?from=${moment(customDateFixed.from).format(
      'yyyy-MM-DDTHH:mm',
    )}&to=${moment(customDateFixed.to).format(
      'yyyy-MM-DDTHH:mm',
    )}&orderStatuses=&dataMakingKinds=&metaProductIds=&isRework=false`,
    customDateFixed.from + customDateFixed.to,
  );
  const [customValue, setCustomValue] = useState<number>();

  const duration = Math.floor(
    moment
      .duration(moment(customDateFixed.to).diff(moment(customDateFixed.from)))
      .asHours(),
  );
  const customHours = duration < 1 ? 1 : duration;
  useEffect(() => {
    if (totalCustom) {
      setCustomValue(totalCustom.totalSalesAmount);
    }
  }, [totalCustom]);

  const confirmCustomDate = () => {
    setCustomDateFixed({ ...customDate });
    setCustomSetting(false);
  };
  return (
    <>
      <div className="salesCard-text">
        {!customValue ? (
          <>
            <div className="customDateSelect">
              <div
                className="customDateSelect-btn"
                onClick={() => changeCustomSetting()}
              >
                {customType === 'byDay' ? '날짜' : '시간'} 선택
              </div>
            </div>
            {customSetting && customType && (
              <div className="customDate-wrap">
                <div className="customDate-title">
                  {customType === 'byDay' ? '날짜' : '시간'} 선택
                </div>
                <div className="customDate-label customDate-label-left">
                  From
                </div>
                <div className="rightPicker">
                  <DatePicker
                    selected={
                      customDate.from ? new Date(customDate.from) : new Date()
                    }
                    onChange={(date: Date | null) => {
                      ChangeDate('from', date, customType);
                    }}
                    showPopperArrow={false}
                    fixedHeight
                    selectsStart
                    locale={ko}
                    showTimeSelect={customType === 'byTime'}
                    timeFormat="HH:mm"
                    timeCaption="시작시간"
                    dateFormat={
                      customType === 'byDay' ? 'yyyy-MM-dd' : 'yy-MM-dd (HH:mm)'
                    }
                  />
                </div>
                <span className="date-m">~</span>

                <div className="customDate-label customDate-label-right">
                  To
                </div>
                <DatePicker
                  selected={
                    customDate.to ? new Date(customDate.to) : new Date()
                  }
                  onChange={(date: Date | null) => {
                    ChangeDate('to', date, customType);
                  }}
                  fixedHeight
                  selectsEnd
                  locale={ko}
                  showTimeSelect={customType === 'byTime'}
                  timeFormat="HH:mm"
                  timeCaption="종료시간"
                  dateFormat={
                    customType === 'byDay' ? 'yyyy-MM-dd' : 'yy-MM-dd (HH:mm)'
                  }
                />
                <div className="clearfix" />
                <div
                  className="yBtn yBtn-xs blue  d-inline-block text-center pull-right mt-3"
                  onClick={() => confirmCustomDate()}
                >
                  확인
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="salesCard-head">
            <div className="customDate-display pull-left">
              <p>
                {moment(customDateFixed.from).format(
                  customType === 'byDay' ? 'yyyy-MM-DD' : 'yy-MM-DD (HH:mm)',
                ) +
                  ' ~ ' +
                  moment(customDateFixed.to).format(
                    customType === 'byDay' ? 'yyyy-MM-DD' : 'yy-MM-DD (HH:mm)',
                  )}
              </p>
              {customType === 'byTime' && (
                <p>총 {customHours.toLocaleString()} 시간</p>
              )}
            </div>
            <CountUp
              className="salesCard-amount"
              start={0}
              end={
                customType === 'byDay'
                  ? customValue
                  : Math.floor(customValue / customHours)
              }
              duration={0.5}
              delay={0.25}
              separator=","
            />
            <span className="unit">원</span>
          </div>
        )}
      </div>
    </>
  );
}
