import { ICommonEachCalculation } from '@interfaces/calculationProductModel.interface';
export const CalcDummy: ICommonEachCalculation = {
  productId: [],
  title: '',
  type: 'single', //"single", "multi",
  infoType: 'etc',
  calcValue: [
    {
      adminType: 'singleNumber',
      adminSelecters: [],
      calcTypeKO: '',
      calcTypeEN: '',
      value: 0,
      qty: [],
      option: [],
    },
  ],
};

export const CalcItem = {
  adminType: 'singleNumber',
  adminSelecters: [],
  calcTypeKO: '',
  calcTypeEN: '',
  value: 0,
  qty: [],
  option: [],
};
export const EmptyTable = {
  min: 0,
  max: 0,
  value: 0,
  siteValue: 0,
};
