import { useState, useEffect } from 'react';
import useFetch from '@utils/customHook/useFetch';
import moment from 'moment';
import axios from 'axios';
import { CONFIRM, MESSAGE, ERROR } from '@utils/textMessage';
import { SERVER_URL } from '@config/path';
import { ProductListType } from '@interfaces/tableCost.interfaces';
import Modal from '@components/modal/ModalComp';
import CalcCostModal from '@components/modal/calcCostModal';
import {
  ICommonEachCalculation,
  TableName,
} from '@interfaces/calculationProductModel.interface';
import { CalcDummy, EmptyTable } from '@dummydata/calcDummy';
import 'dayjs/locale/ko';
import { useLoading } from '@config/loadingContext';
import DeleteModal from '@components/modal/deleteModal';

export default function CalcCost() {
  moment.locale();
  /** 로딩 state*/
  const { loadingNow, loadingHandler } = useLoading();
  /** 제품필터 state*/
  const [productFilter, setProductFilter] = useState<ProductListType[]>();
  const [productFilterNow, setProductFilterNow] = useState<string>('전체');
  useEffect(() => {
    loadingHandler(true);
    const getFilterData = async () => {
      await axios
        .get(SERVER_URL + '/product')
        .then(function (response) {
          let copy: ProductListType[] = response.data.data.filter(
            (code: ProductListType) =>
              code.typeOfPriceDetermintion == 'priceCalculation',
          );
          setProductFilter(copy);
          loadingHandler(false);
        })
        .catch(function (error) {
          console.log('메가 데이터 로드에 실패 하였습니다.');
          loadingHandler(false);
        });
    };
    getFilterData();
    changeProductFilter(productFilterNow);
  }, []);
  /** 계산정보 state*/
  /** 계산정보 get */
  const { payload, loading, error } = useFetch<ICommonEachCalculation>(
    `commonEachCalculation`,
    loadingNow,
  );
  const [calcList, setCalcList] = useState<ICommonEachCalculation[]>([]);
  useEffect(() => {
    if (payload) {
      let copy: ICommonEachCalculation[] = payload.data.filter(
        (code) => !code.site,
      );
      setCalcList(copy);
      nsortBy(nsortNow.type, nsortNow.key, copy);
    } else {
      console.log('payload yet!');
    }
    changeProductFilter(productFilterNow);
  }, [payload]);

  /**S:제품필터 */
  const changeProductFilter = (filt: string) => {
    if (payload) {
      let copy = payload.data.filter((code) => !code.site);
      if (filt === '전체') {
        setCalcList(copy);
      } else if (filt === '공통') {
        let result = copy.filter((code) => code.type == 'multi');
        //let copy = payload.data.filter((code)=> code.productId.)
        setCalcList(result);
      } else {
        let result = copy.filter((code) => code.productId.includes(filt));
        setCalcList(result);
      }
      setProductFilterNow(filt);
    } else {
      console.log('payload yet');
    }
  };
  /**E:제품필터 */

  const [newItem, setNewItem] = useState<ICommonEachCalculation>(
    JSON.parse(JSON.stringify(CalcDummy)),
  );
  const [modal, setModal] = useState<{ [index: string]: boolean }>({
    check: false,
    log: false,
    new: false,
    edit: false,
    pushId: false,
  });
  const openModal = (id: string) => {
    setModal({ ...modal, [id]: !modal[id] });
  };
  const closeModal = (target: string) => {
    loadingHandler(true);
    setNewItem(JSON.parse(JSON.stringify(CalcDummy)));
    setNewTable([JSON.parse(JSON.stringify(EmptyTable))]);
    setModal({ ...modal, [target]: false });
    loadingHandler(false);
  };

  /**
   * 기준 상품 수정하는 함수
   * @param productNameEN
   */
  const EditEvents = async (id: string) => {
    loadingHandler(true);
    await axios
      .get(`${SERVER_URL}/commonEachCalculation/${id}`)
      .then((res) => {
        setNewItem(res.data.data);
        setModal({ ...modal, edit: true });
      })
      .catch((error) => window.alert(`${ERROR.SERVER} ${error}`));
    loadingHandler(false);
  };
  /**
   * 기준 상품 삭제하는 함수
   * @param row: db id 값
   */
  const DelEvents = async (id: string) => {
    if (window.confirm(CONFIRM.DEL)) {
      loadingHandler(true);
      await axios
        .delete(`${SERVER_URL}/commonEachCalculation/${id}`)
        .then((res) => {
          res.status === 200 || res.status === 204
            ? window.alert(MESSAGE.DELDONE)
            : window.alert(MESSAGE.CONFIRMAUTH);
        })
        .catch((error) => window.alert(`${ERROR.SERVER} ${error}`));
      loadingHandler(false);
    }
  };
  /**테이블 명 state */
  const [newTable, setNewTable] = useState<TableName[]>([
    JSON.parse(JSON.stringify(EmptyTable)),
  ]);

  /** 테이블 sort 화살표 색상 */

  const [sortColor, setSortColor] = useState<string>('');

  /**테이블 Sort State */

  const [nsortNow, setNsortNow] = useState<{ type: boolean; key: string }>({
    type: false,
    key: 'createdAt',
  });

  /**테이블 Sort Action */

  const [sortselect, setsortselect] = useState<boolean>(true);

  const nsortBy = (
    type: boolean,
    key: string,
    data: ICommonEachCalculation[],
  ) => {
    setsortselect(!sortselect);
    setSortColor(String([key]));
    let copy = [...data];
    copy.sort((a, b) =>
      !type
        ? String(a[key]) < String(b[key])
          ? -1
          : 1
        : String(a[key]) > String(b[key])
        ? -1
        : 1,
    );
    setCalcList(copy);
    setNsortNow({ ...nsortNow, type: type, key: key });
  };

  /** 딜리트 모달 */

  const changeModal = (key: string, bool: boolean) => {
    setModal({ ...modal, [key]: bool });
  };

  const [delID, setDelID] = useState<string>();
  const openDelConfirm = (id: string) => {
    setDelID(id);
    changeModal('delete', true);
  };

  return (
    <>
      <div className="page-title">
        <div className="title_left">
          <h3>
            <i className="fa fa-star" />
            가격계산형 정보 등록<small>가격계산형 정보</small>
            <div className="pull-right">
              <button
                className="topButtons purple"
                onClick={() => openModal('new')}
              >
                신규 정보 등록
              </button>
            </div>
          </h3>
        </div>
      </div>
      <div className="clearfix" />
      <div className="row">
        <div className="col-12">
          <div className="contents-wrap x_panel mb-2">
            <div className="groupFilter">
              <h5>상품 분류</h5>
              <div className="groupFilter-wrap purple">
                <div className="groupFilter-inner">
                  <div
                    className={`groupFilter-item ${
                      productFilterNow == '전체' ? 'active' : ''
                    }`}
                    onClick={() => changeProductFilter('전체')}
                  >
                    전체
                  </div>
                  <div
                    className={`groupFilter-item ${
                      productFilterNow == '공통' ? 'active' : ''
                    }`}
                    onClick={() => changeProductFilter('공통')}
                  >
                    공통
                  </div>
                  {productFilter?.map((filt) => {
                    return (
                      <div
                        key={filt._id}
                        className={`groupFilter-item ${
                          productFilterNow == filt._id ? 'active' : ''
                        }`}
                        onClick={() => changeProductFilter(filt._id)}
                      >
                        {filt.productNameKO}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="contents-wrap x_panel">
            <div className="x_content">
              {calcList && (
                <div className="react-bootstrap-table">
                  <table className="table table-hover table-bordered GW-cont-head">
                    <thead>
                      <tr>
                        <th
                          className={`sortable cont-w150 ${
                            sortColor === 'type' ? 'colorOn' : ''
                          }`}
                          onClick={() => {
                            nsortBy(!sortselect, 'type', calcList);
                          }}
                        >
                          정보종류{' '}
                          <span
                            className={`order-4 ${
                              nsortNow.type === false ? 'setcolor' : 'nocolor'
                            }`}
                          ></span>
                        </th>
                        <th
                          className={`sortable ${
                            sortColor === 'title' ? 'colorOn' : ''
                          }`}
                          onClick={() => {
                            nsortBy(!sortselect, 'title', calcList);
                          }}
                        >
                          정보명{' '}
                          <span
                            className={`order-4 ${
                              nsortNow.type === false ? 'setcolor' : 'nocolor'
                            }`}
                          ></span>
                        </th>
                        <th>연결제품</th>
                        <th
                          className={`sortable cont-w150 ${
                            sortColor === 'createdAt' ? 'colorOn' : ''
                          }`}
                          onClick={() => {
                            nsortBy(!sortselect, 'createdAt', calcList);
                          }}
                        >
                          생성/수정
                          <span
                            className={`order-4 ${
                              nsortNow.type === false ? 'setcolor' : 'nocolor'
                            }`}
                          ></span>
                        </th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {calcList.map((code, idx) => {
                        let time = moment(code.createdAt).format(
                          'YYYY-MM-DD (HH:mm)',
                        );
                        let time2 = moment(code.modifiedAt).format(
                          'YYYY-MM-DD (HH:mm)',
                        );
                        return (
                          <tr key={idx}>
                            <td>
                              {' '}
                              <p className={'calcTypeLabel ' + code.type}>
                                {code.type == 'single'
                                  ? '개별정보'
                                  : '공용정보'}
                              </p>
                            </td>
                            <td>{code.title} </td>
                            <td>
                              <div className="productNameMatch-wrap  d-inline-block">
                                <div className="productNameMatch">
                                  {code.productId.map((ID, idx) => {
                                    let match = productFilter?.find(
                                      (a) => a._id == ID,
                                    )?.productNameKO;
                                    if (idx === 0) {
                                      return (
                                        <span className="1stProduct" key={idx}>
                                          {match ? match : ID}
                                        </span>
                                      );
                                    } else {
                                      return (
                                        <span className="2ndProduct" key={idx}>
                                          , {match ? match : ID}
                                        </span>
                                      );
                                    }
                                  })}
                                </div>

                                <div className="productNameMatch-hover">
                                  {code.productId.map((ID, idx) => {
                                    let match = productFilter?.find(
                                      (a) => a._id == ID,
                                    )?.productNameKO;
                                    return (
                                      <span key={idx}>
                                        {match ? match : ID}
                                      </span>
                                    );
                                  })}
                                  <h5 className="totalProductLength">
                                    - 총 <span>{code.productId.length}</span>개
                                    상품
                                  </h5>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="editDate-wrap">
                                <div className="editDate-btn">보기</div>
                                <div className="editDate-view">
                                  <div className="editDate-line">
                                    <h5>생성:</h5>
                                    <p className="standardproductID">
                                      {code.createdBy}
                                    </p>
                                    <p className="standardproductTIME">
                                      {time}
                                    </p>
                                  </div>
                                  <div className="editDate-line">
                                    <h5>수정:</h5>
                                    <p className="standardproductID">
                                      {code.modifiedBy}
                                    </p>
                                    <p className="standardproductTIME">
                                      {time2}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <button className="tableBtn tableBtn-gray btnW-70">
                                로그보기
                              </button>
                              <button
                                className="tableBtn tableBtn-orange btnW-70"
                                onClick={() => code._id && EditEvents(code._id)}
                              >
                                수정
                              </button>
                              <button
                                className="tableBtn tableBtn-red btnW-70"
                                onClick={() =>
                                  code._id && openDelConfirm(code._id)
                                }
                              >
                                삭제
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {modal.new && (
        <>
          <Modal
            close={closeModal}
            title={'신규 가격계산형 정보등록'}
            id={'new'}
          >
            <CalcCostModal
              calcType="new"
              newItem={newItem}
              setNewItem={setNewItem}
              closeModal={closeModal}
              productFilter={productFilter}
              newTable={newTable}
              setNewTable={setNewTable}
            />
          </Modal>
        </>
      )}
      {modal.edit && (
        <>
          <>
            <Modal close={closeModal} title={'가격계산형 정보수정'} id={'edit'}>
              <CalcCostModal
                calcType="edit"
                newItem={newItem}
                setNewItem={setNewItem}
                closeModal={closeModal}
                productFilter={productFilter}
                newTable={newTable}
                setNewTable={setNewTable}
              />
            </Modal>
          </>
        </>
      )}
      {modal.delete && delID && (
        <DeleteModal
          delID={delID}
          delEvents={DelEvents}
          changeModal={changeModal}
        />
      )}
    </>
  );
}
