export default function noticeIcon() {
  return (
    <>
      <svg
        id="ab71954f-d116-4109-98b7-859627ea4344"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 131.086 131.086"
      >
        <circle
          cx="65.543"
          cy="65.543"
          r="65.543"
          className="disColor"
          style={{ fill: '#c3141b' }}
        />
        <path
          d="M57.248,97.72c.966,2.422-2.142,3.88-3.457,4.289A5.531,5.531,0,0,1,48.2,100.78a12.471,12.471,0,0,1-2.633-3.494c-3.39-6.2-7.8-18.409-7.8-18.409l4.829-.511,7.586.193S51.678,83.753,57.248,97.72Z"
          style={{ fill: '#fff' }}
        />
        <path
          d="M76.447,87.273a42.127,42.127,0,0,0-25.481-5.456L34.842,83.646l-.524-2.222L32.4,64.021l6.076-2.084A57.31,57.31,0,0,0,64.884,43.329c.981-1.242,1.507-2.009,1.507-2.009l9.438,43.124Z"
          style={{ fill: '#fff' }}
        />
        <path
          d="M77.335,88.813a21.707,21.707,0,0,0-6.971-4.461,57.451,57.451,0,0,1-7.147-17.435,56.8,56.8,0,0,1-1.251-20.17A27.166,27.166,0,0,0,66.3,40.219l9.533,44.225Z"
          style={{ fill: '#fff' }}
        />
        <path
          d="M102.665,57.593c4.289,18.151-.458,34.809-10.6,37.206S70.215,84.425,65.926,66.274s.459-34.81,10.6-37.208S98.376,39.441,102.665,57.593Z"
          style={{ fill: '#fff' }}
        />
        <path
          className="disColor"
          d="M90.291,89.827a7.655,7.655,0,0,1-.96-.062c-6.489-.812-15.394-9.721-19.12-24.633C66.26,49.316,70.689,36.4,77.3,34.26a5.186,5.186,0,0,1,.54-.148,7.2,7.2,0,0,1,.911-.14c.191-.016.387-.025.582-.025,6.837,0,16.216,9.239,20.076,24.7,3.028,12.116.924,21.591-2.055,26.417-1.568,2.544-3.5,4.134-5.58,4.6a6.544,6.544,0,0,1-.907.14Q90.587,89.827,90.291,89.827Z"
          style={{ fill: '#c3141b' }}
        />
        <path
          d="M80.939,69.475,73.71,72.942l-1.9.915c-.408-.927-.936-1.9-1.307-2.888A52.649,52.649,0,0,1,68.716,65.6a51.592,51.592,0,0,1-1.307-7.653l2.3-.269,8.2-1,.6,2.558L80.235,66.5l.1.417Z"
          style={{ fill: '#fff' }}
        />
        <path
          d="M83.1,62.209c.835,3.534-.13,6.786-2.156,7.265s-4.345-2-5.18-5.531.131-6.786,2.157-7.265S82.263,58.676,83.1,62.209Z"
          style={{ fill: '#fff' }}
        />
        <path
          className="disColor"
          d="M81.187,62.661c.4,1.692-.063,3.251-1.033,3.48s-2.082-.957-2.482-2.65.063-3.251,1.033-3.48S80.787,60.968,81.187,62.661Z"
          style={{ fill: '#c3141b' }}
        />
        <path
          d="M46.459,82.328,36.144,83.689a6.6,6.6,0,0,1-6.335-2.245,12.244,12.244,0,0,1-2.687-5.8c-1.309-6.426,2.227-10.9,5.273-11.626l4.344-1.489,4.389-1.579c-2.269,2.042-3.154,6.8-1.971,11.8a17.136,17.136,0,0,0,3.3,6.943,10.154,10.154,0,0,0,.7.773A6.529,6.529,0,0,0,46.459,82.328Z"
          style={{ fill: '#fff' }}
        />
      </svg>
    </>
  );
}
