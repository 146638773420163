import Axios from 'axios';
import { useState, useEffect } from 'react';
import { NEST_SERVER_URL, SERVER_URL } from '@config/path';
export default function useFetchNoData<T>(
  url: string,
  changeElement?:
    | number
    | string
    | (number | string | boolean)[]
    | boolean[]
    | boolean,
): {
  payload: T | null;
  loading: boolean;
  error: string;
} {
  const [payload, setPayload] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const callUrl = async () => {
    await Axios.get(`${NEST_SERVER_URL}/${url}`)
    .then((res) => {
      setPayload(res.data);
      setError(String(res.status));
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        setError(String(error.response.status));
        setPayload(null);
      } else {
        console.error(error);
        setError('기타 에러');
      }
    });
    setLoading(false);
  };
  useEffect(() => {
    callUrl();
  }, [url, changeElement]);
  return { payload, loading, error };
}
