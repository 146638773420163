import { Dispatch, useState, SetStateAction } from 'react';
import {
  FirstInput,
  SecondColumns,
  initialColumnsType,
  LetCode,
} from '@interfaces/tableCost.interfaces';
import RestricStepOne from '@components/table/standardproduct/restriction/restric-step-one';
import RestricStepTwo from '@components/table/standardproduct/restriction/restric-step-two';
import RestricList from '@components/table/standardproduct/restriction/restric-list';
import { MESSAGE } from '@utils/textMessage';
interface Props {
  firstInputs: FirstInput;
  setFirstInputs: Dispatch<SetStateAction<FirstInput>>;
  restrict: boolean;
  setRestrict: Dispatch<SetStateAction<boolean>>;
  NewClass: LetCode;
}
type Step = 'restictionStepOne' | 'restictionStepTwo';

export default function Restriction({
  firstInputs,
  setFirstInputs,
  restrict,
  setRestrict,
  NewClass,
}: Props) {
  const [restiction, setRestiction] = useState<Step>('restictionStepOne');
  // const paper = [...firstInputs.selectors].filter((a) => {
  //   return a.types[0].code.startsWith('MAT');
  // })[0].selecterName;
  const [POLL, setPOLL] = useState('');
  // const [readyPOLL, setReadyPOLL] = useState<boolean>(false);
  const initialColumns = {
    from: {
      id: 'from',
      title: '옵션',
      isDropDisabled: false,
      list: firstInputs.selecters.map((v) => v.selecterName),
    },
    to: {
      id: 'to',
      title: '선택',
      isDropDisabled: false,
      list: [],
    },
  };
  const [columns, setColumns] = useState<initialColumnsType>(initialColumns);
  const [secondColumns, setSecondColumns] = useState<SecondColumns>();
  const restricNext = () => {
    if (columns.to.list.length < 2) {
      alert(MESSAGE.MORE2OPTION);
    } else if (columns.to.list.length < 3) {
      if (POLL === '') {
        alert('기준을 선택하세요.');
        return;
      } else {
        let PollNow = firstInputs.selecters.filter(
          (a) => a.selecterName === POLL,
        )[0];
        let notPOLL = columns.to.list.filter((a) => a !== POLL)[0];
        let notPollNow = firstInputs.selecters.filter(
          (a) => a.selecterName === notPOLL,
        )[0];
        if (PollNow && notPollNow) {
          const copyColumns = {
            option1: {
              id: 'option1',
              title: PollNow.selecterName,
              type: 'typeA',
              list: PollNow.types.map((v) => v.title),

              code: PollNow.types.map((v) => v.code),
            },
            option2: {
              id: 'option2',
              title: notPollNow.selecterName,
              type: 'typeB',
              list: notPollNow.types.map((v) => v.title),

              code: notPollNow.types.map((v) => v.code),
            },
            to: {
              id: 'to',
              title: '제한 옵션 A',
              type: 'typeA',
              isDropDisabled: false,
              list: [],
              code: [],
            },
            to2: {
              id: 'to2',
              title: '제한 옵션 B',
              type: 'typeB',
              isDropDisabled: false,
              list: [],
              code: [],
            },
          };
          setSecondColumns(copyColumns);
        }
      }
      setRestiction('restictionStepTwo');
    } else {
      alert('제한옵션 3개 이하로 선택해 주세요.');
    }
  };
  const restricFinish = () => {
    const restricClass =
      secondColumns && secondColumns.to2.code.map((v: string) => v);
    let cateIndex = firstInputs.selecters.findIndex(
      (a) => a.selecterName === POLL,
    );
    let temp = { ...firstInputs };
    let tempInner = temp.selecters[cateIndex].types.map((a, i) => {
      let classList = a.class;
      if (restricClass) {
        if (
          a.class !== undefined &&
          secondColumns &&
          secondColumns.to.code.indexOf(a.code) > -1
        ) {
          classList = [...a.class, ...restricClass].filter(function (
            b,
            i,
            self,
          ) {
            return self.indexOf(b) === i;
          });
        } else if (
          a.class !== undefined &&
          secondColumns &&
          secondColumns.to.code.indexOf(a.code) === -1
        ) {
          classList = [...a.class];
        } else if (
          a.class === undefined &&
          secondColumns &&
          secondColumns.to.code.indexOf(a.code) > -1
        ) {
          classList = [...restricClass];
        } else {
          classList = [];
        }
      }
      a = { ...a, class: classList };
      return a;
    });
    temp.selecters[cateIndex].types = tempInner;
    setFirstInputs(temp);
  };
  return (
    <>
      <div id="restiction-wrap">
        {restiction === 'restictionStepOne' && (
          <div className="restiction-inner">
            <h3 className="pull-left">
              ※ 주문시 제한될 옵션 관계를 위해 옵션명을 드래그하여 옮겨주세요
            </h3>
            {POLL === '' && (
              <p className="pull-right  restiction-highlight">
                ※ 선택쪽의 옵션중 한개를 &quot;클릭&quot;하여 기준을
                선택해주세요.
              </p>
            )}
            <div className="clearfix"></div>
            <div className="restiction-cont">
              <RestricStepOne
                POLL={POLL}
                setPOLL={setPOLL}
                columns={columns}
                setColumns={setColumns}
                copyData={firstInputs.selecters}
              />
            </div>
            <div className="restric-buttonWrap text-center mt-3">
              <div
                className="yBtn yBtn-xs gray me-2 d-inline-block"
                onClick={() => setRestrict(!restrict)}
              >
                이전으로
              </div>
              <div
                className="yBtn yBtn-small orange text-center d-inline-block"
                onClick={() => {
                  restricNext();
                }}
              >
                선택완료
              </div>
            </div>
          </div>
        )}
        {restiction === 'restictionStepTwo' && (
          <div className="restiction-inner StepTwo">
            <h3>※ 주문시 제한될 상세 옵션을 선택하여 옮겨주세요</h3>
            <div className="restiction-cont">
              <RestricStepTwo
                secondColumns={secondColumns}
                setSecondColumns={setSecondColumns}
                restricNext={restricNext}
                restricFinish={restricFinish}
              />
            </div>
            <RestricList
              firstInputs={firstInputs}
              setFirstInputs={setFirstInputs}
              NewClass={NewClass}
            />
            <div className="restric-buttonWrap text-end">
              <div
                className="yBtn yBtn-xs gray d-inline-block me-2 text-center"
                onClick={() => setRestiction('restictionStepOne')}
              >
                이전으로
              </div>
              <div
                className="yBtn yBtn-xs red d-inline-block me-2 text-center"
                onClick={() => setRestrict(!restrict)}
              >
                <i className="fa fa-times"></i> 닫기
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
