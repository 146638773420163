import {
  FirstInput,
  Order,
  PriceCalcurateArr,
} from '@interfaces/tableCost.interfaces';

/**
 * compareSelecters함수가 실행되고 참이 되면 그냥 원래 배열을 리턴하는 함수
 * @param toBeChangedArr
 * @param originalArr
 * @returns 원래 배열이나 원래 배열의 가격이 부분이 복사된 배열을 리턴
 */

export const compare = (
  toBeChangedArr: FirstInput,
  originalArr: FirstInput,
) => {
  let result = compareSelecters(toBeChangedArr.selecters, originalArr.selecters)
    ? { ...toBeChangedArr, productTypes: originalArr.productTypes }
    : copyPrice(toBeChangedArr, originalArr.productTypes);
  return result;
};

/**
 * 바뀌게 될 배열과 원본배열을 code로 대조하여 사전에 비교하여 복사 작업이 될 필요가 있는지 여부를 가림
 * @param toBeChangedArr 바뀌게 될 json의 selecter 배열
 * @param originalArr 원본의 selecter 배열
 * @returns 불리언
 */

const compareSelecters = (toBeChangedArr: Order[], originalArr: Order[]) => {
  const toBeChangedStr = toBeChangedArr
    .map((e) => e.types.map((e) => e.code + e.class.join()).join(''))
    .sort()
    .join('');
  const originalStr = originalArr
    .map((e) => e.types.map((e) => e.code + e.class.join()).join(''))
    .sort()
    .join('');
  if (toBeChangedStr === originalStr) {
    return true;
  }
  return false;
};
/**
 * 위 함수에서 false 가 떨어지면 기존에 배열에서 새로 생성될 배열로 기존 가격을 복사하는 작업
 * @param toBeChangedArr 복사될 전체 배열
 * @param originalArr 원래의 producttype 배열
 * @returns 복사된 배열
 */
const copyPrice = (
  toBeChangedArr: FirstInput,
  originalArr: PriceCalcurateArr[],
) => {
  const itemsCopy = [...originalArr];
  for (const i of toBeChangedArr.productTypes) {
    const target = i.selectors
      .map((e) => e.code)
      .sort()
      .join('');

    for (const j of itemsCopy) {
      const origin = j.selectors
        .map((e) => e.code)
        .sort()
        .join('');

      if (target == origin) {
        i.price = j.price;
      }
      // else if (i.price.length != ipn) {
      //   i.price = new Array(ipn).fill({ quantity: 0, value: 0 });
      // }
    }
  }
  return toBeChangedArr;
};
