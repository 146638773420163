import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { Data, IMega } from '@interfaces/tableCost.interfaces';
import {
  ICommonEachCalculation,
  TableName,
} from '@interfaces/calculationProductModel.interface';

interface IProps {
  closeCalcModal: () => void;
  newItem: ICommonEachCalculation;
  setNewItem: Dispatch<SetStateAction<ICommonEachCalculation>>;
  megaData: Data[];
  setMegaData: Dispatch<Data[]>;
  modalIndex: number[];
  megaNav: {
    kind: string;
    items: string[];
  } | null;
}
export default function MegaModalCalc({
  closeCalcModal,
  newItem,
  setNewItem,
  megaData,
  setMegaData,
  modalIndex,
  megaNav,
}: IProps) {
  /** 메가 모달 state*/
  const [megaModalData, setMegaModalData] = useState<Data[]>(
    megaData.length > 0
      ? megaData.map((a) => {
          return {
            ...a,
            items: a.items.map(
              (b) =>
                (b = {
                  checked: false,
                  code: b.code,
                  options: b.options,
                  position: b.position,
                  text: b.text,
                  text2: b.text,
                }),
            ),
          };
        })
      : [],
  );
  const [FilterTXT, setFilterTXT] = useState('');
  /** 메가 탭 메뉴 state*/
  const [codeKind, setCodeKind] = useState(
    megaModalData[0] ? megaModalData[0].code : '',
  );
  /** 메가 옵션 체크박스 state*/
  const { adminSelecters } = newItem.calcValue[modalIndex[0]];
  /**
   * 메가 모달 메뉴 초기 셋팅
   * @constant megaModalData
   */
  useEffect(() => {
    if (
      megaModalData.length < 2 ||
      megaNav === null ||
      megaNav.kind === undefined ||
      megaNav.items.length == 0
    ) {
      //초기값이 없을때
      changeCodeKind('coatingCode'); //초기값이 없을때 모달리셋
    } else {
      //초기값이 있을때
      let megaNavIndex = megaModalData.findIndex(
        (a) => a.code === megaNav.kind,
      );

      if (megaNavIndex == -1) {
        changeCodeKind('coatingCode');
      } else {
        let megaNavText = megaModalData[megaNavIndex].code;
        changeCodeKind(megaNavText); //초기값으로 Nav이동
        // let megaCopy = [...megaData];
        // megaCopy[megaNavIndex].items = megaCopy[megaNavIndex].items
        //   .map((a) => {
        //     let matched = megaNav.filter((t) => t === a.code);
        //     if (matched.length > 0) {
        //       return { ...a, checked: true };
        //     } else {
        //       return { ...a, checked: false };
        //     }
        //   })
        //   .sort(function (x, y) {
        //     // 선택했던 항목 위로 분류
        //     return megaNav.indexOf(x.code) - megaNav.indexOf(y.code);
        //   })
        //   .sort(function (x, y) {
        //     return x.checked === y.checked ? 0 : x.checked ? -1 : 1;
        //   });
        // setMegaData(megaCopy);
      }
    }
  }, [megaNav]);
  const changeCodeKind = (code: string) => {
    setCodeKind(code);
    clearMega();
  };
  /**
   * 메가 옵션 체크박스 onCheck시
   * @param idx 체크한옵션 인덱스
   */
  const handleOnCheck = (idx: number, codeNow: string) => {
    let Adata = [...megaModalData];
    let idx2 = Adata[idx].items.findIndex((b) => {
      return b.code === codeNow;
    });
    if (Adata[idx].items[idx2].checked === true) {
      if (!confirm('옵션을 삭제하시겠습니까?')) {
        return;
      }
    }
    Adata[idx].items[idx2].checked = !Adata[idx].items[idx2].checked;
    setMegaModalData(Adata);
  };
  /**
   * 메가 옵션 체크후 확인시 액션, 선택 code firstInputs에 전달
   * @constant firstInputs
   */
  const sendSelected = () => {
    let finalArrayData = megaModalData
      .filter((e) => e.code === codeKind)
      .map((e) => e.items)[0]
      .filter((a) => a.checked !== false);
    let finalChange = finalArrayData.map(({ code, text, text2, options }) =>
      text2 === '' || text2 === null
        ? { code: code, title: text, options: options, class: '' }
        : { code: code, title: text2, options: options, class: '' },
    );
    let prepareS = megaModalData.filter((e) => e.code === codeKind)[0];
    let copyNow = { ...newItem };
    if (adminSelecters) {
      if (modalIndex[1] < adminSelecters.length) {
        if (finalChange.length > 0) {
          let readySelecter = {
            title: prepareS.text,
            codeCategory: prepareS.code,
            type: prepareS.type,
            select: finalChange,
          };
          let copySelecter = [...adminSelecters];
          copySelecter[modalIndex[1]] = readySelecter;
          copyNow.calcValue[modalIndex[0]].adminSelecters = copySelecter;
          setNewItem({ ...copyNow });
          resetModal();
        } else {
          adminSelecters[modalIndex[1]].select = [];
          copyNow.calcValue[modalIndex[0]].adminSelecters = adminSelecters;
          setNewItem({ ...copyNow });
          resetModal();
        }
      } else {
        let readySelecter = {
          title: prepareS.text,
          codeCategory: prepareS.code,
          type: prepareS.type,
          select: finalChange,
        };
        copyNow.calcValue[modalIndex[0]].adminSelecters = [
          ...adminSelecters,
          readySelecter,
        ];
        setNewItem({
          ...copyNow,
        });
        resetModal();
      }
    }
  };

  const resetModal = () => {
    clearMega();
    setCodeKind(megaModalData[0].code);
    closeCalcModal();
  };
  /**
   * 메가 초기화
   * @constant checkedState
   * @constant isOpen
   */
  const clearMega = () => {
    let Cdata = [...megaModalData];
    Cdata.map((a) =>
      a.items.map((b) => {
        return (b.checked = false);
      }),
    );
    setMegaModalData(Cdata);
    setFilterTXT('');
  };
  /**
   * 수정된 옵션명 전달 action
   * @constant megaModalData
   */
  const handleInputs = (
    index1: number,
    index2: number,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = e.target;
    let Bdata = [...megaModalData];
    Bdata[index1].items[index2][name] = value;
    setMegaModalData(Bdata);
  };
  const SearchCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFilterTXT(value);
  };
  /**커스텀 인풋 추가 action */
  const addCustomOption = (idx: number) => {
    let copy = [...megaData];
    let dummy = {
      checked: true,
      code: '',
      options: {},
      text: '',
      text2: '',
      edit: true,
    };
    copy[idx].items = [dummy, ...copy[idx].items];

    setMegaModalData(copy);
  };
  return (
    <>
      <div id="optionCode" className="modalY-wrap">
        <div className="modalY-overlay" onClick={() => closeCalcModal()}></div>
        <div className="modalY">
          <div className="modalY-close" onClick={() => closeCalcModal()}>
            <span>&#10005;</span>
          </div>
          <div className="modalY-body">
            <div className="modal-nav code-kind">
              <ul>
                {megaModalData.map((e, i) => {
                  let keyName = e.text;
                  return (
                    e.items[0] && (
                      <li
                        key={e.code + i}
                        className={codeKind === e.code ? 'active' : ''}
                        onClick={() => changeCodeKind(e.code)}
                      >
                        {keyName}{' '}
                        {e.items.length > 0 && (
                          <span>
                            {e.items[e.items.length - 1].code.slice(0, 3)}
                          </span>
                        )}
                      </li>
                    )
                  );
                })}
              </ul>
            </div>
            <div className="modal-nav code-detail">
              {megaModalData.map((e, i) => {
                let keyName = e.text;
                let innerMap = e.items;
                let productName = e.items[0]?.code.split(':')[0];
                if (FilterTXT !== '') {
                  innerMap = e.items.filter(
                    (a) =>
                      a.code.toLowerCase().includes(FilterTXT.toLowerCase()) ||
                      a.text.toLowerCase().includes(FilterTXT.toLowerCase()) ||
                      a.text2?.toLowerCase().includes(FilterTXT.toLowerCase()),
                  );
                }
                return (
                  codeKind === e.code && (
                    <div
                      key={keyName + i}
                      className={codeKind === keyName ? 'active' : ''}
                    >
                      <div className="code-detail-title">
                        <h4 className="pull-left">{keyName} 선택</h4>
                        <div className="code-detail-search pull-right">
                          <div
                            className="yBtn yBtn-add purple text-center d-inline-block me-2"
                            onClick={() => addCustomOption(i)}
                          >
                            <i className="fa fa-plus"></i> 커스텀 옵션추가
                          </div>
                          <span className="mega-seach-title">
                            <i className="fa fa-search"></i>
                          </span>
                          <input
                            className="mega-seach-input"
                            type="text"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => SearchCode(e)}
                          />
                        </div>
                        <div className="clearfix"></div>
                      </div>
                      <div className="cot-code-wrap">
                        <div className="code-detail-table">
                          <table
                            id="mega-code-table"
                            className="table table-striped"
                            cellSpacing="0"
                          >
                            <thead>
                              <tr>
                                <th></th>
                                <th>코드</th>
                                <th>코드명</th>
                                <th>노출옵션명</th>
                              </tr>
                            </thead>
                            <tbody>
                              {innerMap.map((e, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <input
                                        type="checkbox"
                                        id={`${e.code + index}}_input`}
                                        name={`${productName}`}
                                        //value={e.checked}
                                        checked={e.checked}
                                        onChange={() =>
                                          handleOnCheck(i, e.code)
                                        }
                                      />
                                      <label
                                        className="px-2"
                                        onClick={() => handleOnCheck(i, e.code)}
                                      >
                                        선택
                                      </label>
                                    </td>
                                    <td>
                                      {e.edit ? (
                                        <>
                                          <input
                                            type="text"
                                            name="code"
                                            value={e.code}
                                            onChange={(e) =>
                                              handleInputs(i, index, e)
                                            }
                                          />
                                        </>
                                      ) : (
                                        <>{e.code}</>
                                      )}
                                    </td>
                                    <td>
                                      {e.edit ? (
                                        <>
                                          <input
                                            type="text"
                                            name="text"
                                            value={e.text}
                                            onChange={(e) =>
                                              handleInputs(i, index, e)
                                            }
                                          />
                                        </>
                                      ) : (
                                        <>{e.text}</>
                                      )}
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        name="text2"
                                        onChange={(e) =>
                                          handleInputs(i, index, e)
                                        }
                                        value={e.text2 || ''}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          </div>
          <div className="code-button-group text-center">
            <div
              className="yBtn btn yBtn-small d-inline-block "
              onClick={() => resetModal()}
            >
              닫기
            </div>
            <div
              onClick={() => sendSelected()}
              className="yBtn btn yBtn-small blue d-inline-block code-button-submit"
            >
              선택완료
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
