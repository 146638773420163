import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { SERVER_URL } from '@config/path';
import { NewAddwork, NewAddworkPrice } from '@interfaces/addwork.interface';
import { CONFIRM, MESSAGE } from '@utils/textMessage';
import { useLoading } from '@config/loadingContext';
import { deleteEverything } from '@utils/common';
interface Props {
  selectedSite: string;
  newItem: NewAddwork;
  setNewItem: Dispatch<SetStateAction<NewAddwork>>;
  updateItem: NewAddwork;
  changeUPModal: (bool: boolean) => void;
}
export default function UpdateModal({
  selectedSite,
  newItem,
  setNewItem,
  updateItem,
  changeUPModal,
}: Props) {
  const { loadingHandler } = useLoading();
  const [updateState, setUpdateState] = useState<string>('step1');
  const [updateNow, setUpdateNow] = useState<NewAddwork>();
  const [inputType, setInputType] = useState<string>('same');

  useEffect(() => {
    setTimeout(() => compareInputs(), 500);
  }, []);
  const compareInputs = () => {
    loadingHandler(true);
    setUpdateState('step2');
    let firCopy = newItem.selecters
      .map((e) => e.select.map((e) => e.code).join(''))
      .sort()
      .join('');
    let uptInputs = updateItem.selecters
      .map((e) => e.select.map((e) => e.code).join(''))
      .sort()
      .join('');
    let result = firCopy === uptInputs ? true : false;
    result && console.log('옵션 동일');

    let bool = newItem.price
      .map((codeA, idxA) => {
        let bool =
          updateItem.price[idxA] &&
          codeA.code.join('') == updateItem.price[idxA].code.join('');
        return bool;
      })
      .filter((codeF) => codeF == false);
    let result2 = bool.length > 0 ? false : true;
    result && console.log('가격기준 동일');

    result && result2
      ? setTimeout(() => {
          setUpdateState('step3');
          SameSelector();
          setInputType('same');
        }, 500)
      : setTimeout(() => {
          setUpdateState('step3');
          DifferSelector();
          setInputType('differ');
        }, 500);
    loadingHandler(false);
    return result;
  };
  let finalCopy: NewAddwork = deleteEverything<NewAddwork>(updateItem, [
    '_id',
    'createdAt',
    'createdBy',
    'modifiedAt',
    'modifiedBy',
    '__v',
  ]);
  const SameSelector = () => {
    loadingHandler(true);
    let NewCopy: NewAddwork = {
      ...finalCopy,
      groupCode: newItem.groupCode,
      originId: newItem.originId,
      productId: newItem.productId,
      site: newItem.site,
    };
    NewCopy && setUpdateNow(NewCopy),
      setTimeout(() => setUpdateState('step4'), 500);
    loadingHandler(false);
  };
  const DifferSelector = () => {
    loadingHandler(true);
    if (window.confirm(CONFIRM.DIFFERUP)) {
      let NewCopy: NewAddwork = {
        ...finalCopy,
        groupCode: newItem.groupCode,
        originId: newItem.originId,
        productId: newItem.productId,
        site: newItem.site,
      };
      NewCopy && setUpdateNow(NewCopy),
        setTimeout(() => setUpdateState('step4'), 500);
      loadingHandler(false);
    } else {
      alert('기준가격 업데이트 취소');
      setUpdateState('step1');
      changeUPModal(false);
      loadingHandler(false);
    }
  };
  /**
   * 저장, 완료 Action
   * @constant NewAddwork
   * @constant priceStep
   */
  const saveNow = async () => {
    loadingHandler(true);
    const requestOptions: AxiosRequestConfig = {
      url: SERVER_URL + `/addwork/${newItem._id}`,
      data: updateNow,
      method: 'put',
    };
    try {
      const response = await axios(requestOptions);
      response && alert(MESSAGE.SAVE),
        changeUPModal(false),
        loadingHandler(false);
      return;
    } catch (error) {
      alert('저장실패!');
      loadingHandler(false);
      console.log(MESSAGE.ERROR);
    }
  };

  return (
    <>
      <div
        className="modalY-overlay"
        onClick={() => changeUPModal(false)}
      ></div>
      <div id="standardproduct-window" className="preview-window preview-600">
        <div
          className="closeBtn"
          onClick={() => {
            changeUPModal(false);
          }}
        >
          <i className="fa fa-times"></i>
        </div>

        {newItem && (
          <>
            <div className="bg-f7f7f7 p-5">
              <div className="contents-wrap x_panel">
                <div className="x_title">
                  <h4>
                    <span>
                      <i>{selectedSite}</i>
                    </span>{' '}
                    - {newItem.groupCode} 기준가격 관리
                  </h4>
                </div>
                <div className="clearfix"></div>
                <div className="x_content">
                  <div className="row standard-update">
                    <div
                      className={`col ${
                        updateState == 'step1' ? 'active' : 'complete'
                      }`}
                    >
                      <div className="standard-update-item">
                        <i className="fa fa-cloud-download"></i>
                        <p>
                          <span>업데이트된 기준가격 가져오기</span>
                        </p>
                      </div>
                    </div>
                    <div
                      className={`col ${
                        updateState == 'step1'
                          ? 'yet'
                          : updateState == 'step2'
                          ? 'active'
                          : 'complete'
                      }`}
                    >
                      <div className="standard-update-item">
                        <i className="fa fa-files-o"></i>
                        <p>
                          <span>가격표 기준 비교</span>
                        </p>
                      </div>
                    </div>
                    <div
                      className={`col ${
                        updateState == 'step3'
                          ? 'active'
                          : updateState == 'step4'
                          ? 'complete'
                          : 'yet'
                      }`}
                    >
                      <div className="standard-update-item">
                        <i className="fa fa-sort-numeric-asc"></i>
                        <p>
                          <span>사이트별 가격변경 값 적용</span>
                        </p>
                      </div>
                    </div>
                    {updateState == 'step4' && (
                      <div className="standard-update-complete">
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 130.2 130.2"
                        >
                          <circle
                            className="path circle"
                            fill="none"
                            stroke="#9a29fd"
                            strokeWidth="6"
                            strokeMiterlimit="10"
                            cx="65.1"
                            cy="65.1"
                            r="62.1"
                          />
                          <polyline
                            className="path check"
                            fill="none"
                            stroke="#9a29fd"
                            strokeWidth="6"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            points="100.2,40.2 51.5,88.8 29.8,67.5 "
                          />
                        </svg>{' '}
                        준비완료
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="row pt-2">
                <div className="col-sm-12  text-center complete-btn-wrap mt-4 mb-2">
                  {updateState == 'step4' ? (
                    <div
                      className="price-start-btn btn-inline-block"
                      data-toggle="tooltip"
                      onClick={() => {
                        saveNow();
                      }}
                    >
                      <span>
                        <i className="fa fa-check" aria-hidden="true"></i>
                        기준가격 업데이트
                      </span>
                    </div>
                  ) : (
                    <div className="price-start-btn btn-inline-block disabled">
                      <span>
                        <i className="fa fa-check" aria-hidden="true"></i>
                        기준가격 업데이트
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
