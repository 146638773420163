import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import CSS from 'csstype';
import axios from 'axios';
import { SERVER_URL } from '@config/path';
import {
  FirstInput,
  PriceInner,
  Order,
} from '@interfaces/tableCost.interfaces';
import { CONFIRM, MESSAGE } from '@utils/textMessage';
import { useLoading } from '@config/loadingContext';
import { deleteEverything } from '@utils/common';
interface Props {
  siteName: string;
  siteNameEN: string;
  firstInputs: FirstInput;
  setFirstInputs: Dispatch<SetStateAction<FirstInput>>;
  updateInputs: FirstInput;
  changeModal: (type: string, bool: boolean) => void;
}
interface SendPriceArr {
  selectors: {
    title: string;
    code: string;
  }[];
  price: PriceInner[];
}
export default function UpdateModal({
  siteName,
  siteNameEN,
  firstInputs, //가져온상품
  setFirstInputs,
  updateInputs, //기준상품
  changeModal,
}: Props) {
  const isCalc: boolean =
    firstInputs &&
    firstInputs.typeOfPriceDetermintion &&
    firstInputs.typeOfPriceDetermintion !== 'priceComplete'
      ? true
      : false;
  const { loadingHandler } = useLoading();
  const [updateState, setUpdateState] = useState<string>('step1');
  const [sendSelects, setSendSelects] = useState<Order[]>([]);
  const [sendInputs, setSendInputs] = useState<SendPriceArr[]>([]);
  const [selectsTest, setSelectsTest] = useState<boolean>(true);
  const [pricesTest, setPricesTest] = useState<boolean>(true);
  useEffect(() => {
    setTimeout(() => prepareSelecters(), 500);
  }, []);
  const prepareSelecters = () => {
    loadingHandler(true);
    setUpdateState('step2');

    let copySelect = [...updateInputs.selecters].map((a) => {
      let cleanSelecters = deleteEverything<Order>(a, [
        '_id',
        'createdAt',
        'createdBy',
        'modifiedAt',
        'modifiedBy',
        '__v',
      ]);
      let matchSelecters = firstInputs.selecters.filter(
        (m) => m.kindCode === a.kindCode,
      )[0];
      return {
        ...cleanSelecters,
        types: a.types.map((b) => {
          if (matchSelecters) {
            let matchTypes = matchSelecters.types.filter(
              (n) => n.code === b.code,
            )[0];
            if (matchTypes) {
              return {
                ...b,
                title: matchTypes.title,
                isHide: matchTypes.isHide,
              };
            } else {
              return { ...b };
            }
          } else {
            return { ...b };
          }
        }),
      };
    });
    setSendSelects(copySelect);

    let bool = firstInputs.productTypes
      .map((codeA, idxA) => {
        let bool =
          codeA.selectors.map((codeB) => codeB.code).join('') ===
          (updateInputs.productTypes[idxA]
            ? updateInputs.productTypes[idxA].selectors
                .map((codeC) => codeC.code)
                .join('')
            : '');
        return bool;
      })
      .filter((codeF) => codeF == false);
    let result = bool.length > 0 ? false : true;
    setSelectsTest(result);

    let firstLength = firstInputs.productTypes
      .map((a) => {
        return a.price.length;
      })
      .reduce((a, b) => a + b);

    let updateLength = updateInputs.productTypes
      .map((a) => {
        return a.price.length;
      })
      .reduce((a, b) => a + b);
    setPricesTest(firstLength === updateLength);
    setTimeout(() => {
      setUpdateState('step3');
      preparePrices();
    }, 500);
    loadingHandler(false);
  };
  const preparePrices = () => {
    loadingHandler(true);
    let NewCopy = updateInputs.productTypes.map((codeA, idxA) => {
      let codesStr = codeA.selectors
        .map((a) => {
          return a.code;
        })
        .sort()
        .join();
      let editedPriceArr = firstInputs.productTypes.filter(
        (b) =>
          b.selectors
            .map((a) => {
              return a.code;
            })
            .sort()
            .join() === codesStr,
      )[0];
      let emptyPrice = {
        todayTypes: {
          switch: false,
        },
        sales: true,
        calcValue: '0',
      };
      return {
        selectors: codeA.selectors.map((codeS) => {
          delete codeS._id;
          return codeS;
        }),
        price: codeA.price.map((codeB, idxB) => {
          delete codeB._id;
          let finalValue =
            editedPriceArr &&
            editedPriceArr.price &&
            editedPriceArr.price.filter((a) => a.quantity === codeB.quantity)
              .length > 0
              ? editedPriceArr.price.filter(
                  (a) => a.quantity === codeB.quantity,
                )[0]
              : {
                  ...emptyPrice,
                  quantity: codeB.quantity,
                };
          return {
            ...finalValue,
            value: codeB.value,
          };
        }),
      };
    });
    setSendInputs(NewCopy);
    NewCopy && setTimeout(() => setUpdateState('step4'), 500);
    loadingHandler(false);
  };
  /**
   * 저장, 완료 Action
   * @constant firstInput
   */
  const updateSelecter = async () => {
    loadingHandler(true);
    if (
      window.confirm(
        '기준가격 옵션정보로 업데이트 하시겠습니까? \n * 업데이트시 변경하신 옵션순서가 기준가격순서로 변경됩니다.',
      )
    ) {
      await axios
        .put(
          SERVER_URL + `/productbysite/${firstInputs._id}/update-selecters`,
          { tobeChange: sendSelects },
        )
        .then(function (response) {
          alert(MESSAGE.SAVE);
          // setFirstInputs(response.data.data);
          // changeModal('update', false);
          setSelectsTest(true);
          loadingHandler(false);
        })
        .catch(function (error) {
          alert(MESSAGE.ERROR);
          loadingHandler(false);
        });
    } else {
      loadingHandler(false);
    }
  };
  const updatePrice = async () => {
    loadingHandler(true);
    if (window.confirm('기준가격을 업데이트 하시겠습니까?')) {
      await axios
        .put(
          SERVER_URL + `/productbysite/${firstInputs._id}/replaceproducttype`,
          { data: sendInputs },
        )
        .then(function (response) {
          alert(MESSAGE.SAVE);
          // setFirstInputs(response.data.data);
          // changeModal('update', false);
          setPricesTest(true);
          loadingHandler(false);
        })
        .catch(function (error) {
          alert(MESSAGE.ERROR);
          loadingHandler(false);
        });
    } else {
      loadingHandler(false);
    }
  };

  return (
    <>
      <div
        className="modalY-overlay"
        onClick={() => changeModal('update', false)}
      ></div>
      <div id="standardproduct-window" className="preview-window preview-600">
        <div
          className="closeBtn"
          onClick={() => {
            changeModal('update', false);
          }}
        >
          <i className="fa fa-times"></i>
        </div>

        {firstInputs && (
          <>
            <div className="bg-f7f7f7 p-5">
              <div className="contents-wrap x_panel">
                <div className="x_title">
                  <h4>
                    <span>
                      <i>{siteName}</i>
                    </span>{' '}
                    - {firstInputs.productNameKO} 기준가격 관리
                  </h4>
                </div>
                <div className="clearfix"></div>
                <div className="x_content">
                  <div className="row standard-update">
                    <div
                      className={`col ${
                        updateState == 'step1' ? 'active' : 'complete'
                      }`}
                    >
                      <div className="standard-update-item">
                        <i className="fa fa-cloud-download"></i>
                        <p>
                          <span>업데이트된 기준가격 가져오기</span>
                        </p>
                      </div>
                    </div>
                    <div
                      className={`col ${
                        updateState == 'step1'
                          ? 'yet'
                          : updateState == 'step2'
                          ? 'active'
                          : 'complete'
                      }`}
                    >
                      <div className="standard-update-item">
                        <i className="fa fa-files-o"></i>
                        <p>
                          <span>가격표 기준 비교</span>
                        </p>
                      </div>
                    </div>
                    <div
                      className={`col ${
                        updateState == 'step3'
                          ? 'active'
                          : updateState == 'step4'
                          ? 'complete'
                          : 'yet'
                      }`}
                    >
                      <div className="standard-update-item">
                        <i className="fa fa-sort-numeric-asc"></i>
                        <p>
                          <span>사이트별 가격변경 값 적용</span>
                        </p>
                      </div>
                    </div>
                    {updateState == 'step4' && (
                      <div className="standard-update-complete">
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 130.2 130.2"
                        >
                          <circle
                            className="path circle"
                            fill="none"
                            stroke="#9a29fd"
                            strokeWidth="6"
                            strokeMiterlimit="10"
                            cx="65.1"
                            cy="65.1"
                            r="62.1"
                          />
                          <polyline
                            className="path check"
                            fill="none"
                            stroke="#9a29fd"
                            strokeWidth="6"
                            strokeLinecap="round"
                            strokeMiterlimit="10"
                            points="100.2,40.2 51.5,88.8 29.8,67.5 "
                          />
                        </svg>{' '}
                        준비완료
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="row pt-2">
                <div className="col-sm-12  text-center red  my-2">
                  {!selectsTest && (
                    <div className="red">
                      <i className="fa fa-times"></i> 옵션 비일치
                    </div>
                  )}
                  {!isCalc && !pricesTest && (
                    <div className="red">
                      <i className="fa fa-times"></i> 가격표갯수 비일치
                    </div>
                  )}
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="row pt-2">
                <div className="col-sm-12  text-center complete-btn-wrap mt-4 mb-2">
                  {updateState == 'step4' ? (
                    <>
                      <div
                        className="btn yBtn orange btn-inline-block mx-2"
                        data-toggle="tooltip"
                        onClick={() => {
                          updateSelecter();
                        }}
                      >
                        <span>
                          <i className="fa fa-check" aria-hidden="true"></i>
                          기준옵션 업데이트
                        </span>
                      </div>
                      {!isCalc && (
                        <div
                          className="btn yBtn blue btn-inline-block mx-2"
                          data-toggle="tooltip"
                          onClick={() => {
                            updatePrice();
                          }}
                        >
                          <span>
                            <i className="fa fa-check" aria-hidden="true"></i>
                            기준가격 업데이트
                          </span>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="btn yBtn orange btn-inline-block mx-2 disabled">
                        <span>
                          <i className="fa fa-check" aria-hidden="true"></i>
                          기준옵션 업데이트
                        </span>
                      </div>
                      <div className="btn yBtn blue btn-inline-block mx-2 disabled">
                        <span>
                          <i className="fa fa-check" aria-hidden="true"></i>
                          기준가격 업데이트
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
