import { useCallback, useState, ChangeEvent } from 'react';

export default <T>(
  initialState: T,
): [T, (e: ChangeEvent<HTMLInputElement>) => void, () => void] => {
  const [state, setState] = useState<T>(initialState);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setState((state) => ({ ...state, [name]: value }));
    },
    [state],
  );

  const onReset = useCallback(
    (): void => setState(initialState),
    [initialState],
  );

  return [state, onChange, onReset];
};
