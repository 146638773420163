import Notfound from '@pages/notfound';
import Home from '@pages/home';
import Siteadd from '@pages/sitemanager/siteadd';
import Sitelist from '@pages/sitemanager/sitelist';
import Tablecost from '@pages/standardproduct/tablecost';
import Matrixcost from '@pages/standardproduct/matrixcost';
import Joincost from '@pages/standardproduct/joincost';
import Addwork from '@pages/standardproduct/addwork';
import CalcCost from '@pages/standardproduct/calcCost';
import Paper from '@pages/standardproduct/calc-cost/paper';
// import Printingcost from '@pages/standardproduct/calc-cost/printingcost';
// import Binding from '@pages/standardproduct/calc-cost/binding';
// import Pan from '@pages/standardproduct/calc-cost/pan';
// import Coating from '@pages/standardproduct/calc-cost/coating';
// import Folding from '@pages/standardproduct/calc-cost/folding';
import Loglist from '@pages/administrator/loglist';
import CreateUser from '@pages/administrator/createuser';
import Standardproduct from '@pages/standardproduct/standardproduct';
import Productlist from '@pages/productmanager/productlist';
import Setprice from '@pages/productmanager/setprice';
import Setaddwork from '@pages/productmanager/setaddwork';
import Setetc from '@pages/productmanager/setetc';
import Setcategory from '@pages/productmanager/setcategory';
import Setevent from '@pages/salesmanager/setevent';
import Setpopup from '@pages/productmanager/setpopup';
import Setnotification from '@pages/productmanager/setnotification';
import SetBoard from '@pages/productmanager/setboard';
import SetEstimate from '@pages/productmanager/setestimate';
import { Routes, MenuElement } from '@interfaces/globals.types';

export const navMenuElement: MenuElement = [
  {
    topMenuNameKO: '관리자 홈',
    icon: 'fa-home',
    prohibit: [],
    bannedId: [],
    subMenu: [
      {
        menuName: 'Dashboard',
        type: 'home',
        path: '/home',
        prohibit: [],
        bannedId: [],
        component: Home,
      },
    ],
  },
  {
    topMenuNameKO: '기준 상품 등록',
    icon: 'fa-cube',
    prohibit: ['common manager'],
    bannedId: ['d05004'],
    subMenu: [
      {
        menuName: '기준 상품 리스트',
        type: 'standardproduct',
        path: '/standardproduct/standardproduct',
        prohibit: ['common manager'],
        bannedId: [],
        component: Standardproduct,
      },
      {
        menuName: '기준 상품 등록',
        type: 'tablecost',
        path: '/standardproduct/tablecost',
        prohibit: ['common manager'],
        bannedId: [],
        component: Tablecost,
        subMenu: [
          {
            menuName: '가격 완성형',
            type: 'tablecost',
            path: '/standardproduct/tablecost',
            prohibit: ['common manager'],
            bannedId: [],
            component: Tablecost,
          },
          {
            menuName: '가격 계산형',
            type: 'matrixcost',
            path: '/standardproduct/matrixcost',
            prohibit: ['common manager'],
            bannedId: [],
            component: Matrixcost,
          },
          // {
          //   menuName: '조합 계산형',
          //   type: 'joincost',
          //   path: '/standardproduct/joincost',
          //    bannedId: [], component: Joincost,
          // },
        ],
      },
      {
        menuName: '후가공 정보등록',
        type: 'addwork',
        path: '/standardproduct/addwork',
        prohibit: ['common manager'],
        bannedId: [],
        component: Addwork,
      },
      {
        menuName: '가격계산형 정보등록',
        type: 'carcCost',
        path: '/standardproduct/carcCost',
        prohibit: ['common manager'],
        bannedId: [],
        component: CalcCost,
        subMenu: [
          {
            menuName: '가격계산형 정보등록',
            type: 'carcCost',
            path: '/standardproduct/carcCost',
            prohibit: ['common manager'],
            bannedId: [],
            component: CalcCost,
          },
          {
            menuName: '용지',
            type: 'paper',
            path: '/standardproduct/paper',
            prohibit: ['common manager'],
            bannedId: [],
            component: Paper,
          },
          // {
          //   menuName: '재본',
          //   type: 'binding',
          //   path: '/standardproduct/binding',
          //   prohibit: ['common manager'],
          //   bannedId: [],
          //   component: Binding,
          // },
          // {
          //   menuName: '인쇄비',
          //   type: 'printingcost',
          //   path: '/standardproduct/printingcost',
          //   prohibit: ['common manager'],
          //   bannedId: [],
          //   component: Printingcost,
          // },
          // {
          //   menuName: '판비',
          //   type: 'pan',
          //   path: '/standardproduct/pan',
          //   prohibit: ['common manager'],
          //   bannedId: [],
          //   component: Pan,
          // },
          // {
          //   menuName: '코팅비',
          //   type: 'coating',
          //   path: '/standardproduct/coating',
          //   prohibit: ['common manager'],
          //   bannedId: [],
          //   component: Coating,
          // },
          // {
          //   menuName: '접지비',
          //   type: 'folding',
          //   path: '/standardproduct/folding',
          //    bannedId: [], component: Folding,
          // },
        ],
      },
    ],
  },
  {
    topMenuNameKO: '사이트 관리',
    icon: 'fa-sitemap',
    prohibit: ['common manager'],
    bannedId: ['d05004'],
    subMenu: [
      {
        menuName: '사이트 등록',
        type: 'siteadd',
        path: '/site-manager/siteadd',
        prohibit: ['common manager'],
        bannedId: [],
        component: Siteadd,
      },
      {
        menuName: '등록된 사이트리스트',
        type: 'sitelist',
        path: '/site-manager/sitelist',
        prohibit: ['common manager'],
        bannedId: [],
        component: Sitelist,
      },
    ],
  },
  {
    topMenuNameKO: '사이트별 상품 관리',
    icon: 'fa-cubes',
    prohibit: [],
    bannedId: [],
    subMenu: [
      {
        menuName: '기준 상품 가져오기',
        type: 'productlist',
        path: '/product-manager/productlist',
        prohibit: [],
        bannedId: [],
        component: Productlist,
      },
      {
        menuName: '사이트 판매 단가 결정하기',
        type: 'setprice',
        path: '/product-manager/setprice',
        prohibit: [],
        bannedId: [],
        component: Setprice,
      },
      {
        menuName: '후가공 판매 단가 결정하기',
        type: 'setaddwork',
        path: '/product-manager/setaddwork',
        prohibit: [],
        bannedId: [],
        component: Setaddwork,
      },
      {
        menuName: '판매 방식 설정',
        type: 'setetc',
        path: '/product-manager/setetc',
        prohibit: [],
        bannedId: [],
        component: Setetc,
      },
      {
        menuName: '사이트 카테고리 결정하기',
        type: 'setcategory',
        path: '/product-manager/setcategory',
        prohibit: [],
        bannedId: [],
        component: Setcategory,
      },
    ],
  },
  {
    topMenuNameKO: '판매 관리',
    icon: 'fa-shopping-bag',
    prohibit: [],
    bannedId: [],
    subMenu: [
      {
        menuName: '이벤트 설정',
        type: 'setevent',
        path: '/sales-manager/setevent',
        prohibit: [],
        bannedId: [],
        component: Setevent,
      },
      {
        menuName: '팝업 관리',
        type: 'setpopup',
        path: '/sales-manager/setpopup',
        prohibit: [],
        bannedId: [],
        component: Setpopup,
      },
      {
        menuName: '공지사항 관리',
        type: 'setnotification',
        path: '/sales-manager/setnotification',
        prohibit: [],
        bannedId: [],
        component: Setnotification,
      },
      {
        menuName: '게시판 관리',
        type: 'setboard',
        path: '/sales-manager/setboard',
        prohibit: [],
        bannedId: [],
        component: SetBoard,
      },
      {
        menuName: '견적의뢰 관리',
        type: 'setestimate',
        path: '/sales-manager/setestimate',
        prohibit: [],
        bannedId: [],
        component: SetEstimate,
      },
    ],
  },
  {
    topMenuNameKO: '관리자 설정',
    icon: 'fa-reddit-alien',
    prohibit: ['common manager'],
    bannedId: ['d05004'],
    subMenu: [
      {
        menuName: '로그',
        type: 'administrator',
        path: '/administrator/loglist',
        prohibit: ['common manager'],
        bannedId: [],
        component: Loglist,
      },
      {
        menuName: '계정생성',
        type: 'createuser',
        path: '/administrator/createuser',
        prohibit: ['general manager', 'common manager', 'manager'],
        bannedId: [],
        component: CreateUser,
      },
    ],
  },
];
export const loginRoutes = [
  ...navMenuElement
    .map((e) =>
      e.path
        ? { path: e.path, component: e.component }
        : e.subMenu?.map((e) => {
            if (e.subMenu) {
              return e.subMenu?.map((e) => {
                return { path: e.path, component: e.component };
              });
            } else {
              return { path: e.path, component: e.component };
            }
          }),
    )
    .flat(2),
  {
    path: '*',
    component: Notfound,
  },
];

export const isntLogin: Routes[] = [
  {
    path: '*',
    component: Notfound,
  },
];
