import { z } from 'zod';

export const logModel = z.object({
  _id: z.string().optional(),
  timestamp: z.date(),
  level: z.string(),
  message: z.string(),
  meta: z.object({}).optional(),
});

export const logQeuryModel = z.object({
  page: z.number().optional(),
  limit: z.number().optional(),
  start: z.date().nullable().optional(),
  end: z.date().nullable().optional(),
  filter: z.string().optional(),
});

export const parseQueryModel = z.object({
  page: z.number().optional(),
  limit: z.number().optional(),
  start: z.date().optional(),
  end: z.date().optional(),
  filter: z.string().optional(),
});
