import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SiteaddIn } from '@dummydata/dummy';
import { Site } from '@interfaces/site.interface';
import axios from 'axios';
import { SERVER_URL } from '@config/path';
import { useSidbarContext } from '@config/context';
import { MESSAGE } from '@utils/textMessage';
import sitealertCategorize from '@utils/sitealertFunc';

export default function Siteadd() {
  const history = useHistory();
  const { setNavOpen } = useSidbarContext();
  const [siteInputs, setsiteInputs] = useState<Site>({
    ...SiteaddIn,
  });
  const siteChange = (name: string, value: string | boolean) => {
    setsiteInputs({ ...siteInputs, [name]: value });
  };

  const siteSave = async (inputs: Site) => {
    let siteCopy = {
      topSeller: siteInputs.topSeller,
      sellerName: siteInputs.sellerName,
      sellerNameEN: siteInputs.sellerNameEN,
      siteUrl: siteInputs.siteUrl,
      adminName: siteInputs.adminName,
      adminPosition: siteInputs.adminPosition,
      IdNumber: siteInputs.IdNumber,
      pw: siteInputs.pw,
      menuCategory: [],
    };
    sitealertCategorize(inputs) &&
      (await axios
        .post(SERVER_URL + '/site', siteCopy)
        .then((response) => {
          setsiteInputs({ ...SiteaddIn }); //데이터 업데이트
          alert(MESSAGE.SAVE);
          setNavOpen('sitelist');
          history.push('/site-manager/sitelist');
        })
        .catch(function (error) {
          console.log('데이터 업로드에 실패 하였습니다.');
          alert('사이트 등록 실패');
        }));
  };

  const coOptions = [
    { value: '프린트시티그룹', name: '프린트시티그룹' },
    { value: '아이엠디자인그룹', name: '아이엠디자인그룹' },
  ];

  const rankOptions = [
    { value: '이사', name: '이사' },
    { value: '부장', name: '부장' },
    { value: '차장', name: '차장' },
    { value: '과장', name: '과장' },
  ];

  return (
    <>
      <div className="page-title">
        <div className="title_left">
          <h3>
            <i className="fa fa-sitemap" />
            사이트 등록 <small>사이트관리</small>
          </h3>
        </div>
      </div>
      <div className="clearfix"></div>
      <div className="row">
        <div className="col-12">
          <div className="contents-wrap x_panel site_x_panel">
            <div className="x_content siteadd_wrap">
              <form id="demo-form2" className="form-horizontal form-label-left">
                <div className="form-group">
                  <div className="row">
                    <label
                      className="control-label cost-title col-md-2 col-sm-2 col-xs-12"
                      htmlFor="groupSite"
                    >
                      최상위 판매업체선택
                    </label>
                    <div className="cost-cont cost-cont col-md-2 col-sm-2 col-xs-12">
                      <select
                        className="form-control site-selcet"
                        onChange={(ev: React.ChangeEvent<HTMLSelectElement>) =>
                          siteChange(ev.target.name, ev.target.value)
                        }
                        name="topSeller"
                        value={siteInputs && siteInputs.topSeller}
                        required
                      >
                        <option value="empty">판매업체를 선택하세요</option>
                        {coOptions.map((option) => {
                          return (
                            <option key={option.value} value={option.value}>
                              {option.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <label
                      className="control-label cost-title col-md-2 col-sm-2 col-xs-12"
                      htmlFor="siteName"
                    >
                      기본 판매업체명
                    </label>
                    <div className="cost-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                      <div className="row siteaddin">
                        <div className="siteaddin-kor">
                          <label
                            className="control-label cost-text col-md-1 col-sm-1 col-xs-12"
                            htmlFor="siteName"
                          >
                            한글명
                          </label>
                          <div className="cost-cont col-md-2 col-sm-2 col-xs-12">
                            <input
                              className="form-control"
                              type="text"
                              onChange={(e) =>
                                siteChange(e.target.name, e.target.value)
                              }
                              name="sellerName"
                              value={
                                (siteInputs && siteInputs.sellerName) || ''
                              }
                            />
                          </div>
                        </div>
                        <div className="siteaddin-eng">
                          <label
                            className="control-label cost-text col-md-1 col-sm-1 col-xs-12"
                            htmlFor="siteName"
                          >
                            영문명
                          </label>
                          <div className="cost-cont col-md-2 col-sm-2 col-xs-12">
                            <input
                              className="form-control"
                              type="text"
                              onChange={(e) =>
                                siteChange(e.target.name, e.target.value)
                              }
                              name="sellerNameEN"
                              value={
                                (siteInputs && siteInputs.sellerNameEN) || ''
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <label
                      htmlFor="siteAdress"
                      className="control-label cost-title col-md-2 col-sm-2 col-xs-12"
                    >
                      사이트 url
                    </label>
                    <div className="cost-cont col-md-2 col-sm-2 col-xs-12">
                      <input
                        className="form-control"
                        type="text"
                        onChange={(e) =>
                          siteChange(e.target.name, e.target.value)
                        }
                        name="siteUrl"
                        value={(siteInputs && siteInputs.siteUrl) || ''}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <label
                      htmlFor="numProduct"
                      className="control-label cost-title col-md-2 col-sm-2 col-xs-12"
                    >
                      관리자 정보
                    </label>
                    <div className="cost-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                      <div className="row siteaddin">
                        <div className="siteaddin-kor">
                          <label
                            className="control-label cost-text col-md-1 col-sm-1 col-xs-12"
                            htmlFor="siteName"
                          >
                            이름
                          </label>
                          <div className="cost-cont col-md-2 col-sm-2 col-xs-12">
                            <input
                              className="form-control"
                              type="text"
                              onChange={(e) =>
                                siteChange(e.target.name, e.target.value)
                              }
                              name="adminName"
                              value={(siteInputs && siteInputs.adminName) || ''}
                            />
                          </div>
                        </div>
                        <div className="siteaddin-eng">
                          <label
                            className="control-label cost-text col-md-1 col-sm-1 col-xs-12"
                            htmlFor="siteName"
                          >
                            직급
                          </label>
                          <div className="cost-cont col-md-2 col-sm-2 col-xs-12 site-rank">
                            <select
                              className="form-control site-selcet"
                              onChange={(
                                ev: React.ChangeEvent<HTMLSelectElement>,
                              ) => siteChange(ev.target.name, ev.target.value)}
                              name="adminPosition"
                              value={siteInputs && siteInputs.adminPosition}
                              required
                            >
                              <option value="empty">직급을 선택하세요</option>
                              {rankOptions.map((option) => {
                                return (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <label
                      htmlFor="numProduct"
                      className="control-label cost-title col-md-2 col-sm-2 col-xs-12"
                    >
                      사원번호
                    </label>
                    <div className="cost-cont col-md-2 col-sm-2 col-xs-12">
                      <input
                        className="form-control"
                        type="text"
                        onChange={(e) =>
                          siteChange(e.target.name, e.target.value)
                        }
                        name="IdNumber"
                        value={(siteInputs && siteInputs.IdNumber) || ''}
                      />
                    </div>
                    <p className="cost-text col-md-4 col-sm-4 col-xs-12">
                      ※ 기존 사용하는 사원번호와 비밀번호를 그대로 사용하는걸
                      권장합니다.
                    </p>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <label
                      htmlFor="numProduct"
                      className="control-label cost-title col-md-2 col-sm-2 col-xs-12"
                    >
                      비밀번호
                    </label>
                    <div className="cost-cont col-md-2 col-sm-2 col-xs-12">
                      <input
                        className="form-control"
                        type="text"
                        onChange={(e) =>
                          siteChange(e.target.name, e.target.value)
                        }
                        name="pw"
                        value={(siteInputs && siteInputs.pw) || ''}
                      />
                    </div>
                    <p className="cost-text col-md-4 col-sm-4 col-xs-12">
                      ※ 기존 사용하는 사원번호와 비밀번호를 그대로 사용하는걸
                      권장합니다.
                    </p>
                  </div>
                </div>
                <div className=" mt-4 mb-3 mx-auto d-block text-center align-middle complete-btn-wrap">
                  <div
                    className="btn btn-success yBtn green yBtn-small d-inline-block"
                    onClick={() => siteSave(siteInputs)}
                  >
                    <i className="fa fa-check" aria-hidden="true"></i>
                    사이트 등록
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
