import { z } from 'zod';
import { logModel, logQeuryModel } from './log.model';
import { c } from '@api/contract';

export const logBaseApiUrl = '/logs';

export const logApi = c.router({
  findAllLogs: {
    method: 'GET',
    path: `${logBaseApiUrl}`,
    query: logQeuryModel,
    responses: {
      200: { logs: z.array(logModel), total: z.number() },
      401: 'Not Found',
      500: '서버에 문제가 있으면 리턴한다.',
    },
    summary: 'log 전체 불러오기(검색가능)',
    description: 'log 전체 불러오기(검색가능)',
  },
});
