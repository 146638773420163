import { Dispatch, useState, useEffect, SetStateAction } from 'react';
import {
  ICommonEachCalculation,
  NewCalcSelecters,
} from '@interfaces/calculationProductModel.interface';
import NumberFormat from 'react-number-format';
import es from 'date-fns/esm/locale/es/index.js';
// import ReorderWindow from './reorder/reorder-window';
interface Props {
  closeCalcModal: () => void;
  newItem: ICommonEachCalculation;
  setNewItem: Dispatch<SetStateAction<ICommonEachCalculation>>;
  modalIndex: number[];
}

export default function CalcCustom({
  closeCalcModal,
  newItem,
  setNewItem,
  modalIndex,
}: Props) {
  const [customCalc, setCustomCalc] = useState<NewCalcSelecters>({
    title: '커스텀옵션',
    codeCategory: 'customCode',
    type: 'code',
    select: [
      {
        code: '',
        title: '',
        options: {},
        class: [],
      },
    ],
  });

  const { adminSelecters } = newItem.calcValue[modalIndex[0]];
  useEffect(() => {
    if (adminSelecters) {
      if (modalIndex[1] < adminSelecters.length) {
        setCustomCalc({ ...adminSelecters[modalIndex[1]] });
      }
    }
  }, []);
  /**S: Onchange 기능구현  */
  const changeInput = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const { name, value } = e.target;
    let copy = { ...customCalc };
    copy.select[idx][name] = value;
    setCustomCalc({
      ...copy,
    });
  };
  const addCustomArray = () => {
    setCustomCalc({
      ...customCalc,
      select: [
        ...customCalc.select,
        {
          code: '',
          title: '',
          options: {},
          class: [],
        },
      ],
    });
  };

  const delCustomArray = () => {
    let copy = { ...customCalc };
    let lengthNow = copy.select.length - 1;
    if (lengthNow > 0) {
      copy.select.splice(lengthNow, 1);
      setCustomCalc({
        ...copy,
      });
    } else {
      alert('최소 1줄은 필요합니다.');
    }
  };

  const customFinish = () => {
    let copyFinal = { ...newItem };
    if (adminSelecters) {
      if (modalIndex[1] < adminSelecters.length) {
        let copySelecter = [...adminSelecters];
        copySelecter[modalIndex[1]] = { ...customCalc };
        copyFinal.calcValue[modalIndex[0]] = {
          ...copyFinal.calcValue[modalIndex[0]],
          adminSelecters: [...copySelecter],
        };
        setNewItem(copyFinal);
        closeCalcModal();
      } else {
        copyFinal.calcValue[modalIndex[0]].adminSelecters = [
          ...adminSelecters,
          customCalc,
        ];
        setNewItem({
          ...copyFinal,
        });
        closeCalcModal();
      }
    }
  };
  return (
    <>
      <div id="customModal-wrap">
        <div className="customModal-inner">
          <div className="customModal-cont">
            <h3>
              커스텀 옵션
              <span> - 생성</span>
            </h3>
            <div className="customModal-inputWrap">
              {customCalc.select.map((code, idx) => {
                return (
                  <div className="newoption-wrap form-group" key={idx}>
                    <div className="row">
                      <div className="newoption-title cost-title col-md-3 col-sm-3 col-xs-12">
                        커스텀 옵션 {idx + 1}
                      </div>
                      <div className="newoption-cont cost-cont col-md-9 col-sm-9 col-xs-12">
                        <div className="newoption-custom d-inline-block">
                          <div className="newoption-custom-title">타이틀</div>
                          <div className="newoption-custom-cont">
                            <input
                              key={idx}
                              type="text"
                              className="form-control quarterinput d-inline-block me-1"
                              name="title"
                              value={code.title}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                changeInput(e, idx);
                              }}
                            />
                          </div>
                        </div>
                        <div className="newoption-custom d-inline-block">
                          <div className="newoption-custom-title">코드</div>
                          <div className="newoption-custom-cont">
                            <input
                              key={idx}
                              type="text"
                              className="form-control quarterinput d-inline-block me-1"
                              name="code"
                              value={code.code}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                changeInput(e, idx);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="d-inline-block mb-2">
                <button
                  className="tableBtn tableBtn-green btnW-70 newoption-editbtn"
                  onClick={() => addCustomArray()}
                >
                  <i className="fa fa-plus"></i> 추가
                </button>
              </div>
              {
                //code.qty.length > 1 && (
                <div className="d-inline-block">
                  <button
                    className="tableBtn tableBtn-red btnW-70 newoption-editbtn ms-0"
                    onClick={() => delCustomArray()}
                  >
                    <i className="fa fa-minus"></i> 삭제
                  </button>
                </div>
                //)
              }
            </div>
          </div>
          <div className="reorder-buttonWrap text-center">
            <div
              className="yBtn yBtn-xs gray me-2 d-inline-block"
              onClick={() => closeCalcModal()}
            >
              이전으로
            </div>
            <div
              className="yBtn yBtn-md blue text-center d-inline-block"
              onClick={() => customFinish()}
            >
              선택완료
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
