import React, {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { MEGACodeUrl } from '@config/path';
import axios from 'axios';
import { useLoading } from '@config/loadingContext';
import { Data } from '@interfaces/tableCost.interfaces';
import MegaModalAddwork from '@components/modal/megaModal-addwork';
import AddworkCustom from '@components/modal/customModal-addwork';
import { NewAddwork, TableName } from '@interfaces/addwork.interface';
import AddworkReorder from '@components/table/standardproduct/reorder/addwork-reorder';
interface IProps {
  AddworkType: string;
  newItem: NewAddwork;
  setNewItem: Dispatch<SetStateAction<NewAddwork>>;
  TName: string[];
  setTName: Dispatch<SetStateAction<string[]>>;
  newTable: TableName;
  setNewTable: Dispatch<SetStateAction<TableName>>;
  Tready: boolean;
  setTready: Dispatch<SetStateAction<boolean>>;
  priceReady: boolean;
  BacktoOption: () => void;
  executeScroll: () => void;
}
export default function SetAddWorkSelect({
  AddworkType,
  newItem,
  setNewItem,
  TName,
  setTName,
  newTable,
  setNewTable,
  Tready,
  setTready,
  priceReady,
  BacktoOption,
  executeScroll,
}: IProps) {
  /** S: 후가공 옵션준비 */
  const [megaDataEX, setMegaDataEX] = useState<Data[]>([]);
  const [megaData, setMegaData] = useState<Data[]>([]);
  const [megaData1st, setMegaData1st] = useState<Data[]>([]);
  useEffect(() => {
    async function getMegaData() {
      await axios
        .get(MEGACodeUrl)
        .then(function (response) {
          setMegaDataEX(response.data);
          let filtered = response.data.filter(
            (data: Data) => data.groupKey !== '일반-옵션',
          );
          setMegaData(filtered);
          let filter1st = filtered.filter(
            (thing: Data, index: number, self: Data[]) =>
              index === self.findIndex((t) => t.groupKey === thing.groupKey),
          );
          setMegaData1st(filter1st);
        })
        .catch(function (error) {
          console.log('메가 데이터 로드에 실패 하였습니다.');
        });
    }
    getMegaData();
    if (AddworkType !== 'new') {
      let newKEYs = newItem.price[0].value
        ? Object.keys(newItem.price[0].value[0])
            .map((k) => {
              return k;
            })
            .filter((k) => k !== '_id')
        : alert('가격 데이터가 없습니다.');
      newKEYs && (setTName(newKEYs), MakeNewTable());
      executeScroll();
    }
  }, []);
  /** E: 후가공 옵션준비 */
  const ChangeNewOpt = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'groupCode') {
      let copymega = [...megaData];
      let filtered = copymega.filter((name) =>
        name.groupKey.startsWith(value + '-'),
      );
      setNewItem({
        ...newItem,
        [name]: value,
        selecters: filtered
          .sort((a, b) => a.type.localeCompare(b.type))
          .map((b) => {
            return {
              title: b.text,
              codeCategory: b.code,
              type: b.type,
              select: b.items.map((c) => {
                return {
                  code: c.code,
                  title: c.text,
                  class: [],
                  options: c.options,
                };
              }),
            };
          }),
      });
    } else if (name === 'groupCode2') {
      setNewItem({
        ...newItem,
        ['groupCode']: value,
      });
    } else {
      setNewItem({ ...newItem, [name]: value });
    }
  };
  const ChangeTitle = (
    e: React.ChangeEvent<HTMLInputElement>,
    txt: string,
    idx: number,
  ) => {
    let copy = { ...newItem };
    const { value } = e.target;
    let hasPrice = txt.includes('-OnlyPrice');
    let hasSelect = txt.includes('-OnlySelect');
    copy.selecters[idx].title =
      value + (hasPrice ? '-OnlyPrice' : '') + (hasSelect ? '-OnlySelect' : '');
    setNewItem(copy);
  };

  const [modal, setModal] = useState<{ [index: string]: boolean }>({
    ADW: false,
    EX: false,
    pushId: false,
    custom: false,
    reSelect: false,
    reOption: false,
  });
  const openModal = (str: string) => {
    setModal({ ...modal, [str]: true });
  };
  const EXopen = (str: string) => {
    setModalIndex(newItem.selecters.length);
    setMegaNav(null);
    openModal(str);
  };
  const closeModal = () => {
    setModal({
      ADW: false,
      EX: false,
      pushId: false,
      custom: false,
      reSelect: false,
      reOption: false,
    });
  };
  const delOption = (idx: number) => {
    let copy = { ...newItem };
    copy.selecters.splice(idx, 1);
    setNewItem(copy);
  };
  const editNow = (idx: number) => {
    changeMegaNav(idx);
    let titleNow = newItem.selecters[idx].title;
    if (titleNow.includes('커스텀옵션')) {
      openModal('custom');
    } else {
      openModal('ADW');
    }
  };
  /**
   * 옵션 순서 변경 액션
   * @param index selecter index
   * @constant firstInput
   */
  /** 리오더오픈여부 state*/
  const [reOrderIndex, setReOrderIndex] = useState(0);
  const onReorder = (type: string, index: number) => {
    if (type == 'reOption') {
      if (newItem.selecters[index].select.length < 2) {
        alert('주문옵션이 2개이상이어야 합니다');
        return;
      } else {
        openModal(type);
        setReOrderIndex(index);
      }
    } else {
      if (newItem.selecters.length < 2) {
        alert('주문옵션이 2개이상이어야 합니다');
        return;
      } else {
        openModal(type);
      }
    }
  };
  /**
   * 메가모달오픈 시 초기설정 액션
   * @constant megaNav
   */
  const [modalIndex, setModalIndex] = useState(0);
  const [megaNav, setMegaNav] = useState<{
    kind: string;
    items: string[];
  } | null>(null);
  const changeMegaNav = (index: number) => {
    setModalIndex(index);
    let bkind = newItem.selecters[index].codeCategory;
    let bcode = newItem.selecters[index].select.map((b) => {
      return b.code;
    });
    setMegaNav({ kind: bkind, items: bcode });
  };
  /** E: 후가공 선택 */
  /** S: 옵션명 선택 */
  const ChooseNames = ['min', 'max', 'qty', 'value', 'overValue'];
  const [NameModal, setNameModal] = useState(false);
  const [NameIdx, setNameIdx] = useState(0);
  const ChangeNameModal = (idx: number) => {
    setNameIdx(idx);
    setNameModal(!NameModal);
  };
  const setToObject = (arr: string[]) => {
    let init: { [index: string]: number } = {};
    const result = arr.reduce((acc, curr) => {
      acc[curr] = 0;
      return acc;
    }, init);
    delete result._id;
    return result;
  };
  const ChangeNewTable = (value: string) => {
    let copy = [...TName];
    if (!copy.includes(value)) {
      copy[NameIdx] = String(value);
      setTName(copy);
    } else {
      alert('이미 동일한 항목이 존재합니다.');
    }
    ChangeNameModal(0);
  };
  const AddNewTable = () => {
    let copy = [...TName].filter(
      (filt) => filt !== '_id' && filt !== 'calcValue',
    );
    copy.push('');
    let result = [...copy, 'calcValue'];
    setTName(result);
  };
  const DelNewTable = () => {
    let copy = [...TName].filter(
      (filt) => filt !== '_id' && filt !== 'calcValue',
    );
    let idx = copy.length - 1;
    copy.splice(idx, 1);
    let result = [...copy, 'calcValue'];
    setTName(result);
  };
  const MakeNewTable = () => {
    let resultOBJ = setToObject(TName);
    setNewTable(resultOBJ);
    setTready(true);
  };
  /**항목명 변경 Function */
  const KeyChanger = (str: string) => {
    let result =
      str == 'max'
        ? '최대'
        : str === 'min'
        ? '최소'
        : str === 'qty'
        ? '수량'
        : str === 'value'
        ? '데이터'
        : str === 'calcValue'
        ? '수정데이터'
        : str === 'overValue'
        ? '추가데이터'
        : str;
    return result;
  };
  /** 가격표 만들기 */
  const changeOnlySelect = (type: string, idx: number, checked: boolean) => {
    let copy = { ...newItem };
    if (checked) {
      copy.selecters[idx].title = copy.selecters[idx].title.replace(
        type === 'select' ? '-OnlySelect' : '-OnlyPrice',
        '',
      );
    } else {
      copy.selecters[idx].title =
        copy.selecters[idx].title +
        (type === 'select' ? '-OnlySelect' : '-OnlyPrice');
    }
    setNewItem(copy);
  };
  return (
    <>
      <div className="addworkSelectWrap">
        <div className="newitem-bg contents-wrap x_panel mb-3">
          <div className="x_content">
            <div className="form-horizontal form-label-left">
              <div className="newoption-wrap form-group">
                <div className="row">
                  <div className="newoption-title cost-title col-md-2 col-sm-2 col-xs-12">
                    후가공 선택
                  </div>
                  <div className="newoption-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                    {megaData1st &&
                      megaData1st.map((data) => {
                        let thisVal = data.groupKey.split('-')[0];
                        return (
                          <div className="newoption-radio" key={data.code}>
                            <input
                              id={thisVal}
                              name="groupCode"
                              type="radio"
                              value={thisVal}
                              className={`boxInputs ${data.code}`}
                              checked={newItem.groupCode === thisVal}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                ChangeNewOpt(e);
                              }}
                            />
                            <label htmlFor={thisVal}>{thisVal}</label>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="newoption-wrap form-group">
                <div className="row">
                  <div className="newoption-title cost-title col-md-2 col-sm-2 col-xs-12">
                    후가공 종류
                  </div>
                  <div className="newoption-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                    <input
                      type="text"
                      className="form-control halfinput"
                      name="groupCode2"
                      value={newItem.groupCode}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        ChangeNewOpt(e);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="newoption-wrap form-group">
                <div className="row">
                  <div className="newoption-title cost-title col-md-2 col-sm-2 col-xs-12">
                    후가공 명(workTypeKO)
                  </div>
                  <div className="newoption-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                    <input
                      type="text"
                      className="form-control halfinput"
                      name="workTypeKO"
                      value={newItem.workTypeKO}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        ChangeNewOpt(e);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="newoption-wrap form-group">
                <div className="row">
                  <div className="newoption-title cost-title col-md-2 col-sm-2 col-xs-12">
                    후가공 영문코드(workType)
                  </div>
                  <div className="newoption-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                    <input
                      type="text"
                      className="form-control halfinput"
                      name="workType"
                      value={newItem.workType}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        ChangeNewOpt(e);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="newoption-wrap form-group">
                <div className="row">
                  <div className="newoption-title cost-title col-md-2 col-sm-2 col-xs-12">
                    후가공 구분코드(class)
                  </div>
                  <div className="newoption-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                    <input
                      type="text"
                      className="form-control halfinput"
                      name="class"
                      value={newItem.class}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        ChangeNewOpt(e);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="newoption-wrap form-group">
                <div className="row">
                  <>
                    <div className="newoption-title cost-title col-md-2 col-sm-2 col-xs-12">
                      <div>
                        후가공 옵션 <br />
                        <span className="red">(가격표에 적용됨)</span>
                      </div>

                      <button
                        className="tableBtn tableBtn-skyblue btnW-70 mt-1 me-0"
                        onClick={() => {
                          EXopen('EX');
                        }}
                      >
                        옵션추가
                      </button>
                      <div className="clearfix" />
                      <button
                        className="tableBtn tableBtn-gray btnW-70 mt-1 me-0"
                        onClick={() => {
                          EXopen('custom');
                        }}
                      >
                        커스텀옵션
                      </button>
                      <div className="clearfix" />
                      <button
                        className="tableBtn tableBtn-green btnW-70 mt-1 me-0"
                        onClick={() => onReorder('reSelect', 0)}
                      >
                        옵션순서
                      </button>
                    </div>
                    <div className="newoption-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                      {newItem.selecters.length > 0 &&
                        newItem.selecters.map((data, index) => {
                          return (
                            <div
                              className="newoption-preview"
                              key={'codeCategory' + index}
                            >
                              <div className="newoption-prev-title pe-2">
                                <input
                                  type="text"
                                  className="form-control megaModalTitle"
                                  value={data.title
                                    .replace('-OnlyPrice', '')
                                    .replace('-OnlySelect', '')}
                                  onChange={(e) =>
                                    ChangeTitle(e, data.title, index)
                                  }
                                />
                              </div>

                              <div
                                className="newoption-prev-cont"
                                key={data.codeCategory}
                              >
                                {data.type === 'Code' ||
                                data.type === 'OnlyPrice' ||
                                !data.type ? (
                                  <>
                                    <select className="">
                                      {data.select.map((dataA) => {
                                        return (
                                          <option
                                            value={dataA.code}
                                            key={dataA.code}
                                          >
                                            {dataA.title}
                                          </option>
                                        );
                                      })}
                                    </select>
                                    <div className="d-inline-block">
                                      <button
                                        className="tableBtn tableBtn-orange btnW-70 newoption-editbtn ms-1 me-0"
                                        onClick={() => editNow(index)}
                                      >
                                        <i className="fa fa-edit"></i>
                                        수정
                                      </button>
                                    </div>
                                    <div className="d-inline-block">
                                      <button
                                        className="tableBtn tableBtn-red btnW-70 newoption-editbtn ms-1 me-0"
                                        onClick={() => delOption(index)}
                                      >
                                        <i className="fa fa-times"></i>
                                        삭제
                                      </button>
                                    </div>
                                    <div className="d-inline-block">
                                      <button
                                        className="tableBtn tableBtn-green btnW-90 newoption-editbtn  ms-1 me-0"
                                        onClick={() =>
                                          onReorder('reOption', index)
                                        }
                                      >
                                        <i className="fa fa-sort"></i> 순서변경
                                      </button>
                                    </div>
                                    <div className="d-inline-block">
                                      <input
                                        className="effectPrice ms-1 me-0"
                                        type="checkbox"
                                        id={data.codeCategory + '-ep1'}
                                        checked={data.title.includes(
                                          '-OnlyPrice',
                                        )}
                                        onChange={(e) =>
                                          changeOnlySelect(
                                            'price',
                                            index,
                                            data.title.includes('-OnlyPrice'),
                                          )
                                        }
                                      />
                                      <label
                                        htmlFor={data.codeCategory + '-ep1'}
                                      >
                                        옵션 안보임
                                      </label>
                                    </div>
                                    <div className="d-inline-block">
                                      <input
                                        className="effectPrice ms-1 me-0"
                                        type="checkbox"
                                        id={data.codeCategory + '-ep2'}
                                        checked={data.title.includes(
                                          '-OnlySelect',
                                        )}
                                        onChange={(e) =>
                                          changeOnlySelect(
                                            'select',
                                            index,
                                            data.title.includes('-OnlySelect'),
                                          )
                                        }
                                      />
                                      <label
                                        htmlFor={data.codeCategory + '-ep2'}
                                      >
                                        가격표 영향없음
                                      </label>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="newoption-samewidth">
                                      <input
                                        type="text"
                                        className={data.codeCategory}
                                      />
                                    </div>
                                    <div className="d-inline-block">
                                      <span className="ms-3 red">
                                        미리보기 ONLY (삭제할 필요 없음)
                                      </span>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    {megaData1st &&
                      megaData1st.map((data, idx) => {
                        <div
                          className="newitem-radio"
                          key={data.groupKey + idx}
                        >
                          <input
                            id={data.groupKey.split('-')[0]}
                            value={data.groupKey.split('-')[0]}
                            name="newitem1st"
                            type="radio"
                          />
                          <label htmlFor={data.groupKey.split('-')[0]}>
                            {data.groupKey.split('-')[0]}
                          </label>
                        </div>;
                      })}
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="newitem-bg contents-wrap x_panel mb-3">
          <div className="x_content">
            <div className="form-horizontal form-label-left">
              <div className="newoption-wrap form-group">
                <div className="row">
                  <div className="newoption-title cost-title col-md-2 col-sm-2 col-xs-12">
                    가격표 타이틀
                  </div>
                  <div className="newoption-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                    {TName && (
                      <div className="newTable-wrap">
                        {TName.filter(
                          (filt) => filt !== '_id' && filt !== 'calcValue',
                        ).map((site, idx) => {
                          return (
                            <div
                              className="newoption-radio newTable-input"
                              key={idx}
                            >
                              <div
                                id={idx + 1 + '-input'}
                                className="form-control fakeNameInput"
                                onClick={() => ChangeNameModal(idx)}
                              >
                                {KeyChanger(TName[idx])}
                              </div>
                            </div>
                          );
                        })}
                        {TName.length > 2 && (
                          <div
                            className="input-group-btn input-minus-btn d-inline-block text-center"
                            onClick={() => {
                              DelNewTable();
                            }}
                          >
                            <i className="fa fa-minus"></i>
                          </div>
                        )}
                        <div
                          className="input-group-btn input-plus-btn d-inline-block text-center"
                          onClick={() => {
                            AddNewTable();
                          }}
                        >
                          <i className="fa fa-plus"></i>
                        </div>
                        <div
                          id="setNewBtn"
                          className={`yBtn yBtn-small green text-center d-inline-block ms-2 ${
                            Tready ? 'disabled' : ''
                          }`}
                          onClick={() => {
                            MakeNewTable();
                          }}
                        >
                          <i className="fa fa-check"></i> 확정
                        </div>
                        {Tready && (
                          <div className="newTable-wrap-bg">
                            <div className="text-center">
                              수정을 원하시면 아래 수정버튼을 눌러주세요.
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {NameModal && (
                      <div id="nameModal">
                        <div className="newoption-cont">
                          {ChooseNames.map((codeA) => {
                            return (
                              <div className="newoption-radio">
                                <input
                                  type="radio"
                                  name="nameModal"
                                  className="boxInputs"
                                  id={codeA}
                                  onClick={() => ChangeNewTable(codeA)}
                                />
                                <label htmlFor={codeA}>
                                  {KeyChanger(codeA)}
                                </label>
                              </div>
                            );
                          })}
                          <div className="clearfix"></div>
                          <div className="text-right">
                            <button
                              className="yBtn yBtn-xs gray mt-2 me-0 d-inline-block pull-right"
                              onClick={() => ChangeNameModal(0)}
                            >
                              닫기
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="clearfix"></div>
                    {Tready && (
                      <div className="newTablePrev">
                        <div className="newTablePrev-t">
                          <h3>가격표 미리보기</h3>
                          <button
                            className="tableBtn tableBtn-orange btnW-70"
                            id="modify"
                            onClick={() => {
                              setTready(false);
                            }}
                          >
                            <i className="fa fa-edit"></i>
                            수정
                          </button>
                        </div>
                        <div className="mt-2 fakeTnameWrap">
                          <div className="fakeTnameH">
                            <span>옵션A</span>
                            <span>옵션B</span>
                          </div>
                          {Object.keys(newTable)
                            .filter(
                              (filt) => filt !== '_id' && filt !== 'calcValue',
                            )
                            .map((tab, idx) => {
                              return (
                                <div key={idx} className="fakeTname">
                                  <div className="fakeTnameH fakeTnameC">
                                    {KeyChanger(tab)}
                                  </div>
                                  <div className="fakeTnameB"></div>
                                  <div className="fakeTnameB"></div>
                                  <div className="fakeTnameB"></div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {priceReady && (
          <div id="priceReady-nodrop">
            {AddworkType === 'new' || AddworkType === 'edit' ? (
              <div className="priceReady-nodropmessage">
                <span id="priceReady-nodropbtn" onClick={() => BacktoOption()}>
                  옵션 수정
                </span>
                버튼을 눌러 수정이 가능합니다.
              </div>
            ) : (
              <div className="priceReady-nodropmessage">
                후가 옵션수정을 원하시면 새로운 후가공 생성후 가져오기 하셔야
                합니다.
              </div>
            )}
          </div>
        )}
      </div>
      {modal.reSelect && (
        <AddworkReorder
          reorderType="select"
          newItem={newItem}
          setNewItem={setNewItem}
          reOrderIndex={reOrderIndex}
          closeModal={closeModal}
        />
      )}
      {modal.reOption && (
        <AddworkReorder
          reorderType="option"
          newItem={newItem}
          setNewItem={setNewItem}
          reOrderIndex={reOrderIndex}
          closeModal={closeModal}
        />
      )}
      {modal.ADW && (
        <MegaModalAddwork
          closeModal={closeModal}
          newItem={newItem}
          setNewItem={setNewItem}
          megaData={megaDataEX}
          setMegaData={setMegaDataEX}
          modalIndex={modalIndex}
          megaNav={megaNav}
        />
      )}
      {modal.EX && (
        <MegaModalAddwork
          closeModal={closeModal}
          newItem={newItem}
          setNewItem={setNewItem}
          megaData={megaDataEX}
          setMegaData={setMegaDataEX}
          modalIndex={modalIndex}
          megaNav={megaNav}
        />
      )}
      {modal.custom && (
        <AddworkCustom
          closeModal={closeModal}
          newItem={newItem}
          setNewItem={setNewItem}
          modalIndex={modalIndex}
        />
      )}
    </>
  );
}
