import { MESSAGE } from '@utils/textMessage';
import { FirstInput, TableCostStep } from '@interfaces/tableCost.interfaces';
/**
 * 셀렉트가 비었을떄 체킹하는 함수 기존에 상품명과 영문상품명은 삭제 (영문상품명은 살림)
 * @param inputs fistinput 같은 배열
 * @param stepHandler price 스태 핸들러 함수
 * @returns
 */
export default function alertCategorizeMatrix(
  inputs: FirstInput,
  stepHandler: (ele: TableCostStep) => void,
) {
  return !inputs.category[0].categoryName1st ||
    inputs.category[0].categoryName1st === 'empty' ||
    !inputs.category[0].categoryName2nd
    ? alertExec(MESSAGE.CATEGORY, stepHandler)
    : !inputs.productNameEN
    ? alertExec(MESSAGE.EnNAME, stepHandler)
    : inputs.selecters.filter((item) => !item.selecterName).length > 0
    ? alertExec(MESSAGE.OPTION, stepHandler)
    : inputs.selecters.filter((item) => item.types.length < 1).length > 0
    ? alertExec(MESSAGE.OPTIONCODE, stepHandler)
    : inputs.selecters.filter(
        (item) =>
          item.types[0].code !== undefined &&
          item.types[0].code.startsWith('MAT'),
      ).length < 1
    ? alertExec(MESSAGE.MATERIAL, stepHandler)
    : inputs.selecters.filter(
        (v, i, a) =>
          a.findIndex((t) => t.selecterName === v.selecterName) !== i,
      ).length > 0
    ? alertExec(MESSAGE.OVERLAP, stepHandler)
    : true;
}
/**
 * alert 실행 함수
 * @param text alert가 나타나고 떨어질 priceStep
 * @param stepHandler price 스태 핸들러 함수
 * @returns boolean 메인 컴퍼넌트에 보낼 불리언
 */
const alertExec = (text: string, stepHandler: (ele: TableCostStep) => void) => {
  alert(text);
  stepHandler('preparing');
  return false;
};
