import { createContext, useContext, useState } from 'react';
export type LoadingTypes = {
  loadingNow: boolean;
  loadingHandler: (b: boolean) => void;
};
const LoadingContext = createContext<LoadingTypes>({
  loadingNow: false,
  loadingHandler: () => ({}),
});

export const LoadingProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}): JSX.Element => {
  const [loadingNow, loadingHandler] = useState(false);
  const value = { loadingNow, loadingHandler };
  return (
    <LoadingContext.Provider value={value}>
      {children}
      {loadingNow && (
        <div id="loadingNow">
          <div
            className="loader-overlay"
            onClick={() => loadingHandler(false)}
          ></div>
          <div className="cube-wrapper">
            <div className="cube-folding">
              <span className="leaf1"></span>
              <span className="leaf2"></span>
              <span className="leaf3"></span>
              <span className="leaf4"></span>
            </div>
          </div>
        </div>
      )}
    </LoadingContext.Provider>
  );
};

export function useLoading() {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within LoadingProvider');
  }
  return context;
}
