import useFetch from '@utils/customHook/useFetch';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Pagination from 'react-js-pagination';
import React, { useState } from 'react';
import { SERVER_URL } from '@config/path';

type Type = {
  level: string;
  message: string;
  meta: null;
  timestamp: string;
  _id: string;
};

interface IProps {
  id?: string;
  category?: 'paper' | 'addwork';
  paperSize?: string;
}
interface FetchType {
  payload: { data: Type[]; length: number };
  loading: boolean;
  error: string;
}
export default function loglist({ id, category, paperSize }: IProps) {
  const [page, setPage] = useState<number>(1);
  const [nperpage, setNperpage] = useState(20);
  const [textFilter, setTextFilter] = useState<string | undefined>(undefined);
  const aWeekAgo = new Date(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 7,
    ).getTime(),
  );
  const [startDate, setStartDate] = useState<Date | null>(aWeekAgo);
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const url =
    category == 'addwork'
      ? `logs${
          id ? '/addwork' : ''
        }?page=${page}&nperpage=${nperpage}&fromdate=${startDate}&todate=${endDate}${
          id ? `&id=${id}` : ''
        }`
      : `logs/paper?page=${page}&nperpage=${nperpage}&fromdate=${startDate}&todate=${endDate}&paperSize=${paperSize}`;

  const { payload, loading, error } = useFetch<Type>(url);
  let totalLength = Number(payload?.data.length);
  const handlePage = (e: number) => {
    setPage(e);
  };

  const handleTextFilter = (e: React.ChangeEvent<HTMLInputElement>) =>
    setTextFilter(e.target.value);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="contents-wrap x_panel">
            <div className="clearfix"></div>
            <div className="seachWrap mt-2 text-end">
              <h5 className="d-inline-block me-4">검색</h5>
              <div className="d-inline-block me-2">
                <DatePicker
                  selected={startDate}
                  onChange={(date: Date | null) => {
                    setStartDate(date);
                  }}
                />
              </div>
              <span>~</span>
              <div className="d-inline-block ms-2">
                <DatePicker
                  selected={endDate}
                  onChange={(date: Date | null) => {
                    setEndDate(date);
                  }}
                />
              </div>
              <div className="d-inline-block ms-4">
                <input
                  type="text"
                  value={textFilter}
                  onChange={(e) => handleTextFilter(e)}
                  disabled={true}
                />
              </div>
            </div>
            {!loading && (
              <div className="x_content">
                <table
                  id="site-list-table"
                  className="table table-striped table-bordered"
                  cellSpacing="0"
                >
                  <thead>
                    <tr>
                      {!category && (
                        <>
                          <th>대표사이트명</th>
                          <th>사이트명</th>
                        </>
                      )}
                      <th>시간</th>
                      <th>메세지</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payload ? (
                      payload.data.map((e) => (
                        <tr key={e._id}>
                          {!category && (
                            <>
                              <th>프린트시티</th>
                              <th>아이엠디자인</th>
                            </>
                          )}
                          <td>
                            {moment(e.timestamp).format(
                              'YYYY년 MM월 DD일 HH:mm:ss',
                            )}
                          </td>
                          <td>{e.message}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>수정 내역이 없습니다.</tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <Pagination
            activePage={page}
            itemsCountPerPage={nperpage}
            totalItemsCount={totalLength}
            pageRangeDisplayed={5}
            prevPageText={'‹'}
            nextPageText={'›'}
            onChange={(e) => handlePage(e)}
          />
        </div>
      </div>
    </>
  );
}
