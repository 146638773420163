import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Pagination from 'react-js-pagination';
import React, { useEffect, useState } from 'react';
import { logClient } from '@api/log/log.client';

type LogType = {
  level: string;
  message: string;
  meta: null;
  timestamp: string;
  _id: string;
};
type ResType = {
  logs: LogType[];
  total: number;
};
interface FetchType {
  payload: { data: LogType[]; length: number };
  loading: boolean;
  error: string;
}
export default function Loglist() {
  const [page, setPage] = useState<number>(1);
  const [nperpage, setNperpage] = useState(10);
  const [textFilter, setTextFilter] = useState<string | undefined>(undefined);

  const aWeekAgo = new Date(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 7,
    ).getTime(),
  );

  const [startDate, setStartDate] = useState<Date | null>(aWeekAgo);
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const [payload, setPayload] = useState<LogType[]>();
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState(true);

  const getRequest = async () => {
    const { body } = await logClient.findAllLogs({
      query: {
        page: page,
        limit: nperpage,
        start: startDate,
        end: endDate,
        filter: textFilter,
      },
    });
    const data = body as ResType; //타입단언한 이유는 ts-rest의 AppRouterQuery에 리턴타입이 unknown으로 되어있기 때문에
    setPayload(data.logs);
    setTotal(data.total);
    setLoading(false);
  };

  useEffect(() => {
    getRequest();
  }, [page]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setPage(1);
      getRequest();
    }
  };

  const handlePage = (e: number) => {
    setPage(e);
  };

  const handleTextFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setTextFilter(e.target.value);
  };

  return (
    <>
      <div className="page-title">
        <div className="title_left">
          <h3>
            <i className="fa fa-reddit-alien" /> 관리자
          </h3>
        </div>
      </div>
      <div className="clearfix"></div>
      <div className="row">
        <div className="col-12">
          <div className="contents-wrap x_panel">
            <div className="x_title">
              <h4>
                로그 리스트 <small>Log Table</small>
              </h4>
            </div>

            <div className="clearfix"></div>
            <div className="seachWrap mt-2 text-end">
              <h5 className="d-inline-block me-4">검색</h5>
              <div className="d-inline-block me-2">
                <DatePicker
                  selected={startDate}
                  onChange={(date: Date | null) => {
                    setStartDate(date);
                  }}
                />
              </div>
              <span>~</span>
              <div className="d-inline-block ms-2">
                <DatePicker
                  selected={endDate}
                  onChange={(date: Date | null) => {
                    setEndDate(date);
                  }}
                />
              </div>
              <div className="d-inline-block ms-4">
                <input
                  placeholder="검색어를 입력하세요"
                  type="text"
                  value={textFilter}
                  onChange={(e) => handleTextFilter(e)}
                  onKeyDown={(e) => handleKeyDown(e)}
                />
                <button
                  onClick={() => {
                    setPage(1);
                    getRequest();
                  }}
                >
                  검색
                </button>
              </div>
            </div>
            {!loading && (
              <div className="x_content">
                <div className="react-bootstrap-table">
                  <table
                    id="site-list-table"
                    className="table table-striped table-bordered"
                    cellSpacing="0"
                  >
                    <thead>
                      <tr>
                        <th>대표사이트명</th>
                        <th>사이트명</th>
                        <th>시간</th>
                        <th>메세지</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payload &&
                        payload.map((e) => (
                          <tr key={e._id}>
                            <td>프린트시티</td>
                            <td>아이엠디자인</td>
                            <td>
                              {moment(e.timestamp).format(
                                'YYYY년 MM월 DD일 HH:mm:ss',
                              )}
                            </td>
                            <td>{e.message}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
          <div className="paginationWrap">
            <Pagination
              activePage={page}
              itemsCountPerPage={nperpage}
              totalItemsCount={total}
              pageRangeDisplayed={5}
              prevPageText={'‹'}
              nextPageText={'›'}
              onChange={(e) => handlePage(e)}
              itemClass="pageBtn"
              activeClass="pageBtn on"
            />
          </div>
        </div>
      </div>
    </>
  );
}
