import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLoading } from '@config/loadingContext';
import NumberFormat from 'react-number-format';
import { stringNumberFunc, roundNumberFunc } from '@utils/common';
import { CalcDummy, CalcItem } from '@dummydata/calcDummy';
import axios, { AxiosRequestConfig } from 'axios';
import { MEGACodeUrl } from '@config/path';
import { Data } from '@interfaces/tableCost.interfaces';
import {
  ICommonEachCalculation,
  TableName,
  NewCalcSelect,
} from '@interfaces/calculationProductModel.interface';
import { CONFIRM } from '@utils/textMessage';
import MegaModalCalc from '@components/modal/megaModal-calcCost';
import CalcCustom from '@components/modal/customModal-calcCost';
import CalcReorder from '@components/table/standardproduct/reorder/calc-reorder';
interface IProps {
  SitedNow: string;
  calcType: string;
  newItem: ICommonEachCalculation;
  setNewItem: Dispatch<SetStateAction<ICommonEachCalculation>>;
  newTable: TableName[];
  setNewTable: Dispatch<SetStateAction<TableName[]>>;
}
export default function SetAddWorkPrice({
  SitedNow,
  calcType,
  newItem,
  setNewItem,
  newTable,
  setNewTable,
}: IProps) {
  const { loadingHandler } = useLoading();
  /** 아이템 삭제  */
  const DelCalcItem = (idx: number) => {
    if (window.confirm(CONFIRM.DEL)) {
      let copy = { ...newItem };
      copy.calcValue.splice(idx, 1);
      setNewItem(copy);
      let tableCopy = [...newTable];
      tableCopy.splice(idx, 1);
    }
  };
  /**S: Onchange 기능구현  */
  const ChangeInnerItem = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number,
  ) => {
    const { name, value } = e.target;
    let copy = { ...newItem };
    if (name !== 'value') {
      copy.calcValue[idx][name] = value;
      setNewItem({
        ...copy,
      });
    } else {
      let NumValue =
        value !== '0' && value !== '' ? Number(value.replace(/,/g, '')) : 0;
      copy.calcValue[idx][name] = NumValue;
      setNewItem({
        ...copy,
      });
    }
  };
  const ChangeInnerType = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number,
  ) => {
    const { value } = e.target;
    let copy = { ...newItem };
    copy.calcValue[idx] = { ...CalcItem, adminType: value };
    if (value == 'numbers') {
      copy.calcValue[idx].qty = [0];
    }
    setNewItem({
      ...copy,
    });
  };
  /** S: Number Array */
  const ChangeInnerQty = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number,
    idxA: number,
  ) => {
    const { value } = e.target;
    let copy = { ...newItem };
    let NumValue =
      value !== '0' && value !== '' ? Number(value.replace(/,/g, '')) : 0;
    copy.calcValue[idx].qty[idxA] = NumValue;
    setNewItem({
      ...copy,
    });
  };
  /*줄추가*/
  const AddNumberArray = (idx: number) => {
    let copy = { ...newItem };
    copy.calcValue[idx].qty = [...copy.calcValue[idx].qty, 0];
    setNewItem({
      ...copy,
    });
  };
  /*줄삭제*/
  const DelNumberArray = (idx: number) => {
    let copy = { ...newItem };
    let lengthNow = copy.calcValue[idx].qty.length - 1;
    copy.calcValue[idx].qty.splice(lengthNow, 1);
    setNewItem({
      ...copy,
    });
  };
  /** E: Number Array */

  /** S: Table Data */
  /** 리오더오픈여부 state*/
  const ChangeTableName = (idx: number, type: number) => {
    let copy = [...newTable];
    if (type == 1) {
      copy[idx] = {
        min: 0,
        max: 0,
        value: 0,
        siteValue: 0,
      };
    } else if (type == 2) {
      copy[idx] = {
        min: 0,
        max: 0,
        value: 0,
        siteValue: 0,
        secondValue: 0,
        secondSiteValue: 0,
      };
    } else {
      copy[idx] = {
        min: 0,
        max: 0,
        value: 0,
        siteValue: 0,
        secondValue: 0,
        secondSiteValue: 0,
        thirdValue: 0,
        thirdSiteValue: 0,
      };
    }
    setNewTable([...copy]);
  };
  const [reOrder, setReOrder] = useState(false);
  const [reOrderIndex, setReOrderIndex] = useState([0, 0]);
  const [reOrderColumns, setReOrderColumns] = useState<NewCalcSelect[]>([]);
  const onCalcReorder = (index: number, indexA: number) => {
    let copyItem = newItem.calcValue[index];
    if (copyItem) {
      let copyItemB =
        copyItem.adminSelecters && copyItem.adminSelecters[indexA].select;
      if (copyItemB) {
        if (copyItemB.length < 2) {
          alert('주문옵션이 2개이상이어야 합니다');
        } else {
          copyItemB && setReOrderColumns(copyItemB);
          setReOrder(!reOrder);
          setReOrderIndex([index, indexA]);
        }
      }
    }
  };
  /**
   * 전체 모달
   */
  const [CalcModal, setCalcModal] = useState<{ [index: string]: boolean }>({
    mega: false,
    reorder: false,
    pushId: false,
    custom: false,
  });
  const openCalcModal = (str: string) => {
    let copy = { mega: false, reorder: false, pushId: false, custom: false };
    setCalcModal({ ...copy, [str]: true });
  };
  const closeCalcModal = () => {
    setCalcModal({ mega: false, reorder: false, pushId: false, custom: false });
  };
  /**
   * 메가모달 옵션준비
   */
  const [megaData, setMegaData] = useState<Data[]>([]);
  useEffect(() => {
    async function getMegaData() {
      await axios
        .get(MEGACodeUrl)
        .then(function (response) {
          setMegaData(response.data);
        })
        .catch(function (error) {
          console.log('메가 데이터 로드에 실패 하였습니다.');
        });
    }
    getMegaData();
  }, []);

  const [modalIndex, setModalIndex] = useState([0, 0]);
  const [megaNav, setMegaNav] = useState<{
    kind: string;
    items: string[];
  } | null>(null);
  const changeMegaNav = (index: number, indexA: number) => {
    setModalIndex([index, indexA]);
    if (newItem.calcValue[index].adminSelecters) {
      let bcode = newItem.calcValue[index];
      if (bcode.adminSelecters) {
        let ckind = bcode.adminSelecters[indexA].codeCategory;
        let ccode = bcode.adminSelecters[indexA].select.map((b) => {
          return b.code;
        });
        ccode && ccode.length > 0 && setMegaNav({ kind: ckind, items: ccode });
      }
    }
  };
  const newMegaNav = (index: number, indexA: number) => {
    setMegaNav(null);
    setModalIndex([index, indexA]);
  };

  const delCalcOption = (idx: number, idxA: number) => {
    if (window.confirm(CONFIRM.DEL)) {
      let copy = { ...newItem };
      copy.calcValue[idx].adminSelecters?.splice(idxA, 1);
      setNewItem(copy);
    }
  };
  /**  테이블 만들기 */
  const CreateTable = (idx: number) => {
    let copy = { ...newItem };
    let final = copy.calcValue[idx].adminSelecters;
    let finalFilter = final ? final : [];
    let lateN = finalFilter.length;
    const factorial = (n: number): number => {
      let fa = 1;
      if (n > 0) {
        return finalFilter[n - 1].select.length * factorial(n - 1);
      }
      return fa;
    };
    const arrayFacto = (n: number): number => {
      let fb = 1;
      if (lateN > n + 1) {
        return finalFilter[n + 1].select.length * arrayFacto(n + 1);
      }
      return fb;
    };
    let total = factorial(lateN);
    let NT = JSON.parse(JSON.stringify(newTable[idx]));
    let newArray = [];
    let newArrayInner = [];
    let emptyPrice = [];
    for (let ei = 0; ei < 5; ei++) {
      emptyPrice.push(NT);
    }
    let ini = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
    for (let i = 0; i < total; i++) {
      for (let i2 = 0; i2 < lateN; i2++) {
        newArrayInner.push({
          title: String(
            finalFilter[i2].select[ini[i2] - 1].title
              ? finalFilter[i2].select[ini[i2] - 1].title
              : '',
          ),
          code: finalFilter[i2].select[ini[i2] - 1].code,
        });
        if ((i + 1) % arrayFacto(i2) === 0) {
          ini[i2] === finalFilter[i2].select.length ? (ini[i2] = 1) : ini[i2]++;
        }
      }
      newArray.push({
        selectors: [...newArrayInner],
        value: [...emptyPrice],
      });
      newArrayInner = [];
    }
    copy.calcValue[idx].option = newArray;
    setNewItem(copy);
    return copy;
  };
  /**항목명 변경 Function */
  const KeyChanger = (type: string, str: string) => {
    let result =
      type === 'impositionStartEnd' ||
      type === 'imposition250Price' ||
      type === 'impositionMaxPrice' ||
      type === 'impositionDiscount'
        ? str == 'max'
          ? '조판수'
          : str === 'value'
          ? '시작가'
          : str === 'siteValue'
          ? '시작가 수정'
          : str === 'secondValue'
          ? '종료가'
          : str === 'secondSiteValue'
          ? '종료가 수정'
          : str
        : type === 'domusongPrice'
        ? str === 'value'
          ? '원가'
          : str
        : str == 'max'
        ? '최대'
        : str === 'min'
        ? '최소'
        : str === 'value'
        ? '데이터'
        : str === 'siteValue'
        ? '수정데이터'
        : str === 'secondValue'
        ? '데이터-2'
        : str === 'secondSiteValue'
        ? '수정데이터-2'
        : str === 'thirdValue'
        ? '데이터-3'
        : str === 'thirdSiteValue'
        ? '수정데이터-3'
        : str;
    return result;
  };
  /**
   * 가격 handler
   * @param e input event
   * @param idxA price index
   * @param idxC price.value index
   */
  const handlePrice = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number,
    idxA: number,
    idxC: number,
  ) => {
    const { name, value } = e.target;
    let copyData = { ...newItem };
    let copyPrc = { ...copyData.calcValue[idx].option[idxA].value[idxC] };
    value !== '0' && value !== ''
      ? (copyPrc = { ...copyPrc, [name]: Number(value.replace(/,/g, '')) })
      : (copyPrc = { ...copyPrc, [name]: 0 });

    copyData.calcValue[idx].option[idxA].value[idxC] = copyPrc;
    copyData && setNewItem(copyData);
  };

  /**
   * 가격인풋에서의 키보드 액션
   * @param id 이동기준 index string
   * @param code 누른 키보드 key
   */
  const [idAll, setIdAll] = useState<string>('');
  const keyPressNow = (
    event: React.KeyboardEvent<HTMLElement>,
    id: string,
    code: string,
    idx: number,
  ) => {
    let idB = Number(id.split('-')[2]);
    let idC = Number(id.split('-')[3]);
    let idD = Number(id.split('-')[4]);
    let lengthB = newItem.calcValue[idx].option.length - 1;
    let lengthC = newItem.calcValue[idx].option[idB].value.length - 1;
    let lengthD =
      Object.keys(newItem.calcValue[idx].option[idB].value[idC]).filter(
        (a) =>
          a !== '_id' &&
          a !== 'siteValue' &&
          a !== 'secondSiteValue' &&
          a !== 'thirdSiteValue',
      ).length - 1;
    if (!event.shiftKey) {
      if (code === 'ArrowRight') {
        if (idB < lengthB) {
          if (idD < lengthD) {
            idD++;
          } else {
            idB++;
            idD = 0;
          }
        } else {
          if (idD < lengthD) {
            idD++;
          } else {
            alert('마지막 가격표입니다.');
          }
        }
      } else if (
        code === 'NumpadEnter' ||
        code === 'Enter' ||
        code === 'ArrowDown'
      ) {
        if (idC < lengthC) {
          idC++;
        } else {
          alert('최하단입니다.');
          return;
        }
      } else if (code === 'ArrowUp') {
        if (idC > 0) {
          idC--;
        } else {
          alert('최상단입니다.');
          return;
        }
      } else if (code === 'ArrowLeft') {
        if (idB > 0) {
          if (idD !== 0) {
            idD--;
          } else {
            idB--;
            idD = lengthD;
          }
        } else {
          if (idD !== 0) {
            idD--;
          } else {
            alert('첫 가격표입니다.');
          }
        }
      }
      setIdAll('input-' + idx + '-' + idB + '-' + idC + '-' + idD);
    }
  };
  useEffect(() => document.getElementById(idAll)?.focus(), [idAll]);

  /**
   * 개별 줄추가 액션
   * @param idx calcValue index
   * @param idxA option index
   * @param idxC value index
   */
  const addLine = (idx: number, idxA: number, idxC: number) => {
    let instant = { ...newItem }; //.calcValue[idx].option];
    let tableName = Object.keys(newItem.calcValue[idx].option[idxA].value[0]);
    let init: { [index: string]: number } = {};
    const tableNow = tableName.reduce((acc, curr) => {
      acc[curr] = 0;
      return acc;
    }, init);
    delete tableNow._id;
    instant.calcValue[idx].option[idxA].value.splice(
      idxC,
      0,
      JSON.parse(JSON.stringify(tableNow)),
    );
    let NewCopy = JSON.parse(JSON.stringify(instant));
    setNewItem({ ...NewCopy });
  };
  /**
   * 아래로 줄추가 액션
   * @param idx calcValue index
   * @param idxA option index
   */
  const addMoreLine = (idx: number, idxA: number) => {
    let instant = { ...newItem }; //.calcValue[idx].option];
    let tableName = Object.keys(newItem.calcValue[idx].option[idxA].value[0]);
    let init: { [index: string]: number } = {};
    const tableNow = tableName.reduce((acc, curr) => {
      acc[curr] = 0;
      return acc;
    }, init);
    delete tableNow._id;
    instant.calcValue[idx].option[idxA].value.push(
      JSON.parse(JSON.stringify(tableNow)),
    );
    let NewCopy = JSON.parse(JSON.stringify(instant));
    setNewItem({ ...NewCopy });
  };
  /**
   * 개별 줄제거 액션
   * @param line 제거될줄 index number
   */
  const delLine = (idx: number, idxA: number, idxC: number) => {
    let instant = { ...newItem };
    instant.calcValue[idx].option[idxA].value.splice(idxC, 1);
    let NewCopy = JSON.parse(JSON.stringify(instant));
    instant.calcValue[idx].option[idxA].value.length > 0
      ? setNewItem({ ...NewCopy })
      : alert('최소 1줄은 필요합니다.');
  };
  /** 복사 state */
  const [copiedArray, setCopiedArray] = useState<TableName[]>([]);
  useEffect(() => {
    copiedArray.length > 0 && console.log('카피데이터있음');
  }, [copiedArray]);
  /**
   * 복사 액션
   * @param n 복사된 index string
   */
  const priceCopy = async (idx: number, idxA: number) => {
    loadingHandler(true);
    let copyData = JSON.parse(
      JSON.stringify(newItem.calcValue[idx].option[idxA].value),
    );
    let noID = copyData.map((a: TableName) => {
      delete a._id;
      return a;
    });
    if (noID) {
      setCopiedArray(noID);
      alert('데이터 복사성공!');
      loadingHandler(false);
    } else {
      alert('데이터 복사에 실패하였습니다. 다시 복사버튼을 눌러주세요.');
      loadingHandler(false);
    }
  };
  /**
   * 붙여넣기 액션
   * @param n 붙여넣을 index string
   * @constant newItem
   */
  const pricePaste = (idx: number, idxA: number) => {
    loadingHandler(true);
    let copy = JSON.parse(JSON.stringify(newItem.calcValue));
    copiedArray.length > 0
      ? ((copy[idx].option[idxA].value = [...copiedArray]),
        setNewItem({
          ...newItem,
          calcValue: copy,
        }))
      : alert('카피한 데이터가 없습니다.');

    loadingHandler(false);
  };
  /** E: Table Data */
  // const godummy = () => {
  //   setNewItem({ ...YAddworkDummy });
  // };
  return (
    <>
      {newItem.title == '롤스티커 폭조판관련표' ? (
        <>
          <div className="newitem-bg contents-wrap x_panel p-4 mb-3">
            <div className="newoption-wrap form-group">
              <div className="row">
                <div className="newoption-title cost-title col-md-2 col-sm-2 col-xs-12">
                  테이블형 데이터 (option)
                </div>
                <div className="newoption-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                  <div className="newoption-price-table position-wrap">
                    <div className="price-table-wrap">
                      <div className="pr-block-outer">
                        {newItem.calcValue.map((code, idx) => {
                          return code.option.map((codeA, idxA) => {
                            let NewTableKeys = Object.keys(
                              codeA.value[0],
                            ).filter((a) =>
                              SitedNow == 'standard'
                                ? a !== '_id' &&
                                  a !== 'siteValue' &&
                                  a !== 'secondSiteValue' &&
                                  a !== 'thirdSiteValue'
                                : a !== '_id',
                            );
                            return (
                              <div
                                key={'table-' + idxA}
                                className="d-inline-block pr-table-wrap"
                              >
                                <table className="table table-striped yTable">
                                  <thead>
                                    {codeA.selectors && (
                                      <tr>
                                        <th
                                          colSpan={NewTableKeys.length + 1}
                                          className="blueHead"
                                        >
                                          <span>{code.calcTypeKO}</span>
                                        </th>
                                      </tr>
                                    )}
                                    <tr>
                                      {NewTableKeys.filter((codeB) =>
                                        SitedNow == 'standard'
                                          ? codeB !== 'min' &&
                                            codeB !== 'siteValue' &&
                                            codeB !== 'secondSiteValue' &&
                                            codeB !== 'thirdSiteValue'
                                          : codeB !== 'min',
                                      ).map((codeC) => {
                                        return (
                                          <th key={'span-' + codeC}>
                                            {KeyChanger(code.calcTypeEN, codeC)}
                                          </th>
                                        );
                                      })}
                                      <th>줄관리</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {codeA.value &&
                                      codeA.value.map((codeC, idxC) => {
                                        let NewTableEntry = Object.entries(
                                          codeC,
                                        ).filter((a) =>
                                          SitedNow == 'standard'
                                            ? a[0] !== '_id' &&
                                              a[0] !== 'min' &&
                                              a[0] !== 'siteValue' &&
                                              a[0] !== 'secondSiteValue' &&
                                              a[0] !== 'thirdSiteValue'
                                            : a[0] !== '_id' && a[0] !== 'min',
                                        );
                                        return (
                                          <tr key={idxA + '-' + idxC}>
                                            {NewTableEntry.map(
                                              (codeD, idxD) => {
                                                return (
                                                  <td key={'td-' + idxD}>
                                                    <NumberFormat
                                                      id={
                                                        'input-' +
                                                        idx +
                                                        '-' +
                                                        idxA +
                                                        '-' +
                                                        idxC +
                                                        '-' +
                                                        idxD
                                                      }
                                                      className="val-input"
                                                      type="text"
                                                      name={codeD[0]}
                                                      value={
                                                        codeD[1] !== 0
                                                          ? codeD[1]
                                                          : ''
                                                      }
                                                      onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>,
                                                      ) =>
                                                        handlePrice(
                                                          e,
                                                          idx,
                                                          idxA,
                                                          idxC,
                                                        )
                                                      }
                                                      autoFocus={true}
                                                      autoComplete="off"
                                                      thousandSeparator
                                                      onKeyDown={(
                                                        event: React.KeyboardEvent<HTMLElement>,
                                                      ) => {
                                                        return keyPressNow(
                                                          event,
                                                          (
                                                            event.target as HTMLElement
                                                          ).id,
                                                          event.code,
                                                          idx,
                                                        );
                                                      }}
                                                      disabled={
                                                        SitedNow !==
                                                          'standard' &&
                                                        codeD[0] !==
                                                          'siteValue' &&
                                                        codeD[0] !==
                                                          'secondSiteValue' &&
                                                        codeD[0] !==
                                                          'thirdSiteValue'
                                                      }
                                                    />
                                                  </td>
                                                );
                                              },
                                            )}
                                            <td>
                                              <div
                                                className="line-tools line-tool-plus"
                                                id={`plus-${idxC}`}
                                                onClick={() =>
                                                  addLine(idx, idxA, idxC)
                                                }
                                              >
                                                <i className="fa fa-plus"></i>
                                              </div>
                                              <div
                                                className="line-tools line-tool-minus"
                                                id={`minus-${idxC}`}
                                                onClick={() =>
                                                  delLine(idx, idxA, idxC)
                                                }
                                              >
                                                <i className="fa fa-minus"></i>
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    <tr className="table-line-bottom">
                                      <td colSpan={NewTableKeys.length + 1}>
                                        <div className="text-center">
                                          <div
                                            className="line-tools line-tool-plus-bottom"
                                            id={`plusline-${idx + idxA}`}
                                            onClick={() =>
                                              addMoreLine(idx, idxA)
                                            }
                                          >
                                            <i className="fa fa-plus"></i>
                                            줄추가
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            );
                          });
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {newItem.calcValue.map((code, idx) => {
            let selectersLength = code.adminSelecters
              ? code.adminSelecters.length
              : 1;
            let newOption =
              newItem.calcValue[idx].option != null
                ? newItem.calcValue[idx].option
                : [];
            return (
              <React.Fragment key={idx}>
                {/* <button onClick={() => godummy()}>고더미</button> */}
                <div className="newitem-bg contents-wrap x_panel p-4 mb-3">
                  <div
                    className="calcItemClose"
                    onClick={() => DelCalcItem(idx)}
                  >
                    <p>
                      {'Data-' +
                        (idx < 9 ? '0' + (Number(idx) + 1) : Number(idx) + 1)}
                    </p>
                    <i className="fa fa-times"></i>
                  </div>
                  <div className="x_content">
                    <div className="form-horizontal form-label-left">
                      {code.adminType && (
                        <div className="newoption-wrap form-group">
                          <div className="row">
                            <div className="newoption-title cost-title col-md-2 col-sm-2 col-xs-12">
                              데이터종류 (adminType)
                            </div>
                            <div className="newoption-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                              <div className="newoption-radio">
                                <input
                                  type="radio"
                                  className="boxInputs"
                                  id={'adminTypesingleNumber' + idx}
                                  name={'adminType' + idx}
                                  value="singleNumber"
                                  checked={code.adminType == 'singleNumber'}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) => {
                                    ChangeInnerType(e, idx);
                                  }}
                                />
                                <label htmlFor={'adminTypesingleNumber' + idx}>
                                  단일숫자형
                                </label>
                              </div>
                              <div className="newoption-radio">
                                <input
                                  type="radio"
                                  className="boxInputs"
                                  id={'adminTypenumbers' + idx}
                                  name={'adminType' + idx}
                                  value="numbers"
                                  checked={code.adminType == 'numbers'}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) => {
                                    ChangeInnerType(e, idx);
                                  }}
                                />
                                <label htmlFor={'adminTypenumbers' + idx}>
                                  수량형
                                </label>
                              </div>
                              <div className="newoption-radio">
                                <input
                                  type="radio"
                                  className="boxInputs"
                                  id={'adminTypeselecters' + idx}
                                  name={'adminType' + idx}
                                  value="selectors"
                                  checked={code.adminType == 'selectors'}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) => {
                                    ChangeInnerType(e, idx);
                                  }}
                                />
                                <label htmlFor={'adminTypeselecters' + idx}>
                                  테이블형
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="newoption-wrap form-group">
                        <div className="row">
                          <div className="newoption-title cost-title col-md-2 col-sm-2 col-xs-12">
                            데이터 타이틀 (calcTypeKO)
                          </div>
                          <div className="newoption-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                            <input
                              type="text"
                              className="form-control halfinput"
                              name="calcTypeKO"
                              value={code.calcTypeKO}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                ChangeInnerItem(e, idx);
                              }}
                              disabled={SitedNow !== 'standard'}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="newoption-wrap form-group">
                        <div className="row">
                          <div className="newoption-title cost-title col-md-2 col-sm-2 col-xs-12">
                            데이터 코드 (calcTypeEN)
                          </div>
                          <div className="newoption-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                            <input
                              type="text"
                              className="form-control halfinput"
                              name="calcTypeEN"
                              value={code.calcTypeEN}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                ChangeInnerItem(e, idx);
                              }}
                              disabled={SitedNow !== 'standard'}
                            />
                          </div>
                        </div>
                      </div>
                      {code.adminType == 'selectors' ||
                      (!code.adminType && code.option.length > 0) ? (
                        <>
                          {code.adminType && (
                            <>
                              <div className="newoption-wrap form-group">
                                <div className="row">
                                  <div className="newoption-title cost-title col-md-2 col-sm-2 col-xs-12">
                                    테이블 옵션선택 (adminSelecters)
                                  </div>
                                  <div className="newoption-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                                    {newItem.calcValue[idx].adminSelecters &&
                                      newItem.calcValue[
                                        idx
                                      ].adminSelecters?.map((dataA, idxA) => {
                                        return (
                                          <div
                                            className="newoption-preview"
                                            key={
                                              dataA.codeCategory + idx + idxA
                                            }
                                          >
                                            <div className="newoption-prev-title">
                                              {dataA.title}
                                            </div>
                                            <div className="newoption-prev-cont">
                                              <select className="">
                                                {dataA.select.map((dataB) => {
                                                  return (
                                                    <option
                                                      value={dataB.code}
                                                      key={
                                                        dataB.code + idx + idxA
                                                      }
                                                    >
                                                      {dataB.title}
                                                    </option>
                                                  );
                                                })}
                                              </select>
                                              <div className="d-inline-block">
                                                {dataA.codeCategory !==
                                                'customCode' ? (
                                                  <button
                                                    className="tableBtn tableBtn-orange btnW-70 newoption-editbtn"
                                                    onClick={() => [
                                                      closeCalcModal(),
                                                      changeMegaNav(idx, idxA),
                                                      openCalcModal('mega'),
                                                    ]}
                                                  >
                                                    <i className="fa fa-edit"></i>
                                                    수정
                                                  </button>
                                                ) : (
                                                  <button
                                                    className="tableBtn tableBtn-orange btnW-90 newoption-editbtn"
                                                    onClick={() => [
                                                      closeCalcModal(),
                                                      changeMegaNav(idx, idxA),
                                                      openCalcModal('custom'),
                                                    ]}
                                                  >
                                                    <i className="fa fa-edit"></i>
                                                    커스텀수정
                                                  </button>
                                                )}
                                              </div>
                                              <div className="d-inline-block">
                                                <button
                                                  className="tableBtn tableBtn-red btnW-70 newoption-editbtn ms-0 me-2"
                                                  onClick={() =>
                                                    delCalcOption(idx, idxA)
                                                  }
                                                >
                                                  <i className="fa fa-times"></i>
                                                  삭제
                                                </button>
                                              </div>
                                              <div className="d-inline-block">
                                                <button
                                                  className="tableBtn tableBtn-green btnW-90 newoption-editbtn ms-0"
                                                  onClick={() =>
                                                    onCalcReorder(idx, idxA)
                                                  }
                                                >
                                                  <i className="fa fa-sort"></i>{' '}
                                                  순서변경
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    <div className="input-group-btn mt-3 mb-3 mx-auto d-block">
                                      <div className="text-center w-420">
                                        <div
                                          className="yBtn yBtn-small gray text-center d-inline-block me-2"
                                          onClick={() => [
                                            closeCalcModal(),
                                            newMegaNav(idx, selectersLength),
                                            openCalcModal('mega'),
                                          ]}
                                        >
                                          <i className="fa fa-plus"></i> 옵션
                                          추가하기
                                        </div>
                                        <div
                                          className="yBtn yBtn-small gray text-center d-inline-block me-2"
                                          onClick={() => [
                                            closeCalcModal(),
                                            newMegaNav(idx, selectersLength),
                                            openCalcModal('custom'),
                                          ]}
                                        >
                                          <i className="fa fa-plus"></i>{' '}
                                          커스텀옵션
                                        </div>
                                        <div
                                          className="yBtn yBtn-small blue text-center d-inline-block"
                                          onClick={() => CreateTable(idx)}
                                        >
                                          <i className="fa fa-table"></i> 테이블
                                          만들기
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="newoption-wrap form-group">
                                <div className="row">
                                  <div className="newoption-title cost-title col-md-2 col-sm-2 col-xs-12">
                                    테이블형 데이터 갯수 (no. of value)
                                  </div>
                                  <div className="newoption-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                                    <div className="newoption-radio">
                                      <input
                                        type="radio"
                                        className="boxInputs"
                                        id={'noValue01' + idx}
                                        name={'noValue' + idx}
                                        value="1"
                                        checked={
                                          newTable &&
                                          newTable[idx] &&
                                          Object.keys(newTable[idx]).length == 4
                                        }
                                        onChange={() => {
                                          ChangeTableName(idx, 1);
                                        }}
                                      />
                                      <label htmlFor={'noValue01' + idx}>
                                        1개
                                      </label>
                                    </div>
                                    <div className="newoption-radio">
                                      <input
                                        type="radio"
                                        className="boxInputs"
                                        id={'noValue02' + idx}
                                        name={'noValue' + idx}
                                        value="2"
                                        checked={
                                          newTable &&
                                          newTable[idx] &&
                                          Object.keys(newTable[idx]).length == 6
                                        }
                                        onChange={() => {
                                          ChangeTableName(idx, 2);
                                        }}
                                      />
                                      <label htmlFor={'noValue02' + idx}>
                                        2개
                                      </label>
                                    </div>
                                    <div className="newoption-radio">
                                      <input
                                        type="radio"
                                        className="boxInputs"
                                        id={'noValue03' + idx}
                                        name={'noValue' + idx}
                                        value="3"
                                        checked={
                                          newTable &&
                                          newTable[idx] &&
                                          Object.keys(newTable[idx]).length == 8
                                        }
                                        onChange={() => {
                                          ChangeTableName(idx, 3);
                                        }}
                                      />
                                      <label htmlFor={'noValue03' + idx}>
                                        3개
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {newOption.length > 0 && (
                            <div className="newoption-wrap form-group">
                              <div className="row">
                                <div className="newoption-title cost-title col-md-2 col-sm-2 col-xs-12">
                                  테이블형 데이터 (option)
                                </div>
                                <div className="newoption-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                                  <div className="newoption-price-table position-wrap">
                                    <div className="price-table-wrap">
                                      <div className="pr-block-outer">
                                        {newOption &&
                                          newOption.map((codeA, idxA) => {
                                            let NewTableKeys =
                                              newItem.title !==
                                              '롤스티커 난이도별 도무송비'
                                                ? Object.keys(
                                                    codeA.value[0],
                                                  ).filter((a) =>
                                                    SitedNow == 'standard'
                                                      ? a !== '_id' &&
                                                        a !== 'siteValue' &&
                                                        a !==
                                                          'secondSiteValue' &&
                                                        a !== 'thirdSiteValue'
                                                      : a !== '_id',
                                                  )
                                                : Object.keys(
                                                    codeA.value[0],
                                                  ).filter((a) =>
                                                    SitedNow == 'standard'
                                                      ? a !== '_id' &&
                                                        a !== 'min' &&
                                                        a !== 'max' &&
                                                        a !== 'siteValue' &&
                                                        a !==
                                                          'secondSiteValue' &&
                                                        a !== 'thirdSiteValue'
                                                      : a !== '_id' &&
                                                        a !== 'min' &&
                                                        a !== 'max',
                                                  );
                                            return (
                                              <div
                                                key={'table-' + idxA}
                                                className="d-inline-block pr-table-wrap"
                                              >
                                                <table className="table table-striped yTable">
                                                  <thead>
                                                    {codeA.selectors && (
                                                      <tr>
                                                        <th
                                                          colSpan={
                                                            newItem.title !==
                                                            '롤스티커 난이도별 도무송비'
                                                              ? NewTableKeys.length +
                                                                1
                                                              : NewTableKeys.length
                                                          }
                                                          className="blueHead"
                                                        >
                                                          {codeA.selectors.map(
                                                            (aa) => {
                                                              return (
                                                                <span
                                                                  key={
                                                                    'span-' +
                                                                    aa.code
                                                                  }
                                                                >
                                                                  {aa.title}
                                                                </span>
                                                              );
                                                            },
                                                          )}
                                                        </th>
                                                      </tr>
                                                    )}
                                                    <tr>
                                                      {NewTableKeys.filter(
                                                        (codeB) =>
                                                          SitedNow == 'standard'
                                                            ? codeB !==
                                                                'siteValue' &&
                                                              codeB !==
                                                                'secondSiteValue' &&
                                                              codeB !==
                                                                'thirdSiteValue'
                                                            : codeB,
                                                      ).map((codeC) => {
                                                        return (
                                                          <th
                                                            key={
                                                              'span-' + codeC
                                                            }
                                                          >
                                                            {KeyChanger(
                                                              code.calcTypeEN,
                                                              codeC,
                                                            )}
                                                          </th>
                                                        );
                                                      })}
                                                      {newItem.title !==
                                                        '롤스티커 난이도별 도무송비' && (
                                                        <th>줄관리</th>
                                                      )}
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {codeA.value &&
                                                      codeA.value.map(
                                                        (codeC, idxC) => {
                                                          let NewTableEntry =
                                                            newItem.title !==
                                                            '롤스티커 난이도별 도무송비'
                                                              ? Object.entries(
                                                                  codeC,
                                                                ).filter((a) =>
                                                                  SitedNow ==
                                                                  'standard'
                                                                    ? a[0] !==
                                                                        '_id' &&
                                                                      a[0] !==
                                                                        'siteValue' &&
                                                                      a[0] !==
                                                                        'secondSiteValue' &&
                                                                      a[0] !==
                                                                        'thirdSiteValue'
                                                                    : a[0] !==
                                                                      '_id',
                                                                )
                                                              : Object.entries(
                                                                  codeC,
                                                                ).filter((a) =>
                                                                  SitedNow ==
                                                                  'standard'
                                                                    ? a[0] !==
                                                                        '_id' &&
                                                                      a[0] !==
                                                                        'min' &&
                                                                      a[0] !==
                                                                        'max' &&
                                                                      a[0] !==
                                                                        'siteValue' &&
                                                                      a[0] !==
                                                                        'secondSiteValue' &&
                                                                      a[0] !==
                                                                        'thirdSiteValue'
                                                                    : a[0] !==
                                                                        '_id' &&
                                                                      a[0] !==
                                                                        'min' &&
                                                                      a[0] !==
                                                                        'max',
                                                                );
                                                          return (
                                                            <tr
                                                              key={
                                                                idxA +
                                                                '-' +
                                                                idxC
                                                              }
                                                            >
                                                              {NewTableEntry.map(
                                                                (
                                                                  codeD,
                                                                  idxD,
                                                                ) => {
                                                                  return (
                                                                    <td
                                                                      key={
                                                                        'td-' +
                                                                        idxD
                                                                      }
                                                                    >
                                                                      <NumberFormat
                                                                        id={
                                                                          'input-' +
                                                                          idx +
                                                                          '-' +
                                                                          idxA +
                                                                          '-' +
                                                                          idxC +
                                                                          '-' +
                                                                          idxD
                                                                        }
                                                                        className="val-input"
                                                                        type="text"
                                                                        name={
                                                                          codeD[0]
                                                                        }
                                                                        value={
                                                                          codeD[1] !==
                                                                          0
                                                                            ? codeD[1]
                                                                            : ''
                                                                        }
                                                                        onChange={(
                                                                          e: React.ChangeEvent<HTMLInputElement>,
                                                                        ) =>
                                                                          handlePrice(
                                                                            e,
                                                                            idx,
                                                                            idxA,
                                                                            idxC,
                                                                          )
                                                                        }
                                                                        autoFocus={
                                                                          true
                                                                        }
                                                                        autoComplete="off"
                                                                        thousandSeparator
                                                                        onKeyDown={(
                                                                          event: React.KeyboardEvent<HTMLElement>,
                                                                        ) => {
                                                                          return keyPressNow(
                                                                            event,
                                                                            (
                                                                              event.target as HTMLElement
                                                                            )
                                                                              .id,
                                                                            event.code,
                                                                            idx,
                                                                          );
                                                                        }}
                                                                        disabled={
                                                                          SitedNow !==
                                                                            'standard' &&
                                                                          codeD[0] !==
                                                                            'siteValue' &&
                                                                          codeD[0] !==
                                                                            'secondSiteValue' &&
                                                                          codeD[0] !==
                                                                            'thirdSiteValue'
                                                                        }
                                                                      />
                                                                    </td>
                                                                  );
                                                                },
                                                              )}
                                                              {newItem.title !==
                                                                '롤스티커 난이도별 도무송비' && (
                                                                <td>
                                                                  <div
                                                                    className="line-tools line-tool-plus"
                                                                    id={`plus-${idxC}`}
                                                                    onClick={() =>
                                                                      addLine(
                                                                        idx,
                                                                        idxA,
                                                                        idxC,
                                                                      )
                                                                    }
                                                                  >
                                                                    <i className="fa fa-plus"></i>
                                                                  </div>
                                                                  <div
                                                                    className="line-tools line-tool-minus"
                                                                    id={`minus-${idxC}`}
                                                                    onClick={() =>
                                                                      delLine(
                                                                        idx,
                                                                        idxA,
                                                                        idxC,
                                                                      )
                                                                    }
                                                                  >
                                                                    <i className="fa fa-minus"></i>
                                                                  </div>
                                                                </td>
                                                              )}
                                                            </tr>
                                                          );
                                                        },
                                                      )}
                                                    <tr className="table-line-bottom">
                                                      <td
                                                        colSpan={
                                                          newItem.title !==
                                                          '롤스티커 난이도별 도무송비'
                                                            ? NewTableKeys.length +
                                                              1
                                                            : NewTableKeys.length
                                                        }
                                                      >
                                                        <div
                                                          className="price-tools"
                                                          id={
                                                            'copyBtn-' +
                                                            idx +
                                                            '-' +
                                                            idxA
                                                          }
                                                          onClick={(
                                                            event: React.MouseEvent<HTMLElement>,
                                                          ) =>
                                                            priceCopy(idx, idxA)
                                                          }
                                                        >
                                                          <i className="fa fa-clone"></i>{' '}
                                                          복사
                                                        </div>
                                                        <div
                                                          id={
                                                            'pasteBtn-' +
                                                            idx +
                                                            '-' +
                                                            idxA
                                                          }
                                                          className="price-tools"
                                                          onClick={(
                                                            event: React.MouseEvent<HTMLElement>,
                                                          ) =>
                                                            pricePaste(
                                                              idx,
                                                              idxA,
                                                            )
                                                          }
                                                        >
                                                          <i className="fa fa-paste"></i>{' '}
                                                          붙여넣기
                                                        </div>
                                                        <div
                                                          className="line-tools line-tool-plus-bottom"
                                                          id={`plusline-${
                                                            idx + idxA
                                                          }`}
                                                          onClick={() =>
                                                            addMoreLine(
                                                              idx,
                                                              idxA,
                                                            )
                                                          }
                                                        >
                                                          <i className="fa fa-plus"></i>
                                                          줄추가
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ) : code.adminType == 'numbers' ||
                        (!code.adminType && code.qty.length > 0) ? (
                        <div className="newoption-wrap form-group">
                          <div className="row">
                            <div className="newoption-title cost-title col-md-2 col-sm-2 col-xs-12">
                              수량형 데이터 (qty)
                            </div>
                            <div className="newoption-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                              {code.qty?.map((codeA, idxA) => {
                                return (
                                  <NumberFormat
                                    key={idxA}
                                    type="text"
                                    className="form-control quarterinput d-inline-block me-1"
                                    name="qty"
                                    value={codeA}
                                    thousandSeparator
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                      ChangeInnerQty(e, idx, idxA);
                                    }}
                                  />
                                );
                              })}
                              <div className="d-block">
                                <button
                                  className="tableBtn tableBtn-green btnW-70 newoption-editbtn"
                                  onClick={() => AddNumberArray(idx)}
                                >
                                  <i className="fa fa-plus"></i> 추가
                                </button>
                                {
                                  //code.qty.length > 1 && (
                                  <button
                                    className="tableBtn tableBtn-red btnW-70 newoption-editbtn ms-0"
                                    onClick={() => DelNumberArray(idx)}
                                  >
                                    <i className="fa fa-minus"></i> 삭제
                                  </button>
                                  //)
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="newoption-wrap form-group">
                          <div className="row">
                            <div className="newoption-title cost-title col-md-2 col-sm-2 col-xs-12">
                              단일숫자형 데이터 (value)
                            </div>
                            <div className="newoption-cont cost-cont col-md-10 col-sm-10 col-xs-12">
                              <NumberFormat
                                type="text"
                                className="form-control halfinput"
                                name="value"
                                value={code.value ? code.value : 0}
                                thousandSeparator
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  ChangeInnerItem(e, idx);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </>
      )}
      {reOrder && (
        <div className="calcModalWindow">
          <CalcReorder
            newItem={newItem}
            setNewItem={setNewItem}
            reOrderColumns={reOrderColumns}
            setReOrderColumns={setReOrderColumns}
            reOrderIndex={reOrderIndex}
            setReOrder={setReOrder}
          />
        </div>
      )}
      {CalcModal.mega && (
        <MegaModalCalc
          closeCalcModal={closeCalcModal}
          newItem={newItem}
          setNewItem={setNewItem}
          megaData={megaData}
          setMegaData={setMegaData}
          modalIndex={modalIndex}
          megaNav={megaNav}
        />
      )}
      {CalcModal.custom && (
        <div className="calcModalWindow">
          <CalcCustom
            closeCalcModal={closeCalcModal}
            newItem={newItem}
            setNewItem={setNewItem}
            modalIndex={modalIndex}
          />
        </div>
      )}
    </>
  );
}
