import { Dispatch, SetStateAction, useState } from 'react';
import useFetch from '@utils/customHook/useFetch';
import {
  FirstInput,
  MegaProduct,
  ProductListType,
  LetCode,
} from '@interfaces/tableCost.interfaces';
import CodeSelectTable from '@components/table/standardproduct/code-select/code-select-table';
import { CatalogSaddleStitchFake, CatalogWirelessFake } from '@dummydata/dummy';
import { useLoading } from '@config/loadingContext';
interface Props {
  firstInputs: FirstInput;
  setFirstInputs: Dispatch<SetStateAction<FirstInput>>;
  handleChange: (name: string, value: string | boolean) => void;
  megaProduct: MegaProduct[];
  onlyProduct: boolean;
  changeOnlyProduct: (bool: boolean) => void;
  NewClass: LetCode;
}
export default function CodeWrapTable({
  firstInputs,
  setFirstInputs,
  handleChange,
  megaProduct,
  onlyProduct,
  changeOnlyProduct,
  NewClass,
}: Props) {
  const { loadingNow, loadingHandler } = useLoading();

  /**
   * 이미생성된 제품리스트 패치
   */
  const { payload, error } = useFetch<ProductListType>(`product`, loadingNow);
  /**
   * 이미생성된 상품을 firstInputs의 productNameEN로 비교하여 생성 여부 확인후 boolean return
   * @param list 사이트리스트 현재의 payload.data
   * @param en fistinput.productNameEN
   */
  const checkProductList = (list: ProductListType[], en: string) => {
    let booleanNow = list.filter((item) => item.productNameEN === en);
    return booleanNow.length > 0 ? false : true;
  };
  /**
   * 카테고리 mapping용 필터
   */
  const categoryName1st = megaProduct.filter(
    (thing, index, self) =>
      index === self.findIndex((t) => t.categoryCode === thing.categoryCode),
  );
  const categoryName2nd = megaProduct
    .filter(
      (b, i) => b.categoryText === firstInputs.category[0].categoryName1st,
    )
    .filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.printingKind === thing.printingKind),
    );
  const categoryName3rd = megaProduct.filter(
    (c) =>
      c.categoryText === firstInputs.category[0].categoryName1st &&
      c.printingKind === firstInputs.category[0].categoryName2nd,
  );
  /**
   * 메가모달에서 옵션들 선택후 state업데이트, productNameKO, productNameEN 자동입력
   * @constant firstInput
   */

  const chooseNames = (code: string) => {
    if (code !== '') {
      let chooseMatch = megaProduct.filter((d) => d.code === code)[0];
      if (chooseMatch.viewName === 'CatalogSaddleStitch') {
        setFirstInputs({ ...CatalogSaddleStitchFake });
      } else if (chooseMatch.viewName === 'CatalogWireless') {
        setFirstInputs({ ...CatalogWirelessFake });
      } else {
        setFirstInputs({
          ...firstInputs,
          category: [{ ...firstInputs.category[0], categoryName3rd: code }],
          productNameKO: chooseMatch.text,
          productNameEN: chooseMatch.viewName,
          categoryCode: chooseMatch.categoryCode,
        });
      }
      chooseMatch.viewName == undefined && changeOnlyProduct(false);
      payload &&
        changeOnlyProduct(checkProductList(payload.data, chooseMatch.viewName));
    } else {
      setFirstInputs({
        ...firstInputs,
        category: [{ ...firstInputs.category[0], categoryName3rd: '' }],
        productNameKO: '',
        productNameEN: '',
        categoryCode: '',
      });
    }
  };
  const makeEmpty = (value: string) => {
    if (value === '' || value === undefined) {
      setFirstInputs({
        ...firstInputs,
        category: [
          {
            ...firstInputs.category[0],
            categoryName2nd: '',
            categoryName3rd: '',
          },
        ],
      });
    }
  };
  const [ENchange, setENchange] = useState<boolean>(true);
  const editEN = () => {
    setENchange(!ENchange);
  };

  return (
    <>
      <form id="firstForm" className="form-horizontal form-label-left">
        <div className="form-group">
          <div className="row">
            <label className="cost-title col-md-2 col-sm-2 col-xs-12">
              상품카테고리
            </label>
            <div className="cost-cont cost-cont col-md-10 col-sm-10 col-xs-12">
              <div className="row">
                <div className="col-md-4">
                  <select
                    className="form-control"
                    onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => {
                      handleChange(ev.target.name, ev.target.value),
                        makeEmpty(ev.target.value),
                        changeOnlyProduct(false);
                    }}
                    name="categoryName1st"
                    value={
                      firstInputs && firstInputs.category[0].categoryName1st
                    }
                    required
                  >
                    <option value="empty">카테고리1 선택</option>
                    {categoryName1st.map((a, i) => {
                      return (
                        <option key={a.categoryCode} value={a.categoryText}>
                          {a.categoryText}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-4">
                  <select
                    className="form-control"
                    onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => {
                      handleChange(ev.target.name, ev.target.value),
                        changeOnlyProduct(false);
                    }}
                    name="categoryName2nd"
                    value={
                      firstInputs && firstInputs.category[0].categoryName2nd
                    }
                    required
                  >
                    <option>카테고리2 선택</option>
                    {categoryName2nd.map((b, i) => {
                      // i === 0 &&
                      //   handleChange('categoryName2nd', b.printingKind);
                      return (
                        <option key={b.printingKind} value={b.printingKind}>
                          {b.printingKind}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-4">
                  <select
                    className="form-control"
                    onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => {
                      chooseNames(ev.target.value);
                    }}
                    name="categoryName3rd"
                    value={
                      firstInputs && firstInputs.category[0].categoryName3rd
                    }
                  >
                    <option value="">카테고리3 선택</option>
                    {categoryName3rd.map((c, i) => {
                      return (
                        <option key={c.text} value={c.code}>
                          {c.text}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <label className="cost-title col-md-2 col-sm-2 col-xs-12">
              상품명
            </label>
            <div className="cost-cont cost-cont col-md-10 col-sm-10 col-xs-12">
              <input
                type="text"
                className="form-control"
                placeholder="상품명"
                name="productNameKO"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                value={(firstInputs && firstInputs.productNameKO) || ''}
                required
                //disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <label className="cost-title col-md-2 col-sm-2 col-xs-12">
              영문상품명(URL)
            </label>
            <div className="cost-cont cost-cont col-md-10 col-sm-10 col-xs-12">
              <div className="row">
                <div className="col-md-11 col-sm-11 col-xs-11">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="영문 상품명"
                    name="productNameEN"
                    onChange={(e) => {
                      !ENchange && handleChange(e.target.name, e.target.value);
                      payload &&
                        changeOnlyProduct(
                          checkProductList(payload.data, e.target.value),
                        );
                    }}
                    value={(firstInputs && firstInputs.productNameEN) || ''}
                    required
                    disabled={ENchange}
                  />
                </div>
                <div className="col-md-1 col-sm-1 col-xs-1">
                  <input
                    type="checkbox"
                    name="editProductEN"
                    id="editProductEN"
                    onClick={() => editEN()}
                  />
                  <label htmlFor="editProductEN">수정</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <label className="cost-title col-md-2 col-sm-2 col-xs-12">
              상품 주문옵션코드 선택
            </label>
            <div className="cost-cont col-md-10 col-sm-10 col-xs-12">
              <CodeSelectTable
                firstInputs={firstInputs}
                setFirstInputs={setFirstInputs}
                NewClass={NewClass}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
