import { useState, useEffect } from 'react';
import useFetch from '@utils/customHook/useFetch';
import moment from 'moment';
import axios from 'axios';
import { CONFIRM, MESSAGE, ERROR } from '@utils/textMessage';
import { SERVER_URL } from '@config/path';
import { ProductListType } from '@interfaces/tableCost.interfaces';
import Modal from '@components/modal/ModalComp';
import CalcCostModal from '@components/modal/calcCostModal';
import {
  ICommonEachCalculation,
  TableName,
} from '@interfaces/calculationProductModel.interface';
import { CalcDummy, EmptyTable } from '@dummydata/calcDummy';
import 'dayjs/locale/ko';
import { useLoading } from '@config/loadingContext';

interface Props {
  productName: string;
  productList: ProductListType[];
  calcProductId: string;
  changeModal: (type: string, bool: boolean) => void;
}
export default function EditModalCalc({
  productName,
  productList,
  calcProductId,
  changeModal,
}: Props) {
  moment.locale();
  /** 로딩 state*/
  const { loadingNow, loadingHandler } = useLoading();

  /** 계산정보 state*/
  /** 계산정보 get */
  const { payload, loading, error } = useFetch<ICommonEachCalculation>(
    `commonEachCalculation?productId=` + calcProductId,
    loadingNow,
  );
  const [calcList, setCalcList] = useState<ICommonEachCalculation[]>([]);
  useEffect(() => {
    if (payload) {
      let copy: ICommonEachCalculation[] = [...payload.data];
      setCalcList(copy);
    } else {
      console.log('payload yet!');
    }
  }, [payload]);

  /** 제품필터 state*/
  const [productFilter, setProductFilter] = useState<ProductListType[]>();
  useEffect(() => {
    loadingHandler(true);
    let copy: ProductListType[] = productList.filter(
      (code: ProductListType) =>
        code.typeOfPriceDetermintion == 'priceCalculation',
    );
    setProductFilter(copy);
    loadingHandler(false);
  }, []);

  const [newItem, setNewItem] = useState<ICommonEachCalculation>(
    JSON.parse(JSON.stringify(CalcDummy)),
  );
  const [modal, setModal] = useState<{ [index: string]: boolean }>({
    edit: false,
  });
  const openModal = (id: string, bool: boolean) => {
    setModal({ ...modal, [id]: bool });
  };
  const closeModal = (target: string) => {
    loadingHandler(true);
    setNewItem(JSON.parse(JSON.stringify(CalcDummy)));
    setNewTable([JSON.parse(JSON.stringify(EmptyTable))]);
    setModal({ ...modal, [target]: false });
    loadingHandler(false);
  };

  /**
   * 기준 상품 수정하는 함수
   * @param productNameEN
   */
  const EditEvents = async (id: string) => {
    loadingHandler(true);
    await axios
      .get(`${SERVER_URL}/commonEachCalculation/${id}`)
      .then((res) => {
        setNewItem(res.data.data);
        openModal('edit', true);
      })
      .catch((error) => window.alert(`${ERROR.SERVER} ${error}`));
    loadingHandler(false);
  };
  /**
   * 기준 상품 삭제하는 함수
   * @param row: db id 값
   */
  const DelEvents = async (id: string) => {
    if (window.confirm(CONFIRM.DEL)) {
      loadingHandler(true);
      await axios
        .delete(`${SERVER_URL}/commonEachCalculation/${id}`)
        .then((res) => {
          res.status === 200 || res.status === 204
            ? window.alert(MESSAGE.DELDONE)
            : window.alert(MESSAGE.CONFIRMAUTH);
        })
        .catch((error) => window.alert(`${ERROR.SERVER} ${error}`));
      loadingHandler(false);
    }
  };
  const [newTable, setNewTable] = useState<TableName[]>([
    JSON.parse(JSON.stringify(EmptyTable)),
  ]);
  /**테이블 Sort State */
  const [sortNow, setSortNow] = useState<{ [key: string]: boolean }>({
    type: false,
    title: false,
  });
  /**테이블 Sort Action */
  const sortBy = (key: string) => {
    let copy = [...calcList];
    copy.sort((a, b) =>
      !sortNow[key]
        ? String(a[key]) < String(b[key])
          ? -1
          : 1
        : String(a[key]) > String(b[key])
        ? -1
        : 1,
    );
    setSortNow({ ...sortNow, [key]: !sortNow[key] });
    setCalcList(copy);
  };
  return (
    <>
      <div className="fixedContainer bg-f7f7f7">
        <div className="page-title">
          <div className="title_left">
            <h3>
              <i className="fa fa-star" />
              {productName} - 가격계산형 정보 <small>기준 상품</small>
              <div className="pull-right">
                <button
                  className="goBackBtn"
                  onClick={() => changeModal('calc', false)}
                >
                  이전으로
                </button>
              </div>
            </h3>
          </div>
        </div>
        <div className="clearfix" />
        <div className="row">
          <div className="col-12">
            <div className="clearfix"></div>
            <div className="contents-wrap x_panel">
              <div className="x_content">
                {calcList && (
                  <div className="react-bootstrap-table">
                    <table className="table table-hover table-bordered GW-cont-head">
                      <thead>
                        <tr>
                          <th
                            className="sortable cont-w150"
                            onClick={() => {
                              sortBy('type');
                            }}
                          >
                            정보종류 <span className="order-4"></span>
                          </th>
                          <th
                            className="sortable"
                            onClick={() => {
                              sortBy('title');
                            }}
                          >
                            정보명 <span className="order-4"></span>
                          </th>
                          <th className="cont-w150">생성/수정</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {calcList.map((code, idx) => {
                          let time = moment(code.createdAt).format(
                            'YYYY-MM-DD (HH:mm)',
                          );
                          let time2 = moment(code.modifiedAt).format(
                            'YYYY-MM-DD (HH:mm)',
                          );
                          return (
                            <tr key={idx}>
                              <td>
                                {' '}
                                <p className={'calcTypeLabel ' + code.type}>
                                  {code.type == 'single'
                                    ? '개별정보'
                                    : '공용정보'}
                                </p>
                              </td>
                              <td>{code.title} </td>
                              <td>
                                <div className="editDate-wrap">
                                  <div className="editDate-btn">보기</div>
                                  <div className="editDate-view">
                                    <div className="editDate-line">
                                      <h5>생성:</h5>
                                      <p className="standardproductID">
                                        {code.createdBy}
                                      </p>
                                      <p className="standardproductTIME">
                                        {time}
                                      </p>
                                    </div>
                                    <div className="editDate-line">
                                      <h5>수정:</h5>
                                      <p className="standardproductID">
                                        {code.modifiedBy}
                                      </p>
                                      <p className="standardproductTIME">
                                        {time2}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <button className="tableBtn tableBtn-gray btnW-70">
                                  로그보기
                                </button>
                                <button
                                  className="tableBtn tableBtn-orange btnW-70"
                                  onClick={() =>
                                    code._id && EditEvents(code._id)
                                  }
                                >
                                  수정
                                </button>
                                <button
                                  className="tableBtn tableBtn-red btnW-70"
                                  onClick={() =>
                                    code._id && DelEvents(code._id)
                                  }
                                >
                                  삭제
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {modal.edit && (
        <>
          <>
            <Modal close={closeModal} title={'가격계산형 정보수정'} id={'edit'}>
              <CalcCostModal
                calcType="edit"
                newItem={newItem}
                setNewItem={setNewItem}
                closeModal={closeModal}
                productFilter={productFilter}
                newTable={newTable}
                setNewTable={setNewTable}
              />
            </Modal>
          </>
        </>
      )}
    </>
  );
}
