import { useState, useEffect } from 'react';
import axios from 'axios';
import { useLoading } from '@config/loadingContext';
import { boardType } from '@interfaces/tableCost.interfaces';
import useFetchNoData from '@utils/customHook/useFetchNoData';
import { NEST_SERVER_URL } from '@config/path';

import ModalEmpty from '@components/modal/ModalEmpty';

interface IProps {
  modalId: string | undefined;
  imgsrc: string | undefined;
  closeImgModal: () => void;
}

export default function BardImgModal({
  modalId,
  imgsrc,
  closeImgModal,
}: IProps) {
  const [boardInputs, setBoardInputs] = useState<boardType>();
  const answerChange = (
    e: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value } = e.target;
    boardInputs && setBoardInputs({ ...boardInputs, [name]: value });
  };

  return (
    <>
      <div className="imgmodal_wrap">이미지 미리보기</div>
      <div className="imgmodal">
        <img src={imgsrc} alt="" />
      </div>
    </>
  );
}
