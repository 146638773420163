import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import axios from 'axios';
import useFetch from '@utils/customHook/useFetch';
import { SERVER_URL } from '@config/path';
import { CateType, CateItemType } from '@interfaces/site.interface';
import { ProductListType } from '@interfaces/tableCost.interfaces';
import { Site } from '@interfaces/site.interface';
import { MESSAGE } from '@utils/textMessage';
import { useLoading } from '@config/loadingContext';
import CategoryModalProducts from '@components/modal/category/category-modal-products';
interface Props {
  modalType: string;
  modalIndex: number;
  selectedSite: Site;
  allCate: CateType[];
  setAllCate: Dispatch<SetStateAction<CateType[]>>;
  changeModal: (type: string, bool: boolean) => void;
}

export default function CategoryModalNew({
  modalType,
  modalIndex,
  selectedSite,
  allCate,
  setAllCate,
  changeModal,
}: Props) {
  const { loadingNow, loadingHandler } = useLoading();
  const { payload, loading, error } = useFetch<ProductListType>(
    `productbysite?target=${selectedSite.sellerNameEN}`,
    loadingNow,
  );
  const [productList, setProductList] = useState<ProductListType[]>([]);
  useEffect(() => {
    if (payload) {
      let copy = [...payload.data];
      setProductList(copy);
    } else {
      console.log('payload yet!');
    }
  }, [payload]);
  const [newCate, setNewCate] = useState<CateType>(
    modalType == 'new'
      ? {
          cateName: '',
          subCateCode: '',
          subCateName: '',
          subCateType: '',
          subCateBool: false,
          cateItems: [],
        }
      : allCate[modalIndex],
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let copy = { ...newCate };
    copy[name] = value;
    setNewCate(copy);
  };

  const [productsModal, setProductsModal] = useState<boolean>(false);

  const changeProductsModal = (bool: boolean) => {
    setProductsModal(bool);
  };
  const complete = () => {
    let copy = [...allCate];
    let nameList = copy.map((code) => {
      return code.cateName;
    });
    if (modalType == 'new' && nameList.includes(newCate.cateName)) {
      let findIdx = allCate.findIndex(
        (code) => code.cateName == newCate.cateName,
      );
      let filterSame = allCate.filter(
        (code) => code.cateName == newCate.cateName,
      );
      let combine = [
        ...filterSame,
        { ...newCate, subCateBool: newCate.subCateName !== '' },
      ];
      copy.splice(findIdx, filterSame.length, ...combine);
    } else {
      copy[modalIndex] = {
        ...newCate,
        subCateBool: newCate.subCateName !== '',
      };
    }
    let result = copy.map((code, idx) => {
      return {
        ...code,
        _id: 'tempId' + String(idx),
        cateItems: code.cateItems.map((codeA, idxA) => {
          return { ...codeA, itemPriority: idxA };
        }),
      };
    });
    copy && setAllCate(result);
    changeModal(modalType, false);
  };
  return (
    <>
      <div
        className="modalY-overlay"
        onClick={() => changeModal(modalType, false)}
      ></div>
      <div id="standardproduct-window" className="preview-window preview-800">
        <div
          className="closeBtn"
          onClick={() => {
            changeModal(modalType, false);
          }}
        >
          <i className="fa fa-times"></i>
        </div>

        {newCate && productList && (
          <>
            <div className="bg-f7f7f7 p-5">
              <div className="contents-wrap x_panel">
                <div className="x_title">
                  <h4>
                    {'카테고리 ' +
                      (modalType == 'new' ? '추가' : '수정') +
                      '하기'}
                    {/* <span>
                      <i>{siteName}</i>
                    </span>{' '}
                    - {firstInputs.productNameKO} 옵션 변경 */}
                  </h4>
                </div>
                <div className="clearfix"></div>
                <div className="x_content">
                  <form
                    id="newcateForm"
                    className="form-horizontal form-label-left"
                  >
                    <div className="form-group">
                      <div className="row">
                        <label className="cost-title col-md-3 col-xs-12">
                          대카테고리
                        </label>
                        <div className="cost-cont cost-cont col-md-9 col-xs-12">
                          <input
                            type="text"
                            className="form-control newcateWidth"
                            placeholder="대카테고리"
                            name="cateName"
                            value={newCate.cateName}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => handleChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <label className="cost-title col-md-3 col-xs-12">
                          중카테고리
                        </label>
                        <div className="cost-cont cost-cont col-md-9 col-xs-12">
                          <input
                            type="text"
                            className="form-control newcateWidth"
                            placeholder={
                              modalType == 'new' ? '중카테고리' : '미설정'
                            }
                            name="subCateName"
                            value={newCate.subCateName}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => handleChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <label className="cost-title col-md-3 col-xs-12">
                          노출상품
                        </label>
                        <div className="cost-cont cost-cont col-md-9 col-xs-12">
                          <div
                            className="form-control text-start megaModalBtn"
                            onClick={() => changeProductsModal(true)}
                            name="types"
                          >
                            {newCate.cateItems.length < 1
                              ? '빈공간을 클릭하면 주문옵션 코드선택 창이 나옵니다.'
                              : newCate.cateItems.map((code, idx) => {
                                  return (
                                    <span key={code.productId}>
                                      {(idx == 0 ? '' : ', ') + code.title}
                                    </span>
                                  );
                                })}
                          </div>
                          {productsModal && (
                            <CategoryModalProducts
                              newCate={newCate}
                              setNewCate={setNewCate}
                              productList={productList}
                              changeProductsModal={changeProductsModal}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="row pt-2">
                <div className="col-sm-12  text-center complete-btn-wrap mt-4 mb-2">
                  <div
                    className="price-start-btn btn-inline-block"
                    data-toggle="tooltip"
                    onClick={() => {
                      complete();
                    }}
                  >
                    <span>
                      <i className="fa fa-check" aria-hidden="true"></i> 완료
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
