import { useState, useEffect } from 'react';
import axios from 'axios';
import { useLoading } from '@config/loadingContext';
import { estimateType } from '@interfaces/tableCost.interfaces';
import useFetchNoData from '@utils/customHook/useFetchNoData';
import { NEST_SERVER_URL } from '@config/path';

import ModalEmpty from '@components/modal/ModalEmpty';
import BardImgModal from '@components/modal/boardImgModal';

interface IProps {
  modalId: string | undefined;
  closeModal: () => void;
}

export default function EstimateReqAnswerModal({
  modalId,
  closeModal,
}: IProps) {
  const { payload } = useFetchNoData<estimateType>(
    `estimate-req/${modalId}`,
    modalId,
  );
  useEffect(() => {
    if (payload) {
      setEstimateInputs(payload);
    }
  }, [payload]);

  const [estimateInputs, setEstimateInputs] = useState<estimateType>();

  const answerChange = (
    e: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value } = e.target;
    estimateInputs && setEstimateInputs({ ...estimateInputs, [name]: value });
  };

  /* 답변 등록*/

  const { loadingNow, loadingHandler } = useLoading();

  const answerNow = async (id: string) => {
    loadingHandler(true);
    if (estimateInputs) {
      let answerCopy = {
        estimateMemo: estimateInputs.estimateMemo,
        estimateType: estimateInputs.estimateType,
      };
      await axios
        .put(NEST_SERVER_URL + `/estimate-req/${id}`, answerCopy)
        .then(function (res) {
          loadingHandler(false);
          alert('답변등록 완료');
          closeModal();
        })
        .catch(function (error) {
          console.log('답변 등록 실패');
          loadingHandler(false);
        });
    } else {
      alert('답변 등록 실패');
    }
  };

  // const answerNow = async (id: string) => {
  //   loadingHandler(true);
  //   if (estimateInputs) {
  //     let answerCopy = {
  //       boardAnswer: estimateInputs.estimateMemo,
  //       actionType: estimateInputs.estimateType,
  //     };

  //     try {
  //       const response = await axios.put(
  //         `${NEST_SERVER_URL}/estimate-req/${id}`,
  //         answerCopy,
  //       );

  //       if (response) {
  //         loadingHandler(false);
  //         alert('답변등록 완료');
  //         closeModal();
  //       }
  //     } catch (e) {
  //       console.log(e, '답변 등록 실패');
  //       loadingHandler(false);
  //       alert('답변 등록 실패');
  //     }
  //   }
  // };

  //이미지 클릭시 이미지 모달

  const [imgModal, setImgModal] = useState<boolean>(false);
  const [imgscr, setImgscr] = useState<string>('');

  const imgModalopen = async (scr: string) => {
    setImgModal(true);
    setImgscr(scr);
  };

  const closeImgModal = () => {
    setImgModal(false);
  };

  return (
    <>
      {estimateInputs && (
        <div className="boardwrite_wrap">
          <table className="boardwrite_table">
            <tbody>
              <tr>
                <th>의뢰자 성명</th>
                <td>
                  <input
                    type="text"
                    name="userName"
                    id="userName"
                    value={
                      estimateInputs.userName ? estimateInputs.userName : ''
                    }
                    disabled={true}
                    readOnly
                  />
                </td>
              </tr>
              <tr className="boardordernumber">
                <th>회원아이디</th>
                <td>
                  <input
                    type="text"
                    id="userId"
                    name="userId"
                    value={estimateInputs.userId ? estimateInputs.userId : ''}
                    disabled={true}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>회사명</th>
                <td>
                  <input
                    type="text"
                    name="companyName"
                    id="companyName"
                    value={
                      estimateInputs.companyName
                        ? estimateInputs.companyName
                        : ''
                    }
                    disabled={true}
                    readOnly
                  />
                </td>
              </tr>
              <tr className="boardnumber">
                <th>휴대폰번호</th>
                <td>
                  <input
                    type="text"
                    id="phoneAreaCode"
                    name="phoneAreaCode"
                    value={
                      estimateInputs.phoneAreaCode &&
                      estimateInputs.phoneAreaCode
                    }
                    disabled={true}
                    readOnly
                  />
                  <span>-</span>
                  <input
                    type="text"
                    id="phonePrefix"
                    name="phonePrefix"
                    value={
                      estimateInputs.phonePrefix && estimateInputs.phonePrefix
                    }
                    disabled={true}
                    readOnly
                  />
                  <span>-</span>
                  <input
                    type="text"
                    id="phoneSuffix"
                    maxLength={4}
                    name="phoneSuffix"
                    value={
                      estimateInputs.phoneSuffix && estimateInputs.phoneSuffix
                    }
                    disabled={true}
                    readOnly
                  />
                </td>
              </tr>
              <tr className="boardnumber">
                <th>전화번호</th>
                <td>
                  <input
                    type="text"
                    id="areaCode"
                    name="areaCode"
                    value={estimateInputs.areaCode && estimateInputs.areaCode}
                    disabled={true}
                    readOnly
                  />
                  <span>-</span>
                  <input
                    type="text"
                    id="preFix"
                    name="preFix"
                    value={estimateInputs.preFix && estimateInputs.preFix}
                    disabled={true}
                    readOnly
                  />
                  <span>-</span>
                  <input
                    type="text"
                    id="sufFix"
                    name="sufFix"
                    value={estimateInputs.sufFix && estimateInputs.sufFix}
                    disabled={true}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>이메일</th>
                <td>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    value={estimateInputs.email ? estimateInputs.email : ''}
                    disabled={true}
                    readOnly
                  />
                </td>
              </tr>

              <tr>
                <th>상세내용</th>
                <td>
                  <textarea
                    name="estimateReqDetail"
                    id="estimateReqDetail"
                    value={
                      estimateInputs.estimateReqDetail
                        ? estimateInputs.estimateReqDetail
                        : ''
                    }
                    disabled={true}
                    readOnly
                  ></textarea>
                </td>
              </tr>
              <tr className="boardupimg">
                <th>첨부한 파일 </th>
                <td>
                  {estimateInputs.estimateReqFile.fileName === '' ? (
                    <span className="noImg">첨부한 이미지 없음</span>
                  ) : (
                    <div>
                      <img
                        src={
                          estimateInputs.estimateReqFile &&
                          estimateInputs.estimateReqFile.url
                        }
                        alt="클레임 업로드 이미지1"
                        onClick={() =>
                          imgModalopen(estimateInputs.estimateReqFile.url)
                        }
                      />
                      <span>
                        {estimateInputs.estimateReqFile &&
                          estimateInputs.estimateReqFile.fileName}
                      </span>
                    </div>
                  )}
                </td>
              </tr>
              <tr>
                <th>상태변경</th>
                <td>
                  <select
                    name="estimateType"
                    id="estimateType"
                    value={
                      estimateInputs.estimateType
                        ? estimateInputs.estimateType
                        : ''
                    }
                    onChange={(e) => answerChange(e)}
                  >
                    <option value="">내용을 선택해 주세요</option>
                    <option value="내용 확인">내용 확인</option>
                    <option value="이메일 답변 발송">이메일 답변 발송</option>
                    <option value="전화후 처리">전화후 처리</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>담당자 답변</th>
                <td>
                  <textarea
                    name="estimateMemo"
                    id="estimateMemo"
                    onChange={(e) => answerChange(e)}
                    value={
                      estimateInputs.estimateMemo
                        ? estimateInputs.estimateMemo
                        : ''
                    }
                  ></textarea>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="boardedit_btn">
            {payload && payload.estimateMemo === '' ? (
              <div onClick={() => answerNow(estimateInputs._id)}>답변등록</div>
            ) : (
              <div
                onClick={() => answerNow(estimateInputs._id)}
                className="pink"
              >
                답변수정
              </div>
            )}

            <div onClick={() => closeModal()}>취소하기</div>
          </div>
        </div>
      )}
      {imgModal && (
        <ModalEmpty close={closeImgModal} id={'boardimgmodal'}>
          <BardImgModal
            modalId={modalId}
            imgsrc={imgscr}
            closeImgModal={closeImgModal}
          />
        </ModalEmpty>
      )}
    </>
  );
}
