import { onLogin } from '@utils/login.utils';
import { UserLoginPayload } from '@interfaces/globals.types';
import { Link } from 'react-router-dom';
import useInput from '@utils/customHook/value.customhook';
import { RouteComponentProps } from 'react-router-dom';
import { useAuthDispatch } from '@config/reducer.index';
interface Props {
  history: RouteComponentProps['history'];
  loginhandler: (login: boolean) => void;
}
export default function login({ history, loginhandler }: Props) {
  const dispatch = useAuthDispatch();
  const [{ id, pwd }, onChange, onReset] = useInput<UserLoginPayload>({
    id: '',
    pwd: '',
  });
  const payload = { id, pwd };
  const enterNow = (code: string) => {
    if (code === 'Enter' || code === 'NumpadEnter') {
      onLogin(dispatch, payload, history, loginhandler);
    }
  };

  return (
    <>
      <div className="login">
        <div className="login_wrapper">
          <div className="animate form login_form">
            <section className="login_content">
              <div className="logo-block">
                <img
                  className="md-logo center-margin"
                  src="/pr_logo.png"
                  alt="로고이미지"
                />
              </div>
              <form>
                <h1>상품관리자</h1>
                <div>
                  <input
                    type="text"
                    name="id"
                    className="form-control"
                    placeholder="아이디"
                    value={id}
                    onChange={onChange}
                  />
                </div>
                <div>
                  <input
                    type="password"
                    name="pwd"
                    className="form-control"
                    placeholder="비밀번호"
                    value={pwd}
                    onKeyDown={(event: React.KeyboardEvent<HTMLElement>) => {
                      return enterNow(event.code);
                    }}
                    onChange={onChange}
                  />
                </div>
              </form>
              <div>
                <button
                  value="로그인"
                  className="btn btn-default submit logInBtn"
                  type="submit"
                  onClick={() =>
                    onLogin(dispatch, payload, history, loginhandler)
                  }
                >
                  로그인
                </button>
              </div>

              <div className="clearfix"></div>

              <div className="separator">
                <div className="change_link">
                  <Link to="/" className="to_register">
                    화원가입
                  </Link>
                  <Link to="/" className="reset_pass">
                    비밀번호 찾기
                  </Link>
                </div>

                <div className="clearfix"></div>
                {/* <br />

                                    <div className="login-bottom">
                                        <h5 className="center-margin">IAMDESIGN</h5>
                                        <p>©2021 All Rights Reserved.</p>
                                    </div> */}
              </div>
            </section>
          </div>

          <div id="register" className="animate form registration_form">
            <section className="login_content">
              <form>
                <h1>Create Account</h1>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Username"
                  />
                </div>
                <div>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                  />
                </div>
                <div>
                  <input
                    type="current-password"
                    className="form-control"
                    placeholder="Password"
                  />
                </div>
                <div>
                  <Link className="btn btn-default submit" to="/">
                    Submit
                  </Link>
                </div>

                <div className="clearfix"></div>

                <div className="separator">
                  <p className="change_link">
                    Already a member ?
                    <Link to="/home" className="to_register">
                      Log in
                    </Link>
                  </p>

                  <div className="clearfix"></div>
                  {/* <br />

                                    <div className="login-bottom">
                                        <h5 className="center-margin">IAMDESIGN</h5>
                                        <p>©2021 All Rights Reserved.</p>
                                    </div> */}
                </div>
              </form>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
